import { render, staticRenderFns } from "./TextField.vue?vue&type=template&id=204736b2&scoped=true"
import script from "./TextField.vue?vue&type=script&lang=ts"
export * from "./TextField.vue?vue&type=script&lang=ts"
import style0 from "./TextField.vue?vue&type=style&index=0&id=204736b2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "204736b2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VForm,VTextField,VTextarea,VTooltip})
