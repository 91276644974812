<template>
<svg width="21px" height="22px" viewBox="0 0 21 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="UI-Kit---NEW" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" font-family="AppleColorEmoji, Apple Color Emoji" font-size="13" font-weight="normal">
        <g id="Icons" transform="translate(-132.000000, -650.000000)" fill="#666666">
            <g id="Group-11" transform="translate(132.000000, 650.000000)">
                <text id="💬">
                    <tspan x="0" y="17">💬</tspan>
                </text>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
    export default {};
</script>
