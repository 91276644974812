import { ValidationResult, MessageSeverity, SizingState } from 'types/dto/CalcServiceDomain';

export class GroupValidationResult {
	private readonly msgs: ValidationResult[];

	constructor(msgs: ValidationResult[]) {
		this.msgs = msgs;
	}

	public get State(): SizingState | 'Info' {
		if (this.HasErrors)
			return SizingState.Error;
		else if (this.HasWarnings)
			return SizingState.Warning;
		else if (this.HasInfos)
			return 'Info';
		return SizingState.Completed;
	}

	public get Messages() { return this.msgs ?? []; }
	public get HasErrors() { return this.msgs?.some(x => x.Severity >= MessageSeverity.Error) || false; }
	public get HasWarnings() { return this.msgs?.some(x => x.Severity === MessageSeverity.Warning) || false; }
	public get HasInfos() { return this.msgs?.some(x => x.Severity === MessageSeverity.Info) || false; }
	public get Visible(): boolean { return this.HasErrors || this.HasWarnings || this.HasInfos; }
}
