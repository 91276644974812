<template>
	<v-dialog :value="visible" @input="$emit('input')" persistent max-width="600px" @keydown.esc="resetAndCloseForm">
		<v-form ref="newSizingForm" @submit.prevent="createSizing" v-model="valid">
			<v-card class="pa-4">
				<v-card-title>
					<span v-if="sizingClone"><h2>Copy sizing</h2></span>
					<span v-else><h2>New sizing</h2></span>
				</v-card-title>
				<v-card-text>
					<v-row v-if="sizingClone && targetProjects">
						<v-col sm="12">
							<v-combobox outlined filled label="Target project" v-model="targetProject"
								:items="targetProjects" return-object item-text="Name" item-value="id" />
						</v-col>
					</v-row>
					<v-row>
						<v-col sm="12">
							<v-text-field  outlined v-model="sizingName" label="Product tag*" :rules="[rules.required]" autofocus
								@keydown.enter="createSizing" maxlength="200" />
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions class="pr-4">
					<v-spacer></v-spacer>
					<v-btn color="error" text @click="closeDialog">Cancel</v-btn>
					<v-btn text @click="createSizing" :disabled="!valid" :class="[valid ? 'focus':'']">Create</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { PumpDocument, PumpProject } from 'types/dto/CalcServiceDomain';
	import router from '@/router';
	import store, { SnackActions, SizingActions, ProjectGetters, ProjectActions } from '@/store';
	import SizingInfo from '@/common/SizingInfo';
	
	const cloneProjectName = 'Cloned sizings';

	@Component
	export default class NewSizingDialog extends Vue {
		@Prop() public sizingClone: PumpDocument;
		@Prop() public project: PumpProject;
		@Prop() public newSizingName: string;

		public visible: boolean = true;
		public valid: boolean = true;
		public targetProject: PumpProject = null;
		private manualSizingName: string = null;

		public get sizingName() { return this.manualSizingName === null ? this.newSizingName : this.manualSizingName; }
		public set sizingName(val: string) { this.manualSizingName = val; }

		public rules = { required: (value: any) => !!value || 'Required.' };

		public get targetProjects() {
			const projs = (store.get(ProjectGetters.projects) as PumpProject[]).filter(x => !SizingInfo.isLocked(x) && !x.QuoteId);
			if (this.targetProject && !projs.some(x => x.id === this.targetProject.id))
				return [this.targetProject].concat(projs);
			return projs;
		}

		public get defaultProject() {
			if (SizingInfo.isLocked(this.project) || this.project.QuoteId)
				return this.targetProjects?.find(x => x.Name === cloneProjectName) ?? { Name: cloneProjectName } as any as PumpProject;
			return this.project;
		}

		public created() {
			store.dispatch(ProjectActions.getProjects).then(() => this.targetProject = this.defaultProject);
		}

		public closeDialog() {
			this.resetAndCloseForm();
		}

		public createSizing() {
			if (!this.valid)
				return;
			if (this.sizingClone)
				this.copySizing();
			else
				this.newSizing();
		}

		private async copySizing() {
			const name = this.sizingName;
			let targetProject = this.targetProject;
			if (!name || !this.valid || !targetProject)
				return;

			if (!targetProject.id && targetProject.Name === cloneProjectName) {
				store.dispatch(SnackActions.set, 'Creating new project "' + cloneProjectName + '"...');
				targetProject = await store.dispatch(ProjectActions.create, { name: cloneProjectName, ref: '---' }) as PumpProject;
			}

			store.dispatch(SnackActions.set, `Copying sizing ${name}`);
			store.dispatch(SizingActions.clone, { clone: this.sizingClone, name, targetProjectId: targetProject.id }).then((clone: PumpDocument) => {
				router.push({
					name: 'sizing', params: {
						id: clone.ProjectId,
						sizingid: clone.id
					}
				});
			});
			this.closeDialog();
		}

		private newSizing() {
			const name = this.sizingName;
			if (!name || !this.valid)
				return;

			store.dispatch(SnackActions.set, `Creating sizing ${name}`);
			store.dispatch(SizingActions.create, { name, ProjectId: this.project.id }).then((sizing: PumpDocument[]) => {
				if (!sizing || !sizing.length)
					return;

				router.push({
					name: 'sizing', params: {
						id: sizing[0].ProjectId,
						sizingid: sizing[0].id
					}
				});
			});
			this.closeDialog();
		}

		public resetAndCloseForm() {
			const formEl = this.$refs.newSizingForm as HTMLFormElement;
			formEl.resetValidation();
			this.sizingName = null;
			this.$emit('close-dialog', 'sizing');
		}
	}
</script>