import axios from 'axios';
import store, { SnackActions, NetworkGetters, NetworkMuts } from '@/store';

const checkOnlineUrl = process.env.VUE_APP_CALCSERVICE_ENDPOINT + '/health';

export default class OnlineCheck {
	private onlineCheckTimer: any;

	/**
	 * Check if the AJAX response points to being offline or not. Update connectivity accordingly.
	 * Arm a timer if offline that updates connectivity when network is reachable again.
	 *
	 * @returns true if offline was detected
	 */
	public handleOfflineStatus(r: any) {
		if (this.isOfflineResponse(r)) {
			this.goOffline();
			return true;
		}
		this.goOnline();
		return false;
	}

	public goOnline() {
		if (this.onlineCheckTimer) {
			clearInterval(this.onlineCheckTimer);
			this.onlineCheckTimer = null;
		}

		if (!store.get(NetworkGetters.connected)) {
			store.dispatch(SnackActions.set, 'Back online again');
			store.commit(NetworkMuts.connected);
		}
	}

	public goOffline() {
		if (!store.get(NetworkGetters.connected))
			return;

		store.dispatch(SnackActions.set, 'Network unavailable - going offline');
		store.commit(NetworkMuts.disconnect);
		if (!this.onlineCheckTimer)
			this.onlineCheckTimer = setInterval(this.checkOnline.bind(this), 10000);
	}

	public isOfflineResponse(r: any) {
		const hasResponse = !!(r?.response);
		const status = r?.request?.status;
		const message = r?.message;
		const offline = !hasResponse && (message === 'Network error' || status === 0);

		// Leaving this here since there will most probably be more problems with offline...
		// console.debug(`OnlineCheck: hasResponse: ${hasResponse}, message: ${message}, status: ${status} => offline: ${offline}`);
		return offline;
	}

	public checkOnline(): Promise<any> {
		return axios.get(checkOnlineUrl)
			.then(r => this.handleOfflineStatus(r))
			.catch(r => this.handleOfflineStatus(r));
	}
}
