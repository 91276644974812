<template>
	<div style="float: right">
		<v-btn x-small @click="$emit('change', 1)"><b>+</b></v-btn>
		<br />
		<v-btn x-small @click="$emit('change', -1)"><b>-</b></v-btn>
	</div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class PlusMinus extends Vue {
}
</script>
