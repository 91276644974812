interface ISnackbarState {
	snack: string;
}

const state: ISnackbarState = {
	snack: ''
};

const getters = {};

const actions = {
	setSnack({ commit }: any, snack: string) {
		commit('setSnack', snack);
	}
};

const mutations = {
	setSnack(targetState: ISnackbarState, snack: string) {
		targetState.snack = snack;
	}
};

export const enum SnackActions {
	set = 'snackbar/setSnack'
}

export const snackbar = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
