<template>
<!-- header -->
	<div class="page" v-bind:class="[ hiddenSidebar ? 'small': '' ]">
		<v-row class="header">
			<!-- Top row -->
			<v-col class="title" cols="12" md="4">
				<!-- Breadcrumbs -->
				<ul class="breadcrumbs">
					<li>
						<router-link to="/" class="allcaps">{{ $t('app.dashboard') }}</router-link>
					</li>
					<li>
						<!-- Project name -->
						<v-tooltip bottom open-delay="500" >
							<template v-slot:activator="{ on }">
								<span>
									<v-icon v-if="shared" v-on="on" small class="ma-0">$share</v-icon>
									{{ title }}
									<a v-if="project && project.QuoteId" :href="quoteUrl">							
										<span class="black pqp-link">PQP <v-icon class="icon" color="white" size="10">$launch</v-icon></span>	
									</a>
								</span>
							</template>
							<span v-if="shared">
								Shared with multiple editors
							</span>
						</v-tooltip>
					</li>
				</ul>

				<!-- Sizing name -->
				<v-tooltip bottom :disabled="!canEditSizingTitle" activator="#_titleNameBox">
					Click to edit sizing name
				</v-tooltip>
				<h2>
					<input id="_titleNameBox" type="text" :disabled="!canEditSizingTitle" v-model.lazy.trim="sizingName" class="sizing-title" :class="fontStyle" />
				</h2>

				<!-- Sizing subtitle and duty point stepper -->
				<div class="duty-point-stepper">
					<div>
						<v-tooltip bottom v-if="showSubtitleHeader" :disabled="!canEditSizingSubtitle" activator="#_subtNameBox">
							Click to edit duty point name
						</v-tooltip>
						<input id="_subtNameBox" type="text" :disabled="!canEditSizingSubtitle" v-model.lazy.trim="subtitleHeader" class="sizing-subtitle" />
						<div v-if="showSubtitleHeader" class="float-right">
							<v-btn text @click="switchDp(-1)" x-small icon title="Previous stage/duty point (Alt+Shift+🠜)">
								<v-icon>chevron_left</v-icon>
							</v-btn>
							<v-btn text @click="switchDp(1)" x-small icon title="Next stage/duty point (Alt+Shift+🠞)">
								<v-icon>chevron_right</v-icon>
							</v-btn>
						</div>
					</div>
				</div>
			</v-col>

			<!-- Top sizing stepper -->
			<v-col class="center-flex pl-0" cols="12" md="8" :class="[!hiddenSidebar ? 'tabcol-right' : 'tabcol']">
				<SizingStepper :sizingId="sizingid" :state="currentSizing ? aggregatedState(currentSizing) : null" v-model="step" />
			</v-col>
		</v-row>

		<aside class="border">
			<!-- left menu expand/collapse -->
			<div class="justify-end sticky-expand-sidebar" :class="{ 'd-none': !hiddenSidebar }">
				<v-btn text @click="toggleSidebar" class="inline ml-1">
					<v-icon>chevron_right</v-icon>
				</v-btn>
			</div>

			<!-- sidebar top mode selector/actions -->
			<v-list v-bind:class="{ 'd-none': hiddenSidebar, 'aside-top-actions': true }" class="flat tile">
				<v-list-item class="pa-0 ma-0">
					<v-list-item-content class="d-block pa-0 ma-0">
						<v-btn text  @click="showSizings = true" class="inline"
							v-bind:class="showSizings ? 'activeState' : 'inActiveState'">Sizings</v-btn>
						<v-btn text @click="showSizings = false" :disabled="!currentSizing" class="inline"
							v-bind:class="!showSizings ? 'activeState' : 'inActiveState'">Parameters
						</v-btn>
						<div class="inline aside-top-buttons">
							<v-tooltip v-if="showReload" bottom max-width="500" open-delay="500">
								<template v-slot:activator="{ on }">
									<v-btn text v-on="on" @click="reloadProject" color="primary" class="inline">
										<v-icon size="18">$update</v-icon>
									</v-btn>
								</template>
								<span>Look for changes</span>
							</v-tooltip>
							<ParamFilter v-if="!showSizings && currentSizingValues" @change="paramFilter = $event" />
							<v-tooltip bottom max-width="500" open-delay="500">
								<template v-slot:activator="{ on }">
									<v-btn text v-on="on" @click="newSizing" color="primary" :disabled="offline || locked(project) || !!(project && project.QuoteId)" class="inline">
										<v-icon>$plus</v-icon>
									</v-btn>
								</template>
								<span>Create new sizing</span>
							</v-tooltip>
							<v-btn text @click="toggleSidebar" class="inline" >
								<v-icon>chevron_left</v-icon>
							</v-btn>
						</div>
					</v-list-item-content>
				</v-list-item>
			</v-list>

			<!-- sidebar: sizings -->
			<v-list v-bind:class="{ 'd-none': !showSizings || hiddenSidebar }" class="overflow-y-auto pt-0 sharp-edges aside-lists">
				<v-list-item v-for="item of parentSizings" :key="item.id" :ref="item.id" :class="selectedClass(item, sizingid)" class="pa-0 pt-1">
					<v-list-item-icon class="icon-gutter">
						<SizingIcon :state="aggregatedState(item)" />
						<LockIcon :model="item" />
					</v-list-item-icon>
					<v-list-item-content @click.stop="selectSizing(item.id)" :disabled="navDisabled(item.id)" class="pb-1 pt-2"
						:style="{ cursor: navDisabled(item.id) ? 'default' : 'pointer', color: navDisabled(item.id) ? 'gray' : 'black'}">
						<v-tooltip bottom max-width="500" open-delay="500">
							<template v-slot:activator="{ on }">
								<!-- Parent sizing title -->
								<v-list-item-title  v-on="on" :style="{ color: navDisabled(item.id) ? 'gray' : 'black'}">
									<template v-if="!showChildren(item)">
										<img :src="require('@/assets/staging_icon.svg')" class="stage-mdp-icon" v-if="item.Staged" >
										<img :src="require('@/assets/MDP_icon.svg')" class="stage-mdp-icon" v-if="item.MDP">
									</template>
									<h4 class="d-inline">{{ item.Name }}</h4>
								</v-list-item-title>
							</template>
							<!-- Tooltip text for parent sizing -->
							<span v-text="item.Name"></span>
						</v-tooltip>
						<CommentIcon :target="mainId" v-if="sizingMainId(item) === mainId" type="Comment" class="comment-icon mt-n2"/>
						<v-list-item-subtitle v-text="sizingSubtitle(item)" class="mb-2" />

						<!-- Sizing children -->
						<v-list v-if="showChildren(item)" class="pb-0 pt-0">
							<v-list-item dense v-for="s of sizingWithChildren(item)" :key="s.id" @click.stop="selectSizing(s.id)" class="pl-1" :class="selectedClass(s, sizingid)"
								:disabled="navDisabled(s.id)" :style="{ cursor: navDisabled(s.id) ? 'default' : 'pointer', color: navDisabled(s.id) ? 'gray' : 'black'}">
								<v-list-item-icon class="ma-0 pa-1 pt-3 pr-2">
									<SizingIcon :state="s.State" />
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title>
										<!-- Child sizing title -->
										<v-tooltip bottom max-width="500" open-delay="500">
											<template v-slot:activator="{ on }">
												<div v-on="on" class="child-title">{{ sizingListTitle(s) }}</div>
											</template>
											<!-- Tooltip text of child sizing -->
											<span v-text="sizingListTitle(s)"></span>
										</v-tooltip>
										<!-- Duty point trashcan -->
										<v-tooltip v-if="canDeleteChild(s)" bottom>
											<template v-slot:activator="{ on }">
												<v-icon dense small class="delete-dp" color="primary" v-on="on" @click.stop="removeDutyPoint(s)">$delete</v-icon>
											</template>
											<!-- Tooltip text of DP trashcan -->
											Delete duty point
										</v-tooltip>
									</v-list-item-title>
									<!-- Skip subtitle for MDP since the subtitle is shown in the title above and the pump in the parent -->
									<v-list-item-subtitle v-if="!s.MDP" v-text="sizingSubtitle(s)" />
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-list-item-content>

					<v-list-item-action @click.stop.prevent class="align-self-start ma-2">
						<v-menu min-width="120">
							<template v-slot:activator="{ on }">
								<v-btn @click.stop icon v-on="on"><v-icon color="primary">more_horiz</v-icon></v-btn>
							</template>
							<v-list>
								<v-subheader>Actions</v-subheader>
								<v-list-item v-if="!item.MDP && !item.Staged && !item.ParentId" @click.stop="deleteSizing(item)" :disabled="offline || !canDeleteSizing(item)">{{ $t('common.delete') }}</v-list-item>
								<v-list-item v-if="!item.ParentId" @click.stop="copySizing(item)" :disabled="offline">Copy</v-list-item>
								<v-list-item v-if="!item.ParentId" @click.stop="shareSizing(item)" :disabled="offline">Share</v-list-item>
								<v-list-item v-if="item.Staged" @click.stop="unstageSizing(item)" :disabled="offline || locked(item)">Unstage</v-list-item>
								<v-list-item v-if="!item.Staged" @click.stop="addDutyPoint(item)" :disabled="offline ||locked(item)">Add duty point</v-list-item>
							</v-list>
						</v-menu>
					</v-list-item-action>
				</v-list-item>
				<v-list-item v-if="!sizings.length">
					<v-list-item-title v-if="project && !project.QuoteId">
						<i>No sizings - click <v-icon color="primary">$plus</v-icon> to create one</i>
					</v-list-item-title>
					<v-list-item-title v-else>
						<i>No sizings - create them in PQP</i>
					</v-list-item-title>
				</v-list-item>
			</v-list>

			<ParamList class="aside-lists" v-if="currentSizingValues" :params="currentSizingValues"
				v-bind:class="{ 'd-none': !currentSizing || showSizings || hiddenSidebar }" :filter="paramFilter" />
		</aside>

			<!-- right content -->
			<template v-if="project && step === Step.Project">
				<ProjectEditor :project="project" :values="projectValues"></ProjectEditor>
			</template>
			<template v-if="currentSizingValues">
				<Sizing v-show="step === Step.Sizing" :model="currentSizing" :values="currentSizingValues" :key="sizingid + reloadTrigger" :dutyPoints="dutyPoints" />
			</template>
			<transition name="fade">
				<div v-if="step === Step.Sizing && !currentSizingValues && loading" class="ma-4">
					<v-progress-circular indeterminate class="mr-4" />Loading sizing...
				</div>
			</transition>
			<template v-if="step === Step.Sizing && sizingid && !currentSizing && !loading">
				<v-alert type="warning" max-height="56px">
					Sizing not found. It might have been deleted or otherwise made inaccessible.
				</v-alert>
			</template>
			<template v-if="step === Step.Collab">
				<Collaboration :project="project" :sizing="currentSizing" :target="mainId" />
			</template>
			<template v-if="step === Step.Summary">
				<ReportCreation :project="project" :sizing="currentSizing" />
			</template>

			<!-- Dialogs -->
			<NewSizingDialog @close-dialog="closeDialog" v-if="newSizingDialog" :project="project" :sizingClone="newSizingClone" :newSizingName="newSizingName" />
			<Confirm ref="confirmProject" />
			<ConflictDialog v-if="conflictDoc" @ok="conflictOk" @cancel="conflictDoc = null" :data="conflictDoc" />
			<ShareSizingDialog @close-dialog="closeDialog" v-if="sharingSizing" :sizing="sharingSizing" :project="project" />
	</div>
</template>

<style lang="scss" scoped>
	@import '@/sass/_variables.scss';
	.border {
		border-top: 1px solid $grey-lighten-2;
	}

	.v-list-item__icon {
		align-self: baseline !important;
	}

	.header {
		height: 100px;
		position: fixed;
		.step {
			padding: 8px 12px;
			padding-top: 0;
			.label {
				display: block;
			}
		}
	}

	aside {
		background-color: $white;
	}

	.aside-top-actions {
		background-color: $white;
		z-index: 1;
		position: sticky;
		top: 69px;
		padding: 0;
		border-bottom: 1px solid $grey-lighten-2;
		max-height: 47px;
		border-radius: 0;
	}

	.aside-lists {
		z-index: 1;
		position: sticky;
		top: 117px;
		overflow-y:auto;
		max-height:  calc(100vh - 161px);
	}

	.aside-top-buttons {
		float:right;
		margin-top: 5px;
		padding-right: 1px;
	}

	.v-btn.inline {
		min-width: 30px;
		width: auto;
		padding: 2px 4px;
	}

	.sticky-expand-sidebar {
		position: sticky;
		top: 67px;
	}

	.v-btn {
		&.activeState {
			color: $primary;
			background-color: $grey-lighten-1;
			height:47px;
			padding: 0 11px 0 11px;
		}
		&.inActiveState {
			color: $grey;
			background-color: $white;
			height:47px;
			padding: 0 11px 0 11px;
			border-bottom: 1px solid $grey-lighten-2;
		}
	}

	.fade-enter-active {
		transition: opacity 2.5s ease-out;
	}

	.fade-enter, .fade-leave-to, .fade-leave-active {
		opacity: 0;
	}

	.v-list-item {
		&.selected {
			background-color: $info-lighten-2;
		}
		&.status-warning {
			border-left: 4px solid $warning;
		}
		&.status-error {
			border-left: 4px solid $error;
		}
	}

	.stage-mdp-icon {
		vertical-align: middle;
		padding-bottom: 1px;
		height: 16px;
		margin-right: 3px;
	}

	textarea, input {
		outline: none; 
		border: 1px solid transparent;
	 }

	textarea:focus, input:focus {
		outline: none; 
		border: 1px solid $selection-lighten-1;
	 }

	 .sizing-title {
		width: 100%;
		&.long {
			font-size: clamp(0.875rem, 0.0855rem + 1.3158vw, 1.125rem);
		}
	}

	.sizing-subtitle {
		height: 24px;
		font-size: .8125rem;
		width: 70%;
	}

	.comment-icon {
		z-index:1
	}

	.child-title {
		max-width: 160px;
		overflow-x: hidden;
		text-overflow: ellipsis;
	}

	.delete-dp {
		opacity: 0.5;
		position: absolute;
		top: 11px;
		right: 4px;
	}

	.icon-gutter {
		margin-left: 8px;
		margin-right: 8px !important;
		margin-top: 10px;
		text-align: center;
		justify-content: center;
		min-width: 32px;
	}

	.pqp-link {
		padding: 1px 6px 1px 5px;
		margin-left: 6px;
		border-radius:6px;
		font-weight: normal;
		color: $white;
		.icon {
			margin-bottom: 2px;
		}
	}

	.tabcol {
		margin-top: 3px;
		height: 100%;
		background-color: transparent;
	}

	.theme--light.v-tabs>.v-tabs-bar {
		background-color: $error !important;
	}

	@media only screen and (max-width: 1140px) {
		.v-divider {
			display: none;
		}
	}

	@media only screen and (max-width: 960px) {
		.tabcol {
			margin: 9px 0 0 50px;
			padding-bottom: 0;
		}

		.tabcol-right {
			margin: 12px 0 0 328px;
			padding-bottom: 0;
		}

		.aside-top-actions {
			top: 61px;
		}

		.aside-lists {
			top: 109px;
			max-height:  calc(100vh - 154px);
		}

		.sizing-title.long {
			font-size: 18px;
		}
	}

	.duty-point-stepper {
		max-width: 300px;
		margin-top: -7px;
	}
</style>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop, Watch } from 'vue-property-decorator';
	import { PumpDocument, PumpProject, SizingState, MetaData } from 'types/dto/CalcServiceDomain';
	import Sizing from '@/views/Sizing.vue';
	import ParamList from '@/components/ParamList.vue';
	import LockIcon from '@/components/LockIcon.vue';
	import { ParamBag } from '@/common/ParamBag';
	import SelectField from '@/components/Fields/SelectField.vue';
	import UnitNumeric from '@/components/Fields/UnitNumeric.vue';
	import SizingIcon from '@/components/SizingIcon.vue';
	import CommentIcon from '@/components/CommentIcon.vue';
	import Collaboration from '@/components/Collaboration.vue';
	import ProjectEditor from '@/components/ProjectEditor.vue';
	import NewSizingDialog from '@/views/dialogs/newSizingDialog.vue';
	import ConflictDialog from '@/views/dialogs/ConflictDialog.vue';
	import ShareSizingDialog from '@/views/dialogs/ShareSizingDialog.vue';
	import store, { SnackActions, ProjectActions, ProjectGetters, SizingGetters, SizingMutations, SizingActions, NetworkGetters, DebugActions } from '@/store';
	import { DutyPoints } from '@/common/DutyPoints';
	import Confirm from '@/components/Confirm.vue';
	import ReportCreation from '@/components/ReportCreation.vue';
	import ParamFilter from '@/components/ParamFilter.vue';
	import SizingInfo from '@/common/SizingInfo';
	import { Route } from 'vue-router';
	import { ConflictEvent } from '@/common/compareDocs';
	import sizingService from '@/services/sizing.service';
	import SizingStepper from '@/components/SizingStepper.vue';
	import { getQuoteUrl, localDateString } from '@/common/Tools';

	enum Step { Project = 0, Sizing = 2, Collab = 4, Summary = 6 }
	let activeStep: Step = null;

	Component.registerHooks(['beforeRouteEnter']);
	@Component({
		name: 'project',
		components: {
			Sizing,
			SelectField,
			UnitNumeric,
			ParamList,
			ProjectEditor,
			NewSizingDialog,
			SizingIcon,
			Confirm,
			LockIcon,
			ReportCreation,
			CommentIcon,
			Collaboration,
			ConflictDialog,
			ShareSizingDialog,
			ParamFilter,
			SizingStepper
		},
	})
	export default class Project extends Vue {
		@Prop() public id: string;
		@Prop() public sizingid: string;
		@Prop() public view: string;

		public readonly Step = Object.freeze(Step);
		public step: Step = Step.Project;

		public forceSidebar: boolean = false;
		public hideLgSidebar: boolean = false;
		public showSizings: boolean = true;
		public project: PumpProject = null;

		public newSizingDialog: boolean = false;
		public newSizingName: string = null;
		public newSizingClone: PumpDocument = null;
		public sharingSizing: PumpDocument = null;
		public dutyPoints: DutyPoints = null;

		public projectValues: ParamBag = null;
		public currentSizingValues: ParamBag = null;
		public paramFilter: any = null;

		public conflictDoc: ConflictEvent = null;
		public reloadTrigger: number = 0;
		public loading: boolean = false;
		public parentId: string = null;

		private conflictListener: any;
		private keyListener: any;
		private pumpMap: { [id: string]: string; } = null;
		private static loadedPumpMap: string;
		private static cachedPumpMap: { [id: string]: string; };

		public navDisabled(id: string): boolean {
			if (this.offline)
				return !store.get(SizingGetters.fullyLoaded, { id, skipChildren: true });
			return this.ongoingSync(id);
		}

		private ongoingSync(id: string): boolean {
			return store.get(SizingGetters.loadingSizing, id);
		}

		public toggleSidebar() {
			if (this.$vuetify.breakpoint.smAndDown) {
				this.forceSidebar = !this.forceSidebar;
				this.hideLgSidebar = false;
			} else {
				this.hideLgSidebar = !this.hideLgSidebar;
				this.forceSidebar = false;
			}
		}

		public get hiddenSidebar(): boolean {
			if (this.$vuetify.breakpoint.smAndDown && !this.forceSidebar)
				 return true;
			if (!this.$vuetify.breakpoint.smAndDown && this.hideLgSidebar)
				return true;
			return false;
		}

		public get offline() {
			return !store.get(NetworkGetters.connected);
		}

		public get sizings(): PumpDocument[] {
			const sizings: PumpDocument[] = store.get(SizingGetters.projectSizings, this.id) || [];
			return sizings.sort((a, b) => (a.Name ?? '').localeCompare(b.Name ?? ''));
		}

		public get parentSizings(): PumpDocument[] {
			return this.sizings.filter(x => !x.ParentId);
		}

		public get currentSizing(): PumpDocument {
			if (this.sizingid)
				return store.get(SizingGetters.sizing, this.sizingid);
			return null;
		}

		public get title() {
			return this.project?.Name;
		}

		public get shared() {
			return SizingInfo.isShared(this.project);
		}

		public get showReload() {
			if (this.offline)
				return false;
			return this.shared || !!this.project?.QuoteId;
		}

		public get sizingColor() {
			return (this.currentSizing && SizingIcon.colorForState(this.currentSizing.State));
		}

		public get quoteUrl() {
			return getQuoteUrl(this.project);
		}

		public get fontStyle() {
			if (this.sizingName?.length > 45)
				return 'long';
		}

		public selectedClass(item: PumpDocument, sizingid: string) {
			return {
				selected: item.id === sizingid,
			};
		}

		public showChildren(item: PumpDocument) {
			return (item.Staged || item.MDP) && item.id === this.parentId;
		}

		public sizingWithChildren(item: PumpDocument | string) {
			const id = typeof item === 'string' ? item : item.id;
			const items = this.sizings.filter(x => x.id === id || x.ParentId === id);
			return items.sort((x, y) => (x.Subtitle as any as number) - (y.Subtitle as any as number));
		}

		public sizingSubtitle(item: PumpDocument) {
			// Show any "special" subtitle if one is set (unless overridden by MDP/staging)
			if (!item.Staged && !item.MDP && item.Subtitle?.length)
				return item.Subtitle;

			// Show preloaded pump name until "real" pumpname is loaded
			if (item.Data && Object.keys(item.Data).length)
				return item?.Data?.Pump?.DisplayName || null;
			return this.pumpMap?.[item.id] || null;
		}

		public aggregatedState(item: PumpDocument) {
			if (!item.MDP && !item.Staged)
				return item.State;
			const states = this.sizingWithChildren(item).map(x => x.State);
			if (states.includes(SizingState.Error))
				return SizingState.Error;
			if (states.includes(SizingState.Warning))
				return SizingState.Warning;
			if (states.includes(SizingState.InProgress))
				return SizingState.InProgress;
			return SizingState.Completed;
		}

		public get showSubtitleHeader() {
			const s = this.currentSizing;
			return !!(s && (s.MDP || s.Staged));
		}

		public get canEditSizingTitle() {
			const s = this.currentSizing;
			return !!(s && !this.locked(s) && !s.ParentId);
		}

		public get canEditSizingSubtitle() {
			const s = this.currentSizing;
			return !!(s && !this.locked(s) && s.MDP);
		}

		public canDeleteSizing(item: PumpDocument) {
			return SizingInfo.isSizingDeletable(item);
		}

		public canDeleteChild(item: PumpDocument) {
			return !!(item && !this.locked(item) && item.MDP && item.ParentId);
		}

		public get subtitleHeader() {
			const s = this.currentSizing;
			if (!s || s.MDP)
				return Project.useSubtitle(s) ? s.Subtitle : this.sizingChildRole(s);
			if (s.Staged && s.Subtitle != null)
				return 'Stage ' + s.Subtitle;
			return this.sizingSubtitle(s);
		}

		public set subtitleHeader(val: string) {
			if (this.currentSizing && val?.trim().length)
				store.dispatch(SizingActions.setSubtitle, { sizingId: this.sizingid, name: val.trim() });
		}

		private static useSubtitle(s: PumpDocument) {
			if (!s?.MDP || !s.Subtitle)
				return false;
			const st = s.Subtitle;
			return st.length > 1 || !st.match(/[0-9]/);
		}

		public get sizingName() {
			return this.currentSizing?.Name || '';
		}

		public set sizingName(val: string) {
			if (!this.currentSizing)
				return;
			if (!val?.trim().length)
				store.dispatch(SnackActions.set, 'Sizing name must not be empty');
			else
				store.dispatch(SizingActions.rename, { sizingId: this.sizingid, name: val.trim() });
		}

		public sizingListTitle(item: PumpDocument) {
			if (Project.useSubtitle(item))
				return item.Subtitle;
			return this.sizingChildRole(item) || item.Name || 'No name';
		}

		public locked(item: MetaData) {
			return SizingInfo.isLocked(item);
		}

		public sizingChildRole(item: PumpDocument) {
			// TODO: all this subtitle logic should be clarified and use something like SizingInfo.subtitle instead
			// once the logic is decided upon and made clear...
			if (!item)
				item = this.currentSizing;
			if (!item)
				return;
			if (item.Staged)
				return 'Stage ' + item.Subtitle;
			if (item.MDP)
				return 'Duty point ' + item.Subtitle;
			return null;
		}

		public async reloadProject() {
			await store.dispatch(ProjectActions.getProject, this.id);
			await store.dispatch(SizingActions.getSizings, this.id);
			return this.reloadSizing();
		}

		public async reloadSizing() {
			this.conflictDoc = null;
			store.commit(SizingMutations.purgeSizing, this.sizingid);
			await this.load(this.id, this.sizingid).then(() => ++this.reloadTrigger);
		}

		public conflictOk() {
			if (this.conflictDoc.onAccept) {
				this.conflictDoc.onAccept();
				this.conflictDoc = null;
			} else
				this.reloadSizing();
		}

		private async load(projectId: string, sizingId: string) {
			try {
				this.loading = true;
				this.id = projectId;

				let project = this.project;
				if (!project || project.id !== projectId)
					this.project = project = await store.dispatch(ProjectActions.getProject, projectId) as PumpProject;
				else {
					// Refresh the project info. We might have been shared/unshared.
					setTimeout(() => store.dispatch(ProjectActions.getProject, projectId), 500);
				}

				const projectBag = new ParamBag([]);
				projectBag.useProject(project);
				this.projectValues = projectBag;

				// Load project sizings if we can suspect that this is not already done (i.e. direct link to sizing/non-loaded project)
				const sizingsInMemory = store.get(SizingGetters.projectSizings, projectId) as any[];
				if (!sizingsInMemory || sizingsInMemory.length <= 1)
					await this.loadSizings();

				// Scroll clicked sizing into view in left pane
				const sizing = sizingId && store.get(SizingGetters.sizing, sizingId) as PumpDocument;
				if (sizing) {
					const scrollToId = sizing.ParentId || sizing.id;
					const item = this.$refs[scrollToId] as Vue[];
					if (item?.length && item[0].$el && item[0].$el.scrollIntoView) {
						const target = item[0].$el;
						if (target.getBoundingClientRect) {
							const rect = target.getBoundingClientRect();
							if (rect.bottom > window.innerHeight)
								this.$nextTick(() => target.scrollIntoView());
						}
					}
				}

				this.parentId = sizing && (sizing.ParentId || sizing.id) || null;

				// Prepare map of pump names in the background if needed (delay to allow sizing load to proceed first)
				if (!this.offline && (!Project.cachedPumpMap || Project.loadedPumpMap !== this.id))
					setTimeout(() => this.loadPumpNames(), 350);
				else
					this.pumpMap = Project.cachedPumpMap;

				// Showing project only - nothing left to do
				if (!sizingId)
					return;

				const response = await store.dispatch(SizingActions.getSizing, sizingId) as PumpDocument;
				if (!response)
					return;
				store.dispatch(DebugActions.replace, { category: 'Sizing', entries: response.Status });

				// Wait for other the duty points/stages to load before opening sizing
				const dp = new DutyPoints(response);
				await dp.load();
				if (response.Staged || response.MDP)
					await DutyPoints.loadSiblings(response);

				this.dutyPoints = dp;
				this.currentSizingValues = new ParamBag(this.dutyPoints.asSizings, sizingId, true);
				this.sizingid = sizingId;
			} finally {
				this.loading = false;
			}
		}

		private loadSizings() {
			return store.dispatch(SizingActions.getSizings, this.id);
		}

		private loadPumpNames() {
			return sizingService.getProjectPumps(this.id).then(names => {
				Project.cachedPumpMap = names;
				Project.loadedPumpMap = this.id;
				this.pumpMap = Project.cachedPumpMap;
			});
		}

		public get mainId() {
			return this.sizingMainId(this.currentSizing);
		}

		public sizingMainId(sz: PumpDocument) {
			return sz && (sz.ParentId || sz.id) || null;
		}

		public beforeRouteEnter(to: Route, from: Route, next: any) {
			// Redirect /sizing/szszsz to /project/prprpr/szszsz
			if (to?.params?.sizingid && !to.params.id) {
				store.dispatch(SizingActions.getSizing, to.params.sizingid).then((sizing: PumpDocument) => {
					if (sizing) {
						// Must load the whole project here so all siblings/stages etc exist before showing the sizing!
						store.dispatch(SizingActions.getSizings, sizing.ProjectId).then(() =>
							next({ name: 'sizing', params: { id: sizing.ProjectId, sizingid: sizing.id } }));
					} else
						next();	// The damned sizing did not exist. Fail in the "normal path".
				}).catch(next);
			} else
				next();
		}

		public created() {
			if (this.view === 'parameters')
				this.showSizings = false;
			if (this.id)
				this.project = store.get(ProjectGetters.project, this.id);
			this.setStartStep();

			this.reloadTrigger = 0;
			this.conflictListener = this.onSizingConflict.bind(this);
			window.addEventListener('sizingConflict', this.conflictListener);
		}

		private setStartStep() {
			if (!this.id || !this.sizingid || !this.project || this.sizingid && activeStep === Step.Project)
				activeStep = null;

			if (activeStep != null)
				this.step = activeStep;
			else if (this.sizingid)
				this.step = Step.Sizing;
		}

		@Watch('step')
		public onStepChanged(val: Step) {
			activeStep = val;
		}

		public onSizingConflict(ev: CustomEvent) {
			this.conflictDoc = ev.detail;
		}

		public beforeDestroy() {
			if (this.conflictListener)
				window.removeEventListener('sizingConflict', this.conflictListener);
			if (this.keyListener)
				window.document.removeEventListener('keydown', this.keyListener);
		}

		public mounted() {
			if (!this.id)
				return;
			// Load whatever is not loaded yet
			this.load(this.id, this.sizingid);

			this.keyListener = this.onKey.bind(this);
			window.document.addEventListener('keydown', this.keyListener);
		}

		public onKey(e: KeyboardEvent) {
			if (e.altKey && e.shiftKey) {
				switch (e.key) {
					case 'ArrowLeft': this.switchDp(-1); break;
					case 'ArrowRight': this.switchDp(1); break;
					case 'ArrowUp': this.switchSizing(-1); break;
					case 'ArrowDown': this.switchSizing(1); break;
					default: return;
				}
				e.preventDefault();
			}
		}

		public switchDp(delta: number) {
			this.stepSizing(this.sizingWithChildren(this.mainId), this.sizingid, delta);
		}

		public switchSizing(delta: number) {
			this.stepSizing(this.parentSizings, this.mainId, delta);
		}

		private stepSizing(list: PumpDocument[], curId: string, delta: number) {
			const cur = list?.findIndex(x => x.id === curId);
			if (cur >= 0) {
				const next = cur + delta;
				if (next >= 0 && next < list.length)
					this.selectSizing(list[next].id);
			}
		}

		public selectSizing(sizingid: string) {
			if (sizingid === this.sizingid || this.navDisabled(sizingid))
				return;
			if (!this.step != null || this.step === Step.Project)
				this.step = Step.Sizing;
			const view = this.showSizings ? null : 'parameters';
			this.$router.replace({ name: 'sizing', params: { id: this.id, sizingid, view } });
		}

		public newSizing() {
			this.newSizingName = `New sizing ${localDateString(new Date())}`;
			this.newSizingDialog = true;
		}

		public copySizing(item: PumpDocument) {
			const sizing = store.get(SizingGetters.sizing, item.id);
			if (!sizing)
				return;
			this.newSizingClone = sizing;
			this.newSizingName = sizing.Name + ' (copy)';
			this.newSizingDialog = true;
		}

		public async unstageSizing(item: PumpDocument) {
			const dialog: any = this.$refs.confirmProject;
			const unstageSizing = await dialog.open('This will remove all selected pumps and preconfiguration.');
			if (unstageSizing) {
				const parentId = this.currentSizing?.ParentId;
				await store.dispatch(SizingActions.unstage, item.id);
				if (parentId)
					this.$router.replace({ name: 'sizing', params: { id: this.id, sizingid: parentId } });
			}
		}

		public async addDutyPoint(item: PumpDocument) {
			const dp = await store.dispatch(SizingActions.addDutyPoint, item.id);
			if (dp)
				this.$router.replace({ name: 'sizing', params: { id: dp.ProjectId, sizingid: dp.id } });
		}

		public async removeDutyPoint(child: PumpDocument) {
			if (!child?.id || !child.ParentId)
				return;
			const dialog: any = this.$refs.confirmProject;
			const deleteSizing = await dialog.open('This will delete the duty point.');
			if (deleteSizing) {
				const removingSelf = child.id === this.sizingid;
				if (removingSelf)
					await this.$router.replace({ name: 'sizing', params: { id: this.id, sizingid: child.ParentId } });
				await store.dispatch(SizingActions.removeDutyPoint, child.id);
				if (!removingSelf)
				 	await this.reloadSizing();
			}
		}

		public shareSizing(item: PumpDocument) {
			this.sharingSizing = item;
		}

		public async deleteSizing(item: PumpDocument) {
			const dialog: any = this.$refs.confirmProject;
			const deleteSizing = await dialog.open('This will delete the sizing.');
			if (deleteSizing)
				store.dispatch(SizingActions.removeSizing, item.id).then(() => {
					if (item.id === this.sizingid)
						this.$router.replace({ name: 'project', params: { id: this.id } });
					else
						this.loadSizings();
				});
			return true;
		}

		public closeDialog(type: string) {
			switch (type) {
				case 'sizing':
					this.newSizingDialog = false;
					break;
				case 'shareSizing':
					this.sharingSizing = null;
					break;
			}
		}
	}
</script>