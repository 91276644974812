import { render, staticRenderFns } from "./ExpandableCard.vue?vue&type=template&id=1d1c2095&scoped=true"
import script from "./ExpandableCard.vue?vue&type=script&lang=ts"
export * from "./ExpandableCard.vue?vue&type=script&lang=ts"
import style0 from "./ExpandableCard.vue?vue&type=style&index=0&id=1d1c2095&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d1c2095",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VCard,VCardText,VContainer,VList,VListGroup,VListItemTitle})
