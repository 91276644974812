import store, { NetworkGetters, SizingActions, SizingGetters } from '@/store';
import { DeratingMethod, OutletEndConfig, PumpDocument, ValidationResult } from 'types/dto/CalcServiceDomain';
import { MDPPumpResult, PumpResult, PumpSearch, PumpStatus, PumpUsage } from 'types/dto/PumpSearch';
import PumpService from '@/services/pumps.service';

export default class PumpManager {
	public constructor(private readonly sizingIds: string[]) { }

	public get selected(): PumpResult {
		if (!this.sizingIds?.length)
			return null;
		const pump = store.get(SizingGetters.sizing, this.sizingIds[0])?.Data?.Pump as PumpResult;
		return pump?.Id ? pump : null;
	}

	public get pumpDutyString(): string {
		const duty = PumpManager.mdpSearchFilter(this.dutyPoints);
		return !duty ? null : JSON.stringify(duty);
	}

	public get selectedPumpFilter(): PumpSearch[] {
		if (!this.selected)
			return null;
		return PumpManager.mdpSearchFilter(this.dutyPoints, true);
	}

	public async search(statuses?: PumpStatus[], includeUnsuitable?: boolean, includeWacko?: boolean): Promise<MDPPumpResult[]> {
		return await this.searchInternal(false, statuses, includeUnsuitable, includeWacko);
	}

	public async updateSelected() {
		const oldPump = this.selected;
		if (!oldPump)
			return;

		const results = await this.searchInternal(true);
		if (!results?.length) {
			console.error('Could not find currently selected pump ' + oldPump.Id);
			return;
		}

		const newPump = results[0];
		let idx = 0;
		const returnedDps = newPump?.DutyPoints?.length || 0;
		const batch: any[] = [];
		for (const dp of this.dutyPoints) {
			if (idx >= returnedDps) {
				console.error('Pump duty point ' + (idx + 1) + ' is missing in result');
				continue;
			}

			const newPumpDp = newPump.DutyPoints[idx];
			if (!PumpManager.isSamePump(dp.Data?.Pump, newPumpDp))
				batch.push({ sizingId: dp.id, sections: { Pump: newPumpDp } });
			idx++;
		}
		if (batch.length)
			await store.dispatch(SizingActions.updateSections, batch);
	}

	public async writeSelected(selectedPump: MDPPumpResult, extraProps?: any) {
		const allSuitable = selectedPump && !selectedPump.DutyPoints.some(x => !x.Suitable);
		const target = 'Pump';
		const batch: any[] = [];

		this.sizingIds.forEach((sizingId, idx) => {
			const dpPump = selectedPump?.DutyPoints[idx] || null;

			// Mark pump unsuitable in this duty point if it is unsuitable in any other duty point
			// This should be handled in a more generic way when DP errors/warnings/suitability are combined.
			// The suitability flag needs to be set for all DPs since an individual DP sizing can not be
			// guaranteed to have access to the other duty points until loaded from the pump service. Offline...
			if (!allSuitable && dpPump?.Suitable)
				dpPump.Suitable = false;

			const sections: any = { Frame: {}, BearingAssembly: {}, Motor: {}, Drive: {}, VBelt: {}, Flange: {}, ShaftSeal: {} };
			sections.Pump = dpPump || {};
			batch.push({ sizingId, sections, target, newValue: dpPump?.DisplayName });
		});

		// Wait until all duty points are updated
		await store.dispatch(SizingActions.updateSections, batch);

		// Preconfigure the selected pump if specified
		if (this.sizingIds.length && extraProps && Object.keys(extraProps)?.length) {
			const sizingId = this.sizingIds[0];
			let idx = 0;
			const last = this.sizingIds.length - 1;
			for (const k of Object.keys(extraProps))
				await store.dispatch(SizingActions.setValue, { value: extraProps[k], sizingId, valueName: `Pump.${k}`, skipSave: idx++ < last });
		}
	}

	public static isSamePump(a: PumpResult, b: PumpResult) {
		if (a?.Id && b?.Id) {
			const oldJSON = JSON.stringify(PumpManager.canonicalizeResult(b));
			const newJSON = JSON.stringify(PumpManager.canonicalizeResult(a));
			if (oldJSON === newJSON)
				return true;
		}
		return false;
	}

	public static canonicalizeResult(pump: PumpResult) {
		if (!pump?.Id)
			return null;

		// Create a "clean" pump, with only duty related data (assuming pump is selected and material availability does not change)
		pump = Object.assign({}, pump);
		delete pump.ImpellerMaterial;
		delete pump.ImpellerMaterials;
		delete pump.CaseMaterial;
		delete pump.CaseMaterials;
		delete pump.UsableFlanges;
		delete pump.UsableFrames;

		// Consolidate forms of non-messages and uninteresting message state
		if (!pump?.Messages?.length)
			delete pump.Messages;
		else
			pump.Messages = pump.Messages.map(m => ({ Severity: m.Severity, Message: m.Message } as any as ValidationResult));
		return pump;
	}

	private get dutyPoints() {
		if (!this.sizingIds?.length)
			return null;

		const dps: PumpDocument[] = this.sizingIds.map(id => store.get(SizingGetters.sizing, id));
		if (dps.some(x => x == null)) {
			console.error('One or more duty points do not exist in store');
			return null;
		}
		return dps;
	}

	private async searchInternal(forSelectedPump: boolean, statuses?: PumpStatus[], includeUnsuitable?: boolean, includeWacko?: boolean): Promise<MDPPumpResult[]> {
		if (!store.get(NetworkGetters.connected))
			return null;

		const dps = this.dutyPoints;
		if (!dps?.length)
			return null;

		if (forSelectedPump && !this.selected)
			return null;

		const filter = PumpManager.mdpSearchFilter(dps, forSelectedPump);
		if (filter == null) {
			console.error('Failed to determine duty for pump search');
			return null;
		}

		const results = await PumpService.getPumps(filter, !!includeUnsuitable, !!includeWacko, statuses);
		if (!results?.length)
			return [];

		if (dps.length > 1) {
			results.forEach(x => {
				x.DutyPoints?.forEach((newPumpDp, idx) => {
					if (idx > 0) {
						// Remove "search result properties" that should not be in the actual sizing
						// These are only  kept in the "root pump" for showing selection chips which is... bad
						delete newPumpDp.ImpellerMaterials;
						delete newPumpDp.CaseMaterials;
					}
				});
			});
		}
		return results;
	}

	private static mdpSearchFilter(dps: PumpDocument[], forSelectedPump: boolean = false): PumpSearch[] {
		if (!dps?.length)
			return null;

		const filters = dps.map(dp => PumpManager.filterForDuty(dp, forSelectedPump));
		if (filters.some(x => x == null || !(x.PDH > 0 && x.FlowRate > 0 && x.SlurryDensity > 0))) {
			console.error('One or more duty points are missing information for pump search');
			return null;
		}
		return filters;
	}

	private static filterForDuty(p: PumpDocument, forSelectedPump: boolean): PumpSearch {
		const data = p?.Data;
		if (!data)
			return null;

		const slurry = data.Slurry;
		const useManualReduction = slurry?.DeratingMethod === DeratingMethod.Manual;
		const filter: PumpSearch = {
			PDH: data.Heads?.PDH,
			FlowRate: slurry?.FlowRate,
			SlurryDensity: slurry?.SlurryDensity,
			FrothFactor: slurry?.FrothFactor,
			ServiceClass: data.ServiceClass,
			d100: data.Particles?.d100,
			NPSHA: data.Heads?.NPSHA,
			Usage: data.Outlet?.EndConfig === OutletEndConfig.PressFilter ? PumpUsage.PressFilter : undefined,
			DeratingMethod: slurry?.DeratingMethod || undefined,
			ManualHR: useManualReduction && slurry?.ManualHR || undefined,
			ManualER: useManualReduction && slurry?.ManualER || undefined,
			ManualQR: useManualReduction && slurry?.ManualQR || undefined,
			InletHead: data.Heads?.InletHead || undefined,

			// Material related stuff
			LiquidTemp: data.Liquid?.Temp || undefined,
			CorrosionIndex: data.Liquid.CorrosionIndex || undefined
		};

		if (forSelectedPump) {
			const selected = data.Pump?.Id ? data.Pump : null;
			if (!selected) {
				console.error('Search for selected pump was requested, but no pump was selected in ' + p.id);
				return filter;
			}
			filter.SpecificPumpId = selected.Id;
			filter.SelectedImpellerMaterial = selected.ImpellerMaterial || undefined;
			filter.SelectedCaseMaterial = selected.CaseMaterial || undefined;
			filter.TrimPercentage = selected.TrimPercentage;
		}

		return filter;
	}
}
