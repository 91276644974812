import { render, staticRenderFns } from "./SystemCurve.vue?vue&type=template&id=8bdd3e34&scoped=true"
import script from "./SystemCurve.vue?vue&type=script&lang=ts"
export * from "./SystemCurve.vue?vue&type=script&lang=ts"
import style0 from "./SystemCurve.vue?vue&type=style&index=0&id=8bdd3e34&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8bdd3e34",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VTooltip})
