<template>
	<svg width="90%" v-if="cfg && vb" :viewBox="`0 0 ${totW + 2*m} ${totH + 2*m}`">
		<line :x1="mx" :y1="my-mr+10" :x2="px" :y2="py-pr+10" class="belt">
			<animate attributeName="stroke-dashoffset" from="100" to="0" :dur="bdur" repeatCount="indefinite" />
		</line>
		<line :x1="mx" :y1="my+mr-10" :x2="px" :y2="py+pr-10" class="belt">
			<animate attributeName="stroke-dashoffset" from="0" to="100" :dur="bdur" repeatCount="indefinite" />
		</line>
		<circle :cx="mx" :cy="my" :r="mr" class="sheave"/>
		<text class="sh-lbl" :x="mx" :y="my+35" text-anchor="middle">M
			<animateTransform attributeName="transform" type="rotate" :from="`0 ${mx} ${my}`" :to="`360 ${mx} ${my}`" :dur="mdur" repeatCount="indefinite"/>
		</text>
		<circle :cx="px" :cy="py" :r="pr" class="sheave" />
		<text class="sh-lbl" :x="px" :y="py+35">P
			<animateTransform attributeName="transform" type="rotate" :from="`0 ${px} ${py}`" :to="`360 ${px} ${py}`" :dur="pdur" repeatCount="indefinite"/>
		</text>
		<rect :x="bx" :y="by" :width="maxW" :height="maxH" class="bounds" />
	</svg>
</template>

<style scoped>
	.belt {
		stroke: black;
		stroke-dasharray: 60 40;
		stroke-width: 12;
	}
	.sheave {
		stroke: black;
		stroke-width: 10;
		fill: #aaf;
	}
	.bounds {
		stroke: red;
		stroke-width: 5;
		fill: transparent;
	}
	.sh-lbl {
		font-family: Roboto-Medium, sans-serif;
		font-weight: bold;
		font-size: 100px;
		fill: black;
		text-anchor: middle;
		/*
		Replaced by my+35/py+35 since IE Edge does not support baseline :-(
		alignment-baseline: central;
		dominant-baseline: central;
		*/
	}
</style>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { VBeltResult } from 'types/dto/VBelt';
	import { DriveConfig } from 'types/dto/PumpBuild';

	const rpmScale = 50.0;

	@Component
	export default class BeltDrive extends Vue {
		@Prop() public vb: VBeltResult;
		@Prop() public cfg: DriveConfig;

		public m = 20;

		public get totW() { return this.vb.ActualCenter + this.mr + this.pr; }
		public get totH() { return Math.max(this.vb.MotorSheaveDiameter, this.vb.PumpSheaveDiameter); }

		public get maxW() { return this.cfg.CrsMax + this.cfg.MaxSheaveMotor / 2 + this.cfg.MaxSheavePump / 2; }
		public get maxH() { return Math.max(this.cfg.MaxSheaveMotor, this.cfg.MaxSheavePump); }

		public get mr() { return this.vb.MotorSheaveDiameter / 2; }
		public get mx() { return this.m + this.mr; }
		public get my() { return this.m + this.totH / 2; }

		public get pr() { return this.vb.PumpSheaveDiameter / 2; }
		public get px() { return this.m + this.mr + this.vb.ActualCenter; }
		public get py() { return this.m + this.totH / 2; }

		public get pdur() { return (rpmScale * 60.0 / this.vb.PumpSpeed).toFixed(3) + 's'; }
		public get mdur() {
			const ratio = 1.0 * this.pr / this.mr;
			return (rpmScale * 60.0 / (this.vb.PumpSpeed * ratio)).toFixed(3) + 's';
		}
		public get bdur() { return (1300.0 * rpmScale / (this.pr * this.vb.PumpSpeed)).toFixed(3) + 's'; }

		public get bx() { return this.m + this.totW / 2 - this.maxW / 2; }
		public get by() { return this.m + this.totH / 2 - this.maxH / 2; }
}
</script>
