<template>
	<v-card flat class="pa-0 mb-2">
		<v-card-text class="pa-0">
			<v-list class="pa-0">
				<v-list-item-group>
					<v-list-item v-for="item of availableSeals" :key="item.id"
						class="v-list-border" :class="selectedKey === key(item) ? 'sel-list-item': 'active-item'" active-class="active-item"
						@mouseover="showCommentIcon = selectedKey === key(item)" @mouseout="showCommentIcon = false">
						<v-list-item-icon>
							<v-icon :color="selectedKey === key(item) ? 'selection' : 'grey'" :disabled="locked || !item.Suitable" @click="itemClick(item)">
								{{ selectedKey === key(item) ? 'radio_button_on' : 'radio_button_off' }}
							</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>		
								<h3 class="d-flex align-center">
									<ParamList :params="getValueBag(item)" :names="['ShaftSeal.Description']" notitles="true" inline="true"
										:class="{ 'unsuitable': !item.Suitable }" />			
									<DeliveryReadiness v-if="!!pumpId" partType="ShaftSeal" :id="item.id" :pumpId="pumpId" /> 
								</h3>			
								<CommentIcon v-if="target && selectedKey === key(item)" :target="target" field="ShaftSeal" type="Comment" :visible="showCommentIcon" />
							</v-list-item-title>
							<v-list-item-subtitle class="grid auto">
								<ParamList :params="getValueBag(item)" :names="propsBrief" inline="true" :showZeros="false" :sizingId="buildManager && buildManager.sizingId" />
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
			<v-switch dense hide-details v-if="hasUnsuitable" class="ml-2" :label="$t('common.includeUnsuitable')" v-model="showUnsuitable" />
		</v-card-text>
	</v-card>
</template>

<style lang="scss" scoped>
	@import '@/sass/_variables.scss';
	.unsuitable .dp-value {
		color: $error;
	}
</style>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { ParamBag } from '@/common/ParamBag';
	import ParamList from '@/components/ParamList.vue';
	import CommentIcon from '@/components/CommentIcon.vue';
	import BuildManager from '@/common/BuildManager';
	import { ShaftSealResult } from 'types/dto/PumpBuild';
	import InsightService from '@/services/insight.service';
	import DeliveryReadiness from '@/components/DeliveryReadiness.vue';

	@Component({
		components: {
			ParamList,
			CommentIcon,
			DeliveryReadiness
		}
	})
	export default class ShaftSealList extends Vue {
		@Prop() public values: ParamBag;
		@Prop() public locked: boolean;
		@Prop() public buildManager: BuildManager;
		@Prop() public target: string;

		public readonly propsBrief = [ 'ShaftSeal.Note', 'ShaftSeal.QuenchFlow', 'ShaftSeal.QuenchPressure', 'ShaftSeal.MaxInletHead' ];
		public showCommentIcon: boolean = false;
		public showUnsuitable = false;

		public mounted() {
			this.buildManager.doSearchSeals();
		}

		public get availableSeals() {
			const cur = this.selectedItem;
			return this.buildManager.availableSeals.filter(s => (this.showUnsuitable || s.Suitable) || cur && s.id === cur.id);
		}

		public get hasUnsuitable() {
			return this.buildManager.availableSeals.some(s => !s.Suitable);
		}

		public key(m: ShaftSealResult) {
			return m?.id || null;
		}

		public getValueBag(m: ShaftSealResult) {
			const curSel = this.selectedItem;
			if (curSel && this.key(curSel) === this.key(m))
				return this.values;

			// Add static data to all duty points
			const mixed = (m.DutyPoints || []).map((x, idx) => ({ values: { ShaftSeal: Object.assign({}, m, x) }, messages: m.Messages }));
			return this.values.createVariants(mixed);
		}

		public get selectedItem() {
			return this.buildManager.selectedSeal;
		}

		public get selectedKey() {
			return this.key(this.selectedItem);
		}

		public get pumpId() {
			return this.buildManager?.selectedPump?.Id || null;
		}

		public itemClick(seal: ShaftSealResult) {
			if (this.locked)
				return;
			const selected = this.buildManager.selectedSeal;
			if (selected?.id === seal.id)
				seal = null;
			if (seal)
				InsightService.trackEvent('Sizing:SelectShaftSeal', seal);
			else
				InsightService.trackEvent('Sizing:DeselectShaftSeal', selected);
			this.buildManager.selectedSeal = seal;
		}
	}
</script>
