<template>
		<v-tabs class="tabList" v-model="currentStep">
			<v-tabs-slider color="transparent"></v-tabs-slider>
				<v-tab class="tabs first">
					<p class="step-title">{{ $t('app.step') }} 1</p> 
					<p class="tab-title">{{ $t('app.customerAndSite') }}</p>
				</v-tab>
				<v-tab class="tabs spacing" tabindex="-1" :disabled="true">
				</v-tab>	
				<v-tab class="tabs middle" :class="{ 'disabled': !sizingId}">
					<p class="step-title center-flex" :class="{ 'icon-title': state }">{{ $t('app.step')}} 2
						<SizingIcon v-if="state" size="15" :state="state" class="pl-1" />
					</p>
					<p class="tab-title">{{ $t('app.pumpSizing') }} </p>	
				</v-tab>
				<v-tab class="tabs spacing" tabindex="-1" :disabled="true">
				</v-tab>	
				<v-tab class="tabs middle">
					<p class="step-title">{{ $t('app.step') }} 3</p>
					<p class="tab-title">{{ $t('app.collaboration') }}</p>
				</v-tab>
				<v-tab class="tabs spacing" tabindex="-1" :disabled="true">
				</v-tab>	
				<v-tab class="tabs last">
					<p class="step-title">{{ $t('app.step') }} 4</p>
					<p class="tab-title">{{ $t('app.summary') }}</p>
				</v-tab>
		</v-tabs>
</template>

<style lang="scss" scoped>
	@import '@/sass/_variables.scss';
	.tabs {
		text-align: left;
		align-items: center;
		display: block;
		width: clamp(9.75rem, 10vw, 13.5rem);
		background-color: $grey-lighten-2;
		margin-left: -6px;
		
		&.v-tab--active {
			background-color: $accent-lighten-2;
		}
		&.first {
			clip-path: polygon(95% 0, 100% 50%, 95% 100%, 0% 100%, 0 50%, 0% 0%); 
			margin-right: -3px;
		}
		&.middle {
			clip-path: polygon(95% 0, 100% 50%, 95% 100%, 0% 100%, 5% 50%, 0% 0%); 
			margin-right: -3px;
		}
		&.last {
			clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 5% 50%, 0% 0%); 
		}
		&.spacing {
			clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%); 
			background-color: #fff;
			opacity: 1;
			width: 13px;
			margin-right: -80px;
		}
		&.disabled {
			color: $grey !important;
			pointer-events: none;
			font-weight: normal !important;
		}
		.step-title {
			padding-top: 11px;
			margin-bottom: 5px;
			font-size: 12px;
			font-weight: normal;
			color: $grey;
		}
	}

	.icon-title {
		margin-top: -1px;
	}

	.tabList {
		display: flex;
		position: inherit;
		justify-content: flex-end;
		background-color: transparent;
		width: fit-content;
	}

	.tab-title {
		line-height: 0.85;
	}

	@media only screen and (max-width: 960px) {
		.tabList {
			justify-content: flex-start;
		}

		.tabs {
			&.tabs.spacing {
				background-color: $grey-lighten-1;
			}
		}
	}
</style>

<script lang="ts">
	import Vue from 'vue';
	import Component from 'vue-class-component';
	import { Prop } from 'vue-property-decorator';
	import SizingIcon from '@/components/SizingIcon.vue';
	import { SizingState } from 'types/dto/CalcServiceDomain';

	enum Step { Project = 0, Sizing = 2, Collab = 4, Summary = 6 }

	@Component({
		name: 'sizingStepper',
		components: {
			SizingIcon
		}
	})
	
	export default class SizingStepper extends Vue {
		@Prop() public sizingId: string;
		@Prop() public state: SizingState;
		@Prop() public value: Step;

		public currentStep: Step = Step.Project;

		public created() {
			this.currentStep = this.value ?? Step.Project;
			this.$watch('currentStep', (val: Step) => this.$emit('input', val));
		}
	}
</script>