import Vue from 'vue';
import { PumpProject } from 'types/dto/CalcServiceDomain';
import { localizedDateString } from '@/i18n';
import store, { AuthGetters } from '@/store';

const quoteBaseUrl: string = process.env.VUE_APP_PQP_BASE_URL;

export function uniqueValues(list: any[], prop?: string) {
	const used: any = {};
	for (const p of (list || [])) {
		const value = (prop ? (p as any)[prop] : p) || [];
		if (value) {
			if (value instanceof Array)
				(value as string[]).filter(x => x).forEach(x => used[x] = true);
			else
				used[value] = true;
		}
	}
	return Object.keys(used);
}

export function minmax(values: any[], prop?: string) {
	let min: number;
	let max: number;
	values?.forEach(v => {
		const val = prop ? v[prop] : v;
		if (min == null || val < min)
			min = val;
		if (max == null || val > max)
			max = val;
	});
	return { min, max };
}

export function localDateString(utcString: string | Date, relative?: boolean) {
	return localizedDateString(store.get(AuthGetters.locale), utcString, relative);
}

function isObject(obj: any) {
	if (!obj)
		return false;
	return !Array.isArray(obj) && typeof obj === 'object';
}

export function delay(ms: number) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

export async function retry<T>(action: () => (Promise<T>), wait: number = 5000, retries: number = 5): Promise<T> {
	let tryAgain = false;
	do {
		try {
			if (tryAgain)
				console.debug(`Retrying; ${retries} attempts left...`);
			return await action();
		} catch (err: any) {
			if (err?.response?.status === 429) {
				await delay(wait);
				tryAgain = true;
			} else
				throw err;
		}
	} while (tryAgain && --retries > 0);
	console.debug('Gave up trying...');
	throw new Error('Request timed out');
}

const loadingScript = {} as any;
export function loadScript(src: string): Promise<any> {
	if (!loadingScript[src]) {
		loadingScript[src] = new Promise<void>((resolve, _) => {
			const script = document.createElement('script');
			script.onload = () => { setTimeout(() => resolve(), 10); };
			script.async = undefined;
			script.defer = undefined;
			script.src = src;
			document.head.appendChild(script);
		});
	}
	return loadingScript[src];
}

export function merge(source: any, target: any, keepOrphans?: boolean): boolean {
	let changed: boolean;

	Object.keys(source).forEach(sourceKey => {
		const sourceValue = source[sourceKey];
		let targetValue = target[sourceKey];

		if (Array.isArray(sourceValue) && Array.isArray(targetValue)) {
			if (targetValue.length > sourceValue.length) {
				targetValue.splice(sourceValue.length, targetValue.length - sourceValue.length);
				changed = true;
			}

			for (let i = 0; i < sourceValue.length; i++) {
				if (i >= targetValue.length) {
					targetValue.push(sourceValue[i]);
					changed = true;
				} else if (!targetValue[i] || !isObject(sourceValue[i]) && !isObject(targetValue[i])) {
					// tslint:disable-next-line: triple-equals
					if (targetValue[i] != sourceValue[i]) {
						// console.log(`§§§ Setting ${sourceKey}[${i}] to ${sourceValue[i]}`);
						Vue.set(targetValue, i, sourceValue[i]);
						changed = true;
					}
				} else
					changed = merge(sourceValue[i], targetValue[i]) || changed;
			}
		} else if (targetValue && isObject(sourceValue) || isObject(sourceValue)) {
			// Merge value recursively either source or target are an object (i.e. not an array/string)
			if (sourceValue != null && targetValue == null) {
				// console.log('§§§ Creating new object ' + sourceKey);
				targetValue = Vue.set(target, sourceKey, {});
			}
			if (targetValue != null)
				changed = merge(sourceValue, targetValue) || changed;
		} else {
			// tslint:disable-next-line: triple-equals
			if (targetValue != sourceValue) {
				// console.log('§§§ Setting ' + sourceKey + ' to ' + sourceValue);
				Vue.set(target, sourceKey, sourceValue);
				changed = true;
			}
		}
	});

	if (!keepOrphans) {
		// Clear old orphaned values
		const targetKeys = target ? Object.keys(target) : [];
		targetKeys.forEach(oldTargetKey => {
			if (source[oldTargetKey] == null && target[oldTargetKey] != null) {
				target[oldTargetKey] = null;
				changed = true;
			}
		});
	}

	return changed;
}

export function GetExpandCollapseIcon(expanded: boolean) {
	return expanded ? '$collapse' : '$expand';
}

export function getQuoteUrl(project: PumpProject) {
	if (project?.QuoteUrl)
		return project?.QuoteUrl;
	return project?.QuoteId ? `${quoteBaseUrl}/quotation/${project?.QuoteId}` : null;
}

export function canonicalText(x: any): string {
	if (x == null)
		return null;
	if (typeof x !== 'object')
		return x.toString();
	const entries = Object.entries(x).filter(x => x[1] != null);
	entries.sort(([x], [y]) => x > y ? 1 : x < y ? -1 : 0);
	return JSON.stringify(entries);
}
