
const MENU_TOGGLE = 'MENU_TOGGLE';
const MENU_OPEN = 'MENU_OPEN';
const MENU_CLOSE = 'MENU_CLOSE';
const MESSAGES_OPEN = 'MESSAGES_OPEN';
const MESSAGES_CLOSE = 'MESSAGES_CLOSE';

interface IMenuState {
	menuOpen: boolean;
	messagesOpen: boolean;
}

const state: IMenuState = {
	menuOpen: false,
	messagesOpen: false
};

const getters = {
	open: (currentState: IMenuState) => currentState.menuOpen,
	messagesOpen: (currentState: IMenuState) => currentState.messagesOpen
};

const actions = {
	[MENU_OPEN]: ({ commit }: any) => commit(MENU_OPEN),
	[MENU_CLOSE]: ({ commit }: any) => commit(MENU_CLOSE),
	[MENU_TOGGLE]: ({ commit }: any) => commit(MENU_TOGGLE),
	[MESSAGES_OPEN]: ({ commit }: any) => commit(MESSAGES_OPEN),
	[MESSAGES_CLOSE]: ({ commit }: any) => commit(MESSAGES_CLOSE)
};

const mutations = {
	[MENU_OPEN](currentState: any) {
		currentState.menuOpen = true;
	},
	[MENU_CLOSE](currentState: any) {
		currentState.menuOpen = false;
	},
	[MENU_TOGGLE](currentState: any) {
		currentState.menuOpen = !currentState.menuOpen;
	},
	[MESSAGES_OPEN](currentState: any) {
		currentState.messagesOpen = true;
	},
	[MESSAGES_CLOSE](currentState: any) {
		currentState.messagesOpen = false;
	}
};

export const MenuActions = {
	[MENU_OPEN]: `menu/${MENU_OPEN}`,
	[MENU_CLOSE]: `menu/${MENU_CLOSE}`,
	[MENU_TOGGLE]: `menu/${MENU_TOGGLE}`,
	[MESSAGES_OPEN]: `menu/${MESSAGES_OPEN}`,
	[MESSAGES_CLOSE]: `menu/${MESSAGES_CLOSE}`
};

export const enum MenuGetters {
	open = 'menu/open',
	messagesOpen = 'menu/messagesOpen'
}

export const menu = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
