<template>
<div :title="state" style="list-style:none !important; display: flex;">
	<svg :stroke="color" :fill="color" xmlns="http://www.w3.org/2000/svg" :height="sz" :width="sz" viewBox="0 0 48 48">
		<path d="M24 44Q19.75 44 16.1 42.475Q12.45 40.95 9.75 38.25Q7.05 35.55 5.525 31.9Q4 28.25 4 24Q4 19.8 5.525 16.15Q7.05 12.5 9.75 9.8Q12.45 7.1 16.1 5.55Q19.75 4 24 4Q28.2 4 31.85 5.55Q35.5 7.1 38.2 9.8Q40.9 12.5 42.45 16.15Q44 19.8 44 24Q44 28.25 42.45 31.9Q40.9 35.55 38.2 38.25Q35.5 40.95 31.85 42.475Q28.2 44 24 44Z"/>
		<g stroke="white" fill="white">
			<path v-if="icon == 'check_circle'" d="M21.05 33.1 35.2 18.95 32.9 16.7 21.05 28.55 15.05 22.55 12.8 24.8Z"/>
			<path v-if="icon == 'error'" d="M22.65 26.35H25.65V13.7H22.65ZM24 34Q24.7 34 25.175 33.525Q25.65 33.05 25.65 32.35Q25.65 31.65 25.175 31.175Q24.7 30.7 24 30.7Q23.3 30.7 22.825 31.175Q22.35 31.65 22.35 32.35Q22.35 33.05 22.825 33.525Q23.3 34 24 34Z"/>
			<path v-if="icon == 'cancel'" d="M16.5 33.6 24 26.1 31.5 33.6 33.6 31.5 26.1 24 33.6 16.5 31.5 14.4 24 21.9 16.5 14.4 14.4 16.5 21.9 24 14.4 31.5Z"/>
			<path v-if="icon == 'info'" d="M22.65 34H25.65V22H22.65ZM24 18.3Q24.7 18.3 25.175 17.85Q25.65 17.4 25.65 16.7Q25.65 16 25.175 15.5Q24.7 15 24 15Q23.3 15 22.825 15.5Q22.35 16 22.35 16.7Q22.35 17.4 22.825 17.85Q23.3 18.3 24 18.3Z"/>
			<path v-if="icon == 'remove_circle'" d="M14 25.35H34V22.35H14Z"/>
		</g>
	</svg>
</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { SizingState } from 'types/dto/CalcServiceDomain';
	import colors from '@/sass/_variables.scss';

	@Component
	export default class SizingIcon extends Vue {
		@Prop() public state: SizingState;
		@Prop() public size: number;

		public get color() {
			return SizingIcon.colorForState(this.state);
		}

		public get sz() {
			return this.size ?? 19;
		}

		public static colorForState(state: SizingState | 'Info') {
			switch (state) {
				case SizingState.Error:
					return colors.error;
				case SizingState.Warning:
					return colors.warning;
				case SizingState.Completed:
					return colors.success;
				case 'Info':
					return colors.selection;
				default:
					return colors.grey;
			}
		}

		public get icon() {
			return SizingIcon.iconForState(this.state);
		}

		// Original material icon name
		private static iconForState(state: SizingState | 'Info') {
			switch (state) {
				case SizingState.Error:
					return 'cancel';
				case SizingState.Warning:
					return 'error';
				case SizingState.Completed:
					return 'check_circle';
				case 'Info':
					return 'info';
				default:
					return 'remove_circle';
			}
		}
	}
</script>
