<template>
	<v-card outlined class="pa-4 pb-6" v-if="sizing && values" :key="sizing && sizing.id">
		<v-row>
			<v-col class="pl-9 pt-4 mb-0">
				<h2>Sizing preview</h2>
			</v-col>
		</v-row>
		<v-row v-if="hasErrors">
			<v-col class="pl-9">
				<span class="d-flex align-center">
					<SizingIcon state="Error" class="pr-1" />
					<strong>Sizing has errors</strong>
				</span>
			</v-col>
		</v-row>
		<v-row v-else-if="hasWarnings">
			<v-col class="pl-9">
				<span class="d-flex align-center">
					<SizingIcon state="Warning" class="pr-1" />
					<strong>Sizing has {{hasWarnings}} warnings</strong>
				</span>
			</v-col>
		</v-row>
		<v-row>
			<!-- pump type, slurry data, etc -->
			<v-col sm="6" xs="12" class="sizing-left" max-width="100px">		
				<ul class="sizing-list">
					<li>{{ getValueDescription('Pump.DisplayName') }}</li>
					<li>{{ getValueDescription('Slurry.SolidsDensity') }}</li>
					<li>{{ getValueDescription('Slurry.FlowRate') }}</li>
					<li>{{ getValueDescription('Heads.TDH') }}</li>
					<li>{{ getValueDescription('Slurry.SlurryDensity') }}</li>
					<li>{{ getValueDescription('ServiceClass.DutyClass') }}</li>
				</ul>
			</v-col>
			<v-col sm="6" xs="12">
				<ul class="sizing-list pl-0">
					<li><strong>{{pumpDescription}}</strong></li>
					<li>{{ getValueText('Slurry.SolidsDensity') }}</li>
					<li>{{ getValueText('Slurry.FlowRate') }}</li>
					<li>{{ getValueText('Heads.TDH') }}</li>
					<li>{{ getValueText('Slurry.SlurryDensity') }}</li>
					<li>{{ getValueText('ServiceClass.DutyClass') }}</li>
				</ul>
			</v-col>
		</v-row>
	</v-card>
		<v-card v-else outlined class="pa-4 pb-6">
		<v-row>
			<v-col class="pl-9 pt-4 mb-0">
				<h2>Sizing preview</h2>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="pl-9 pt-2 pb-4 mb-0">
				<span class="grey--text">Mouse over a sizing to see preview.</span>
			</v-col>
		</v-row>
	</v-card>
</template>

<style lang="css" scoped>
	ul.sizing-list li {
		list-style-type: none;
		font-size: 14px;
	}

	.sizing-left {
		max-width: 200px;
	}

	.align-center {
		align-items: center;
	}
</style>

<script lang="ts">
import { ParamBag } from '@/common/ParamBag';
import store, { AuthGetters, SizingGetters } from '@/store';
import { MessageSeverity, PumpDocument } from 'types/dto/CalcServiceDomain';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import SizingIcon from '@/components/SizingIcon.vue';

@Component({
	components: {
		SizingIcon
	}
})
export default class SizingPreview extends Vue {
	@Prop() public values: ParamBag;

	get sizing() {
		return this.values ? store.get(SizingGetters.sizing, this.values.sizingId) as PumpDocument : null;
	}

	get hasWarnings() {
		return this.sizing?.Status?.filter(x => x.Severity === MessageSeverity.Warning).length || '';
	}

	get hasErrors() {
		return this.sizing?.Status.some(x => x.Severity >= MessageSeverity.Error) || false;
	}

	get pumpDescription() {
		const pump = this.values.get<any>('Pump');
		return pump?.DisplayName || ' ';
	}

	get useImperial() {
		const sou = ParamBag.getSizingSOU(this.values.sizingId) || store.get(AuthGetters.systemOfUnit);
		return sou === 'Imperial';
	}

	public getValueDescription(name: string) {
		return this.values?.getParam(name)?.definition?.Description;
	}

	public getValueText(name: string) {
		return this.values?.getParam(name).getUnitValue(this.useImperial).toString() || ' ';
	}
}
</script>
