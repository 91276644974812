<template>
	<v-container>
		<v-layout wrap>
			<v-flex xs12>
				<h1 class="text-xs-center">{{ title }}</h1>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus rutrum non risus sit amet consectetur. Cras porttitor nibh orci, elementum volutpat velit commodo in. Nulla ac tempor metus, quis porta odio. Quisque sed ex fringilla, fringilla ante vel, molestie ex. Curabitur velit mi, maximus in vehicula at, pellentesque et lectus. Pellentesque ut enim in odio vehicula tincidunt non vel tortor. Sed sit amet egestas nisl, at viverra justo. Donec est est, tempor quis mi at, luctus tempor lacus. Quisque sit amet tortor arcu. Curabitur cursus orci eget rhoncus vulputate. Integer ex est, ullamcorper vitae ultricies ac, lacinia in neque. Nunc laoreet nisl et rhoncus egestas.
				</p>
				<p>
					Sed blandit sapien quam, in hendrerit orci accumsan nec. Vivamus nisi ante, varius nec molestie nec, porttitor eget mi. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Fusce ut luctus nisi, et dapibus orci. In euismod massa eget lectus rutrum, a sodales erat tincidunt. In augue nibh, tincidunt eu vehicula et, aliquet porta turpis. Duis luctus a ex vitae tincidunt. Donec et iaculis nunc, a tempus mauris. Ut bibendum laoreet lacus.
				</p>
				<p>
					Praesent et justo at ligula elementum porttitor et id est. Phasellus quam magna, lobortis non tincidunt a, suscipit sit amet nibh. In vitae faucibus velit. Sed porta consequat risus. Integer in nunc at risus rutrum sagittis. Aenean dictum metus sed libero ornare placerat. Proin auctor, velit sed volutpat laoreet, lacus nibh condimentum sem, in sagittis dui eros ac ipsum. Duis tempor imperdiet elementum. Sed interdum mollis hendrerit.
				</p>
				<p>
					Phasellus accumsan tortor orci, ac feugiat ligula congue gravida. Vivamus aliquam gravida quam non auctor. Suspendisse tempor congue tellus sed aliquet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In id est dui. Ut molestie in neque ac vehicula. Phasellus dapibus, enim eget sollicitudin feugiat, orci sem luctus metus, vel venenatis neque est dapibus leo. Pellentesque convallis ante nibh, in consequat nunc fringilla quis. Nullam rutrum ante sit amet consequat fermentum. Maecenas blandit luctus dolor at scelerisque. Duis arcu massa, rhoncus vitae nunc ut, scelerisque aliquet mauris. Praesent consectetur aliquam euismod. Aenean eu cursus nisl, sed sagittis lectus. Proin rutrum sit amet mi eget lobortis. Nunc eu orci ac neque cursus pulvinar quis ut orci.
				</p>
				<p>
					Sed orci erat, feugiat quis nunc quis, consequat congue justo. Quisque eu risus nulla. Maecenas imperdiet urna felis, id lobortis lectus cursus in. Sed fringilla neque non tempor iaculis. Maecenas mollis arcu eu velit imperdiet ultricies. Sed mauris sapien, mattis in metus sit amet, eleifend mollis nunc. In hac habitasse platea dictumst. Nulla aliquam nisl commodo egestas tempor. Morbi blandit velit sed ornare euismod. Praesent viverra, orci vel porttitor rutrum, ipsum ex aliquet ligula, vitae porta ipsum turpis ac mi.
				</p>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script lang="ts">
	import Vue from 'vue';
	import { Component } from 'vue-property-decorator';

	@Component({
		name: 'About',
	})
	export default class Project extends Vue {
		public get title() { return this.$t('app.about'); }
	}
</script>