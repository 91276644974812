<template>
	<v-list-item class="pl-6 pr-6">
		<v-list-item-content class="grid slurryMode">
			<div class="mb-3">
				<span class="theme--light thin">Specify flow rate by</span>
				<v-chip-group mandatory v-model="flowMode" active-class="sel-choice-chip">
					<v-chip small value="vol" :disabled="locked" :outlined="flowMode !== 'vol'" v-keyboard-click>volume</v-chip>
					<v-chip small value="mass" :disabled="locked" :outlined="flowMode !== 'mass'" v-keyboard-click>mass</v-chip>
				</v-chip-group>
			</div>
			<div class="mb-3">
				<span class="theme--light thin" v-text="$tc('Slurry.DensityMode')"></span>
				<v-chip-group mandatory v-model="densityMode" active-class="sel-choice-chip">
					<v-chip small value="SolidsDensity" :disabled="locked" :outlined="densityMode !== 'SolidsDensity'"
						v-keyboard-click v-text="$tc('DensityMode.SolidsDensity')" />
					<v-chip small value="SlurryDensity" :disabled="locked" :outlined="densityMode !== 'SlurryDensity'"
						v-keyboard-click v-text="$tc('DensityMode.SlurryDensity')" />
				</v-chip-group>
			</div>

			<div class="mb-3">
				<span class="theme--light thin">Specify concentration by</span>
				<v-chip-group mandatory v-model="concMode" active-class="sel-choice-chip">
					<v-chip small value="vol" :disabled="locked" :outlined="concMode !== 'vol'" v-keyboard-click>volume</v-chip>
					<v-chip small value="mass" :disabled="locked" :outlined="concMode !== 'mass'" v-keyboard-click>weight</v-chip>
				</v-chip-group>
			</div>

			<UnitNumeric v-if="flowMode === 'mass'" :locked="locked" readwrite :param="getParam('Slurry.MassFlow')" :target="target" key="MassFlow" />
			<UnitNumeric v-else :locked="locked" :param="getParam('Slurry.FlowRate')" :target="target" key="FlowRate" />

			<UnitNumeric v-if="densityMode === 'SlurryDensity'" :locked="locked" :param="getParam('Slurry.SlurryDensity')" :target="target" key="SlurryDensity" />
			<UnitNumeric v-else :locked="locked" :param="getParam('Slurry.SolidsDensity')" :target="target" key="SolidsDensity" />

			<UnitNumeric v-if="concMode === 'mass'" :locked="locked" readwrite :param="getParam('Slurry.ConcByWeight')" :target="target" key="ConcByWeight" />
			<UnitNumeric v-else :locked="locked" readwrite :param="getParam('Slurry.ConcByVolume')" :target="target" key="ConcByVolume" />

			<UnitNumeric :locked="locked" :param="getParam('Slurry.FrothFactor')" :target="target" />

			<div style="height: 90%"> <!-- Enlarge height to prevent contents from aligning too low -->
				<UnitNumeric v-if="flowMode === 'mass'" :locked="true" inline readwrite :param="getParam('Slurry.FlowRate')" :target="target" key="FlowRateRO" />
				<UnitNumeric v-if="densityMode === 'SlurryDensity'" :locked="true" inline readwrite :param="getParam('Slurry.SolidsDensity')" :target="target" key="SolidsDensityRO" />
				<UnitNumeric v-else :locked="true" inline readwrite :param="getParam('Slurry.SlurryDensity')" :target="target" key="SlurryDensityRO" />
				<UnitNumeric :locked="locked" inline readwrite :param="getParam('Slurry.SlurryViscosity')" :target="target" key="SlurryViscosityRO" />
				<UnitNumeric :locked="locked" inline readwrite :param="getParam('Slurry.FrothFlow')" :target="target" key="FrothFlowRO" />
			</div>
		</v-list-item-content>
	</v-list-item>
</template>

<style lang="scss" scoped>
@import '@/sass/_variables.scss';

	.slurryMode {
		padding-top: 4px;
	}
	.slurryMode div span {
		margin-right: 6px;
	}
	.slurryMode div .v-item-group {
		display: inline-block;
		vertical-align: middle;
	}

</style>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop, Watch } from 'vue-property-decorator';
	import UnitNumeric from '@/components/Fields/UnitNumeric.vue';
	import { ParamBag } from '@/common/ParamBag';
	import { ParamValue } from '@/common/ParamValue';
	import SelectField from '@/components/Fields/SelectField.vue';
	import { DensityMode, SlurryMode } from 'types/dto/CalcServiceDomain';

	@Component({
		components: {
			UnitNumeric,
			SelectField
		}
	})
	export default class SlurryData extends Vue {
		@Prop() public values: ParamBag;
		@Prop() public locked: boolean;
		@Prop() public target: string;

		public concMode: string = 'vol';
		public flowMode: string = 'vol';

		public created() {
			this.selectedMode = this.currentMode;
		}

		public getParam(n: string): ParamValue {
			return this.values.getParam(n);
		}

		public get currentMode() {
			return this.values?.get<SlurryMode>('Slurry.SlurryMode') || SlurryMode.ConcByVolume;
		}

		public get densityMode() {
			return this.values?.get<DensityMode>('Slurry.DensityMode') || DensityMode.SolidsDensity;
		}

		public set densityMode(newMode: DensityMode) {
			this.values.set('Slurry.DensityMode', newMode);
		}

		@Watch('currentMode')
		private savedModeChanged(newMode: SlurryMode) {
			this.selectedMode = this.currentMode;
		}

		@Watch('selectedMode')
		private selectedModeChanged(newMode: SlurryMode) {
			// Recheck here to avoid accidental write in watch init race
			if (this.values && this.values.get<SlurryMode>('Slurry.SlurryMode') !== newMode)
				this.values.set('Slurry.SlurryMode', newMode);
		}

		public get selectedMode() {
			const m = (this.concMode || 'vol') + (this.flowMode || 'vol');
			switch (m) {
				case 'volvol': return SlurryMode.ConcByVolume;
				case 'massvol': return SlurryMode.ConcByWeight;
				case 'volmass': return SlurryMode.MassFlowAndVolConc;
				case 'massmass': return SlurryMode.MassFlowAndWeightConc;
			}
		}

		public set selectedMode(mode: SlurryMode) {
			if (mode === SlurryMode.ConcByWeight || mode === SlurryMode.MassFlowAndWeightConc)
				this.concMode = 'mass';
			else
				this.concMode = 'vol';

			if (mode === SlurryMode.MassFlowAndVolConc || mode === SlurryMode.MassFlowAndWeightConc)
				this.flowMode = 'mass';
			else
				this.flowMode =  'vol';
		}
	}
</script>
