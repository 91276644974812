<template>
<svg width="15px" height="16px" viewBox="0 0 15 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="UI-Kit---NEW" stroke="none" stroke-width="1" fill="currentColor" fill-rule="evenodd">
        <g id="Icons" transform="translate(-123.000000, -292.000000)" fill="#4B494C" fill-rule="nonzero">
            <g id="UI-036--General-enquiry" transform="translate(123.000000, 292.000000)">
                <polygon id="Path" points="6.5 11 7.51 11 7.51 12 6.5 12"></polygon>
                <path d="M12.5,0 L2.5,0 C1.11997342,0.00165305817 0.00165305817,1.11997342 0,2.5 L0,9.5 C0,10.879 1.122,12 2.5,12 L5.5,12 L5.5,11 L2.5,11 C1.673,11 1,10.327 1,9.5 L1,2.5 C1,1.673 1.673,1 2.5,1 L12.5,1 C13.327,1 14,1.673 14,2.5 L14,9.5 C14,10.327 13.327,11 12.5,11 L12,11 L12,12.793 L10.207,11 L8.52,11 L8.52,12 L9.793,12 L13,15.207 L13,11.95 C14.14,11.718 15,10.707 15,9.5 L15,2.5 C15,1.122 13.879,0 12.5,0 Z" id="Path"></path>
                <path d="M7.5,2.44 C6.491,2.44 5.67,3.261 5.669,4.269 L5.669,4.769 L6.669,4.77 L6.669,4.27 C6.669,3.812 7.042,3.44 7.499,3.44 C7.957,3.44 8.329,3.813 8.331,4.222 C8.27460657,4.83171057 7.8814688,5.35846884 7.313,5.586 C7.12376389,5.66231236 6.99988344,5.84595621 7,6.05 L7,7.55 L8,7.55 L8,6.363 C8.746,5.942 9.245,5.18 9.33,4.27 C9.33,3.262 8.51,2.441 7.5,2.44 L7.5,2.44 Z M7,8.99 L8,8.99 L8,10 L7,10 L7,8.99 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
    export default {};
</script>
