<template>
	<v-dialog width="600" v-model="visible">
		<v-card @keyup.esc="close" class="pa-4">
			<v-card-title>
				<span><h2>Share {{ sizing ? 'sizing' : 'project' }}</h2></span>
				<b class="sizing-title">{{ (sizing || project).Name }}</b>
			</v-card-title>
			<v-card-text>
				<UserSelector :disabled="working" v-model="user" />
				<v-switch v-if="canMakeEditor" label="Make project editor" v-model="makeEditor" :disabled="working" />
				<v-textarea outlined label="Message" v-model="message" :disabled="working" rows="4" maxlength="2000" />
			</v-card-text>
			<v-card-actions class="mr-4">
				<v-spacer></v-spacer>
				<v-btn color="primary" text @click="close">{{ $t('common.cancel') }}</v-btn>
				<v-btn text @click="ok" :disabled="!canSend" :class="[canSend ? 'focus':'']">{{ $t('common.ok') }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style lang="scss" scoped>

	.sizing-title {
		margin-left: 6px;
	}
	
</style>
<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { PumpDocument, PumpProject } from 'types/dto/CalcServiceDomain';
	import store, { SnackActions, AuthGetters, ProjectActions } from '@/store';
	import UserSelector, { IUserEntry } from '@/components/UserSelector.vue';
	import SizingInfo from '@/common/SizingInfo';
	import MsgService from '@/services/messages.service';
	import { MessageType, TargetType } from 'types/dto/Messages';
	import InsightService from '@/services/insight.service';

	@Component({
		components: {
			UserSelector
		}
	})
	export default class ShareSizingDialog extends Vue {
		@Prop() public sizing: PumpDocument;
		@Prop() public project: PumpProject;

		public visible: boolean = false;
		public makeEditor: boolean = true;
		public message: string = null;
		public working: boolean = false;
		public user: IUserEntry = null;

		public mounted() {
			this.visible = true;
			const uname = store.get(AuthGetters.fullName);
			if (this.sizing)
				this.message = uname + ' would like to share this sizing with you.';
			else
				this.message = uname + ' would like to share this project with you.';
		}

		public get canSend() {
			return this.user != null && this.user.id !== this.me;
		}

		public get canMakeEditor() {
			return this.project && !SizingInfo.isLocked(this.project);
		}

		private get me() {
			return store.get(AuthGetters.userId);
		}

		public async ok() {
			if (!this.user) {
				this.close();
				return;
			}

			try {
				this.working = true;
				store.dispatch(SnackActions.set, 'Sharing...');

				if (this.makeEditor && this.canMakeEditor) {
					const editors = SizingInfo.editors(this.project, true);
					if (!editors.includes(this.user.id)) {
						const newEditors = editors.concat([this.user.id]);
						await store.dispatch(ProjectActions.setEditors, { projectId: this.project.id, editors: newEditors });
					}
				}

				let targetUrl: string;
				if (this.sizing)
					targetUrl = this.$router.resolve({ name: 'directsizing', params: { sizingid: this.sizing.id } }).href;
				else if (this.project)
					targetUrl = this.$router.resolve({ name: 'project', params: { id: this.project.id } }).href;

				if (targetUrl)
					targetUrl = `${window.location.origin}/${targetUrl}`;

				await MsgService.send({
					Type: MessageType.Direct,
					TargetType: this.sizing ? TargetType.Sizing : TargetType.Project,
					Target: (this.sizing || this.project).id,
					TargetUrl: targetUrl,
					Text: this.message,
					Recipients: [this.user.id]
				});

				InsightService.trackEvent(this.sizing ? 'Sizing:SendLink' : 'Project:SendLink');
			} catch (ex) {
				store.dispatch(SnackActions.set, 'Sharing failed. Please try later.');
			} finally {
				this.working = false;
			}

			this.close();
		}

		public close() {
			this.visible = false;
			this.$emit('close-dialog', 'shareSizing');
		}
	}
</script>
