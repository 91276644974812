<template>
	<v-dialog
		v-model="dialog"
		:max-width="options.width"
		:style="{ zIndex: options.zIndex }"
		@keydown.esc="cancel"
		@keydown.enter="agree"
	>
		<v-card class="pa-4">
			<v-toolbar dense flat>
				<v-toolbar-title><h3>{{ title }}</h3></v-toolbar-title>
			</v-toolbar>
			<v-card-text class="pl-4" v-show="!!message">{{ message }}</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="grey" text @click.native="cancel">{{ options.cancelText }}</v-btn>
				<v-btn v-focus :color="options.color" class="focus" text @click.native="agree">{{ options.agreeText }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style lang="scss" scoped>
	@import '@/sass/_variables.scss';
	.v-btn.focus {
		background-color: $primary;
	}
</style>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class Confirm extends Vue {
	public dialog: boolean = false;
	private resolve: any = null;
	public message: string = '';
	public title: string = 'Are you sure?';

	public options: any = {
		color: 'white',
		width: 330,
		zIndex: 200,
		agreeText: 'Yes',
		cancelText: 'No',
	};

	public open(message: string, options: any) {
		this.dialog = true;
		this.message = message;
		this.options = Object.assign(this.options, options);
		return new Promise((resolve, _) => {
			this.resolve = resolve;
		});
	}

	public agree() {
		this.resolve(true);
		this.dialog = false;
	}

	public cancel() {
		this.resolve(false);
		this.dialog = false;
	}
}
</script>