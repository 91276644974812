import { PumpResult } from './PumpSearch';
import { BasePumpFrameDef, BearingAssemblyResult, MotorResult, Drive, ShaftSealResult } from './PumpBuild';
import { VBeltResult } from 'types/dto/VBelt';
import { PipingModule } from './PipingModule';

export interface SiteInfo {
	Altitude: number;
	AmbientTemp: number;
	MainsVoltage: number;
	MainsFreq: number;
	SystemOfUnits: 'Imperial' | 'Metric';
}

export interface LiquidInfo {
	Temp: number;
	pH: number;
	Density: number;
	DynamicViscosity: number;
	VapourPressure: number;
	CorrosionIndex: number;
}

export interface ParticleInfo {
	d80: number;
	d50: number;
	d100: number;
	Sub200micron: number;
	Sub40micron: number;
}

export enum SlurryMode {
	ConcByVolume = 'ConcByVolume',
	ConcByWeight = 'ConcByWeight',
	MassFlowAndWeightConc = 'MassFlowAndWeightConc',
	MassFlowAndVolConc = 'MassFlowAndVolConc'
}

export enum DensityMode {
	SolidsDensity = 'SolidsDensity',
	SlurryDensity = 'SlurryDensity'
}

export enum DeratingMethod {
	Manual = 'Manual',
	HI2005 = 'HI2005'
}

export interface SlurryInfo {
	FlowRate: number;
	SolidsDensity: number;
	ConcByVolume: number;
	ConcByWeight: number;
	FrothFactor: number;
	SlurryDensity: number;
	SlurryViscosity: number;
	SlurryMode: SlurryMode;
	DeratingMethod?: DeratingMethod;
	ManualHR: number;
	ManualER: number;
	ManualQR: number;
}

export enum TDHMode {
	Calculate = 'Calculate',
	Manual = 'Manual'
}

export interface HeadsInfo {
	TDHMode: TDHMode;
	NPSHA: number;
	TDH: number;
	PDH: number;
	InletHead: number;
}

export interface ServiceClassInfo {
	DutyClass: number;
	MinBepPercent: number;
	MaxBepPercent: number;
	MaxTipSpeedNR: number;
	MaxTipSpeedHC: number;
	MaxHead: number;
	SlurryHRFactor: number;
	MaxInletVelocity: number;
}

export enum InletEndConfig {
	Tank = 'Tank', Sump = 'Sump', Thickener = 'Thickener'
}

export interface InletInfo {
	InletMin: number;
	InletMax: number;
	InletDesign: number;
	EndConfig: InletEndConfig;
	Pipes: Pipe[];
	TankPressure: number;

	// Calculated
	PipeEndLoss: number;
	PipeLoss: number;
	TankPressureHead: number;
}

export enum OutletEndConfig {
	Free = 'Free', Cyclone = 'Cyclone', Nozzle = 'Nozzle', PressureVessel = 'PressureVessel', Submerged = 'Submerged',
	PressFilter = 'PressFilter'
}

export interface OutletInfo {
	StaticOutlet: number;
	EndConfig: OutletEndConfig;
	PipeEndLoss: number;
	PipeLoss: number;
	Pressure: number;
	Pipes: Pipe[];
}

export interface AdditionalLength {
	Key: string;
	Amount: string;
}

export interface Pipe {
	RoughnessHeight: number;
	Diameter: number;
	Length: number;
	ExtraLength?: number;
	AdditionalLengths: AdditionalLength[];
	Module: PipingModule;

	// Calculated
	DepositionVelocity: number;
	BottomVelocity: number;
	Velocity: number;
	FrictionLoss: number;
	OutDiameter: number;
	TaperPieceEqLength: number;
}

export const enum DocState { Deleted = 1 }

export interface MetaData {
	id: string;
	Created: Date | string;
	CreatedBy: string;
	LastChanged: Date | string;
	LastChangedBy: string;
	DocState?: DocState;
	Editors: string[];
}

export enum ParentRelation { Stage = 1, DutyPoint = 2 }

export interface PumpDocMetaData extends MetaData {
	ProjectId: string;
	Name: string;
	State: SizingState;

	ParentId: string;
	ParentRelation?: ParentRelation;
	Subtitle: string;
	Staged: boolean;
	MDP: boolean;
}

export interface PumpDocument extends PumpDocMetaData {
	Data: PumpParams;
	Status: ValidationResult[];
	Notes: string;
	Assumed: string[];
}

export interface PumpParams {
	Site: SiteInfo;
	Liquid: LiquidInfo;
	Particles: ParticleInfo;
	Slurry: SlurryInfo;
	Heads: HeadsInfo;
	ServiceClass: ServiceClassInfo;
	Inlet: InletInfo;
	Outlet: OutletInfo;
	Pump: PumpResult;
	Frame: BasePumpFrameDef;
	ShaftSeal: ShaftSealResult;
	BearingAssembly: BearingAssemblyResult;
	Flange: any;
	Motor: MotorResult;
	Drive: Drive;
	VBelt: VBeltResult;
}

export enum MessageSeverity {
	UsedValue = -2,
	Debug = -1,
	Missing = 0,
	Info = 1,
	Warning = 2,
	Error = 3,
	InternalError = 4
}

export interface ValidationResult {
	Severity: MessageSeverity;
	Source: string;
	ParamName: string;
	Message: string;
	External?: boolean;
}

export interface ParamChange {
	ParamName: string;
	OldValue: string;
	NewValue: string;
}

export enum SizingState {
	InProgress = 'InProgress',
	Warning = 'Warning',
	Error = 'Error',
	Completed = 'Completed'
}

export interface CalcResult {
	Results: ValidationResult[];
	Changes: ParamChange[];
	State: SizingState;
}

export interface SelectValue {
	text: string;
	value: string;
}

export interface ParameterDef {
	Name: string;
	Type: string;
	Unit: string;
	PreferredUnit: string;
	PrefImperialUnit: string;
	ReadOnly?: boolean;
	Description: string;
	StaticMin: number;
	StaticMax: number;
	Decimals: number;
	Order?: number;
	Tags?: string[];
	Values?: SelectValue[];
	ImperialValues?: SelectValue[];
}

export interface PumpProject extends MetaData {
	Name: string;
	CustomerId: string;
	CustomerName: string;
	CustomerReference: string;
	QuoteId: string;
	QuoteUrl: string;
	Notes: string;
	Site: SiteInfo;
}

export interface PumpStage {
	PDH: number;
}

export interface StagingDefinition {
	Stages: PumpStage[];
}

// Source of validation errors from Staging.cs when restage is required
export const StagingErrorSource = 'Staging';

export interface CopySizingInputs {
	SizingId: string;
	NewName?: string;
	TargetProjectId?: string;
}

export interface SystemCurveInputs {
	SizingId: string;
	FromFlow: number;
	ToFlow: number;
	MaxHead: number;
	WaterOnly?: boolean;
}

export interface SystemCurveResult {
	Points: number[];
}

