<template>
	<transition name="change" mode="out-in">
	<v-card outlined class="pt-2 pb-8" key="recentSizings" v-if="items && items.length">
		<v-row>
			<v-col class="pt-6">
				<h2 class="pl-10">Recently edited</h2>
			</v-col>
		</v-row>
		<v-row no-gutters>
			<v-col>
				<v-list dense>
					<v-list-item v-for="i of items" :key="i.id" class="pl-10 pr-7">
						<v-list-item-content @mouseenter="sizingHover(i)">
							<div class="ellipsis inlined sizing">
								<a class="item-link" :href="sizingLink(i)"><h4>{{ i.Name }}</h4></a>
							</div>
							<div class="ellipsis inlined project">
								in <a class="item-link" :href="projectLink(i)">{{ i.ProjectName || 'project' }}</a>
								<span class="grey--text ml-1">{{ localDateString(i.LastChanged, true) }}</span>
							</div>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-col>
		</v-row>
	</v-card>
	</transition>
</template>

<style lang="scss" scoped>
	@import '@/sass/_variables.scss';
	.ellipsis {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	a.item-link {
		color: $primary !important;
		text-decoration: none;
	}

	a.item-link:hover {
		text-decoration: underline;
	}

	.sizing {
		font-size: 90%;
		font-weight: bold;
	}

	.project {
		font-size: 85%;
	}

	.inlined {
		display: inline-block;
	}

	.change-enter-active, .change-leave-active{
		transition: opacity 0.5s ease;
	}
	.change-enter, .change-leave-to {
		opacity: 0;
	}

	.v-list-item:hover {
    	background-color: $selection-lighten-1
	}

	.v-card {
		border:0;
	}

</style>

<script lang="ts">
import { ParamBag } from '@/common/ParamBag';
import { delay, localDateString } from '@/common/Tools';
import SizingService from '@/services/sizing.service';
import store, { ProjectGetters, SizingActions, SizingGetters } from '@/store';
import { PumpDocMetaData, PumpDocument } from 'types/dto/CalcServiceDomain';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

type Entry = PumpDocMetaData & { ProjectName?: string };

@Component({})
export default class RecentSizings extends Vue {
	@Prop() public values: ParamBag;

	private static projectCache: any = {};
	public recent: Entry[] = [];

	public async created() {
		await delay(1000);
		const sizings = await SizingService.getRecentSizings() as Entry[];
		this.getMissingProjects(sizings);
		this.recent = sizings;
	}

	private async getMissingProjects(entries: Entry[]) {
		const missing = new Set<string>();
		entries.filter(x => !x.ProjectName).forEach(sz => {
			sz.ProjectName = store.get(ProjectGetters.project, sz.ProjectId)?.Name ||
				RecentSizings.projectCache[sz.ProjectId]?.Name || null;
			if (sz.ProjectName == null)
				missing.add(sz.ProjectId);
		});

		// Load missing projects (i.e. outside current filter but contain changed sizings)
		for (const projId of missing) {
			const proj = await SizingService.getProject(projId);
			if (proj) {
				RecentSizings.projectCache[projId] = proj;
				entries.filter(x => x.ProjectId === projId).forEach(x => x.ProjectName = proj.Name);
			}
		}
	}

	public get items() {
		const items = this.recent;
		this.getMissingProjects(items);
		for (const sz of items) {
			const localItem = store.get(SizingGetters.sizing, sz.id) as PumpDocument;
			if (localItem?.LastChanged > sz.LastChanged)
				sz.LastChanged = localItem.LastChanged;
		}
		items.sort((a, b) => a.LastChanged === b.LastChanged ? 0 : a.LastChanged < b.LastChanged ? 1 : -1);
		return items.slice(0, 10);
	}

	public sizingLink(sz: Entry) {
		return `#/project/${sz.ProjectId}/${sz.id}`;
	}

	public projectLink(sz: Entry) {
		return `#/project/${sz.ProjectId}`;
	}

	public async sizingHover(sz: Entry) {
		if (sz && !store.get(SizingGetters.sizing, sz.id))
			sz = await store.dispatch(SizingActions.getSizing, sz.id);
		this.$emit('sizingHover', sz);
	}

	public localDateString = localDateString;
}
</script>
