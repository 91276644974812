import { render, staticRenderFns } from "./FilterList.vue?vue&type=template&id=2b333b0b&scoped=true"
import script from "./FilterList.vue?vue&type=script&lang=ts"
export * from "./FilterList.vue?vue&type=script&lang=ts"
import style0 from "./FilterList.vue?vue&type=style&index=0&id=2b333b0b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b333b0b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VChip,VChipGroup,VIcon})
