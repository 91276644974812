<template>
	<div :class="{ 'comment-icon-container': true, 'unread': unread, 'extChange': externalChange, 'showing': showing }">
		<v-menu max-width="750" open-delay="500" :disabled="showAdd" :close-on-content-click="!showOnClick" :open-on-hover="!showOnClick" offset-y v-model="showing">
			<template v-slot:activator="{ on }">
				<div v-show="visible || count" v-on="on" @click.stop="newComment">
					<slot v-bind:count="count" v-bind:expanded="showing">
						<!-- Show tooltip when there are no messages/not adding comments. Otherwise the v-menu pops out. -->
						<v-tooltip bottom open-delay="500" :disabled="count > 0 || showAdd">
							<template v-slot:activator="{ on }">
								<span v-on="on">
									<v-icon class="comment-icon">$comment</v-icon>{{ count || ''}}
								</span>
							</template>
							Click to add a comment
						</v-tooltip>
					</slot>
				</div>
				<v-menu style="top: -8px" offset-y v-model="showAdd" :close-on-content-click="false">
					<!-- Empty activator binding to suppress "The activator slot must be bound" -->
					<template #activator="{}">
						<div><!-- Menu anchor element --></div>
					</template>
					<!-- Focusing via v-focus only works at insert time, so v-if to recreate the whole thing each time -->
					<div style="width: 400px" v-if="showAdd">
						<v-card>
							<v-card-text>
								<v-text-field v-focus label="Write comment" v-model="commentText" maxlength="2000"
									messages="Press enter to save comment; esc to cancel" @keydown.enter="saveComment" />
							</v-card-text>
						</v-card>
					</div>
				</v-menu>
			</template>
			<div v-if="count || showOnClick" style="width: 750px">
				<v-card>
					<v-card-text>
						<ChangeLog :target="target" :type="type" :subtarget="field" :noui="!showOnClick" :showDelete="showDelete" />
					</v-card-text>
				</v-card>
			</div>
		</v-menu>
	</div>
</template>

<style>
	.comment-icon-container span {
		margin-left: 4px;
		font-size: 75%;
		font-weight: 400;
		position: absolute;
		right: 4px;
		top: 0;
		cursor: pointer;
		color: black;
	}

	.comment-icon-container.unread {
		text-shadow: 0 0 10px #00f !important;
	}

	.comment-icon-container.extChange {
		text-shadow: 0 0 10px #0a0;
	}

	.readonlyValue .comment-icon-container {
		display: inline-block;
		position: unset;
		vertical-align: top;
	}

	.comment-icon {
		margin-top:-2px;
	 	margin-bottom:2px;
		margin-right:-3px
	}

</style>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop, Watch } from 'vue-property-decorator';
	import { Message, MessageType } from 'types/dto/Messages';
	import ChangeLog from '@/components/ChangeLog.vue';
	import store, { AuthGetters, MsgGetters, MsgActions, SizingGetters } from '@/store';
	import SizingInfo from '@/common/SizingInfo';

	@Component({
		components: {
			ChangeLog
		}
	})
	export default class CommentIcon extends Vue {
		@Prop() public target: string;
		@Prop() public field: string;
		@Prop() public type: MessageType;
		@Prop() public show: boolean;
		@Prop() public visible: boolean;
		@Prop() public showOnClick: boolean;
		@Prop() public showDelete: boolean | string;

		public showAdd: boolean = false;
		public commentText: string = '';
		private lastReadCount: number;

		public showing: boolean = false;
		public unread: boolean = false;
		public externalChange: boolean = false;

		get count() {
			if (!this.target)
				return 0;
	
			const msgs = store.get(MsgGetters.messages)(this.target, this.type, this.field) as Message[];
			if (msgs == null)
				return 0;

			if (this.target === this.me) {
				// My inbox; show # of unread messages
				const unreadCount = msgs.filter(x => !x.Read).length;
				this.unread = unreadCount > 0;
				return unreadCount;
			}

			// "Other inbox" (i.e. comment field) - set unread flag if the # of messages created by others increases
			const othersCount = msgs.filter(x => x.Source !== this.me).length;

			// Mark comment bubble if a change was made to a field by someone else
			this.externalChange = false;
			if (this.type === MessageType.Comment && this.field) {
				const lastChange = store.get(MsgGetters.lastChange)(this.target, this.field) as Message;
				if (lastChange && lastChange.Source !== this.me)
					this.externalChange = true;
			}

			if (this.lastReadCount == null)
				this.lastReadCount = othersCount;
			else {
				this.unread = othersCount > this.lastReadCount;
				this.lastReadCount = othersCount;
			}
			return this.externalChange ? msgs.length + 1 : msgs.length;
		}

		get me() {
			return store.get(AuthGetters.userId);
		}

		public created() {
			if (!this.target)
				return;
			store.dispatch(MsgActions.loadInbox, { id: this.target });
		}

		public beforeDestroy() {
			if (this.target)
				store.dispatch(MsgActions.unloadInbox, { id: this.target });
		}

		public newComment() {
			if (this.field)
				this.showAdd = true;
		}

		public saveComment() {
			const cleanText = this.commentText?.trim();
			if (cleanText?.length) {
				// Assume that this is a SizingComment for now...
				const sz = store.get(SizingGetters.sizing, this.target);
				if (sz) {
					const editors = SizingInfo.editors(sz);
					const cc = [sz.id].concat((editors || []).filter(x => x !== this.me));
					store.dispatch(MsgActions.addComment, { target: this.target, subtarget: this.field, text: cleanText, cc });
				}
			}
			this.commentText = '';
			this.showAdd = false;
		}

		@Watch('show')
		public showChange(show: boolean) {
			if (show)
				this.newComment();
		}

		@Watch('showing')
		public onShowing(showing: boolean) {
			if (showing && this.unread) {
				// Longer delay until marking user inbox read since that is a "destructive operation"
				const delayUntilRead = (this.target === this.me) ? 5000 : 3000;
				setTimeout(() => {
					// If message list is still open, mark all messages as read
					if (this.showing)
						this.markRead();
				}, delayUntilRead);
			}
		}

		private markRead() {
			this.unread = false;
			if (this.target !== this.me)
				return;

			const msgs = store.get(MsgGetters.messages)(this.target, this.type, this.field) as Message[];
			if (msgs) {
				const unread = msgs.filter(x => !x.Read);
				if (unread.length)
					store.dispatch(MsgActions.markRead, { userId: this.me, msgIds: unread.map(x => x.id) });
			}
		}
	}
</script>
