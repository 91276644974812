import i18n from '@/i18n';

interface Unit {
	id: string;
	name: string;
	factor: number;
	offset?: number;
	imperial?: boolean;
	base: string;
	group?: string;
}

export default class UnitValue {
	private baseUnitVal: number | null;
	private baseUnit: Unit = null;
	private unitGroup: string = null;
	private nonNumeric: boolean;

	private currentValue: any = null;
	private currentUnit: Unit = null;

	// Metadata
	private decimals: number;

	public constructor(baseValue: any, unitId: string, decimals?: number, imperial?: boolean, forceString?: boolean) {
		const unit = unitId && UnitValue.units.find(x => x.id === unitId);
		if (!unitId || !unit) {
			// Undefined unit - assume non-numeric
			this.baseUnitVal = this.currentValue = baseValue;
			return;
		}

		this.nonNumeric = forceString;
		this.baseUnitVal = forceString ? baseValue : UnitValue.asNumber(baseValue);
		this.decimals = decimals;

		this.baseUnit = unit?.base && UnitValue.units.find(x => x.id === unit.base) || unit;
		this.changeUnit(unit);

		// Got base unit - try to find something "nice" for the selected imperialism
		if (unit && unit.base === unit.id)
			this.changeSystemOfUnits(imperial);
	}

	public set(value: any) {
		const v = UnitValue.asNumber(value);
		this.currentValue = v;

		let newVal: number;
		if (v == null)
			newVal = null;
		else {
			const curUnit = this.currentUnit;
			if (!curUnit || curUnit === this.baseUnit)
				newVal = v;
			else
				newVal = (v - (curUnit.offset || 0)) / curUnit.factor;
		}
		return this.setBaseUnitValue(newVal);
	}

	public get baseUnitValue() {
		if (this.isNonNumeric)
			return this.currentValue;
		return this.baseUnitVal;
	}

	public get unit() {
		return this.currentUnit;
	}

	public setBaseUnitValue(value: any) {
		const newVal = UnitValue.asNumber(value);
		const oldRounded = UnitValue.round(this.baseUnitVal, 1, this.decimals);
		const newRounded = UnitValue.round(newVal, 1, this.decimals);
		if (oldRounded !== newRounded) {
			this.baseUnitVal = newVal;
			this.changeUnit(this.currentUnit);
			return true;
		}
		return false;
	}

	public get usableUnits(): Unit[] {
		if (!this.baseUnit)
			return [];
		const baseUnitId = this.baseUnit.id;
		if (this.unitGroup)
			return UnitValue.units.filter(x => x.base === baseUnitId && x.group === this.unitGroup);
		return UnitValue.units.filter(x => x.base === baseUnitId && !x.group);
	}

	// Be aware that this does not translate strings. Use toString and pass a translation section name for that.
	public get valueString() {
		let v = this.currentValue;
		if (v == null || (v as any) === '')
			return null;
		if (this.isNonNumeric) {
			// For objects, try heuristic name deduction for now
			const descr = v.DisplayName || v.Description || v.Name || v.Id || v.id;
			if (descr)
				return descr;

			// Failed to "descriptify" object - don't show random JSON
			if (typeof (v) === 'object')
				return null;
			return UnitValue.readableString(v);
		}
		if (this.currentUnit)
			v = UnitValue.round(v, this.currentUnit.factor, this.decimals);
		return v.toString();
	}

	// This is where enums are translated. Make callers pass the param name to get the translation key.
	public static readableString(v: string, xlationSection?: string): string {
		if ((v as any) === true)
			return i18n.t('common.yes') as string;
		else if ((v as any) === false)
			return i18n.t('common.no') as string;
		else if (typeof v !== 'string' || !v.length)
			return v?.toString();

		if (xlationSection) {
			// Using index notation here since keys with "." etc in them will break otherwise (i.e. "1.4436")
			const xlationKey = `${xlationSection}['${v}']`;
			let result: string;
			if (i18n.te(xlationKey))
				result = i18n.t(xlationKey) as string;
			else if (i18n.te(xlationKey, 'en'))
				result = i18n.t(xlationKey, 'en') as string;

			if (result) {
				if (typeof result === 'object')
					console.warn(`Got an object when translating '${xlationKey}'`);
				else
					return result;
			}
		}
		return v;
	}

	public get unitString() {
		return this.currentUnit?.name || null;
	}

	public toString(xlateSection?: string, includeUnit?: boolean) {
		let text = this.valueString;
		if (text == null)
			return null;
		if (xlateSection && this.isNonNumeric)
			text = UnitValue.readableString(this.currentValue, xlateSection);
		if (includeUnit === false)
			return text;
		const unit = this.unitString;
		return unit ? text + ' ' + unit : text;
	}

	public changeUnit(newUnit: Unit) {
		this.currentUnit = newUnit;
		this.unitGroup = newUnit?.group;

		const baseValue = this.baseUnitVal;
		if (baseValue == null || newUnit === this.baseUnit) {
			this.currentValue = baseValue;
			return;
		}

		// Convert and round value to new unit
		if (!this.isNonNumeric)
			this.currentValue = (baseValue * newUnit.factor) + (newUnit.offset || 0);
	}

	private static readonly defaultSOUConversions = [{ m: 'm', i: 'ft' }, { m: 'm/s', i: 'ft/s' }, { m: 'c', i: 'f' }];

	public changeSystemOfUnits(imperial: boolean): Unit {
		const curUnit = this.currentUnit;
		let newUnit: Unit;

		// Already in the right SOU?
		if (curUnit && !!curUnit.imperial === !!imperial)
		 	return;

		// Default conversion present?
		if (!newUnit && curUnit) {
			let newUnitMatch: { m: string, i: string };
			if (curUnit.imperial)
				newUnitMatch = UnitValue.defaultSOUConversions.find(x => curUnit.id === x.i);
			else
				newUnitMatch = UnitValue.defaultSOUConversions.find(x => curUnit.id === x.m);

			if (newUnitMatch)
				newUnit = this.usableUnits.find(x => x.id === (curUnit.imperial ? newUnitMatch.m : newUnitMatch.i));
		}

		// Just grab the first available
		if (!newUnit)
			newUnit = this.usableUnits.find(x => !!x.imperial === !!imperial);

		if (newUnit && newUnit !== curUnit) {
			this.changeUnit(newUnit);
			return newUnit;
		}
		return;
	}

	private get isNonNumeric() {
		const cv = this.currentValue;
		if (cv === true || cv === false)
			return true;

		// Treat values without unit as non-numeric
		return cv && (this.nonNumeric || !this.currentUnit);
	}

	public static asNumber(value: any): number | null {
		if (value != null && typeof value === 'string')
			value = value.replace(',', '.').trim();

		const val = parseFloat(value);
		if (val === undefined || val === null || !isFinite(val) || isNaN(val))
			return null;
		return val;
	}

	public static round(val: number, scale: number, decimals: number) {
		if (decimals == null || !val)
			return val;
		const scaleDecimals = scale ? Math.floor(Math.log10(scale)) : 0;
		const decimalsAtCurrentScale = decimals - scaleDecimals;
		if (!decimalsAtCurrentScale)
			return Math.round(val);
		const deciFactor = Math.pow(10, decimalsAtCurrentScale);
		return Math.round(deciFactor * val) / deciFactor;
	}

	private static readonly units: Unit[] = [
		{ id: 'm3/h', name: 'm³/h', factor: 3600, base: 'm3/s' },
		{ id: 'm3/s', name: 'm³/s', factor: 1.0, base: 'm3/s' },
		{ id: 'l/min', name: 'l/min', factor: 60000, base: 'm3/s' },
		{ id: 'l/s', name: 'l/s', factor: 1000, base: 'm3/s' },
		{ id: 'usgpm', name: 'USGPM', factor: 15850.372483753, base: 'm3/s', imperial: true },

		{ id: 'm', name: 'm', factor: 1.0, base: 'm' },
		{ id: 'ft', name: 'ft', factor: 3.280839895, base: 'm', imperial: true },

		{ id: 'mm', name: 'mm', factor: 1000, base: 'm', group: 'mm' },
		{ id: 'micron', name: 'μm', factor: 1000000, base: 'm', group: 'mm' },
		{ id: 'in', name: 'in', factor: 1 / 0.0254, base: 'm', group: 'mm' },
		{ id: 'mil', name: 'mil', factor: 1000 / 0.0254, base: 'm', group: 'mm' },
		// TODO: 'mesh' unit (if required) needs a special field with fixed values (see PD6/mesh_mm_fill)

		{ id: 'c', name: '°C', factor: 1.0, base: 'c' },
		{ id: 'f', name: '°F', factor: 1.8, offset: 32, base: 'c', imperial: true },

		{ id: 'kg/m3', name: 'kg/m³', factor: 1.0, base: 'kg/m3' },
		{ id: 'sg', name: 'S.G.', factor: 0.001, base: 'kg/m3', imperial: true },

		{ id: 'kg/s', name: 'kg/s', factor: 1.0, base: 'kg/s' },
		{ id: 'tonne/h', name: 'tonne/h', factor: 3.6, base: 'kg/s' },

		{ id: 'm/s', name: 'm/s', factor: 1.0, base: 'm/s' },
		{ id: 'ft/s', name: 'ft/s', factor: 3.280839895, base: 'm/s', imperial: true },

		{ id: 'pas', name: 'Pa⋅s', factor: 1.0, base: 'pas' },
		{ id: 'cp', name: 'cP', factor: 1000.0, base: 'pas' },

		{ id: 'pag', name: 'pag', factor: 1.0, base: 'pag' },
		{ id: 'kpag', name: 'kPag', factor: 0.001, base: 'pag' },
		{ id: 'barg', name: 'barg', factor: 0.00001, base: 'pag' },
		{ id: 'psig', name: 'psig', factor: 0.00014504021, base: 'pag' },

		{ id: 'pipemm', name: 'mm', factor: 1000, base: 'm', group: 'pipemm' },
		{ id: 'pipein', name: 'in', factor: 1 / 0.0254, base: 'm', group: 'pipemm', imperial: true },

		{ id: 'w', name: 'W', factor: 1.0, base: 'w' },
		{ id: 'kw', name: 'kW', factor: 0.001, base: 'w' },
		{ id: 'hp', name: 'hp', factor: 1.0 / 746, base: 'w' },

		{ id: 'n', name: 'N', factor: 1.0, base: 'n' },
		{ id: 'kn', name: 'kN', factor: 0.001, base: 'n' },
		{ id: 'lbf', name: 'lbf', factor: 0.22480894387096, base: 'n' },

		{ id: 'measure', name: 'mm', factor: 1, base: 'measure', group: 'm_mm' },
		{ id: 'measure', name: 'in', factor: 1 / 25.4, base: 'measure', group: 'm_mm', imperial: true },

		{ id: 'ppm', name: 'ppm', factor: 1.0, base: 'ppm' },
		{ id: 'mg/l', name: 'mg/l', factor: 1.0, base: 'ppm' },

		{ id: 'percent', name: '%', factor: 1.0, base: 'percent' },
		{ id: 'h', name: 'h', factor: 1.0, base: 'h' },
		{ id: 'v', name: 'V', factor: 1.0, base: 'v' },
		{ id: 'hz', name: 'Hz', factor: 1.0, base: 'hz' },
		{ id: 'rpm', name: 'rpm', factor: 1.0, base: 'rpm' },
		{ id: '1', name: '', factor: 1.0, base: '' }
	];
}
