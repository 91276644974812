<template>
	<v-list-item class="pl-6 pr-6">
		<v-list-item-content class="grid align-top">
			<UnitNumeric :param="getParam('Diameter')" :locked="locked" :onChange="onDiameterChange" />
			<UnitNumeric :param="getParam('RoughnessHeight')" :locked="locked" />
			<UnitNumeric :param="getParam('Length')" :locked="locked" />
			<div class="wide" v-if="diameter || totalLength">
				<div class="additional-table">
					<v-label>{{$t('PipeParts.Title')}}</v-label>
					<table>
						<tbody>
							<tr class="row-styling" v-for="item of fields" :key="item.key">
								<td class="name">{{$t('PipeParts.' + item.key)}}</td>
								<td class="amount">
									<input :disabled="locked" type="text" v-show="item.amountEditable" v-model="item.inputtedValue"
										@keyup.enter="item.inputtedValue = $event.target.value;" @blur="() => calculateLength(item, diameter)">
								</td>
								<td class="measures">
									<input :disabled="locked" type="text" v-show="item.lengthEditable" v-model="item.inputtedValue"
										@keyup.enter="item.inputtedValue = $event.target.value;" @blur="() => calculateLength(item, diameter)">
									<span v-if="!item.lengthEditable">{{item.unitValue.valueString}}</span>
								</td>
								<td class="unit">
									{{item.unitValue.unitString}}
								</td>
							</tr>
							<tr class="row-styling" v-if="taperLength" style="font-style: italic">
								<td class="name">+ {{`${$t('Pipe.TaperPieceEqLength')}`.toLowerCase()}}</td>
								<td class="amount"></td>
								<td class="measures">
									<span>{{taperLength.valueString}}</span>
								</td>
								<td class="unit">{{taperLength.unitString}}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="mt-6">
					<UnitNumeric :param="getParam('DepositionVelocity')" :locked="locked" inline />
					<UnitNumeric :param="getParam('BottomVelocity')" :locked="locked" inline />
					<UnitNumeric :param="getParam('Velocity')" :locked="locked" inline />
					<UnitNumeric :param="getParam('TaperPieceEqLength')" inline />
					<UnitNumeric :param="getParam('FrictionLoss')" :locked="locked" inline />
				</div>
			</div>
		</v-list-item-content>
	</v-list-item>
</template>

<style lang="scss" scoped>
table, td {
  border: 1px solid rgb(235, 235, 235);
  border-collapse: collapse;
  overflow: auto;
  margin-bottom: 10px;
  margin-top: 5px;
}

tr.row-styling {
	height: 36px;
}

td.name {
	width: 50%;
	padding-left: 10px;
}

td.amount {
	width: 15%;
	text-align: center;
	overflow: hidden;
}

td.measures {
	padding-left: 10px;
	padding-right: 5px;
	overflow: hidden;
	text-align: right;
	border-right: none;
}

td.unit {
	border-left: none;
	padding-right: 10px;
}

input[type=text] {
   width: 60px;
	margin: 0;
	border: 1px solid black;
	padding: 6px
}

input[type=text]:disabled {
	border: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.additional-table {
	margin-top: 12px;
}
</style>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop, Watch } from 'vue-property-decorator';
	import { ParamBag } from '@/common/ParamBag';
	import UnitNumeric from '@/components/Fields/UnitNumeric.vue';
	import UnitValue from '@/common/UnitValue';
	import { AdditionalLength } from 'types/dto/CalcServiceDomain';

	interface AdditionalSizingLengths {
		DisplayName: string;
		key: string;
		factor?: number;
		unitValue: UnitValue;
		amountEditable: boolean;
		lengthEditable: boolean;
		inputtedValue?: string;
	}

	const FurtherLengthKey = 'FurtherLength';
	const SumKey = 'Sum';

	@Component({
		components: {
			UnitNumeric
		}
	})
	export default class PipeEditor extends Vue {
		@Prop() public index: number;
		@Prop() public target: string;
		@Prop() public values: ParamBag;
		@Prop() public locked: boolean;

		public readonly fields: AdditionalSizingLengths[] = [{
				DisplayName: 'Long bends',
				key: 'LongBends',
				factor: 0.022,
				unitValue: new UnitValue(0, 'm', 1, false),
				amountEditable: true,
				lengthEditable: false,
				inputtedValue: ''
			}, {
				DisplayName: 'Sharp bends',
				key: 'SharpBends',
				factor: 0.028,
				unitValue: new UnitValue(0, 'm', 1, false),
				amountEditable: true,
				lengthEditable: false,
				inputtedValue: ''
			}, {
				DisplayName: 'Full bore valves',
				key: 'BoreValves',
				factor: 0.022,
				unitValue: new UnitValue(0, 'm', 1, false),
				amountEditable: true,
				lengthEditable: false,
				inputtedValue: ''
			}, {
				DisplayName: 'Further equiv. length',
				key: FurtherLengthKey,
				unitValue: new UnitValue(0, 'm', 1, false),
				amountEditable: false,
				lengthEditable: true,
				inputtedValue: ''
			}, {
				DisplayName: 'Sum',
				key: SumKey,
				unitValue: new UnitValue(0, 'm', 1, false),
				amountEditable: false,
				lengthEditable: false
			}
		];

		public created() {
			const dia = this.diameter;
			const savedParts = this.getParam('AdditionalLengths').getValue() as any as AdditionalLength[] ?? [];

			// Initialize state from previously saved data
			for (const item of this.fields) {
				const savedValue = savedParts.find(x => x.Key === item.key)?.Amount;
				item.inputtedValue = savedValue || '';
				item.unitValue = new UnitValue(savedValue ?? 0, 'm', 1, false);

				// Display in current system of units
				if (this.useImperial)
					item.unitValue.changeSystemOfUnits(true);
				this.calculateLength(item, dia);
			}

			// Watch for future SoU changes
			this.$watch('useImperial', (imperial: boolean) => this.updateImperialness(imperial));
		}

		public getParam(n: string) {
			return this.values?.getParam(this.targetPath(n), `Pipe.${n}`);
		}

		public targetPath(field?: string) {
			const fieldRef = field ? ('.' + field) : '';
			return `${this.target}[${this.index}]${fieldRef}`;
		}

		get useImperial() {
			return ParamBag.useImperial(this.values?.sizingId);
		}

		get totalLength() {
			let sum: number = 0;
			for (const i of this.fields.filter(x => x.key !== SumKey))
				sum += i.unitValue.baseUnitValue || 0;
			return sum || 0;
		}

		get serializedParts() {
			const setValues = this.fields
				.filter(x => !!x.inputtedValue && UnitValue.asNumber(x.inputtedValue) > 0 && x.key !== SumKey)
				.map(item => ({ Key: item.key, Amount: UnitValue.asNumber(item.inputtedValue) }));
			if (setValues.length)
				return setValues;
			return null;
		}

		get taperLength() {
			const tpLen = this.getParam('TaperPieceEqLength');
			if (!tpLen)
				return;
			const uv = tpLen.getUnitValue(this.useImperial);
			if (uv?.baseUnitValue > 0)
				return uv;
		}

		public updateImperialness(useImperial: boolean) {
			this.fields.forEach(x => x.unitValue.changeSystemOfUnits(useImperial));
			const addField = this.fields.find(x => x.key === FurtherLengthKey);
			addField.inputtedValue = addField.unitValue.valueString;
		}

		@Watch('diameter')
		public updateDiameter(dia: number) {
			this.fields.forEach(x => this.calculateLength(x, dia));
		}

		@Watch('totalLength')
		public updateSum(sum: number, oldSum: number, skipSave?: boolean) {
			const sumField = this.fields.find(x => x.key === SumKey);
			sumField.unitValue.setBaseUnitValue(sum);

			// Write serialized version in memory. Flushed by setValue below if needed.
			this.getParam('AdditionalLengths').setValue(this.serializedParts, true);

			// Write extra length if the length actually changed (no save during init...)
			const oldVal = +(this.getParam('ExtraLength').getValue() as any ?? 0);
			const newVal = +(sum ?? 0);

			if (Math.round(10.0 * oldVal) !== Math.round(10.0 * newVal))
				this.getParam('ExtraLength').setValue(sum, true);

			this.$emit('changed', this.index);
		}

		public calculateLength(item: AdditionalSizingLengths, diameter: number) {
			if (item.key === SumKey)
				return;
			const val = UnitValue.asNumber(item.inputtedValue);
			let length: number;
			if (item.key === FurtherLengthKey)
				length = val;
			else
				length = val * item.factor * diameter * 1000;
			item.unitValue.set(length);
		}

		public get diameter() {
			const param = this.getParam('Diameter');
			return Number(param.getValue() || 0);
		}

		public onDiameterChange(newVal: number) {
			this.updateDiameter(newVal);
			this.updateSum(this.totalLength, 0, true);
			this.getParam('Diameter').setValue(newVal, true);
			this.$emit('changed', this.index);
		}
	}
</script>