<template>
	<div>
		<v-data-table dense :items="log" item-key="id" :headers="headers">
			<template v-slot:item="x">
				<tr>
					<td>{{ new Date(x.item.time).toISOString().split('T')[1].split('Z')[0] }}</td>
					<td>
						<v-chip v-if="x.item.Severity != null" x-small :color="color(x.item)" v-text="type(x.item)" />
					</td>
					<td>{{ x.item.ParamName }}</td>
					<td>{{ x.item.Message }}</td>
					<td>{{ x.item.Source }}</td>
				</tr>
			</template>
		</v-data-table>
		<v-btn v-if="log && log.length > 0" x-small @click="clear">Clear log</v-btn>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import Component from 'vue-class-component';
	import store, { DebugGetters, DebugMutations } from '@/store';
	import { ValidationResult } from 'types/dto/CalcServiceDomain';
	import colors from '@/sass/_variables.scss';

	@Component
	export default class MessageLog extends Vue {
		public readonly headers = [
			{ text: 'Timestamp', value: 'time' },
			{ text: 'Level', value: 'Severity' },
			{ text: 'Parameter', value: 'ParamName' },
			{ text: 'Text', value: 'Message' },
			{ text: 'Source', value: 'Source' }
		];

		public get log() {
			return store.get(DebugGetters.log);
		}

		public color(item: ValidationResult) {
			if (item.Severity < -2 || item.Severity > 4)
				return colors.selection;
			return [colors.greenLighten1, colors.greyLighten1, colors.warningLighten1, colors.blueLighten, colors.warning, colors.error, colors.error][item.Severity + 2];
		}

		public type(item: ValidationResult) {
			return this.$t(`MessageSeverity.${item.Severity}`);
		}

		public clear() {
			store.commit(DebugMutations.clear);
		}
	}
</script>
