var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.sortedPipes),function(ref,rowIdx){
var pipe = ref[0];
var idx = ref[1];
var id = ref[2];
return _c('v-list-group',{key:id,staticClass:"pipe-list",attrs:{"sub-group":"","value":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_c('div',{staticStyle:{"float":"left","padding-top":"8px"}},[_vm._v(" "+_vm._s(_vm.$t('paramGroups.Pipe'))+" #"+_vm._s(rowIdx + 1)+" "),(_vm.isClosestToPump(pipe))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('img',_vm._g({staticStyle:{"vertical-align":"middle","margin-left":"6px","padding-bottom":"4px"},attrs:{"src":require('@/assets/pump_icon.svg'),"width":"21"}},on))]}}],null,true)},[_vm._v(" Closest to the pump ")]):_vm._e()],1),_c('div',{staticStyle:{"float":"right"}},[(_vm.hasError(pipe))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-2 mb-1 pt-1",attrs:{"color":"red"}},on),[_vm._v("cancel")])]}}],null,true)},[_vm._v(" Selection has errors ")]):(_vm.isComplete(pipe))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-2 mb-1 pt-1",attrs:{"color":"green"}},on),[_vm._v("check_circle")])]}}],null,true)},[_vm._v(" Selection completed ")]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-2 mb-1 pt-1",attrs:{"color":"orange"}},on),[_vm._v("error")])]}}],null,true)},[_vm._v(" More information needed ")]),(_vm.enableParts)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","tabindex":"-1"},on:{"click":function($event){$event.stopPropagation();return _vm.add(false, rowIdx)}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("publish")])],1)]}}],null,true)},[_vm._v(" Insert part above ")]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","tabindex":"-1"},on:{"click":function($event){$event.stopPropagation();return _vm.remove(idx)}}},on),[_c('v-icon',{attrs:{"color":"primary","x-small":""}},[_vm._v("$clear")])],1)]}}],null,true)},[_vm._v(" Delete ")])],1)])]},proxy:true}],null,true)},[(pipe.Module)?_c('PipePartEditor',{attrs:{"prevPipe":_vm.getPrevPipe(idx),"nextPipe":_vm.getNextPipe(idx),"index":idx,"buildManager":_vm.buildManager,"values":_vm.values,"target":_vm.target,"locked":_vm.locked,"reverseFlow":_vm.location === 'inlet'},on:{"changed":_vm.pipeChanged}}):_c('PipeEditor',{attrs:{"index":idx,"values":_vm.values,"target":_vm.target,"locked":_vm.locked},on:{"changed":_vm.pipeChanged}})],1)}),(!_vm.locked)?_c('v-list-item',[(_vm.enableParts)?_c('v-list-item-content',{staticClass:"px-2 grid"},[_c('v-btn',{attrs:{"disabled":!_vm.canAddPart},on:{"click":function () { return _vm.add(false); }}},[_vm._v("Add pipe part")]),_c('v-btn',{attrs:{"disabled":!_vm.canAddPart},on:{"click":function () { return _vm.add(true); }}},[_vm._v("Add custom piping")])],1):_c('v-list-item-content',{staticClass:"px-2 grid"},[_c('v-btn',{on:{"click":function () { return _vm.add(true); }}},[_vm._v("Add pipe")])],1)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }