interface ILoaderState {
	loading: number;
}

const state: ILoaderState = {
	loading: 0
};

const getters = {
	loading: (targetState: ILoaderState) => targetState.loading
};

const actions = {};

const mutations = {
	startLoading: (targetState: ILoaderState) => targetState.loading++,
	finishLoading: (targetState: ILoaderState) => targetState.loading--,
};

export const enum LoaderMuts {
	start = 'loader/startLoading',
	finish = 'loader/finishLoading'
}

export const loader = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
