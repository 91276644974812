<template>
	<!-- header -->
	<div>
		<div class="dashboard" v-bind:class="{ small: $vuetify.breakpoint.smAndDown }">
			<v-row class="header" elevation="4">
				<!-- title -->
				<v-col class="title" cols="12" sm="3">
					<ul class="breadcrumbs">
						<li class="allcaps">{{ $t('app.dashboard') }}</li>
					</ul>
					<h1>Overview</h1>
				</v-col>
			</v-row>

			<article>
				<!-- left -->
				<v-card outlined>
					<v-row class="pr-10 pl-10 pt-5">
						<!-- Project list title -->
						<v-col cols="12" sm="3">
							<h2>{{ $t('app.projects') }}</h2>
						</v-col>

						<v-col class="actions d-flex align-center justify-end" cols="12" sm="9">
							<v-btn dark @click="newProject" :disabled="offline">{{ $t('app.newProject') }}</v-btn>
						</v-col>
					</v-row>
					<v-card-text class="no-padding">					
					<v-row class="pr-5 pl-5">
						<v-col cols="12">
							<!-- project filters -->
							<ProjectFilterPanel @filter="onFilterChange" @search="onSearch" />
						</v-col>	
					</v-row>
						<!-- projects -->
						<template v-if="hasProjects">
							<template>
								<v-list class="project-tile" tile v-for="project of projects" :key="project.id">
									<v-list-group no-action :value="lastOpenedProject === project.id" @click="loadSizings(project.id)">
										<template v-slot:activator>
											<v-list-item-action class="mr-0" >
												<v-menu v-if="!projectLocked(project)">
													<template v-slot:activator="{ on }">
														<v-btn @click.stop icon v-on="on">
															<v-icon color="primary">more_horiz</v-icon>
														</v-btn>
													</template>
													<v-list>
														<v-subheader>Actions</v-subheader>
														<v-list-item @click.stop="navigateToProject(project.id)">Open</v-list-item>
														<v-list-item @click.stop="newSizing(project)" :disabled="offline || !!project.QuoteId">Add sizing</v-list-item>
														<v-list-item @click="shareProject(project)" :disabled="offline">Share project</v-list-item>
														<v-list-item @click.stop="deleteProject(project.id)" :disabled="offline || !canDelete(project)">{{ $t('common.delete') }}</v-list-item>
														<v-list-item @click.stop="preloadProject(project.id)" :disabled="offline">Download (offline)</v-list-item>
														<v-list-item @click.stop="leaveProject(project.id)" v-if="otherProjectOwner(project)" :disabled="offline">Leave project</v-list-item>
													</v-list>
												</v-menu>
											</v-list-item-action>
											<v-list-item-title>						
												<h3>
													<v-icon v-if="projectLocked(project)" small color="primary" class="mt-n1 pr-1">lock</v-icon>
													<v-icon v-else-if="projectShared(project)" small color="primary" class="mt-n1 mr-1">$share</v-icon>
													{{ project.Name }}
													<Username v-if="otherProjectOwner(project)" class="ml-1" style="font-size: 80%" :id="project.CreatedBy" />
													<span class="small" v-if="searchResult" v-text="explainHits(project)"></span>
												</h3>
												<div v-if="project.CustomerName">
													<span class="grey--text">
														{{ project.CustomerName }}
													</span>
													<span class="grey--text">
														({{ project.CustomerReference || project.CustomerId }})
													</span>
													<span v-if="project.QuoteId" class="quote-id black white--text ml-1">Q</span>
												</div>
												<template>
													<span class="tile-border"></span>
												</template>
											</v-list-item-title>								
										</template>
										<template v-if="hasSizings(project.id)">
											<v-list-item v-for="sizing of projectSizings(project.id)" :key="sizing.id">
											<!-- use item-subtitle here if we need another row on each sizing -->
												<v-list-item-action class="mr-1">
													<v-menu>
														<template v-slot:activator="{ on }">
															<v-btn @click.stop="" icon v-on="on">
																<v-icon color="primary">more_horiz</v-icon>
															</v-btn>
														</template>
														<v-list>
															<v-subheader>Actions</v-subheader>
															<v-list-item @click.stop="navigateToSizing(project.id, sizing.id)" :disabled="navDisabled(sizing.id)">Open</v-list-item>
															<v-list-item @click.stop="copySizing(project, sizing)" :disabled="sizing.ParentId || offline">Copy</v-list-item>
															<v-list-item @click.stop="shareSizing(sizing, project)" :disabled="sizing.ParentId || offline">Share</v-list-item>
															<v-list-item @click.stop="deleteSizing(sizing)" :disabled="!canDeleteSizing(sizing) || offline">{{ $t('common.delete') }}</v-list-item>
															<v-list-item @click.stop="preloadSizing(sizing.id, project.id)" :disabled="offline || fullyLoaded(sizing.id)">Download (offline)</v-list-item>
														</v-list>
													</v-menu>
												</v-list-item-action>
												<v-list-item-icon class="pr-4 mr-0 mt-5">
													<SizingIcon :state="sizing.State"/>
													<v-progress-circular v-if="ongoingSync(sizing.id)" :size="15" :width="2" indeterminate class="sizing-loader"></v-progress-circular>
													<LockIcon :model="sizing" />
													<v-tooltip bottom v-if="offline">
														<template v-slot:activator="{ on }">
															<v-icon v-on="on" class="pr-1" color="primary">{{ offlineIcon(sizing.id) }}</v-icon>
														</template>
														<span>{{ offlineText(sizing.id) }}</span>
													</v-tooltip>
												</v-list-item-icon>								
												<v-list-item-content @mouseenter="selectSizing(sizing)" class="" @mouseleave="selectSizing(null)" :disabled="navDisabled(sizing.id)">
													<v-list-item-title @click="navigateToSizing(project.id, sizing.id)" class="sizing-title pl-0 center-flex" :class="{'disabled' : navDisabled(sizing.id)}">
														<h3 class="d-inline normal">{{ sizing.Name }} </h3>
															<img :src="require('@/assets/staging_icon.svg')" class="stage-icon" v-if="sizing.Staged" >
															<img :src="require('@/assets/MDP_icon.svg')" class="stage-icon" v-if="sizing.MDP">
															<span class="thin">&nbsp;| {{ getLocalDateString(sizing.LastChanged) }} by <Username :id="sizing.LastChangedBy || sizing.CreatedBy" /></span>		
													</v-list-item-title>
												</v-list-item-content>										
											</v-list-item>
										</template>
										<template v-else>
											<v-list-item v-if="noSizings(project.id) && !(loadingSizings && loadingProject === project.id)">
												<v-list-item-title>
													<h4>
														<i v-if="!project.QuoteId">No sizings - create one from the <v-icon color="primary">more_horiz</v-icon> menu</i>
														<i v-else>No sizings - create one in PQP</i>
													</h4>
												</v-list-item-title>
											</v-list-item>
											<v-list-item v-else-if="loadingSizings && loadingProject === project.id">
												<v-list-item-title class="text-xs-center grey--text">
													Loading sizings...<v-progress-circular :size="24" class="ma-4 pl-1" indeterminate />
												</v-list-item-title>
											</v-list-item>
										</template>
									</v-list-group>
								</v-list>
							</template>
						</template>

						<!-- no projects -->
						<v-layout align-center justify-center row fill-height>
							<div class="text-center ma-4">
								<div v-if="loadingProjects" class="ma-4">
									Loading projects...<v-progress-circular :size="24" class="ml-4" indeterminate />
								</div>
								<div v-else-if="!hasProjects && !searchPhrase">
									<template v-if="showSharedProjects">
										<strong class="grey--text">No shared projects found</strong>
									</template>
									<template v-else>
										<strong class="grey--text">No projects</strong><br />
										<span class="grey--text">Please create a new project.</span><br /><br />
										<v-btn @click="newProject" :disabled="offline">{{ $t('app.newProject') }}</v-btn>
									</template>
								</div>
								<div v-else-if="!hasProjects && searchPhrase">
									<strong class="grey--text">No projects or sizings found matching '{{ searchPhrase }}'</strong>
								</div>
								<div v-if="searchPhrase && olderHits && olderHits.length">
									<a href="#" class="allcaps" @click.stop.prevent="loadOlderHits">See more matches hidden by filter</a>
								</div>
								<AdminBox v-if="canShowAdmin" class="mt-2 pl-5 pr-5 pb-3" />
								<a v-if="!!pumpAdminUrl" :href="pumpAdminUrl">PumpAdmin <v-icon size="12">$launch</v-icon></a>
							</div>
						</v-layout>
					</v-card-text>
				</v-card>
			</article>

			<!-- right floating panel for large screens -->
			<aside v-if="$vuetify.breakpoint.mdAndUp">
				<RecentSizings class="mb-8" v-if="!offline" @sizingHover="selectSizing" />
				<SizingPreview :values="values" />
			</aside>
		</div>
		<NewProjectDialog @close-dialog="closeDialog" v-if="newProjectDialog" :newProjectName="newProjectName" />
		<NewSizingDialog @close-dialog="closeDialog" v-if="newSizingDialog" :project="newSizingProject" :sizingClone="newSizingClone" :newSizingName="newSizingName" />
		<ShareSizingDialog @close-dialog="closeDialog" v-if="sharingProject || sharingSizing" :project="sharingProject" :sizing="sharingSizing" />
		<Confirm ref="confirmDashboard" />
	</div>
</template>

<style lang="scss" scoped>
	@import '@/sass/_variables.scss';
	.v-list-item__title {
		padding: 0 14px;
	}
	
	.v-list-item:hover {
   	background-color: $selection-lighten-1;
	}

	.tile-border{
		background-color: $grey-lighten-1;
		position:absolute; 
		left:30px; 
		right: 28px; 
		bottom: 0px;  
		height: 1px;
	}

	.v-list-group--active {
		background-color: $white;
	}

	.stage-icon {
		width: 16x;
		height: 16px;
		margin: 0 0 4px 4px;
	}

	.v-input--selection-controls__input {
		display: none !important;
	}

	.sizing-loader {
		margin: 2px 0 0 9px;
	}

	.header {
		margin: -24px -30px 0 -30px;
		padding: 5px 24px 24px 24px; 
	}

	.quote-id {
		padding: 0px 2px;
		border-radius:6px;
		font-size: 75%;
	}

	.sizing-title {
		cursor: pointer;
		&.disabled > :is(h3), :is(span) {
			cursor: default;
			color: $grey;
		}
	}

	@media only screen and (max-width: 800px) {
		.header {
			min-width: 86%; // This percentage width makes sure that the header fits to a small screen.
		}
	}
</style>

<script lang="ts">
	import Vue from 'vue';
	import Component from 'vue-class-component';
	import { PumpProject, PumpDocument } from 'types/dto/CalcServiceDomain';
	import router from '@/router';
	import { localDateString } from '@/common/Tools';
	import { ParamBag } from '@/common/ParamBag';
	import NewProjectDialog from '@/views/dialogs/newProjectDialog.vue';
	import NewSizingDialog from '@/views/dialogs/newSizingDialog.vue';
	import ShareSizingDialog from '@/views/dialogs/ShareSizingDialog.vue';
	import SizingIcon from '@/components/SizingIcon.vue';
	import Confirm from '@/components/Confirm.vue';
	import LockIcon from '@/components/LockIcon.vue';
	import Username from '@/components/Username.vue';
	import ProjectFilterPanel from '@/components/ProjectFilterPanel.vue';
	import SizingPreview from '@/components/SizingPreview.vue';
	import RecentSizings from '@/components/RecentSizings.vue';
	import AdminBox from '@/components/AdminBox.vue';
	import store, { AuthMuts, AuthGetters, SizingActions, SizingGetters, ProjectGetters, ProjectActions, NetworkGetters, SnackActions } from '@/store';
	import { Watch } from 'vue-property-decorator';
	import SizingInfo from '@/common/SizingInfo';
	import { SizingSearchResults } from 'types/dto/SizingSearch';
	import ProjectFilter from '@/common/ProjectFilter';
	import { UserSetting } from '@/store/modules/auth.store';

	@Component({
		components: {
			NewProjectDialog,
			NewSizingDialog,
			ShareSizingDialog,
			SizingIcon,
			Confirm,
			LockIcon,
			Username,
			ProjectFilterPanel,
			SizingPreview,
			RecentSizings,
			AdminBox
		},
	})
	export default class Dashboard extends Vue {
		public highlightedSizing: PumpDocument = null;
		public newProjectDialog: boolean = false;
		public newProjectName: string = null;
		public newSizingDialog: boolean = false;
		public newSizingName: string = null;
		public newSizingProject: PumpProject = null;
		public newSizingClone: PumpDocument = null;
		public showSharedProjects: boolean = false;
		public sharingProject: PumpProject = null;
		public sharingSizing: PumpDocument = null;
		private readonly sizingsLoaded: any = {};
		public loadingProject: string = null;
		public values: ParamBag = null;

		public searchPhrase: string = '';
		public searchResult: SizingSearchResults = null;
		public olderHits: string[] = null;

		get lastOpenedProject() {
			return store.get(AuthGetters.lastOpenedProject) as string;
		}

		public get offline() {
			return !store.get(NetworkGetters.connected);
		}

		public get canShowAdmin() {
			return !this.offline && store.get(AuthGetters.internalUser) && store.get(AuthGetters.hasRole, 'developer') || false;
		}

		public get pumpAdminUrl() {
			const url = process.env.VUE_APP_PUMPADMIN_URL;
			if (url?.length && store.get(AuthGetters.hasRole, 'pumpadmin'))
				return url;
		}

		private isDirty(id: string): boolean {
			return store.get(SizingGetters.isDirty, id);
		}

		public fullyLoaded(id: string): boolean {
			return store.get(SizingGetters.fullyLoaded, {id, skipChildren: false });
		}

		public ongoingSync(id: string): boolean {
			return store.get(SizingGetters.loadingSizing, id);
		}

		public offlineText(id: string): string {
			if (this.isDirty(id) && !store.get(SizingGetters.loadingSizing, id))
				return 'Offline changes';

			if (!this.offline)
				return '';
			return !store.get(SizingGetters.fullyLoaded, { id }) ? 'Partly available' : 'Available';
		}

		public offlineIcon(id: string): string {
			if (this.isDirty(id) && !store.get(SizingGetters.loadingSizing, id))
				return 'mdi-cloud-alert';

			if (!this.offline)
				return '';
			return !store.get(SizingGetters.fullyLoaded, { id, skipChildren: false }) ? 'mdi-cloud-download-outline' : 'mdi-cloud-download';
		}

		public navDisabled(id: string): boolean {
			if (this.offline)
				return !store.get(SizingGetters.fullyLoaded, { id, skipChildren: true });
			if (this.isDirty(id) && this.ongoingSync(id))
				return true;
			return false;
		}

		get allProjects(): PumpProject[] {
			const me = store.get(AuthGetters.userId);
			const all = store.get(ProjectGetters.projects) as PumpProject[];
			let projects: PumpProject[];
			if (this.showSharedProjects)
				projects = all.filter(x => x.CreatedBy !== me);
			else
				projects = all.filter(x => x.CreatedBy === me);
			return projects;
		}

		get projects(): PumpProject[] {
			let list = this.allProjects;
			if (!list?.length)
				return list;

			if (this.searchResult?.Hits) {
				const ids = this.searchResult.Hits.map(x => x.ProjectId);
				list = list.filter(x => ids.includes(x.id));
			}

			const sort = store.get(AuthGetters.setting, UserSetting.projectSortOrder);
			if (sort?.length) {
				const rev = sort.startsWith('-') ? -1 : 1;
				const key = (sort.startsWith('-') ? sort.slice(1) : sort) as keyof PumpProject;
				list.sort((a, b) => {
					let diff = (a[key] as string ?? '').localeCompare(b[key] as string ?? '');
					if (!diff)
						diff = (a.Name as string ?? '').localeCompare(b.Name as string ?? '');
					return rev * diff;
				});
			}
			return list;
		}

		public projectSizings(id: string): PumpDocument[] {
			let sizings: PumpDocument[] = (store.get(SizingGetters.projectSizings, id) || []).filter((x: PumpDocument) => !x.ParentId);
			if (this.searchResult) {
				const project = this.searchResult.Hits.find(x => x.ProjectId === id);
				if (project?.SizingIds?.length) {
					const filtered = sizings.filter(x => project?.SizingIds.includes(x.id));
					if (filtered.length)
						sizings = filtered;
				}
			}
			return sizings.sort((a, b) => (a.Name ?? '').localeCompare(b.Name ?? ''));
		}

		get loadingSizings(): boolean {
			return store.get(SizingGetters.loading);
		}

		get loadingProjects(): boolean {
			return !store.get(AuthGetters.userId) || store.get(ProjectGetters.loading);
		}

		get hasProjects() {
			return !!this.projects?.length;
		}

		public async preloadProject(id: string) {
			const dialog: any = this.$refs.confirmDashboard;
			const includeSizings = await dialog.open('This will make the project available offline.');
			if (includeSizings) {
				store.dispatch(ProjectActions.getProject, id);
				this.loadingProject = id;
				const sizings = await store.dispatch(SizingActions.getSizings, id);
				if (!sizings || !sizings.length)
					return;
				const data: PumpDocument[] = Object.values(sizings) || [];
				data.forEach((s: PumpDocument) => store.dispatch(SizingActions.getSizing, s.id));
			}
		}

		public async leaveProject(id: string) {
			const project = await store.dispatch(ProjectActions.getProject, id);
			if (!project)
				return;
			const me = store.get(AuthGetters.userId);
			const editors = SizingInfo.editors(project, true);
			if (!editors || !editors.includes(me))
				return;
			await store.dispatch(ProjectActions.setEditors, { projectId: id, editors: editors.filter(x => x !== me) });
		}

		public async preloadSizing(id: string, projectId: string): Promise<void> {
			await store.dispatch(SizingActions.getSizing, id);
			const sizing = store.get(SizingGetters.sizing, id);
			if (sizing.Staged || sizing.MDP) {
				const sizings = (store.get(SizingGetters.projectSizings, projectId) || []).filter((x: PumpDocument) => x.ParentId === id);
				const data: PumpDocument[] = Object.values(sizings) || [];
				data.forEach((s: PumpDocument) => store.dispatch(SizingActions.getSizing, s.id));
			}
		}

		public getLocalDateString(date: string | Date) {
			return localDateString(date);
		}

		public hasSizings(projectId: string) {
			const sizings = store.get(SizingGetters.projectSizings, projectId);
			return sizings && sizings.length > 0;
		}

		public noSizings(projectId: string) {
			const sizings = store.get(SizingGetters.projectSizings, projectId);
			return sizings && sizings.length < 1;
		}

		public canDelete(project: PumpProject) {
			if (!this.noSizings(project.id))
				return false;
			const me = store.get(AuthGetters.userId);
			return project.CreatedBy === me;
		}

		public projectShared(project: PumpProject) {
			return SizingInfo.isShared(project);
		}

		public projectLocked(project: PumpProject) {
			return SizingInfo.isLocked(project);
		}

		public otherProjectOwner(project: PumpProject) {
			if (!project || !project.CreatedBy)
				return false;
			const me = store.get(AuthGetters.userId);
			return me && project.CreatedBy !== me;
		}

		public explainHits(project: PumpProject) {
			const hit = this.searchResult?.Hits?.find(x => x.ProjectId === project.id);
			if (hit.SizingIds?.length)
				return `(${hit.SizingIds?.length} sizings match)`;
			return '';
		}

		public async loadOlderHits() {
			for (const pid of this.olderHits || [])
				await store.dispatch(ProjectActions.getProject, pid);
			this.olderHits = null;
		}

		public sizingLocked(item: PumpDocument) {
			return SizingInfo.isLocked(item);
		}

		public canDeleteSizing(sizing: PumpDocument) {
			return SizingInfo.isSizingDeletable(sizing);
		}

		@Watch('offline')
		private offlineChanged(offline: boolean) {
			// If coming online, clear all "loadedness" of project sizings, since they are probably
			// stale and sizings might be missing since they were loaded from local storage
			if (!offline)
				Object.keys(this.sizingsLoaded).forEach(k => Vue.set(this.sizingsLoaded, k, false));
		}

		public onFilterChange(s: ProjectFilter) {
			this.showSharedProjects = s?.shared || false;
		}

		public onSearch(s: { phrase: string, result: any, hidden: string[] }) {
			this.searchPhrase = s?.phrase || '';
			this.searchResult = s?.result || null;
			this.olderHits = s?.hidden || [];
		}

		public loadSizings(projectId: string) {
			if (!this.sizingsLoaded[projectId]) {
				this.loadingProject = projectId;
				Vue.set(this.sizingsLoaded, projectId, true);
				store.dispatch(SizingActions.getSizings, projectId);
			}
		}

		public async selectSizing(sizing: PumpDocument) {
			if (sizing == null) {
				this.highlightedSizing = null;
				return;
			}
			if (this.highlightedSizing?.id === sizing.id)
				return;
			// check if already cached
			const selected = store.get(SizingGetters.sizing, sizing.id);
			if (!selected)
				return;
			if (store.get(SizingGetters.fullyLoaded, { id: sizing.id, skipChildren: true })) {
				this.values = new ParamBag([selected], sizing.id, true);
				this.highlightedSizing = selected;
			} else {
				const s = await store.dispatch(SizingActions.getSizing, sizing.id);
				this.values = new ParamBag([s], sizing.id, true);
				this.highlightedSizing = s;
			}
		}

		public navigateToSizing(id: string, sizingid: string) {
			if (this.navDisabled(sizingid))
				return;
			store.commit(AuthMuts.lastOpenedProject, id);
			router.push({ name: 'sizing', params: { id, sizingid } });
		}

		public navigateToProject(id: string) {
			store.commit(AuthMuts.lastOpenedProject, id);
			router.push({ name: 'project', params: { id } });
		}

		public newProject() {
			this.newProjectName = `${this.$t('app.newProject')} ${localDateString(new Date())}`;
			this.newProjectDialog = true;
		}

		public shareProject(project: PumpProject) {
			this.sharingProject = project;
		}

		public shareSizing(sz: PumpDocument, p: PumpProject) {
			this.sharingProject = p;
			this.sharingSizing = sz;
		}

		public async deleteProject(id: string) {
			const hasSizings = await store.dispatch(SizingActions.getSizings, id);
			if (hasSizings?.length) {
				store.dispatch(SnackActions.set, 'Project contains sizings; unable to delete');
				return;
			}
			const dialog: any = this.$refs.confirmDashboard;
			const deleteProject = await dialog.open('This will delete the project.');
			if (deleteProject)
				store.dispatch(ProjectActions.removeProject, id);
		}

		public newSizing(project: PumpProject) {
			this.newSizingClone = null;
			this.newSizingName = `New sizing ${localDateString(new Date())}`;
			this.newSizingProject = project;
			this.newSizingDialog = true;
		}

		public copySizing(project: PumpProject, item: PumpDocument) {
			const sizing = store.get(SizingGetters.sizing, item.id);
			if (!sizing)
				return;
			this.newSizingClone = sizing;
			this.newSizingName = sizing.Name + ' (copy)';
			this.newSizingProject = project;
			this.newSizingDialog = true;
		}

		public async deleteSizing(item: PumpDocument) {
			const dialog: any = this.$refs.confirmDashboard;
			const deleteSizing = await dialog.open('This will delete the sizing.');
			if (deleteSizing)
				store.dispatch(SizingActions.removeSizing, item.id);
		}

		public closeDialog(type: string) {
			switch (type) {
				case 'project':
					this.newProjectDialog = false;
					break;
				case 'sizing':
					this.newSizingDialog = false;
					this.newSizingProject = null;
					break;
				case 'shareSizing':
					this.sharingProject = null;
					this.sharingSizing = null;
					break;
				default:
					break;
			}
		}
	}
</script>