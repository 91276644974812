<template>
	<div>
		<ul v-if="compact" v-bind:class="{ inline: inline != null && inline !== false }">
			<li v-for="item of allParams" v-bind:key="'c' + item.key" :class="{ columns: columns != null && columns !== false }">
				<template v-if="titlesOnly">{{ item.title }}</template>
				<template v-else>
					<span class="thin align"> {{ notitles ? '' : item.title + ':' }} 	</span> 
					<DutyPointValue :param="params.getParam(item.key)" :sizingId="sizingId || params.sizingId" :fullWidth="inline != null && inline !== false"
						:mode="mode" :assumed="item.assumed" />			
				</template>
			</li>
		</ul>
		<v-list v-else dense tile expand class="sharp-edges pt-0">
			<v-list-group v-for="group of allParamsGrouped" :key="group.group" v-model="group.expanded" class="param-list">
			  <template #appendIcon>
            <v-icon>$plus</v-icon>
          </template>
				<template v-slot:activator>
					<v-list-item-content>
						<v-list-item-title class="param-section" v-text="group.title" />
					</v-list-item-content>
				</template>
				<v-list-item v-for="item of group.params" :key="'d' + item.key" class="pt-0">
					<v-list-item-title v-text="item.title" v-if="!notitles"></v-list-item-title>
					<v-list-item-action-text>
						<DutyPointValue :param="params.getParam(item.key)" :sizingId="sizingId || params.sizingId"
							:mode="mode" :assumed="item.assumed" />
					</v-list-item-action-text>
				</v-list-item>
			</v-list-group>
		</v-list>
	</div>
</template>

<style lang="scss" scoped>
	@import '@/sass/_variables.scss';
	// readonly mode
	ul {
		list-style-type: none;
		padding-left: 0;
		&.inline li {
			display: inline-block;
			margin-right: 8px;
			&:last-child {
				margin-right: 0;
			}
		}
		li {
			line-height: 150%;
		}
	}
	.param-section {
		color: #000;
		text-transform: uppercase;
	}

	span.align {
		line-height: 1.1;
		vertical-align: text-bottom;
	}

	// sidebar mode, extra dense
	div.v-list div.v-list-group div.v-list-group__header {
		// TODO: doesn't seem to apply properly
		min-height: 18px;
	}
	.v-list--dense .v-list-item .v-list-item__content,
	.v-list-item--dense .v-list-item__content {
		padding: 0;
	}
	.v-list-item--dense,
	.v-list--dense .v-list-item {
		min-height: 18px;
	}
	.v-list-item__title {
		font-weight: normal !important;
	}
	li.columns {
		display: inline-block;
		margin-right: 12px;
	}
	.v-list-item--active .v-icon {
		color: $grey;
	}
</style>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { ParameterDef } from 'types/dto/CalcServiceDomain';
	import { ParamBag } from '@/common/ParamBag';
	import DutyPointValue from '@/components/DutyPointValue.vue';
	import { tryToTranslate } from '@/i18n';

	@Component({
		components: {
			DutyPointValue
		}
	})
	export default class ParamList extends Vue {
		@Prop() public sizingId: string;
		@Prop() public params: ParamBag;
		@Prop() public names: string[];
		@Prop() public inline: boolean;
		@Prop() public columns: boolean;
		@Prop() public notitles: boolean;
		@Prop() public titlesOnly: boolean;
		@Prop() public showZeros: boolean;
		@Prop() public filter: 'All' | 'UserDefined' | 'Basic' | 'Problematic';
		@Prop() public mode: string;

		private static readonly killGroups = [ 'Pipe', 'Inlet.Pipes', 'Outlet.Pipes' ];
		private static readonly redirectGroups: { [type: string]: string } = {
			Frame: 'Build',
			Flange: 'Build',
			Drive: 'Build'
		};
		private static aggregatedParams: string[];

		public created() {
			if (!ParamList.aggregatedParams)
				ParamList.aggregatedParams = ParamBag.aggregatedValueNames;
		}

		public get compact() {
			return this.names != null;
		}

		private get groupMap() {
			const map = {} as { [key: string]: ParameterDef };
			ParamBag.groups.forEach(g => map[g.Name] = g);
			return map;
		}

		public get assumedParams() {
			const found: any = {};
			this.params?.variants?.forEach(v => v?.Assumed?.forEach(s => found[s] = true));
			return Object.keys(found);
		}

		public get allParams() {
			const namedList = this.names != null;
			const showZeros = this.showZeros === undefined ? namedList : this.showZeros;
			const paramNames = this.names || (this.mode === 'ShowAll' ? this.params.allValueNames : this.params.visibleValueNames);
			const remove = ParamList.killGroups;
			const filter = this.filter;
			const assumedNames = this.assumedParams;

			const params = paramNames.map(k => {
				const pdef = ParamBag.getDefinition(k);
				if (!pdef || pdef.Type === 'Object' || pdef.Type === 'Group' || pdef.Name.endsWith('[]'))
					return null;

				if (filter === 'UserDefined' && pdef.ReadOnly)
					return null;
				if (filter === 'Basic' && !pdef.Tags?.includes('Std') && !this.hasProblem(k))
					return null;
				if (filter === 'Problematic' && !this.hasProblem(k))
					return null;

				const group = pdef.Name.split('.')[0];
				if (!namedList && (remove.includes(group) || remove.includes(k) || ParamList.aggregatedParams.includes(k)))
					return null;

				if (!showZeros) {
					// TODO: This triggers a dependency on all values, causing lots of reloads. Smarter solution is needed.
					// Also, orphan value cleaning triggers massive changes in ParamBag...
					if (!this.params.get<any>(k))
						return null;
				}
				const groupName = ParamList.redirectGroups[group] as string || group;
				return {
					key: pdef.Name,
					group: groupName,
					title: tryToTranslate(pdef.Name) || pdef.Description,
					order: pdef.Order || 9999999999,
					assumed: assumedNames.includes(k)
				};
			}).filter(x => x);

			// If a list of names was supplied, use given order
			if (this.names)
				return params;
			return params.sort((a, b) => a.order === b.order ? 0 : a.order < b.order ? -1 : 1);
		}

		public get allParamsGrouped() {
			const groups = this.allParams.reduce((record: Record<string, Array<typeof param>>, param) => {
				record[param.group] = record[param.group] || [];
				record[param.group].push(param);
				return record;
			}, {});

			const groupMap = this.groupMap;

			const flatGroups = Object.entries(groups).map(([key, params]) => {
				const pdef = groupMap[key];
				const groupTitle = tryToTranslate(`paramGroups.${key}`) || pdef.Description || pdef.Name || '???';
				return {
					group: key,
					title: groupTitle,
					params,
					expanded: true,
					order: pdef?.Order?.toString().padStart(10) || pdef?.Name || '9999999999'
				};
			});

			return flatGroups.sort((a, b) => a.order === b.order ? 0 : a.order < b.order ? -1 : 1);
		}

		private hasProblem(valueName: string) {
			return this.params.hasProblem(valueName);
		}
	}
</script>
