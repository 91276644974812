<template>
<svg width="17px" height="17px" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="UI-Kit---NEW" stroke="none" stroke-width="1" fill="currentColor" fill-rule="evenodd">
        <g id="Icons" transform="translate(-291.000000, -653.000000)" fill="currentColor" fill-rule="nonzero" stroke="#FFFFFF" stroke-width="0.7">
            <g id="Update" transform="translate(292.000000, 654.000000)">
                <path d="M12.8571429,3 C11.5873016,1.19178082 9.6031746,0 7.42063492,0 C4.16666667,0 1.19047619,2.63013699 0.476190476,6.08219178 L2.77777778,6.08219178 C3.29365079,3.61643836 5.03968254,2.38356164 7.38095238,2.38356164 C9.16666667,2.38356164 10.5952381,3.08219178 11.3888889,4.47945205 L9.44444444,6.49315068 L15,6.49315068 L15,0.739726027 L12.8571429,3 Z" id="Path"></path>
                <path d="M12.0238095,8.95890411 C11.5079365,11.4246575 9.76190476,12.6164384 7.42063492,12.6164384 C5.71428571,12.6164384 4.32539683,11.9589041 3.49206349,10.6849315 L5.55555556,8.54794521 L0,8.54794521 L0,14.3424658 L2.02380952,12.2054795 C3.37301587,13.890411 5.31746032,15 7.42063492,15 C10.6746032,15 13.4920635,12.369863 14.1666667,8.95890411 L12.0238095,8.95890411 L12.0238095,8.95890411 Z" id="Path"></path>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
    export default {};
</script>
