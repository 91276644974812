export default class Debounce {
	private pending: any;
	private triggers: number = 0;
	private waiter: Promise<any>;
	public paused: boolean;
	public triggeredDuringPause: boolean;

	public constructor(
		private readonly name: string,
		private readonly delay: number,
		private readonly method: (arg?: any) => Promise<any>
	) { }

	public trigger(arg?: any) {
		if (this.paused) {
			// console.debug(`Debouncer '${this.name}' triggered during pause`);
			this.triggeredDuringPause = true;
			return;
		}
		this.triggeredDuringPause = false;
		this.triggers++;
		if (this.pending)
			return this.waiter;

		let resolve: (value: any) => void;
		this.waiter = new Promise(r => resolve = r);
		this.pending = setTimeout(() => {
			// console.debug(`Debouncer '${this.name}' running after ${this.delay} ms; ${this.triggers} triggers hit.`);
			const exec = this.method(arg);
			this.pending = null;
			this.triggers = 0;

			(exec || Promise.resolve()).finally(() => {
				this.triggeredDuringPause = false;
				resolve(undefined);
			});
		}, this.delay);
		return this.waiter;
	}
}
