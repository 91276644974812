import ApiService from './api.service';
import { MessageResult, MessageType, Message, ForwardingRule } from 'types/dto/Messages';

function enc(x: any) {
	return x ? encodeURIComponent(x) : '';
}

class MsgService {
	private readonly API = process.env.VUE_APP_MSGSERVICE_ENDPOINT;

	public async getMessages(target: string, types: MessageType[], createdAfter?: string): Promise<MessageResult> {
		let params = '';
		if (createdAfter)
			params += `?createdAfter=${enc(createdAfter)}`;
		if (types?.length)
			params += `${params.length ? '&' : '?'}types=${enc(types.join(','))}`;
		return ApiService.get<MessageResult>(`${this.API}/inbox/${enc(target)}${params}`);
	}

	public async send(msg: Message): Promise<Message> {
		return ApiService.post<Message>(`${this.API}/outbox`, msg);
	}

	public async markRead(msgIds: string[]): Promise<Message[]> {
		if (msgIds?.length)
			return ApiService.post<Message[]>(`${this.API}/read`, msgIds);
	}

	public async getForwards() {
		return ApiService.get<ForwardingRule[]>(`${this.API}/inbox/forwards`);
	}

	public async setForwarding(rule: ForwardingRule) {
		return ApiService.post<ForwardingRule>(`${this.API}/inbox/forward`, rule);
	}

	public async delete(msgId: string) {
		return ApiService.delete(`${this.API}/msg/${enc(msgId)}`);
	}
}
export default new MsgService();
