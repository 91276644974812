import { ValidationResult, MessageSeverity } from 'types/dto/CalcServiceDomain';

interface LogEntry extends ValidationResult {
	id: string;
	time: number;
	category: string;
}

interface IDebugState {
	log: LogEntry[];
}

const state: IDebugState = {
	log: []
};

const getters = {
	log: (targetState: IDebugState) => targetState.log
};

const actions = {
	replace({ commit }: any, { category, entries }: { category: string, entries: ValidationResult[] }) {
		commit('clearCategory', category);
		commit('add', { category, entries });
	}
};

const mutations = {
	add(targetState: IDebugState, { category, entries }: { category: string, entries: ValidationResult[] }) {
		if (!entries || !entries.length)
			return;

		let timeStamp = new Date().getTime();
		const wrapped = entries.map(x => ({
			id: (Math.random() * 250000000).toString(16),
			time: timeStamp++,
			category,
			Severity: x.Severity === undefined ? MessageSeverity.Debug : x.Severity,
			Message: x.Message,
			Source: x.Source || 'Client',
			ParamName: x.ParamName
		}));
		targetState.log.push(...wrapped);
	},
	clear(targetState: IDebugState) {
		targetState.log = [];
	},
	clearCategory(targetState: IDebugState, category: string) {
		targetState.log = targetState.log.filter(x => x.category !== category);
	}
};

export const enum DebugMutations {
	add = 'debug/add',
	clear = 'debug/clear',
	clearExternal = 'debug/clearCategory'
}

export const enum DebugActions {
	replace = 'debug/replace'
}

export const enum DebugGetters {
	log = 'debug/log'
}

export const debug = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
