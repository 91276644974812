<template>
<svg width="13px" height="14px" viewBox="0 0 13 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="UI-Kit---NEW" stroke="none" stroke-width="1" fill="currentColor" fill-rule="evenodd">
        <g id="Icons" transform="translate(-157.000000, -328.000000)" fill="currentColor" fill-rule="nonzero">
            <g id="UI-076-menu" transform="translate(157.000000, 328.000000)">
                <path d="M0,6.03 L13,6.03 L13,7.03 L0,7.03 L0,6.03 Z M0,8.8817842e-16 L13,8.8817842e-16 L13,1 L0,1 L0,8.8817842e-16 Z M0,12.01 L13,12.01 L13,13.01 L0,13.01 L0,12.01 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
    export default {};
</script>
