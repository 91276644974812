import Vue from 'vue';
import router from '@/router';
import { ApplicationInsights, IEventTelemetry } from '@microsoft/applicationinsights-web';
import store, { NetworkGetters } from '@/store';

class InsightService {
	private static appInsights: ApplicationInsights;

	public static init(options: { key: string, baseName?: string }) {
		const instrumentationKey = options.key;
		if (!instrumentationKey)
			return;

		const appInsights = new ApplicationInsights({
			config: {
				instrumentationKey,
				url: 'https://az416426.vo.msecnd.net/scripts/b/ai.2.min.js'
			}
		});
		appInsights.loadAppInsights();

		InsightService.appInsights = appInsights;
		Vue.prototype.$appInsights = appInsights;
		this.setupPageTracking(options);
	}

	public static disableTelemetry(value: boolean = false) {
		if (InsightService.appInsights?.config)
			InsightService.appInsights.config.disableTelemetry = value;
	}

	public static trackEvent(event: string, value?: any) {
		if (!InsightService.appInsights || !store.get(NetworkGetters.connected))
			return;
		const payload: IEventTelemetry = { name: event };
		InsightService.appInsights.trackEvent(payload, value);
	}

	public static setUserContext(userId?: string) {
		if (InsightService.appInsights)
			InsightService.appInsights.setAuthenticatedUserContext(userId);
	}

	private static setupPageTracking(options: any) {
		const baseName = options.baseName || '(Vue App)';
		router.beforeEach((route, from, next) => {
			const name = baseName + ' / ' + route.name;
			InsightService.appInsights.startTrackPage(name);
			next();
		});

		router.afterEach((route) => {
			const name = baseName + ' / ' + route.name;
			const url = location.protocol + '//' + location.host + route.fullPath;
			InsightService.appInsights.stopTrackPage(name, url);
		});
	}
}

export default InsightService;
