<template>
<svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="UI-Kit---NEW" stroke="none" stroke-width="1" fill="currentColor" fill-rule="evenodd">
        <g id="Icons" transform="translate(-294.000000, -292.000000)" fill="currentColor" fill-rule="nonzero">
            <g id="Group" transform="translate(294.000000, 292.000000)">
                <g id="tick" transform="translate(0.000000, -0.000000)">
                    <polygon id="Path" points="10.343 5.142 6.696 8.789 4.649 6.743 3.942 7.45 6.696 10.203 11.05 5.85"></polygon>
                    <path d="M12.799,2.193 C9.875,-0.731 5.117,-0.731 2.193,2.193 C-0.731,5.118 -0.731,9.876 2.193,12.8 L2.546,12.446 L2.208,12.814 L2.578,13.154 L3.255,12.418 L2.896,12.088 C0.365474001,9.54739932 0.367262857,5.43839639 2.9,2.9 C5.435,0.366 9.559,0.366 12.092,2.9 C14.6258558,5.44053054 14.6258558,9.55246946 12.092,12.093 C10.8866901,13.3041084 9.25065363,13.9883671 7.542,13.996 L7.55,14.996 C9.52107319,14.9861968 11.4081587,14.1967055 12.799,12.8 C15.723,9.876 15.723,5.118 12.799,2.193 L12.799,2.193 Z" id="Path"></path>
                    <path d="M6.996,13.996 L7.496,13.996 L7.496,14.996 L6.996,14.996 L6.996,13.996 Z M3.35,13.748 C3.619,13.926 3.898,14.086 4.186,14.227 L4.626,13.329 C4.3755933,13.206528 4.13341568,13.0679021 3.901,12.914 L3.35,13.748 Z M5.076,14.596 C5.38,14.7 5.691,14.783 6.007,14.847 L6.205,13.867 C5.93154066,13.8119806 5.66196687,13.7391589 5.398,13.649 L5.076,14.596 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
    export default {};
</script>
