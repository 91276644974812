<template>
	<v-container fluid wrap class="pa-0">
		<v-row>
			<v-col cols="3" v-if="!offline">
				<v-img :src="pumpImage" :alt="pumpText" contain height="200" max-width="200" />
			</v-col>
			<v-col>
				<h2>{{ pump.DisplayName }}</h2>
				<ParamList v-if="pumpBag" :params="pumpBag" :names="paramNames" :showZeros="false" />
				<div v-for="err of unsuitableErrors" :key="err.Message" class="error--text" v-text="err.Message"></div>
			</v-col>
			<v-col>
				<br />
				<ParamList v-if="pumpBag" :params="pumpBag" :names="paramNames2" :showZeros="false" />
				<v-btn small class="mt-4 mr-2" @click="showCurve = !showCurve">{{showCurve ? 'Hide' : 'Show'}} curve</v-btn>
				<v-btn small class="mt-4" v-if="isDevloper" @click="viewPump">Open pump viewer</v-btn>
			</v-col>
			<v-col v-if="!offline && showCurve" class="pt-0">
				<PumpCurve v-if="showCurve" class="pt-0" :variants="variantDocs" :sizingId="params.sizingId" settingsKey='pumpInfoCurve' />
			</v-col>
		</v-row>
	</v-container>
</template>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { PumpResult } from 'types/dto/PumpSearch';
	import { MessageSeverity } from 'types/dto/CalcServiceDomain';
	import { ParamBag } from '@/common/ParamBag';
	import store, { NetworkGetters, AuthGetters } from '@/store';
	import ParamList from '@/components/ParamList.vue';
	import PumpCurve from '@/components/PumpCurve.vue';

	@Component({
		components: {
			ParamList,
			PumpCurve
		}
	})
	export default class PumpInfo extends Vue {
		@Prop() public sizingId: string;
		@Prop() public pump: PumpResult;
		@Prop() public dutyPoints: PumpResult[];
		@Prop() public params: ParamBag;

		private showCurve: boolean = false;

		private static readonly baseParamNames = [
			'ImpellerDiameter', 'MaxSphere', 'BepFlow', 'BepEfficiency', 'DutySpeed', 'DutyEfficiency', 'FrothEfficiency', 'TipSpeed', 'NPSHR',
			'AbsorbedPower', 'AgitatorPower'
		].map(x => 'Pump.' + x);

		private static readonly baseParamNames2 = [
			'Pump.Status', 'Pump.ImpellerMaterial', 'Pump.CaseMaterial', 'Slurry.DeratingMethod', 'Pump.HR', 'Pump.ER', 'Pump.QR'
		];

		public get paramNames() {
			return PumpInfo.baseParamNames;
		}

		public get paramNames2() {
			return PumpInfo.baseParamNames2;
		}

		private get offline() {
			return !store.get(NetworkGetters.connected);
		}

		private get pumpBag() {
			if (!this.dutyPoints || !this.params)
				return null;
			const dps = this.dutyPoints.map(x => ({ values: { Pump: x }, messages: x.Messages }));
			return this.params.createVariants(dps, true);
		}

		private get variantDocs() {
			return this.pumpBag?.variants || [];
		}

		get pumpText() {
			return this.pump.PumpRange;
		}

		get pumpImage() {
			return require(`@/assets/${this.image}`);
		}

		public get isDevloper() {
			return store.get(AuthGetters.hasRole, 'developer');
		}

		public get unsuitableErrors() {
			const p = this.pump;
			if (!p || p.Suitable || !p.Messages)
				return [];
			return p.Messages.filter(x => x.Severity >= MessageSeverity.Error && x.ParamName === 'Pump.DisplayName');
		}

		get image(): string {
			const range = this.pump.PumpRange;
			const picName = (name: string) => `Product_Picture_Pump_${name}.jpg`;

			switch (range.toUpperCase()) {
				case 'HM': case 'MM': case 'HH': case 'HG':
					return picName('HM_MM_HH_HG');
				case 'HR': case 'MR':
					return picName('HR_MR');
				case 'MDM':
					return picName('MDM');
				case 'MDR':
					return picName('MDR');
				case 'THOMAS':
					return picName('Thomas');
				case 'VF':
					return picName('VF');
				case 'VS': case 'VSHM': case 'VSMM': case 'VSHR': case 'STGVA':
					return picName('VS_v2');
				case 'VT':
					return picName('VT');
				case 'XM':
					return picName('XM');
				case 'XR':
					return picName('XR');
				case 'VASA HD':
					return picName('VASA HD');
				default:
					return 'generic_pump_v2.jpg';
			}
		}

		public viewPump() {
			const ff = this.params.get('Slurry.FrothFactor');
			const [hr, er, qr] = [this.pump.HR, this.pump.ER, this.pump.QR];

			this.$router.push({
				name: 'pump',
				params: { id: this.pump.Id },
				query: {
					PDH: this.params.get('Heads.PDH'),
					FlowRate: this.params.get('Slurry.FlowRate'),
					SlurryDensity: this.params.get('Slurry.SlurryDensity'),
					ManualHR: (hr > 0 && hr < 1.0 ? hr : undefined) as any,
					ManualER: (er > 0 && er < 1.0 ? er : undefined) as any,
					ManualQR: (qr > 0 && qr < 1.0 ? qr : undefined) as any,
					FrothFactor: (ff > 1.0 ? ff : undefined) as any,
					CorrosionIndex: this.params.get('Liquid.CorrosionIndex'),
					LiquidTemp: this.params.get('Liquid.Temp'),
					TrimPercentage: this.params.get('Pump.TrimPercentage')
				}
			});
		}
	}
</script>
