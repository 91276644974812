<template>
<article class="padding content content-wrapper">
	<v-container>
		<v-row>
			<v-col>
				<v-card flat class="base-padding">
					<v-card-title>
						<h3>Project collaboration settings</h3>
					</v-card-title>

					<v-card-text class="center-flex">
						<v-label><span class="pr-3 thin">Editors:</span></v-label>

						<v-chip-group>
							<template v-for="e of editors">
								<v-tooltip :key="e" bottom max-width="500" open-delay="500" :disabled="false" >
									<template v-slot:activator="{ on }">
										<!-- Needs a wrapper div since the tooltip does not appear when v-on is on a disable component -->
										<div v-on="on">
											<v-chip :class="['editor', e === me && 'self']" :close="canRemoveEditor(e)" @click:close="removeEditor({ id: e })" :disabled="creators.includes(e)"
												tabindex="-1">
													<Username :id="e" spanClass="small" />
											</v-chip>
										</div>
									</template>
									<ul class="pl-2">
										<li v-for="r of userRoles(e)" :key="r" v-text="r"></li>
									</ul>
								</v-tooltip>
							</template>

							<v-tooltip bottom max-width="500" open-delay="500">
								<template v-slot:activator="{ on }">
									<v-chip v-on="on" v-if="canAddEditor" class="btn-add" @click="startAddingEditor" @keyup.space="startAddingEditor">
										<v-icon class="plus-icon">$plus</v-icon>
									</v-chip>
								</template>
								Add editor
							</v-tooltip>

							<v-chip v-if="adding" close @click:close="removeEditor(newEditor)" tabindex="-1" class="editor input-chip">
								<UserSelector v-model="newEditor" v-focus @enter="addEditor" @esc="stopAddingEditor" class="add-editor" />
								<v-tooltip v-if="newEditor" bottom max-width="500" open-delay="500">
									<template v-slot:activator="{ on }">
										<v-btn class="btn-add-confirm" icon v-on="on" x-small @click="addEditor">
											<v-icon size="14" color="success">$chipCheck</v-icon>
										</v-btn>
									</template>
									Add editor (or press enter)
								</v-tooltip>
							</v-chip>
						</v-chip-group>
						<br />
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-row v-if="target || sizingid">
			<v-col>
				<v-card flat class="base-padding">
					<v-card-title><h3 class="subtitle">Comments</h3></v-card-title>
					<v-card-text>
						<ChangeLog :target="target || sizingid" type="Comment" showDelete />
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-row v-if="sizingid">
			<v-col>
				<v-card flat class="base-padding">
					<v-card-title><h3 class="subtitle">Changes</h3></v-card-title>
					<v-card-text>
						<ChangeLog :target="sizingid" type="Change" />
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<Confirm ref="confirm" />
	</v-container>
	</article>
</template>


<style lang="scss" scoped>  
@import '@/sass/main.scss';

	.v-text-field.add-editor {
		margin:25px 0 0 1px;
		width: 200px;
	}

	.input-chip {
		background-color:$white !important;
		border: 1px solid $grey-lighten-1;
		&:hover {
			background-color:$selection-lighten-1 !important;
			border:0 !important;
		}
	}

	.btn-add {
		background-color:$primary !important;
		opacity: 1;
		&:hover {
			background-color:$selection-lighten-1 !important;
			border:0 !important;
			opacity: 1;
		}
	}

	.btn-add-confirm:hover {
		border-radius: 20px;
		background-color: $selection-lighten-1;
	}

	.editor {
		opacity: 1;
		border-color: $grey !important;
		&:hover {
			opacity: 1;
			background-color:$selection-lighten-1 !important;
			border:0 !important;
		}
	}

	.self {
		background-color: $selection-lighten-1 !important;
		color: $white;
		border:0 !important;
	}

	.v-icon.plus-icon {
		color: $white;
		&:hover {
			color: $primary;
		}
	}

	@media only screen and (max-width: 960px) {
		.content-wrapper {
			margin-top:55px;
		}
	}
</style>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop } from 'vue-property-decorator';
	import ChangeLog from '@/components/ChangeLog.vue';
	import Username from '@/components/Username.vue';
	import Confirm from '@/components/Confirm.vue';
	import UserSelector, { IUserEntry } from '@/components/UserSelector.vue';
	import { PumpDocument, PumpProject } from 'types/dto/CalcServiceDomain';
	import store, { AuthGetters, ProjectActions, SnackActions } from '@/store';
	import SizingInfo from '@/common/SizingInfo';
	import InsightService from '@/services/insight.service';

	@Component({
		name: 'project',
		components: {
			ChangeLog,
			Username,
			Confirm,
			UserSelector
		},
	})
	export default class Collaboration extends Vue {
		@Prop() public target: string;
		@Prop() public sizing: PumpDocument;
		@Prop() public project: PumpProject;

		public adding: boolean = false;
		public newEditor: IUserEntry = null;

		public get me() {
			return store.get(AuthGetters.userId);
		}

		public get creators() {
			return SizingInfo.creators(this.sizing || this.project);
		}

		public get editors() {
			return SizingInfo.editors(this.sizing || this.project);
		}

		public get sizingid() {
			return this.sizing?.id;
		}

		public get canAddEditor() {
			return !this.readOnly;
		}

		public get readOnly() {
			return !this.project || SizingInfo.isLocked(this.sizing) || SizingInfo.isLocked(this.project);
		}

		public canRemoveEditor(e: string) {
			if (this.readOnly)
				return false;
			return this.editors.includes(e) && !this.creators.includes(e);
		}

		public userRoles(e: string) {
			const roles = [];
			const pr = this.project;
			if (pr) {
				if (pr.Editors?.includes(e))
					roles.push('Project editor');
				if (pr.CreatedBy === e)
					roles.push('Project creator');
			}
			const sz = this.sizing;
			if (sz) {
				if (sz.Editors?.includes(e))
					roles.push('Sizing editor');
				if (sz.CreatedBy === e)
					roles.push('Sizing creator');
			}
			return roles;
		}

		private static openedBefore: boolean;

		public created() {
			if (!Collaboration.openedBefore) {
				Collaboration.openedBefore = true;
				InsightService.trackEvent('Collab:OpenTab');
			}
		}

		public startAddingEditor() {
			this.adding = true;
		}

		public stopAddingEditor() {
			this.newEditor = null;
			this.adding = false;
		}

		public async addEditor() {
			const e = this.newEditor;
			if (!e)
				return;

			if (this.editors.includes(e.id)) {
				store.dispatch(SnackActions.set, 'The user can already edit this project');
				return;
			}

			store.dispatch(SnackActions.set, 'Adding user...');
			this.stopAddingEditor();
			const newEditors = SizingInfo.editors(this.project, true).concat([e.id]);
			store.dispatch(ProjectActions.setEditors, { projectId: this.project.id, editors: newEditors });
		}

		public async removeEditor(e: { id: string }) {
			const wasAdding = this.adding && (!e || this.newEditor && e.id === this.newEditor.id);
			this.stopAddingEditor();

			// No need to confirm cancellation of adding a new user
			if (wasAdding)
				return;

			const dialog: any = this.$refs.confirm;
			const sure = await dialog.open('This user will no longer be able to make changes or receive comments/change notifications.',
			 	{ width: 500 });
			if (!sure)
				return;

			const newEditors = SizingInfo.editors(this.project, true).filter(x => x !== e.id);
			store.dispatch(ProjectActions.setEditors, { projectId: this.project.id, editors: newEditors });
		}
	}
</script>
