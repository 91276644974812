<template>
	<v-card class="elevation-12 pa-4" min-height="200">
		<v-toolbar class="elevation-0">
			<v-toolbar-title><h2>{{ t('signIn') }}</h2></v-toolbar-title>
			<v-spacer></v-spacer>
		</v-toolbar>
		<v-card-text class="pl-0">
			<v-card-title v-if="!authenticating" class="word-wrap-normal">
				{{ title }}
			</v-card-title>
			<div v-else class="pl-4 text-xs-center">
				<v-progress-circular :size="50" indeterminate />
			</div>
			<div class="pl-4 pt-4">
				<p class="big">{{ description }}</p>
			</div>
		</v-card-text>
		<v-card-actions v-if="!authenticating">
			<v-spacer></v-spacer>
			<v-btn v-if="loggedIn" color="error" @click="handleLogout">{{ t('signOut') }}</v-btn>
			<v-btn dark v-if="!loggedIn" @click="handleLogin">{{ t('signIn') }}</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import authService from '@/services/auth.service';
import TokenService from '@/services/token.service';
import store, { AuthGetters, AuthActions, NetworkGetters } from '@/store';

@Component
export default class Login extends Vue {
	public t(key: string) {
		return this.$t('app.' + key);
	}

	public get authenticating() {
		return store.get(AuthGetters.authenticating);
	}

	public get authenticationError() {
		return store.get(AuthGetters.authenticationError);
	}

	public get authenticationErrorCode() {
		return store.get(AuthGetters.authenticationErrorCode);
	}

	public get loggedIn() {
		// Need to check the cookie as well since it may have been removed by the browser
		const isLoggedIn = store.get(AuthGetters.loggedIn);
		return (isLoggedIn && !!TokenService.getToken()) || false;
	}

	public get offline() {
		return !store.get(NetworkGetters.connected);
	}

	public handleLogout() {
		store.dispatch(AuthActions.logout);
	}

	public handleLogin() {
		if (this.offline)
			store.dispatch(AuthActions.login, { code: '' });
		else {
			// Pass redirect/current path to login page to be able to return to the requested page afterwards
			const currentPath = this.$route.query?.redirect as string || this.$route.path;
			authService.redirect(currentPath);
		}
	}

	public get title() {
		if (this.offline)
			return this.t('offlineTitle');
		if (this.authenticationError)
			return this.t('authFailed');
		if (this.loggedIn || this.authenticating)
			return this.t('welcome');
		return this.t('needAccount');
	}

	public get description() {
		if (this.offline) {
			return this.t('offlineDesc');
		}
		if (this.authenticationError) {
			if (this.authenticationErrorCode && this.authenticationErrorCode.toString() !== '400')
				return `${this.authenticationError} (error ${this.authenticationErrorCode})`;
			return this.authenticationError;
		}
		if (this.authenticating)
			return this.t('xferToDash');
		if (!this.loggedIn)
			return this.t('useCreds');
	}
}
</script>
