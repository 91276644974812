<template>
<svg class="plus" width="5px" height="5px" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="UI-Kit---NEW" stroke="none" stroke-width="1" fill="currentColor" fill-rule="evenodd">
        <g id="Icons" transform="translate(-295.000000, -328.000000)" fill="currentColor" fill-rule="nonzero">
            <g id="UI-043-Expand" transform="translate(295.000000, 328.000000)">
                <polygon id="Path" points="13 6 7 6 7 0 6 0 6 6 0 6 0 7 6 7 6 13 7 13 7 7 13 7"></polygon>
            </g>
        </g>
    </g>
</svg>
</template>

<style>
    svg.plus {
        height: 16px;
        width: 16px;
    }
</style>

<script>
    export default {};
</script>
