// tslint:disable max-classes-per-file
class QItem {
	constructor(public readonly id: string, public ctor: () => Promise<any>, state: string) {
		this.setState(state);
	}

	public state?: string;
	public work?: Promise<any>;
	public done?: Promise<any>;
	public signalDone?: (x: any) => void;
	public signalFailed?: (x: any) => void;

	public setState(state: string) { this.state = state; }
	public toString() { return `${this.id}: ${this.state}`; }
}

export default class WorkQueue {
	private readonly entries: QItem[] = [];

	public add<T>(id: string, ctor: () => Promise<T>) {
		const entry = this.createEntry(id, ctor);
		if (this.entries.length)
			this.start(this.entries[0]);
		return entry.done as Promise<T>;
	}

	public replace<T>(id: string, ctor: () => Promise<T>) {
		if (id) {
			const existingIdx = this.entries.findIndex(x => x.id === id && !x.work);
			if (existingIdx >= 0) {
				const existing = this.entries[existingIdx];
				existing.ctor = ctor;
				existing.setState('replaced');

				// Move to back of queue
				this.entries.push(this.entries.splice(existingIdx, 1)[0]);
				return existing.done as Promise<T>;
			}
		}
		return this.add(id ?? 'UnnamedWork', ctor);
	}

	public async waitAll() {
		let waited: boolean;
		do {
			waited = false;
			for (const entry of this.entries) {
				if (entry.done && entry.state !== 'done' && entry.state !== 'failed') {
					await entry.done;
					waited = true;
				}
			}
		} while(waited);
	}

	private start(entry: QItem) {
		if (!entry.work) {
			if (entry.state === 'working') {
				console.error(`Deadlock warning: job ${entry.id} seems have started a new job in a single-job work queue`);
				return;
			}
			entry.setState('working');
			entry.work = entry.ctor()
				.then(x => this.onDone(x, entry, true))
				.catch(x => this.onDone(x, entry, false));
		}
	}

	private createEntry<T>(id: string, ctor: () => Promise<T>) {
		const entry: QItem = new QItem(id, ctor, 'added');
		entry.done = new Promise<any>((resolve, reject) => {
			entry.signalDone = resolve;
			entry.signalFailed = reject;
		});
		this.entries.push(entry);
		return entry;
	}

	private onDone(result: any, entry: QItem, success: boolean) {
		entry.setState(success ? 'done' : 'failed');
		if (success && entry.signalDone)
			entry.signalDone(result);
		else if (!success && entry.signalFailed)
			entry.signalFailed(result);

		this.entries.shift();
		if (this.entries.length)
			this.start(this.entries[0]);
	}
}
