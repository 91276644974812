<template>
	<v-card flat fill-height>
		<SimpleWarnings v-if="problems" :msgs="buildManager.vBeltMessages" />
		<v-card flat>
			<v-card-title v-if="fixedVBelt" class="headline pt-1 pb-1"><h3>Selected belt drive</h3></v-card-title>
			<v-card-title v-else class="headline pt-1 pb-1"><h3>Suitable belt drives</h3></v-card-title>
			<v-card-text>
				<v-row v-if="!fixedVBelt">
					<v-col>
						<FilterList :filters="filters" xlatePrefix="VBelt" />
					</v-col>
					<v-col>
						<v-slider v-if="all && all.length" :label="$t('VBelt.MinServiceFactor')"
							hide-details v-model="minServiceFactor" :thumb-label="false" min="1" max="2" step="0.05">
							<template v-slot:append>{{ minServiceFactor.toFixed(2) }}</template>
						</v-slider>
					</v-col>
				</v-row>

				<v-data-table :items="driveList" :headers="headers" persistent :loading="searching"
					item-key="Id" :hide-default-footer="!!fixedVBelt" ref="vbTable" :no-data-text="noDataText">
					<template v-slot:item="props">
						<tr @click="props.expand(!props.isExpanded)" :class="isSelected(props.item) && 'sel-list-item'"
							@mouseover="showCommentIcon = isSelected(props.item)" @mouseout="showCommentIcon = false">
							<td class="pr-2" @click.stop="driveClick(props.item)">
								<v-icon :color="isSelected(props.item) ? 'selection' : 'grey'" :disabled="locked">
									{{ isSelected(props.item) ? 'radio_button_on' : 'radio_button_off' }}
								</v-icon>
							</td>
							<td class="pa-0">
								<v-tooltip bottom v-if="hasWarning(props.item)" color="warning">
									<template v-slot:activator="{ on }">
										<span class="d-flex align-start" v-on="on">
											<v-icon small color="warning" class="cursor-help ml-1">error</v-icon>
										</span>
									</template>
									<div v-for="w of warnings(props.item)" v-text="w" :key="w" />
								</v-tooltip>
							</td>
							<td class="text-right pl-2">{{ Math.abs(props.item.PumpSpeed - pumpSpeed) }}</td>
							<td class="text-right">{{ renderItem(props.item, 'PumpSpeed') }}</td>
							<td>{{ props.item.DriveType }}</td>
							<td class="text-right">{{ renderItem(props.item, 'MotorSheaveDiameter') }}</td>
							<td class="text-right">{{ renderItem(props.item, 'PumpSheaveDiameter') }}</td>
							<td class="text-right">{{ renderItem(props.item, 'NoOfBelts') }}</td>
							<td class="text-right">{{ renderItem(props.item, 'BeltLength') }}</td>
							<td class="text-right">{{ renderItem(props.item, 'ActualCenter') }}</td>
							<td class="text-right">{{ renderItem(props.item, 'BeltLoad') }}</td>
							<td v-if="isSelected(props.item)" class="readonlyValue pa-0">
								<CommentIcon v-if="target" :target="target" field="VBelt" type="Comment" :visible="showCommentIcon" style="padding-left: 6px" />
							</td>
						</tr>
					</template>
					<template v-slot:expanded-item="props">
						<td :colspan="headers.length">
							<v-card flat color="gray">
								<v-card-text>
										<v-layout>
											<v-flex xs3 pa-2>
												<BeltDrive :cfg="motor.Drive" :vb="props.item" />
											</v-flex>
											<v-flex xs9 pl-3>
												<v-layout row wrap>
													<v-flex>
														<ParamList key="VB1" :sizingId="values.sizingId" :showZeros="false" :params="getParamBag(props.item)" :names="beltProps" />
													</v-flex>
													<v-flex>
														<ParamList key="VB2" :sizingId="values.sizingId" :showZeros="false" :params="getParamBag(props.item)" :names="moreBeltProps" />
													</v-flex>
												</v-layout>
											</v-flex>
										</v-layout>
								</v-card-text>
							</v-card>
						</td>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</v-card>
</template>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { MessageSeverity, PumpParams, ValidationResult } from 'types/dto/CalcServiceDomain';
	import { VBeltResult, VBeltDuty } from 'types/dto/VBelt';
	import { ParamBag } from '@/common/ParamBag';
	import { MotorResult } from 'types/dto/PumpBuild';
	import { PumpResult } from 'types/dto/PumpSearch';
	import ParamList from '@/components/ParamList.vue';
	import BeltDrive from '@/components/BeltDrive.vue';
	import FilterList from '@/components/FilterList.vue';
	import CommentIcon from '@/components/CommentIcon.vue';
	import SimpleWarnings from '@/components/SimpleWarnings.vue';
	import BuildManager from '@/common/BuildManager';
	import ItemFilter from '@/common/ItemFilter';
	import InsightService from '@/services/insight.service';

	const driveHeaders: any[] = [
		{ text: '', value: '', align: 'end', class: 'pr-2' },
		{ text: '', value: '', sortable: '', class: 'pa-0' },
		{ text: 'Δ', align: 'end', class: 'pl-2' },
		{ text: 'Pump speed', value: 'PumpSpeed', align: 'end' },
		{ text: 'Drive type', value: 'DriveType', align: 'start' },
		{ text: 'Motor sheave', value: 'MotorSheaveDiameter', align: 'end' },
		{ text: 'Pump sheave', value: 'PumpSheaveDiameter', align: 'end' },
		{ text: 'Belts', value: 'NoOfBelts', align: 'end' },
		{ text: 'Belt size', value: 'BeltLength', align: 'end' },
		{ text: 'Centers', value: 'ActualCenter', align: 'end' },
		{ text: 'Belt load', value: 'BeltLoad', align: 'end' },
		{ text: '', sortable: '', class: 'pa-0' }
	];

	const beltPropList = [
		'VBelt.ServiceFactor',
		'VBelt.BeltLength',
		'VBelt.SheaveWidth',
		'BearingAssembly.BearingWetLife', // Ugly hack instead of uniquely defining these for VBelt
		'BearingAssembly.BearingDriveLife'
	];

	const beltPropList2 = [
		'VBelt.MotorSheaveDef',
		'VBelt.MotorBushDef',
		'VBelt.PumpSheaveDef',
		'VBelt.PumpBushDef',
		'VBelt.BeltDef'
	];

	@Component({
		components: {
			ParamList,
			BeltDrive,
			FilterList,
			CommentIcon,
			SimpleWarnings
		}
	})
	export default class VBeltList extends Vue {
		@Prop() public locked: boolean;
		@Prop() public pump: PumpResult;
		@Prop() public motor: MotorResult;
		@Prop() public values: ParamBag;
		@Prop() public buildManager: BuildManager;
		@Prop() public target: string;

		public searching: boolean = false;
		public readonly headers = driveHeaders;
		public readonly beltProps = beltPropList;
		public readonly moreBeltProps = beltPropList2;
		public readonly imageUrl: string = process.env.BASE_URL + 'img/vbelts.jpg';
		public readonly filters = [ new ItemFilter<VBeltResult>('DriveType') ];
		public minServiceFactor: number = 1.3;
		public all: VBeltResult[] = [];
		public showCommentIcon: boolean = false;
		private bags: Record<string, ParamBag>;

		public created() {
			this.bags = {};
		}

		public get fixedVBelt() {
			return this.buildManager.selectedVBelt;
		}

		private expandDrive(vbelt: VBeltResult) {
			const tableData = this.$refs.vbTable as any;
			if (tableData) {
				// Try Vuetify 2 first, then fall back to 1.5
				if (tableData.expand)
					tableData.expand(vbelt, true);
				else
					this.$set(tableData.expanded, vbelt.Id, true);
			}
		}

		public mounted() {
			if (this.fixedVBelt)
				this.expandDrive(this.fixedVBelt);
			else {
				const work = this.buildManager.doSearchVBelts();
				if (work) {
					this.searching = true;
					work.finally(() => this.searching = false);
				}
			}
		}

		public hasWarning(item: VBeltResult) {
			return this.warnings(item)?.length > 0;
		}

		public warnings(item: VBeltResult) {
			const pb = this.getParamBag(item);
			const errs = (
				pb.errorsInGroup('VBelt').Messages
				.concat(pb.errorsInGroup('BearingAssembly.BearingWetLife').Messages)
				.concat(pb.errorsInGroup('BearingAssembly.BearingDriveLife').Messages)
			).filter(x => x.Severity >= MessageSeverity.Warning);
			return ParamBag.mergeMessages(errs).map(x => x.Message);
		}

		get useImperial() {
			return ParamBag.useImperial(this.values.sizingId);
		}

		get problems() {
			return this.buildManager.vBeltMessages.some(x => x.Severity >= MessageSeverity.Warning);
		}

		get noDataText() {
			if (this.all?.length && this.driveList && !this.driveList.length)
				return 'All found drives are hidden by filters';
			return 'No suitable drives found. Change motor or drive arrangement?';
		}

		get pumpSpeed() {
			return this.pump?.DutySpeed || 0;
		}

		public get driveList() {
			this.bags = {};
			if (this.fixedVBelt)
				return [this.fixedVBelt];

			const available = this.buildManager.availableVBelts;
			this.all = available;

			const minFactor = this.minServiceFactor;
			let filtered = available.filter(x => x.DutyPoints && !x.DutyPoints.some(dp => dp.ServiceFactor && dp.ServiceFactor < minFactor));
			this.filters.forEach(f => f.update(filtered));
			this.filters.forEach(f => filtered = f.filter(filtered));
			return filtered;
		}

		public isSelected(vb: VBeltResult) {
			return vb && this.fixedVBelt && vb.Id === this.fixedVBelt.Id;
		}

		public driveClick(vb: VBeltResult) {
			if (this.locked)
				return;
			if (this.buildManager.selectedVBelt && vb && this.buildManager.selectedVBelt.Id === vb.Id)
				vb = null;

			if (vb?.Id)
				InsightService.trackEvent('Sizing:SelectVBelt', vb);
			else
				InsightService.trackEvent('Sizing:DeselectVBelt', this.buildManager.selectedVBelt);

			this.buildManager.selectedVBelt = vb;
			return true;
		}

		public renderItem(p: VBeltResult, prop: string) {
			const forceImperial = p?.DriveType?.endsWith('VX') && prop !== 'ActualCenter' && prop !== 'BeltLoad';
			const val = (p as any)[prop];
			const uv = this.values.createValue('VBelt.' + prop, val, forceImperial || this.useImperial);
			return uv && (uv.toString() || '').replace(' ', ' ') || '';
		}

		// Create a parambag for a belt instance
		public getParamBag(vb: VBeltResult) {
			const existing = this.bags[vb.Id];
			if (existing)
				return existing;

			const curSel = this.fixedVBelt;
			let dps: VBeltDuty[];
			if (curSel?.Id === vb.Id)
				dps = this.values.variants.map(x => x.Data?.VBelt || {} as VBeltDuty);
			else
				dps = vb.DutyPoints || [];

			// Inject warnings (hack to reuse the bearing live parameter defs of BearingAssembly...)
			const brgLifeWarn = 'Bearing life time is below 40000 hours';
			const status: ValidationResult[] = this.buildManager.vBeltMessages.slice() || [];
			let worstLife = Math.min(vb.BearingWetLife, ...dps.map(x => x.BearingWetLife));
			if (worstLife > 0 && worstLife < 40000)
				status.push({ Source: '', ParamName: 'BearingAssembly.BearingWetLife', Severity: MessageSeverity.Warning, Message: brgLifeWarn });
			worstLife = Math.min(vb.BearingDriveLife, ...dps.map(x => x.BearingDriveLife));
			if (worstLife > 0 && worstLife < 40000)
				status.push({ Source: '', ParamName: 'BearingAssembly.BearingDriveLife', Severity: MessageSeverity.Warning, Message: brgLifeWarn });
			if (vb.Messages?.length)
				status.push(...vb.Messages);

			const gen = (x: VBeltDuty) => ({
				values: { VBelt: { ...x }, BearingAssembly: { BearingWetLife: x.BearingWetLife, BearingDriveLife: x.BearingDriveLife } } as PumpParams,
				messages: status
			});
			return this.bags[vb.Id] = this.values.createVariants(dps.map(x => gen(Object.assign({}, vb, x))));
		}
	}
</script>
