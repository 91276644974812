<template>
	<svg v-if="show" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" :key="svgKey"
		:viewBox="flip ? `${minY-2} ${minX-2} ${totH+4} ${totW+4}` : `${minX-2} ${minY-2} ${totW+4} ${totH+4}`">
		<g :transform="flip ? `rotate(90, ${totH / 2}, ${totH / 2})` : undefined">
			<defs>
				<pattern :id="defId('diagonalHatch')" patternUnits="userSpaceOnUse" width="10" height="10" patternTransform="rotate(45 5 5)">
					<rect x="0" y="0" width="10" height="10" stroke-width="0" fill="#eee" />
					<path d="M 0,5 l 10,0" stroke="#000000" stroke-width="2" fill="none"/>
				</pattern>
				<pattern :id="defId('threading')" patternUnits="userSpaceOnUse" width="8" height="8" patternTransform="rotate(80 4 4)">
					<rect x="0" y="0" width="8" height="8" stroke-width="0" fill="#eee" />
					<path d="M 0,0 l 8,0" stroke="#000000" stroke-width="4"/>
				</pattern>
				<path :id="defId('uparrow')" d="M 0,1 9,19.5 -9,19.5 z" fill="blue" />
			</defs>

			<!-- Max sheave/belt area -->
			<g v-if="ba && vBelt && motor && motor.Drive">
				<rect :x="boundaryX" :y="boundaryY" :width="motor.Drive.MaxSheaveWidth" :height="boundaryH"
					stroke="red" stroke-width="2" fill="none" stroke-dasharray="5,5" />
			</g>

			<!-- Bearings -->
			<g v-if="ba" stroke="black" stroke-width="2">
				<g :id="defId('brgDrvPart')">
					<rect :x="brgDrvX" :y="axleC - brgDrvInnerR - brgDrvPartH" :width="brgDrvPartW" :height="brgDrvPartH" rx="3" ry="3" class="bearing" />

					<!-- Cross in bearing rect -->
					<line :x1="brgDrvX" :y1="axleC - brgDrvInnerR - brgDrvPartH" :x2="brgDrvX + brgDrvPartW" :y2="axleC - brgDrvInnerR" />
					<line :x1="brgDrvX + brgDrvPartW" :y1="axleC - brgDrvInnerR - brgDrvPartH" :x2="brgDrvX" :y2="axleC - brgDrvInnerR" />
				</g>
				<use :href="defHref('brgDrvPart')" x="0" :y="2 * brgDrvInnerR + brgDrvPartH" />
				<template v-if="dblDrive">
					<use :href="defHref('brgDrvPart')" :x="brgDrvPartW" y="0" />
					<use :href="defHref('brgDrvPart')" :x="brgDrvPartW" :y="2 * brgDrvInnerR + brgDrvPartH" />
				</template>

				<g :id="defId('brgWetPart')" stroke="black" stroke-width="2">
					<rect :x="brgWetX" :y="axleC - brgWetInnerR - brgWetPartH" :width="brgWetPartW" :height="brgWetPartH" rx="3" ry="3" class="bearing" />

					<!-- Cross in bearing rect -->
					<line :x1="brgWetX" :y1="axleC - brgWetInnerR - brgWetPartH" :x2="brgWetX + brgWetPartW" :y2="axleC - brgWetInnerR" />
					<line :x1="brgWetX + brgWetPartW" :y1="axleC - brgWetInnerR - brgWetPartH" :x2="brgWetX" :y2="axleC - brgWetInnerR" />
				</g>
				<use :href="defHref('brgWetPart')" x="0" :y="2 * brgWetInnerR + brgWetPartH" />
				<template v-if="dblWet">
					<use :href="defHref('brgWetPart')" :x="brgWetPartW" y="0" />
					<use :href="defHref('brgWetPart')" :x="brgWetPartW" :y="2 * brgWetInnerR + brgWetPartH" />
				</template>
			</g>

			<!-- Axle and impeller parts -->
			<g v-for="part in axleDrawOrder" :key="part.id">
				<path v-if="part.path" :d="coordsToPath(part.path, part.x, axleC)" class="axle" :fill="part.fill || '#eee'" />
				<rect v-else :x="part.x" :y="axleC + part.y" :width="part.w" :height="part.h"
					class="axle" :fill="part.fill || '#eee'" />
			</g>

			<!-- "Marking" on drive end -->
			<rect v-if="ba" :x="axleX(0)" :y="axleC - axleParts[0].h/6" :width="axleParts[0].w * 0.9" :height="axleParts[0].h/3"
				stroke="black" stroke-width="2" fill="#ddd" :rx="ba.DRIVE_END_DIAMETER / 4" :ry="ba.DRIVE_END_DIAMETER / 4" />

			<!-- Pump sheave -->
			<g v-if="ba && vBelt">
				<path :d="coordsToPath(pumpSheave.path, pumpSheave.x, axleC)" class="sheave" />
			</g>

			<template v-if="ba && vBelt && motor">
				<g>
					<g :transform="`translate(${motorX + (reverseMount ? 0 : 0.75) * motorW}, ${motorY}) rotate(${reverseMount ? 180 : 0} ${motorW * 0.25 / 2} ${motorH / 2})`">
						<g :transform="`scale(${motorW}, ${motorH})`">
							<g stroke="black" stroke-width="1" fill="#eee" vector-effect="non-scaling-stroke">
								<!-- Large axle end stair (partially covered by main blok) -->
								<rect x="-0.72" y="0.05" width="0.1" height="0.9" rx="0.02" ry="0.02" vector-effect="non-scaling-stroke" /> 

								<!-- Main blok -->
								<rect x="-0.65" y="0" width="0.65" height="1" rx="0.03" ry="0.03" vector-effect="non-scaling-stroke" /> 

								<!-- Fan cover -->
								<polygon points="0,0.02 0.25,0.15 0.25,0.85 0,0.98" vector-effect="non-scaling-stroke" /> 

								<!-- Cooling tracks -->
								<rect v-for="i in 12" :key="i" vector-effect="non-scaling-stroke" fill="#ddd"
									x="-0.65" :y="(0.1 + 0.06 * i)" width="0.65" height="0.02" rx="0.01" ry="0.01" />

								<!-- Small axle end stairs -->
								<rect x="-0.75" y="0.3" width="0.03" height="0.4" rx="0.01" ry="0.01" vector-effect="non-scaling-stroke" /> 
							</g>
						</g>
					</g>

					<!-- Motor shaft -->
					<rect :x="motorShaftX" :y="motorY + motorH / 2 - motor.ShaftDiameter / 2" :width="motorShaftL" :height="motor.ShaftDiameter"
						class="axle" :fill="'#eee'" /> 
					<rect :x="motorShaftX + (reverseMount ? 0.1 * motorShaftL : 0)" :y="motorY + motorH / 2 - motor.ShaftDiameter / 6"
						:width="motorShaftL * 0.9" :height="motor.ShaftDiameter / 3" stroke="black" stroke-width="2" fill="#ddd"
						:rx="motor.ShaftDiameter / 4" :ry="motor.ShaftDiameter / 4" />
				</g>

				<!-- Motor sheave -->
				<path :d="coordsToPath(motorSheave.path, motorSheave.x, axleC + beltFactor * vBelt.ActualCenter)" class="sheave" />

				<!-- Belts -->
				<line v-for="(b, i) of belts" :key="i" :x1="b.x1" :y1="b.y1" :x2="b.x2" :y2="b.y2" stroke="rgb(0,0,0,0.6)" :stroke-width="b.w" />
			</template>

			<template v-if="measurements && isInternal">
				<g v-for="part in axleParts" :key="'m_' + part.id">
					<!-- Measurement scale -->
					<template v-if="!part.fake && part.w > 10">
						<line :x1="part.x" :y1="textY" :x2="part.x + part.w" :y2="textY" class="measure-line" />
						<line :x1="part.x" :y1="textY-textH/3" :x2="part.x" :y2="textY+textH/3" class="measure-line" />
						<line :x1="part.x + part.w" :y1="textY - textH/3" :x2="part.x + part.w" :y2="textY+textH/3" class="measure-line" />

						<text :x="part.x + part.w/2" :y="textY + textH * 1.1" class="label" :font-size="textH * 0.8">
							{{Math.round(part.w)}}
						</text>

						<g v-if="hints" :transform="`translate(${part.x + part.w / 2}, ${axleC - part.h / 2 + textH * 0.3})`" class="label">
							<text fill="#888" stroke="#eee" stroke-width="3" :font-size="textH * 0.4">{{part.id}}</text>
							<text fill="#888" :font-size="textH * 0.4">{{part.id}}</text>
						</g>
					</template>
				</g>

				<!-- "Balance points" -->
				<use :href="defHref('uparrow')" v-if="!!driveAX" :x="driveAX" :y="axleC + brgDrvInnerR + brgDrvPartH" />
				<use :href="defHref('uparrow')" v-if="!!wetAX" :x="wetAX" :y="axleC + brgWetInnerR + brgWetPartH" />
			</template>

			<!-- Center line -->
			<line :x1="0" :y1="axleC" :x2="minX + totW" :y2="axleC" stroke-dasharray="20,20,5,5" stroke="black" stroke-width="2" />
		</g>
	</svg>
</template>

<style scoped>
	.axle {
		stroke: black;
		stroke-width: 3;
	}
	.bearing {
		stroke: black;
		stroke-width: 3;
		fill: white;
	}
	.measure-line {
		stroke: gray;
		stroke-width: 2;
	}
	.label {
		font-family: Roboto-Medium, serif;
		fill: black;
		text-anchor: middle;
	}
	.sheave {
		fill: rgb(238,238,238,0.6);
		stroke: black;
		stroke-linecap: butt;
		stroke-linejoin: bevel;
		stroke-width: 2;
	}
</style>

<script lang='ts'>
	import Vue from 'vue';
	import { Component, Prop, Watch } from 'vue-property-decorator';
	import { BearingAssembly } from 'types/dto/BearingAssembly';
	import pumpsService from '@/services/pumps.service';
	import store, { AuthGetters } from '@/store';
	import { VBeltResult } from 'types/dto/VBelt';
	import { BearingArrangement, DriveArrangement, MotorResult } from 'types/dto/PumpBuild';

	interface AxlePart {
		id: string;
		x?: number;
		y?: number;
		w: number;
		h: number;
		fake?: boolean;
		dx?: number;
		fill?: string;
		path?: number[];
		defer?: boolean;
	}

	@Component
	export default class BADrawing extends Vue {
		@Prop() public id: string;
		@Prop() public pumpData: Promise<any> | any;
		@Prop() public vBelt: VBeltResult;
		@Prop() public useBA: BearingAssembly;
		@Prop() public measurements: boolean;
		@Prop() public hints: boolean;
		@Prop() public flipVerticals: boolean;
		@Prop() public driveArr: DriveArrangement;
		@Prop() public motor: MotorResult;

		public ba: BearingAssembly = null;
		private pump: any = null;
		public readonly svgKey = `drw_${Math.random()}`;

		public get show() {
			return Boolean((this.ba || this.pump) && this.totAxleW);
		}

		public get isInternal() {
			return store.get(AuthGetters.internalUser);
		}

		public get isVert() {
			return [2, 3, 4].includes(this.pump?.PumpType);
		}

		public get flip() {
			return (this.flipVerticals || (this.flipVerticals as any) === '') && this.isVert;
		}

		public defId(name: string) {
			return `${this.svgKey}_${name}`;
		}

		public defUrl(name: string) {
			return `url(#${this.defId(name)})`;
		}

		public defHref(name: string) {
			return `#${this.defId(name)}`;
		}

		public get totW() { return this.totAxleW - this.minX; }

		public get totH() {
			const totAxleH = this.totAxleH;
			const driveH = (this.vBelt ? this.vBelt.ActualCenter + totAxleH / 2 + this.motorH / 2 : 0);
			return 2.2 * this.textH + Math.max(totAxleH, driveH, this.boundaryH);
		}

		public get driveEndLength() {
			return this.ba?.DRIVE_END_LENGTH || 0;
		}

		public get axleParts(): AxlePart[] {
			let parts: AxlePart[] = [];
			const ba = this.ba;
			if (ba) {
				parts = [
					{ id: 'DriveEnd', w: this.driveEndLength, h: ba.DRIVE_END_DIAMETER },
					{ id: 'BrgSeat', w: ba.DRIVE_END_BRG_SEAT_LENGTH, h: ba.DRIVE_END_BRG_SEAT_DIAMETER },
					{ id: 'BetweenBearings', w: ba.LENGTH_BETWEEN_BEARING, h: ba.DIA_BETWEEN_BEARINGS },
					{ id: 'L1', w: ba.SHAFT_L1, h: ba.SHAFT_D1 },
					{ id: 'L2', w: ba.SHAFT_L2, h: ba.SHAFT_D2 },
					{ id: 'L3', w: ba.SHAFT_L3, h: ba.SHAFT_D3 },
					{ id: 'L4', w: ba.SHAFT_L4, h: ba.SHAFT_D4 },
					{ id: 'L5', w: ba.SHAFT_L5, h: ba.SHAFT_D5 }
				].filter(p => p.w && p.h);
			}

			const lastPart = parts.length ? parts[parts.length - 1] : null;
			if (this.pump) {
				// Add shaft extension
				const se = this.pump.ShaftExtension;
				if (ba?.NeedsShaftExt && se)
					parts.push({ id: 'ShaftExt', w: se.Length, h: se.Diameter });

				// Add impeller
				const stubRadius = lastPart ? lastPart.h / 2.0 : 0.7 * this.pump.INLET_DIAMETER / 2;
				this.addPump(parts, stubRadius);
			} else if (ba?.NeedsShaftExt && lastPart) {
				// No pump; add a fake shaft extension
				parts.push({ id: 'FakeShaftExt', w: 110, h: lastPart.h * 0.8, fake: true });
			}

			// Add made-up threading for impeller attachment
			if (ba && lastPart && !this.pump)
				parts.push({ id: 'ImpThread', w: lastPart.h * 0.65, h: lastPart.h * 0.65, fake: true, fill: this.defUrl('threading') });

			// Place parts in space
			let x = 0;
			parts.forEach(p => {
				p.y = -p.h / 2.0;
				if (p.x == null) {
					p.x = x;
					x += (p.w ?? 0) + (p.dx ?? 0);
				}
			});
			return parts;
		}

		public get axleDrawOrder() {
			const parts = this.axleParts.slice();
			const sortVal = (p: AxlePart) => p.defer ? 100 : 0;
			parts.sort((a, b) => sortVal(a) - sortVal(b));
			return parts;
		}

		public get axleC() { return this.totAxleH / 2; }

		public axleX(idx: number) {
			return this.axleParts.slice(0, idx).reduce((prev, cur) => prev + (cur.w ?? 0) + (cur.dx ?? 0), 0);
		}

		public get totAxleW() {
			return this.axleX(this.axleParts.length);
		}

		public get totAxleH() {
			const psd = this.vBelt?.PumpSheaveDiameter ?? 0;
			const msp = this.motor?.Drive?.MaxSheavePump ?? 0;
			return Math.max(this.brgMaxH, psd, msp, ...this.axleParts.map(x => x.h ?? 0));
		}

		public get minX() {
			return Math.min(this.boundaryX, this.reverseMount ? this.motorX : 0);
		}

		public get minY() {
			return Math.min(0, this.motorY, this.boundaryY);
		}

		public get textH() {
			if (!this.measurements)
				return 0;
			return Math.round(this.totAxleH / 4.0);
		}

		public get textY() {
			return this.totAxleH + this.textH;
		}

		public get dblDrive() { return this.ba?.BearingArrangement === BearingArrangement.DoubleDrive; }

		public get dblWet() { return this.ba?.BearingArrangement === BearingArrangement.DoubleWet; }

		public get brgMaxH() { return !this.ba ? 0 : 2 * Math.max(this.brgDrvInnerR + this.brgDrvPartH, this.brgWetInnerR + this.brgWetPartH); }

		public get brgDrvX() { return this.axleX(2) - this.ba.BRGDRV_WIDTH; }

		public get brgDrvInnerR() { return this.ba?.DRIVE_END_BRG_SEAT_DIAMETER / 2; }

		public get brgDrvPartW() { return this.dblDrive ? this.ba?.BRGDRV_WIDTH / 2 : this.ba?.BRGDRV_WIDTH; }

		public get brgDrvPartH() { return this.brgDrvPartW; }

		public get brgWetX() { return this.axleX(3); }

		public get brgWetInnerR() { return this.ba?.SHAFT_D1 / 2; }

		public get brgWetPartW() { return this.dblWet ? this.ba?.BRGWET_WIDTH / 2 : this.ba?.BRGWET_WIDTH; }

		public get brgWetPartH() { return this.brgWetPartW; }

		public get driveAX() {
			const ba = this.ba;
			if (!ba)
				return;
			let dist: number;
			switch (ba.BRG_MOUNT) {
				case 1: dist = ba.BRGDRV_A_DIMENSION; break;
				case -1: dist = ba.BRGDRV_WIDTH - ba.BRGDRV_A_DIMENSION; break;
				default: dist = ba.BRGDRV_WIDTH / 2.0; break;
			}
			return this.brgDrvX + ba.BRGDRV_WIDTH - (dist || 0);
		}

		public get wetAX() {
			const ba = this.ba;
			if (!ba)
				return;
			let dist: number;
			switch (ba.BRG_MOUNT) {
				case 1: dist = ba.BRGWET_A_DIMENSION; break;
				case -1: dist = ba.BRGWET_WIDTH - ba.BRGWET_A_DIMENSION; break;
				default: dist = ba.BRGWET_WIDTH / 2.0; break;
			}
			return this.brgWetX + (dist || 0);
		}

		public get reverseMount() {
			if (!this.vBelt || !this.motor)
				return false;
			return [DriveArrangement.VBeltReverseOverhead, DriveArrangement.VBeltDriveHighMount].includes(this.driveArr);
		}

		public get beltFactor() {
			const motorOnTop = [DriveArrangement.VBeltRight, DriveArrangement.VBeltOverhead, DriveArrangement.VBeltReverseOverhead]
				.includes(this.driveArr);
			return motorOnTop ? -1 : 1;
		}

		public get motorX() {
			const brgSeatX = this.axleX(1);
			if (this.reverseMount)
				return brgSeatX - this.motor.Drive.MaxSheaveWidth - this.motorW;
			return brgSeatX;
		}

		public get motorY() {
			if (!this.vBelt)
				return 0;
			return this.axleC + this.beltFactor * (this.vBelt?.ActualCenter ?? 0) - this.motorH / 2;
		}

		public get motorW() {
			return 1.435 * this.motorH;
		}

		public get motorShaftL() {
			return this.motor?.ShaftLength;
		}

		public get motorH() {
			const motor = this.motor;
			if (!motor?.MotorFrame)
				return 0;
			if (motor.MotorStandard === 'IEC') {
				const frameSize = parseFloat(motor.MotorFrame?.replaceAll(/[^0-9]/g, ''));
				if (frameSize > 0)
					return frameSize * 1.8;
			} else if (motor.MotorStandard === 'NEMA') {
				const frame = motor.MotorFrame?.replaceAll(/[^0-9]/g, '');

				// For the “D” dimension of a three-digit frame number, consider only the first two digits
				// and use the divisor 4
				if (frame?.length >= 3) {
					const D = parseFloat(frame.substr(0, 2)) / 4.0 * 25.4;
					return D * 1.8;
				}
			}
			return 200;
		}

		public get motorShaftX() {
			if (this.reverseMount)
				return this.motorX + this.motorW;
			return this.motorX - this.motorShaftL;
		}

		public get boundaryX() {
			const drive = this.motor?.Drive;
			if (!drive)
				return 0;
			return this.axleX(1) - drive.MaxSheaveWidth ?? 0;
		}

		public get boundaryY() {
			const drive = this.motor?.Drive;
			if (!drive)
				return 0;
			const pumpLimit = this.axleC - this.beltFactor * drive.MaxSheavePump / 2;
			const motorLimit = this.motorY + this.motorH / 2 + this.beltFactor * drive.MaxSheaveMotor / 2;
			return Math.min(pumpLimit, motorLimit);
		}

		public get boundaryH() {
			const drive = this.motor?.Drive;
			if (!drive || !this.vBelt)
				return 0;
			return this.vBelt.ActualCenter + drive.MaxSheaveMotor / 2 + drive.MaxSheavePump / 2;
		}

		public coordsToPath(path: number[], x0: number, y0: number) {
			const cooked = path.map((c, i) => c + ((i % 2) === 0 ? x0 : y0));
			return 'M ' + cooked.join(' ') + ' z';
		}

		public async created() {
			if (this.pumpData)
				this.pump = await this.pumpData;
			if (this.useBA) {
				this.ba = this.useBA;
				return;
			}
			if (!this.id)
				return;
			try {
				this.ba = await pumpsService.getBADefinition(this.id);
			} catch (e) {
				console.error('Failed to get bearing assembly definition');
			}
		}

		@Watch('id')
		public async onBAchange(newId: string) {
			if (this.ba?.id && newId && this.ba?.id !== newId)
				this.ba = await pumpsService.getBADefinition(this.id);
		}

		private addPump(parts: AxlePart[], stubR: number) {
			const pump = this.pump;
			const shroudPart = 0.2;
			const closed = pump.IMPELLER_TYPE?.startsWith('C');
			const vaneW = pump.IMPELLER_WIDTH * (1 - (closed ? 2 : 1) * shroudPart);
			const stub = pump.IMPELLER_LENGTH - pump.IMPELLER_WIDTH / 2.0;
			const backW = stub + shroudPart * pump.IMPELLER_WIDTH;
			const radius = pump.IMPELLER_DIAMETER_SHROUD / 2.0;
			const backPath = [
				0, 0,
				0, stubR,
				0.7 * stub, stubR,
				stub, stubR + stub / 2.0,
				stub, radius,
				backW, radius,
				backW, pump.INLET_DIAMETER / 2.0,
				backW + 0.5 * backW, 0,
				backW, -pump.INLET_DIAMETER / 2.0,
				backW, -radius,
				stub, -radius,
				stub, -(stubR + stub / 2.0),
				0.7 * stub, -stubR,
				0, -stubR
			];

			const fill = this.defUrl('diagonalHatch');
			const fake = true;
			const holeWidth = (1 - shroudPart) * pump.IMPELLER_WIDTH;
			parts.push({ id: 'ImpBack', w: backW, h: pump.IMPELLER_DIAMETER_SHROUD, path: backPath, fill, fake, defer: true });
			parts.push({ id: 'Vanes', w: vaneW, h: pump.IMPELLER_DIAMETER_VANE, fake, fill: '#eee', dx: (closed ? 0 : -holeWidth) });

			if (closed)
				parts.push({ id: 'ImpFront', w: pump.IMPELLER_WIDTH * shroudPart, h: pump.IMPELLER_DIAMETER_SHROUD,
					dx: -holeWidth, fill, fake });

			parts.push({ id: 'Inlet', w: holeWidth, h: pump.INLET_DIAMETER, fill: 'white', fake });
		}

		private get sheaveX() {
			if (this.reverseMount && this.ba) {
				const driveEndGap = this.motor.Drive.MaxSheaveWidth - this.driveEndLength;
				const commonLength = this.motorShaftL - driveEndGap;
				const shaftStart = this.motorX + this.motorW;
				const offset = commonLength / 2 - this.vBelt.SheaveWidth / 2;
				return shaftStart + Math.max(0, offset);
			}
			const shaftStart2 = this.motorX;
			return shaftStart2 - this.vBelt.SheaveWidth;
		}

		public get pumpSheave(): AxlePart {
			const vb = this.vBelt;
			if (vb)
				return BADrawing.sheavePath(vb, vb.PumpSheaveDiameter, this.sheaveX);
		}

		public get motorSheave(): AxlePart {
			const vb = this.vBelt;
			if (!vb)
				return;
			return BADrawing.sheavePath(vb, vb.MotorSheaveDiameter, this.sheaveX);
		}

		public get belts() {
			const vb = this.vBelt;
			if (!vb)
				return;
			const edgeW = vb.SheaveWidth / (4.0 * vb.NoOfBelts);
			const sw = (vb.SheaveWidth - 2 * edgeW) / (1.0 * vb.NoOfBelts);
			const sh = sw * 0.75;

			const lines: any[] = [];
			const bw = sw * 0.75;
			const dir = this.beltFactor;
			let x = this.sheaveX + edgeW + sw / 2;
			const pY = this.axleC - dir * (vb.PumpSheaveDiameter / 2) + dir * 0.3 * sh;
			const mY = this.axleC + dir * (vb.ActualCenter + vb.MotorSheaveDiameter / 2) - dir * 0.3 * sh;

			for (let i = 0; i < vb.NoOfBelts; i++) {
				lines.push({ x1: x, y1: pY, x2: x, y2: mY, w: bw });
				x += sw;
			}
			return lines;
		}

		private static sheavePath(vb: VBeltResult, diam: number, x0: number) {
			const radius = diam / 2.0;
			const edgeW = vb.SheaveWidth / (4.0 * vb.NoOfBelts);
			const sw = (vb.SheaveWidth - 2 * edgeW) / (1.0 * vb.NoOfBelts);
			const sh = sw * 0.75;
			const path: number[] = [];

			// Square top left edge
			path.push(0, -radius);
			path.push(edgeW, -radius);

			const x = edgeW;

			for (let i = 0; i < vb.NoOfBelts; i++) {
				// Top track
				path.push(x + sw * i, -radius);
				path.push(x + sw * i + sw / 2, sh - radius);

				// Vertical track line down and up again
				path.push(x + sw * i + sw / 2, -sh + radius);
				path.push(x + sw * i + sw / 2, sh - radius);
			}

			// Square top right edge
			path.push(vb.SheaveWidth - edgeW, -radius);
			path.push(vb.SheaveWidth, -radius);

			// Square bottom right edge
			path.push(vb.SheaveWidth, radius);
			path.push(vb.SheaveWidth - edgeW, radius);

			for (let i = vb.NoOfBelts - 1; i >= 0; --i) {
				// Bottom track
				path.push(x + sw * i + sw / 2, -sh + radius);
				path.push(x + sw * i, radius);
			}

			// Square bottom left edge
			path.push(0, radius);
			return { id: 'PumpSheave', w: vb.SheaveWidth, h: diam, x: x0, path, fake: true, defer: true, dx: -vb.SheaveWidth };
		}
	}
</script>
