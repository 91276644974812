<template>
	<v-card outlined>
		<v-card-text class="no-padding">
			<v-list>
				<v-list-group v-model="expandModel" class="notabkey" :append-icon="icon">
					<template v-slot:activator>
						<v-list-item-title class="center-flex ml-2">						
								<h3>{{ translatedTitle }}</h3>			
							<GroupValidation :values="values" :groups="groups" />
						</v-list-item-title>
					</template>
					<slot />
				</v-list-group>
			</v-list>
		</v-card-text>
	</v-card>
</template>


<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop, Watch } from 'vue-property-decorator';
	import GroupValidation from '@/components/GroupValidation.vue';
	import { tryToTranslate } from '@/i18n';
	import { GetExpandCollapseIcon } from '@/common/Tools';

	@Component({
		components: {
			GroupValidation,
		},
	})
	export default class SizingSection extends Vue {
		@Prop() public title: string;
		@Prop() public values: any;
		@Prop() public groups: string[];
		@Prop() public expanded: boolean;
		@Prop() public disabled: boolean;
		@Prop() public wide: boolean;

		public expandModel: boolean = true;

		public get translatedTitle() {
			return tryToTranslate(`paramGroups.${this.title}`) || this.title;
		}

		public created() {
			this.onChange(this.expanded);
		}

		public mounted() {
			if (typeof document.querySelectorAll === 'function')
				document.querySelectorAll('.notabkey div').forEach(x => x.setAttribute('tabIndex', '-1'));
		}

		public get icon() {
			return GetExpandCollapseIcon(this.expandModel);
		}

		@Watch('expanded')
		public onChange(val: any) {
			const wasExpanded = this.expandModel;
			if (val != null)
				this.expandModel = val === true || val === '';
		}

		@Watch('expandModel')
		public onExpandedChange(value: boolean) {
			this.$emit('change', value);
		}
	}
</script>
