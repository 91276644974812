import { PumpProject } from 'types/dto/CalcServiceDomain';
import SizingService from '@/services/sizing.service';
import Vue from 'vue';
import { LocalStorageService } from '@/services/localstorage.service';
import store, { NetworkGetters, SnackActions } from '@/store';
import { ParamBag } from '@/common/ParamBag';
import { ActionContext } from 'vuex';
import InsightService from '@/services/insight.service';
import { merge, uniqueValues } from '@/common/Tools';
import ProjectFilter from '@/common/ProjectFilter';

interface IStoredSProject {
	project: PumpProject;
	loading: boolean;
	isDirty: boolean;
}

interface IProjectState {
	projects: { [id: string]: IStoredSProject };
	loading: boolean;
}

const projectState: IProjectState = {
	projects: {},
	loading: false
};

type StoreActionContext = ActionContext<IProjectState, IProjectState>;

interface NamedProjectValue {
	value: any;
	projectId: string;
	valueName: string;
}

const getters = {
	loading: (targetState: IProjectState) => targetState.loading,
	hasProjects: (targetState: IProjectState) => Object.keys(targetState.projects).length > 0,
	allProjects: (targetState: IProjectState) => Object.values(targetState.projects).map(x => x.project) || [],
	singleProject: (targetState: IProjectState) => (id: string) => targetState.projects[id] && targetState.projects[id].project,
	getValueRef: (state: IProjectState) => ({ projectId, valueName }: { projectId: string, valueName: string }) => {
		const p = state.projects[projectId];
		if (p) {
			if (!p.project || !p.project.id) {
				console.error(`getValueRef: project was not fully loaded when accessing ${projectId}.${valueName}`);
				return null;
			}
		} else {
			console.error(`getValueRef: project ${projectId} does not exist`);
			return null;
		}
		return ParamBag.getParamField(p.project, valueName, false);
	},
};

const actions = {
	async getProject({ commit, state }: StoreActionContext, id: string) {
		if (!store.get(NetworkGetters.connected)) {
			const p = await LocalStorageService.getItem<IStoredSProject>('p:' + id);
			if (!p) {
				store.dispatch(SnackActions.set, `Project is not downloaded for offline use`);
				return;
			}
			commit('add', p.project);
			return state.projects[p.project.id].project;
		} else {
			try {
				commit('startLoading');
				const data = await SizingService.getProject(id);
				if (data) {
					commit('add', data);
					return state.projects[data.id].project;
				}
			} catch (e) {
				console.log('Unable to get project', e);
				Promise.reject(e);
			} finally {
				commit('finishLoading');
			}
		}
	},
	async getProjects({ commit, state }: StoreActionContext, filter: ProjectFilter) {
		if (!store.get(NetworkGetters.connected)) {
			const projects = await LocalStorageService.startsWith('p:');
			if (!projects || !Object.keys(projects).length) {
				store.dispatch(SnackActions.set, `Projects are not downloaded for offline use`);
				return null;
			}
			const data = Object.values(projects).map((p: IStoredSProject) => p.project);
			data.forEach((p: PumpProject) => { commit('add', p); return state.projects[p.id]?.project; });
			return data;
		} else {
			try {
				commit('startLoading');
				const oldIds = Object.keys(state.projects);
				const data = await SizingService.getProjects(filter) || [];
				const newIds: string[] = [];
				data.forEach((p: PumpProject) => { commit('add', p); newIds.push(p.id); });

				// Purge projects not returned from backend
				oldIds.forEach(oldId => {
					if (oldId && !newIds.includes(oldId))
						commit('remove', oldId);
				});
				return data.map(x => state.projects[x.id]?.project);
			} catch (e) {
				console.log('Unable to get projects', e);
				Promise.reject(e);
			} finally {
				commit('finishLoading');
			}
		}
	},
	async create({ commit }: StoreActionContext, { name, ref }: any) {
		try {
			InsightService.trackEvent('Project:Create');
			commit('startLoading');
			const data = await SizingService.createProject(name, ref);
			commit('add', data);
			return data;
		} catch (e) {
			console.log('Unable to create projects', e);
			Promise.reject(e);
		} finally {
			commit('finishLoading');
		}
	},
	async update({ commit }: StoreActionContext, payload: any) {
		const p: PumpProject = payload.project || payload;
		const target: string = payload.target;
		const newValue: string = payload.newValue;

		if (!store.get(NetworkGetters.connected)) {
			commit('add', p);
			return p;
		}

		try {
			commit('startLoading');
			const data = await SizingService.updateProject(p, target, newValue);
			commit('clean', p.id);
			if (data)
				commit('add', data);
			return data;
		} catch (e) {
			console.log('Unable to update project', e);
			Promise.reject(e);
		} finally {
			commit('finishLoading');
		}
	},
	async removeProject({ commit }: StoreActionContext, id: string) {
		try {
			InsightService.trackEvent('Project:Delete');
			commit('startLoading');
			await SizingService.deleteProject(id);
			commit('remove', id);
		} catch (e) {
			console.log('Unable to remove projects', e);
			Promise.reject(e);
		} finally {
			commit('finishLoading');
		}
	},
	async setValue({ dispatch, commit, state }: StoreActionContext, { value, projectId, valueName }: any) {
		commit('setValue', { value, projectId, valueName });
		const s = state.projects[projectId];
		if (!s.isDirty)
			return s.project;
		return await dispatch('update', { project: s.project, target: valueName, newValue: value });
	},
	async setEditors({ dispatch, commit, state }: StoreActionContext, { projectId, editors }: { projectId: string, editors: string[] }) {
		const s = state.projects[projectId];
		if (!s)
			return;
		editors = uniqueValues(editors?.filter(x => x !== s.project.CreatedBy) || []);
		if (JSON.stringify(editors) === JSON.stringify(s.project.Editors || []))
			return;
		InsightService.trackEvent('Project:SetEditors', { editors });
		commit('setMeta', { projectId, valueName: 'Editors', value: editors } as NamedProjectValue);
		return await dispatch('update', { project: s.project, target: 'Editors' });
	},
	async becomeEditor({ commit, state }: StoreActionContext, { currentOwnerId, newEditorId }: { currentOwnerId: string, newEditorId: string }) {
		InsightService.trackEvent('Project:BecomeEditor', { currentOwnerId, newEditorId });
		const work = SizingService.becomeEditor(currentOwnerId, newEditorId);
		Object.values(state.projects).forEach(p => {
			if (p?.project?.CreatedBy === currentOwnerId)
				commit('remove', p.project.id);
		});
		return await work;
	}
};

const mutations = {
	startLoading: (targetState: IProjectState) => targetState.loading = true,
	finishLoading: (targetState: IProjectState) => targetState.loading = false,
	add(targetState: IProjectState, proj: PumpProject) {
		if (!proj?.id)
			return;
		let p: IStoredSProject;
		const existing = targetState.projects[proj.id];
		if (existing) {
			existing.loading = false;
			existing.isDirty = false;
			merge(proj, existing.project);
			p = existing;
		} else {
			p = { project: proj, loading: false, isDirty: false };
			Vue.set(targetState.projects, proj.id, p);
		}
		LocalStorageService.setItem('p:' + proj.id, p);
	},
	remove(targetState: IProjectState, id: string) {
		if (!id)
			return;
		Vue.delete(targetState.projects, id);
		LocalStorageService.removeItem('p:' + id);
	},
	materializeValue(targetState: IProjectState, { projectId, valueName }: { projectId: string, valueName: string }) {
		const s = targetState.projects[projectId];
		if (s) {
			const pf = ParamBag.getParamField(s.project, valueName, true);
			if (!pf)
				console.error(`materializeValue: failed to materialize ${projectId}.${valueName}`);
		} else
			console.error(`materializeValue: project ${projectId} does not exist`);
	},
	setValue(targetState: IProjectState, { value, projectId, valueName }: NamedProjectValue) {
		const s = targetState.projects[projectId];
		const pf = ParamBag.getParamField(s.project, valueName, true);
		if (pf?.container) {
			const oldVal = pf.container[pf.field];
			// tslint:disable-next-line: triple-equals
			if (value != oldVal) {
				Vue.set(pf.container, pf.field, value);
				s.isDirty = true;
			}
		}
	},
	setMeta(targetState: IProjectState, { value, projectId, valueName }: NamedProjectValue) {
		const s = targetState.projects[projectId];
		if (!s)
			return;
		const oldVal = (s.project as any)[valueName];
		// tslint:disable-next-line: triple-equals
		if (value != oldVal) {
			Vue.set(s.project, valueName, value);
			s.isDirty = true;
		}
	},
	clean(targetState: IProjectState, id: string) {
		const s = targetState.projects[id];
		if (s)
			s.isDirty = false;
	}
};

export const enum ProjectMutations {
	materializeValue = 'project/materializeValue'
}

export const enum ProjectActions {
	getProjects = 'project/getProjects',
	getProject = 'project/getProject',
	removeProject = 'project/removeProject',
	create = 'project/create',
	update = 'project/update',
	setValue = 'project/setValue',
	setEditors = 'project/setEditors',
	becomeEditor = 'project/becomeEditor'
}

export const enum ProjectGetters {
	projects = 'project/allProjects',
	project = 'project/singleProject',
	hasProjects = 'project/hasProjects',
	loading = 'project/loading',
	isDirty = 'project/isDirty',
	getValueRef = 'project/getValueRef'
}

export const project = {
	namespaced: true,
	state: projectState as any,
	getters,
	actions: actions as any,
	mutations
};
