<template>
	<!-- Set a min width approximating the final curve to avoid line break after loading is done -->
	<div style="min-width: 680px">
		<div class="ma-4" v-if="!curveData && !failure">
			<v-progress-circular indeterminate />&nbsp;
			<i>Loading curve...</i>
		</div>
		<div :v-if="perfChartId" class="perfChart ma-0">
			<div class="alert alert-danger hidden" role="alert" id="report-error"></div>
			<div :id="perfChartId"></div>
			<div v-if="curveData || failure" class="chartConfig pb-3">
				<v-switch v-if="showSlurry" v-model="includeSlurry" dense label="Slurry curve" hide-details />
				<v-switch v-if="showNPSHR || pump && pump.NPSHR > 0" v-model="includeNPSH" dense label="NPSH" hide-details />
				<v-switch v-if="showSystemCurve" v-model="includeSystemCurve" dense label="System curve" hide-details />
				<v-switch v-if="showOverlays" v-model="includeOverlays" dense label="Service class limits" hide-details />
				<v-switch v-model="includeDutyLimits" dense label="Duty limits" hide-details />
				<v-switch v-if="showDutySpeed" v-model="includeDutySpeed" dense label="Include duty speed" hide-details />
				<v-switch v-if="!noZoom" v-model="zoom" dense label="Zoom" hide-details />
				<ul v-if="errors && errors.length" class="mb-4">
					<li class="error--text" v-for="error of errors" :key="error" v-text="error"></li>
				</ul>
			</div>
		</div>
	</div>
</template>

<style>
	/* Remember to sync this with site.css in report service! */
	.perfChart {
		/* Make sure chart legend and switch are not cut by left edge of container */
		margin: 10px;
	}

	.perfChart .line {
		fill: none;
		stroke-width: 3;
	}

	.perfChart .path {
		fill: none;
	}

	.perfChart .overlay {
		fill: none;
		pointer-events: all;
	}

	/* Style the dots by assigning a fill and stroke */
	.perfChart .dot {
		stroke: none;
	}

	.perfChart .focus circle {
		fill: none;
	}

	.lineText, .dotText {
		fill: black;
		font-weight: 400;
	}

	.perfChart .npsh-lines .line {
		stroke-width: 1;
		stroke: red;
		stroke-dasharray: 5 5;
	}

	.perfChart .npsh-lines text {
		fill: red;
	}

	.perfChart .dutypoint-lines .line {
		stroke-width: 1;
		stroke: rgb(128, 177, 211);
	}

	.perfChart .dutypoint-lines.current .line {
		stroke: blue;
	}

	.perfChart .dutypoint-lines.frothflow .line {
		stroke-dasharray: 3 2;
	}

	.perfChart .head-lines .head.line {
		stroke-width: 3;
		stroke: rgb(251, 128, 114);
		fill: none;
	}

	.perfChart .head-lines .additional.line {
		stroke: rgb(128, 177, 211);
		stroke-width: 1;
	}

	.perfChart .head-lines .additional.marked.line {
		stroke: blue;
	}

	.chartConfig div {
		margin-right: 6px;
	}

	.chartConfig ul {
		margin-top: 20px;
	}

	.chartConfig div.v-input {
		margin-top: 4px;
		margin-right: 10px;
		display: inline-block;
	}
</style>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop, Watch } from 'vue-property-decorator';
	import { ChartInputs, OverlayShape, OverlayType } from 'types/dto/Reports';
	import { PumpDocument, TDHMode, SystemCurveInputs, MessageSeverity } from 'types/dto/CalcServiceDomain';
	import ReportingService from '@/services/reporting.service';
	import SizingService from '@/services/sizing.service';
	import store, { AuthActions, AuthGetters, SizingGetters } from '@/store';
	import Debounce from '@/common/Debounce';
	import { DutyPoints } from '@/common/DutyPoints';
	import { minmax } from '@/common/Tools';
	import { ParamBag } from '@/common/ParamBag';
	import { PumpResult } from 'types/dto/PumpSearch';

	@Component
	export default class PumpCurve extends Vue {
		@Prop() public pumpId: string;
		@Prop() public sizingId: string;
		@Prop() public variants: PumpDocument[];
		@Prop() public width: number;
		@Prop() public noZoom: boolean;
		@Prop() public settingsKey: string;
		@Prop() public defaults: { IncludeNPSH?: boolean };
		@Prop() public forceImperial?: boolean;
		@Prop() public showNPSHR?: boolean;
		@Prop() public forceTrim?: number;
		
		public curveData: boolean = false;
		public includeNPSH: boolean = false;
		public includeSlurry: boolean = null;
		public includeOverlays: boolean = false;
		public includeSystemCurve: boolean = false;
		public includeDutySpeed: boolean = false;
		public includeDutyLimits: boolean = false;
		public zoom: boolean = false;
		public failure: string = null;

		private renderChart: any;
		private maxFlow: number = 0;
		private maxHead: number = 0;
		private systemCurve: number[] = null;
		private stages: PumpDocument[] = null;
		private renderDelay: Debounce;
		private pumpsChangedDelay: Debounce;
		private readonly suffix = Math.random();
		private readyForUser = false;
		private readonly loadCurveDebounce = new Debounce('Load curve', 10, () => this.loadCurve());

		private get currentDutyPoint() {
			const dps = this.variants;
			if (dps?.length === 1)
				return dps[0];
			return dps?.find(x => x.id === this.sizingId);
		}

		public get pump() {
			const duty = this.currentDutyPoint?.Data?.Pump;
			if (duty)
				return duty;
			if (this.pumpId)
				return { Id: this.pumpId, HR: 1, QR: 1, ER: 1 } as any as PumpResult;
			return null;
		}

		public get perfChartId() {
			const pid = (this.pump?.Id);
			if (pid)
				return `performance-chart-${pid}-${this.suffix}`;
		}

		public get settings() {
			const settings = {
				UseSlurry: this.includeSlurry,
				IncludeNPSH: this.includeNPSH,
				IncludeOverlays: this.includeOverlays,
				IncludeSystemCurve: this.includeSystemCurve,
				IncludeDutySpeed: this.includeDutySpeed,
				IncludeDutyLimits: this.includeDutyLimits
			};
			this.$emit('settings', settings);
			return settings;
		}

		public set settings(s: any) {
			if (s) {
				this.includeSlurry = s.UseSlurry;
				this.includeNPSH = s.IncludeNPSH;
				this.includeOverlays = s.IncludeOverlays;
				this.includeSystemCurve = s.IncludeSystemCurve;
				this.includeDutySpeed = s.IncludeDutySpeed;
				this.includeDutyLimits = s.IncludeDutyLimits;
			}
		}

		@Watch('settings')
		public onSettings(value: any) {
			// This watcher is needed to trigger the $emit in the settings getter to always deliver settings to the parent
			if (this.readyForUser && this.settingsKey)
				store.dispatch(AuthActions.updateSetting, { key: this.settingsKey, value });
		}

		public created() {
			if (this.settingsKey) {
				// Read "last user settings"
				const defaults = store.get(AuthGetters.setting, this.settingsKey);
				if (defaults)
					this.settings = defaults;
			} else if (this.defaults)
				this.includeNPSH = this.defaults.IncludeNPSH ?? false;

			// Override with "automatic" settings
			this.includeSlurry = this.showSlurry;

			if (this.pump?.Suitable === false || this.pump?.Messages?.some(x => x.Severity >= MessageSeverity.Error))
				this.includeDutyLimits = true;

			this.renderDelay = new Debounce(`Chart render trigger (${this.perfChartId})`, 250, () => {
				if (this.renderChart)
					this.renderChart();
				return Promise.resolve();
			});

			this.pumpsChangedDelay = new Debounce(`Chart pumpchange trigger (${this.perfChartId})`, 100, () => {
				// Clear and reevaluate staging if pumps change
				if (this.stages)
					this.stages = null;
				this.renderDelay.trigger();
				return Promise.resolve();
			});

			this.loadCurveDebounce.trigger();
		}

		get useImperial() {
			return this.forceImperial ?? ParamBag.useImperial(this.sizingId);
		}

		get showSlurry() {
			if (!this.pump)
				return false;

			// Show slurry switch if any derating factor rounds to <= 99% or there is a solids concentration (affects system curve)
			if (this.pump.HR < 0.995 || this.pump.ER < 0.995 || this.pump.QR < 0.995)
				return true;
			if (this.variants?.some(x => x.Data?.Slurry?.ConcByVolume > 0))
				return true;
			return this.variants?.map(x => x.Data?.Pump).filter(x => x?.Id)
				.some(x => x.HR < 0.995 || x.ER < 0.995 || x.QR < 0.995) || false;
		}

		get isStaged() {
			return !!(this.variants?.some(x => x.Staged));
		}

		get showOverlays() {
			return !!(this.pump && this.variants?.some(x => x.Data.ServiceClass?.MaxHead != null));
		}

		get showDutySpeed() {
			return this.variants?.some(x => x.Data?.Pump?.DutySpeed > 0);
		}

		get showSystemCurve() {
			return !!(!this.isStaged && this.pump && this.variants?.length && this.variants[0].Data.Heads?.TDHMode === TDHMode.Calculate);
		}

		@Watch('showSlurry')
		public showSlurryChanged(showSlurry: boolean) {
			this.includeSlurry = !!showSlurry;
		}

		@Watch('includeNPSH')
		public npshChanged() {
			this.loadCurveDebounce.trigger();
		}

		@Watch('includeDutyLimits')
		public dutyLimitsChanged() {
			this.loadCurveDebounce.trigger();
		}

		@Watch('includeSlurry')
		public slurryChanged(newVal: any, oldVal: any) {
			if (oldVal != null)
				this.loadCurveDebounce.trigger();
		}

		@Watch('includeOverlays')
		public overlaysChanged() {
			this.loadCurveDebounce.trigger();
		}

		private get renderTrigger() {
			return '' + this.useImperial + this.zoom + JSON.stringify(this.requestedArea);
		}

		private get variantsTrigger() {
			return JSON.stringify(this.variants?.map(x => x.Data) || {});
		}

		@Watch('renderTrigger')
		public rerender() {
			if (this.renderDelay)
				this.renderDelay.trigger();
		}

		@Watch('variantsTrigger')
		public variantsChanged(newVal: any, oldVal: any) {
			if (oldVal != null && this.pumpsChangedDelay)
				this.pumpsChangedDelay.trigger();
		}

		private get deratingVaries() {
			if (!this.includeSlurry)
				return false;
			const pumpDuties = this.variants.filter(x => x.Data?.Pump).map(x => x.Data.Pump);
			const ranges = [ minmax(pumpDuties, 'HR'), minmax(pumpDuties, 'ER'), minmax(pumpDuties, 'QR') ];
			return ranges.some(x => x.max - x.min >= 0.005);
		}

		private get impellerTrim() {
			return this.pump?.TrimPercentage > 0 && this.pump.TrimPercentage < 100;
		}

		public get errors() {
			if (this.failure)
				return [this.failure];
			if (!this.variants?.length)
				return;

			const errors = [];
			if (this.impellerTrim)
				errors.push(this.$t('curve.impellerTrim'));
			if (this.deratingVaries)
				errors.push(this.$t('curve.deratingVaries'));
			if (this.currentDutyPoint.MDP && this.includeOverlays)
				errors.push(this.$t('curve.dutyCurrentOnly'));
			if (this.currentDutyPoint.MDP && this.showSystemCurve && this.includeSystemCurve)
				errors.push(this.$t('curve.sysCurrentOnly'));
			if (this.isStaged && this.stages?.length === 1)
				errors.push(this.$t('curve.mixedStages'));
			return errors;
		}

		public get relatedDuties() {
			return this.stages || this.variants || [];
		}

		private get requestedArea() {
			// Load stages for requested area lines, since this.variants are only MDP duty points
			if (this.isStaged)
				this.loadStages();
			else
				this.stages = null;

			if (!this.maxFlow || !this.maxHead || !this.variants)
				return [];

			const dutypoints = this.relatedDuties.filter(x => x?.Data?.Slurry?.FlowRate && x.Data.Heads?.PDH);
			const markers = dutypoints.map(dp => ({
				Style: dp.id === this.sizingId ? 'current' : undefined,
				FlowRate: dp.Data.Slurry?.FlowRate,
				PDH: dp.Data.Heads?.PDH
			}));

			const frothFlows = dutypoints.filter(x => x.Data?.Slurry?.FrothFactor > 1);
			if (frothFlows.length) {
				markers.push(...frothFlows.map(dp => ({
					Style: 'frothflow ' + (dp.id === this.sizingId ? 'current' : ''),
					FlowRate: dp.Data.Slurry?.FlowRate * dp.Data.Slurry.FrothFactor,
					PDH: dp.Data.Heads?.PDH
				})));
			}

			if (!dutypoints.length)
				this.failure = 'No valid dutypoints';
			return markers;
		}

		private loadStages() {
			if (this.stages)
				return;
			const stageParent = store.get(SizingGetters.sizing, this.variants[0].id);
			if (!stageParent)
				return;

			const stages = new DutyPoints(stageParent, true);
			stages.load().then(() => {
				const allStagesAndDps = (this.variants || []).concat(stages.asSizings || []);
				const pumps = allStagesAndDps.filter(x => x.Data?.Pump?.Id).map(x => x.Data.Pump);
				const samePumps = !pumps.length || pumps.every(x => x.Id === pumps[0].Id && x.TrimPercentage === pumps[0].TrimPercentage);
				if (!samePumps) {
					// Two different pumps selected in stages => skip showing klyka for other stages
					this.stages = [this.currentDutyPoint];
					return;
				}

				// No conflicting pumps - use stages as duty points and redraw the curve
				this.stages = stages.asSizings;
				if (this.curveData)
					this.renderDelay.trigger();
			});
		}

		private get sysCurveInputs(): SystemCurveInputs {
			if (!this.maxFlow || !this.maxHead || !this.sizingId)
				return;
			if (!this.includeSystemCurve || !this.showSystemCurve)
				return;
			return {
				SizingId: this.sizingId,
				FromFlow: 0.1 / 3600.0, // Start at 0.1 m3/h to avoid zero flow discontinuities
				ToFlow: this.maxFlow / 3600.0,
				MaxHead: this.maxHead,
				WaterOnly: !this.includeSlurry
			};
		}

		private get sysCurveTrigger() {
			return JSON.stringify(this.sysCurveInputs || {});
		}

		@Watch('sysCurveTrigger')
		private onSysCurveChanged() {
			const sci = this.sysCurveInputs;
			if (sci) {
				SizingService.systemCurve(sci).then(data => {
					this.systemCurve = data?.Points;
					this.renderDelay.trigger();
				});
			} else {
				this.systemCurve = null;
				this.renderDelay.trigger();
			}
		}

		private get reloadCurveTrigger() {
			const sc = this.currentDutyPoint?.Data?.ServiceClass;
			const p = this.pump;
			if (!p?.Id)
				return '';
			const [hr, er, qr] = [p.ActualHR ?? p.HR ?? 1, p.ActualER ?? p.ER ?? 1, p.ActualQR ?? p.QR ?? 1];
			let trigger = `${p.Id}_${sc && JSON.stringify(sc) || ''}_${hr}_${er}_${qr}_${p.SpeedLimit ?? 0}`;

			// This triggers both when toggling includeDutySpeed and changes of the duty speed value. How convenient!
			if (this.includeDutySpeed)
				 trigger += `_${p.DutySpeed}`;
			if (this.forceTrim > 0)
				trigger += `_${this.forceTrim}`;
			return trigger;
		}

		@Watch('reloadCurveTrigger')
		private onCurveChanged(newVal: any, oldVal: any) {
			if (oldVal != null)
				this.loadCurveDebounce.trigger();
		}

		private loadCurve() {
			// Settings are not initalized yet, so bail
			if (this.includeSlurry === null)
				return;

			if (!this.pump || !this.pump.Id) {
				this.failure = 'No pump';
				return;
			}
			
			const ci: ChartInputs = {
				PumpResult: this.pump,
				IncludeNPSH: this.includeNPSH,
				IncludeDutyLimits: this.includeDutyLimits,
				UseSlurry: this.includeSlurry,
				SpeedLimit: this.pump.SpeedLimit,
				OverrideTrimPercentage: this.forceTrim
			};

			if (this.includeOverlays)
				ci.DutyClass = this.currentDutyPoint?.Data?.ServiceClass;

			if (this.includeDutySpeed) {
				ci.ExtraSpeeds = this.relatedDuties.map(x => ({
					Speed: x.Data?.Pump?.DutySpeed,
					Flow: x.Data?.Slurry?.FlowRate,
					Marked: x.id === this.currentDutyPoint?.id
				}));
			}

			this.failure = null;
			return ReportingService.initCharts().then(initChart => {
				ReportingService.getCurveData(ci).then(cd => {
					if (!cd) {
						this.failure = 'Failed to load curve data. Please try again.';
						return;
					}

					if (!initChart) {
						this.failure = 'Failed to load graph scripts. Please try again.';
						return;
					}

					this.maxFlow = cd.graphInfo.SCALE_Q_MAX || 0;
					this.maxHead = cd.graphInfo.SCALE_H_MAX || 0;

					this.renderChart = () => {
						if (this.perfChartId) {
							let overlays = cd.overlays;
							if (this.systemCurve) {
								const scOverlay = { Shape: OverlayShape.Curve, Coords: this.systemCurve, Type: OverlayType.Neutral };
								overlays = (cd.overlays || []).concat([scOverlay]);
							}

							const width = (this.zoom ? 2 : 1) * 690;
							initChart(this.perfChartId, cd.belDataset, cd.efficienceDataset, cd.headDataset,
								this.requestedArea, cd.graphInfo, cd.npshgraphDataset, width, 0.75 * width,
								this.useImperial, this.includeSlurry, overlays);
						} else
							this.failure = 'Missing chart element id';
					};

					if (this.curveData)
						this.renderDelay.trigger();
					else
						this.renderChart();

					this.curveData = !!cd;
					this.readyForUser = true;
				}).catch(() => this.failure = 'Failed to fetch curve data');
			}).catch(() => this.failure = 'Curve initialization failed');
		}
	}
</script>