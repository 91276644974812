import ApiService from './api.service';
import { PumpSearch, MDPPumpResult, PumpStatus, PumpType } from 'types/dto/PumpSearch';
import { VBeltInputs, VBeltResults } from 'types/dto/VBelt';
import { PumpDocument, ValidationResult } from 'types/dto/CalcServiceDomain';
import { PumpBuildOptions, BuildParams, BearingAssemblyResult, BuildInputs, PumpDriveOptions,
	DriveParams, ShaftSealResult } from 'types/dto/PumpBuild';
import { BearingAssembly } from 'types/dto/BearingAssembly';
import { buildUrl } from './buildUrl';
import { cleanCopyForSend } from '@/common/ParamTools';
import { PipingModule } from 'types/dto/PipingModule';
import { PipingFilter } from '@/common/PipingManager';

export interface RecalcResult {
	Changed: PumpDocument[];
	Messages: ValidationResult[];
}

export interface DeliveryReadinessValue {
	Value: string;
	AppliesTo: 'ImpellerMaterial' | 'CasingMaterial' | 'MaterialNumber';
	AppliesToId: string;
}

export interface PumpPDBData {
	id: string;
	DeliveryReadiness: DeliveryReadinessValue[];
}

export interface PumpEntry {
	Id: string;
	Type: PumpType;
	Status: PumpStatus;
	Series: string;
	Model: string;
	DisplayName: string;
	ImpellerDescr: string;
	MinTrimPercentage?: number;
}

class PumpService {
	private readonly PUMPS = process.env.VUE_APP_PUMPSERVICE_ENDPOINT;
	private readonly BUILD = process.env.VUE_APP_BUILDSERVICE_ENDPOINT;

	public getPumps(s: PumpSearch[], includeUnsuitable?: boolean, includeWacko?: boolean, statuses?: PumpStatus[]) {
		const url = buildUrl(this.PUMPS, 'pumps');
		if (s?.length && s[0].SpecificPumpId)
			url.searchParams.append('specificPumpId', s[0].SpecificPumpId);
		if (includeUnsuitable)
			url.searchParams.append('includeUnsuitable', 'true');
		if (includeWacko)
			url.searchParams.append('includeWacko', 'true');
		if (statuses?.length)
			url.searchParams.append('statuses', statuses.join(','));

		return ApiService.post<MDPPumpResult[]>(url.href, s).then(res => res.map(x => {
			// Patch in missing static pump data from duty point 0 to other duty points
			if (x.DutyPoints?.length > 1)
				x.DutyPoints = x.DutyPoints.map((dp, i) => !i ? dp : Object.assign({}, x.DutyPoints[0], dp));
			return x;
		}));
	}

	public async getPumpEntries() {
		return ApiService.get<PumpEntry[]>(this.PUMPS + '/pumps/list');
	}

	public getPumpDataForBuild(pumpId: string) {
		return ApiService.get(this.PUMPS + '/pumpdataforbuild/' + encodeURIComponent(pumpId));
	}

	public getPdbInfo(ids: string[]) {
		return ApiService.get<PumpPDBData[]>(this.PUMPS + `/pdbinfo?ids=${ids.join(',')}`);
	}

	public recalculate(dutyPoints: PumpDocument[]) {
		return ApiService.post<RecalcResult>(this.BUILD + `/recalculate`, dutyPoints.map(cleanCopyForSend));
	}

	public getBuildOptions(bc: BuildParams) {
		return ApiService.post<PumpBuildOptions>(this.BUILD + `/buildoptions`, bc);
	}

	public getShaftSeals(bi: BuildInputs[]) {
		return ApiService.post<ShaftSealResult[]>(this.BUILD + `/shaftseals`, bi);
	}

	public getBearingAssemblies(bi: BuildInputs[]) {
		return ApiService.post<BearingAssemblyResult[][]>(this.BUILD + `/bearingassemblies`, bi);
	}

	public getDriveOptions(bc: DriveParams) {
		return ApiService.post<PumpDriveOptions>(this.BUILD + `/driveoptions`, bc);
	}

	public getVBelts(bc: VBeltInputs) {
		return ApiService.post<VBeltResults>(this.BUILD + `/vbelts`, bc);
	}

	public getPartPdbInfo(partType: string, pumpId: string, ids: string[], context?: any) {
		let query = `ids=${ids.join(',')}`;
		if (context)
			Object.entries(context).forEach(([key, val]) => query += `&${encodeURI(key)}=${encodeURI((val ?? '').toString())}` );
		return ApiService.get<PumpPDBData[]>(this.BUILD + `/pdbinfo/${pumpId}/${partType}?${query}`);
	}

	public getBADefinition(id: string) {
		return ApiService.get<BearingAssembly>(this.BUILD + `/bearingassembly/${id}`);
	}

	public getPipingModules(filter: PipingFilter) {
		return ApiService.post<PipingModule[]>(this.BUILD + `/modules/piping`, filter);
	}

	public getPipingMessages(filter: PipingFilter, moduleName: string) {
		return ApiService.post<ValidationResult[]>(this.BUILD + `/modules/piping/${moduleName}/messages`, filter);
	}
}
export default new PumpService();
