<template>
<svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="currentColor" fill-rule="nonzero">
        <path d="M12,9 C11.008,9 10.133,9.49 9.587,10.235 L5.855,8.373 C5.941,8.095 6,7.806 6,7.5 C6,7.194 5.941,6.905 5.855,6.627 L9.586,4.766 C10.147666,5.5389595 11.0445271,5.99742124 12,6 C13.654,6 15,4.654 15,3 C15,1.346 13.654,-8.8817842e-16 12,-8.8817842e-16 C10.346,-8.8817842e-16 9,1.346 9,3 C9,3.304 9.059,3.592 9.143,3.869 L5.411,5.731 C4.84953233,4.95977297 3.95395533,4.50251237 3,4.5 C1.346,4.5 0,5.846 0,7.5 C0,9.154 1.346,10.5 3,10.5 C3.991,10.5 4.864,10.011 5.411,9.269 L9.144,11.131 C9.05272155,11.4118133 9.00417907,11.7047537 9,12 C9,13.654 10.346,15 12,15 C13.654,15 15,13.654 15,12 C15,10.346 13.654,9 12,9 Z M12,1 C13.103,1 14,1.897 14,3 C14,4.103 13.103,5 12,5 C10.897,5 10,4.103 10,3 C10,1.897 10.897,1 12,1 Z M3,9.5 C1.897,9.5 1,8.603 1,7.5 C1,6.397 1.897,5.5 3,5.5 C4.103,5.5 5,6.397 5,7.5 C5,8.603 4.103,9.5 3,9.5 Z M12,14 C10.897,14 10,13.103 10,12 C10,10.897 10.897,10 12,10 C13.103,10 14,10.897 14,12 C14,13.103 13.103,14 12,14 Z" id="Shape"></path>
    </g>
</svg>
</template>

<script>
    export default {};
</script>
 