<template>
	<v-app-bar fixed app flat extension-height="5" color="primary">
		<v-toolbar-title>
			<router-link to="/" custom color="white">
				<img :src="require('@/assets/M_logo.svg')">
				<img class="pl-3 mb-n1" :src="require('@/assets/PD_logo.svg')">
			</router-link>
		</v-toolbar-title>
		<v-spacer></v-spacer>
		<!-- Override abs pos of .comment-icon -->
		<OnlineStatus class="online-positioning" />
		<CommentIcon v-if="validUser" :target="validUser" v-slot="info" style="position: static" :showOnClick="true" :visible="true" showDelete>
			<v-tooltip bottom open-delay="500" :disabled="info.expanded">
				<template v-slot:activator="{ on }">
					<div v-on="on">
						<v-icon color="white" @click="checkInbox" size="20px">$bell</v-icon>
						<span v-if="info.count" style="padding-bottom: 7px; margin-left: -2px; font-size: 90%">{{ info.count }}</span>
					</div>
				</template>
				<span v-text="tooltip(info)"></span>
			</v-tooltip>
		</CommentIcon>
		<v-btn icon @click.stop="toggleSidebar">
			<v-icon color="white" size="18px">$menu</v-icon>
		</v-btn>
		<template v-slot:extension>
			<Loader />
		</template>
	</v-app-bar>
</template>

<style lang="scss" scoped>

	.v-app-bar {
		.v-toolbar__title {
			h4, h5 {
				color: #fff;
				font-size: 22px;
				font-weight: 400;
			}
			h5 {
				font-size: 12px;
			}
		}
		.v-toolbar__content {
			height: 70px!important; // note: remove this if we need to extend the app-bar in other ways, like adding tabs or something
		}
		img.logo {
			cursor: pointer;
			margin: 4px 12px 0;
			max-height: 42px;
		}
		.v-toolbar__extension {
			background-color: #fff;
		}
		.comment-icon.unread * {
			text-shadow: 0 0 10px #fff, 0 0 20px #fff;
		}
	}

	.online-positioning {
		margin: 0 0 0 auto;
		background-color: black !important;
		color: white !important;
		padding-right: 25px;
	}

</style>

<script lang="ts">
	import Vue from 'vue';
	import Component from 'vue-class-component';
	import Loader from '@/components/Loader.vue';
	import CommentIcon from '@/components/CommentIcon.vue';
	import store, { MenuActions, AuthGetters, MsgActions } from '@/store';
	import InsightService from '@/services/insight.service';
	import OnlineStatus from '@/components/OnlineStatus.vue';


	@Component({
		components: {
			Loader,
			CommentIcon,
			OnlineStatus
		},
	})
	export default class Navigation extends Vue {
		public checkInbox() {
			store.dispatch(MsgActions.checkInbox, { id: this.validUser });
			InsightService.trackEvent('Collab:OpenInbox');
		}

		public toggleSidebar() {
			store.dispatch(MenuActions.MENU_TOGGLE);
		}

		public get validUser() {
			return store.get(AuthGetters.loggedIn) && store.get(AuthGetters.userId) || null;
		}

		public tooltip(info: { count: number }) {
			if (info?.count > 0)
				return `${info.count} new notifications!`;
			return 'Personal notifications';
		}
	}
</script>