<template>
	<v-dialog :value="visible" @input="$emit('input')" persistent max-width="600px" @keydown.esc="resetAndCloseForm">
		<v-form ref="newProjectForm" @submit.prevent="newProject" v-model="valid">
			<v-card class="pa-4">
				<v-card-title>
					<span><h2>{{ $t('app.newProject') }}</h2></span>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col sm="12">
							<v-text-field outlined v-model="projectName" label="Project name*" hint="Name of the project"
								:rules="[rules.required]" autofocus maxlength="200" />
						</v-col>
					</v-row>
					<v-row>
						<v-col sm="12">
							<v-text-field outlined v-model="customerReference" label="Customer reference*" hint="Customer reference"
								maxlength="200" />
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="error" text @click="closeDialog">Cancel</v-btn>
					<v-btn :class="[valid ? 'focus':'']" text @click="newProject" :disabled="!valid || !projectName">Create</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop } from 'vue-property-decorator';
	import store, { SnackActions, ProjectActions, AuthMuts } from '@/store';
	import router from '@/router';
	import { PumpProject } from 'types/dto/CalcServiceDomain';

	@Component
	export default class NewProjectDialog extends Vue {
		@Prop() public newProjectName: string;

		public visible: boolean = true;
		public customerReference: string = null;
		public rules = { required: (value: any) => !!value || 'Required.' };
		public valid: boolean = true;
		private manualProjectName: string = null;

		public get projectName() { return this.manualProjectName === null ? this.newProjectName : this.manualProjectName; }
		public set projectName(val: string) { this.manualProjectName = val; }

		public closeDialog() {
			this.resetAndCloseForm();
		}

		public newProject() {
			if (!this.valid)
				return;
			if (this.projectName) {
				store.dispatch(SnackActions.set, `Creating project ${this.projectName}`);
				store.dispatch(ProjectActions.create, { name: this.projectName, ref: this.customerReference }).then((res: PumpProject) => {
					store.commit(AuthMuts.lastOpenedProject, res.id);
					router.push({ name: 'project', params: { id: res.id } });
				});
			}
			this.resetAndCloseForm();
		}

		public resetAndCloseForm() {
			const formEl = this.$refs.newProjectForm as HTMLFormElement;
			formEl.resetValidation();
			this.projectName = null;
			this.$emit('close-dialog', 'project');
		}
	}
</script>