import Vue from 'vue';
import { uniqueValues } from './Tools';

export interface FilterValue {
	enabled: boolean;
	noMatches: boolean;
	value: string;
	parent?: any;
	group?: boolean;
}

export default class ItemFilter<T> {
	public defaultValue: string;
	private filterMap: { [key: string]: FilterValue } = {};
	private optional: FilterValue = { enabled: false, noMatches: false, value: null as string };

	constructor(
		public readonly propName: keyof T,
		public readonly xlateKey?: string,
		private readonly allowEmpty?: boolean,
		private readonly grouping?: Array<{ match: RegExp, value: string }>) { }

	public get active() {
		const filters = this.values;
		return filters?.some(f => f.enabled && !f.noMatches);
	}

	public clear() {
		this.values.forEach(x => x.enabled = false);
	}

	public get enabled() {
		return this.values.some(x => x.enabled);
	}

	public get values() {
		const vals = Object.values(this.filterMap);
		if (vals)
			vals.sort((x, y) => {
				const xs = x?.value;
				const ys = y?.value;
				// tslint:disable-next-line: triple-equals
				return xs == ys ? 0 : xs < ys ? -1 : 1;
			});

		if (this.allowEmpty)
			return vals.concat(this.optional);

		this.grouping?.forEach(g => {
			const group = vals.filter(x => g.match.test(x.value));
			if (group.length > 1) {
				// Insert group element before "children"
				const groupElement = {
					get enabled() { return group.some(x => x.enabled); },
					set enabled(val: boolean) { group.forEach(x => x.enabled = val); },
					get noMatches() { return group.every(x => x.noMatches); },
					// tslint:disable-next-line: no-empty
					set noMatches(_: boolean) {},
					value: g.value,
					group: true
				};
				vals.splice(vals.indexOf(group[0]), 0, groupElement);
				group.forEach(x => x.parent = groupElement);
			}
		});
		return vals;
	}

	// Return a single filter value if one is selected, or the single available option if there is only one
	public get singleValue() {
		const filters = this.values;
		if (!filters?.length)
			return;
		if (filters.length === 1)
			return filters[0].value;
		const selected = filters.filter(x => x.enabled);
		if (selected.length === 1)
			return selected[0].value;
	}

	public update(items: T[]) {
		const newValues = uniqueValues(items, this.propName as string);
		newValues.forEach(fv => {
			if (!this.filterMap[fv])
				// tslint:disable-next-line: triple-equals
				Vue.set(this.filterMap, fv, { enabled: fv == this.defaultValue, noMatches: false, value: fv } as FilterValue);
		});

		if (this.allowEmpty) {
			// If any empty value is present, add optional (none) filter options
			const hasEmptyValue = items?.length && items.some((x: any) => {
				const val = x[this.propName];
				return val == null || val === '' || val.length === 0;
			});

			this.optional.noMatches = !hasEmptyValue;
		}

		// Keep filters that have options checked open so user can select > 1 option (multi select)
		// Otherwise, selecting one option will remove the others in the same filter (single select)
		const keepEnabled = this.values.some(x => x.enabled);
		Object.keys(this.filterMap).forEach(k => {
			this.filterMap[k].noMatches = !keepEnabled && !newValues.includes(k);
		});
	}

	public toggle(filter: any) {
		Vue.set(filter, 'enabled', !filter.enabled);
	}

	public set(value: any) {
		if (this.filterMap?.[value] && !this.filterMap[value].enabled)
			Vue.set(this.filterMap[value], 'enabled', true);
	}

	public filter(items: T[]) {
		if (!this.active)
			return items;

		return items.filter(item => {
			const value = (item as any)[this.propName];
			if (value == null || !value.length && Array.isArray(value)) {
				if (this.allowEmpty && this.optional.enabled)
					return true;
				return !this.values.some(x => x.enabled);
			}

			if (Array.isArray(value))
				return value.some((x: string) => this.filterMap[x] && this.filterMap[x].enabled);

			const filter = value != null && this.filterMap[value];
			return !filter || filter.enabled;
		});
	}
}
