<template>
	<v-snackbar v-model="show" color="primary" style="white-space: pre-wrap; text-indent: 0">{{message}}
		<v-btn light text @click.native="show = false">Close</v-btn>
	</v-snackbar>
</template>

<script lang="ts">
	import Vue from 'vue';
	import Component from 'vue-class-component';
	import store, { SnackActions } from '@/store';

	@Component
	export default class Snackbar extends Vue {
		public show: boolean = false;
		public message: string = '';
		public created() {
			store.watch((state: any) => state.snackbar.snack, (msg: string) => {
				if (msg && msg !== '') {
					this.show = true;
					this.message = msg.trim();
					store.dispatch(SnackActions.set, '');
				}
			});
		}
	}
</script>