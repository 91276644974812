<template>
	<ul style="line-height: 22px">
		<li v-for="w of errors" :key="key(w)" v-text="w.Message" class="error--text" />
		<li v-for="w of warnings" :key="key(w)" v-text="w.Message" class="warning--text" />
		<li v-for="w of infos" :key="key(w)" v-text="w.Message" class="selection--text" />
	</ul>
</template>

<style scoped>
	li:last-child {
		padding-bottom: 20px;
	}
</style>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { MessageSeverity, ValidationResult } from 'types/dto/CalcServiceDomain';

	@Component
	export default class SimpleWarnings extends Vue {
		@Prop() public msgs: ValidationResult[];

		public get errors() {
			return this.msgs?.filter(x => x.Severity > MessageSeverity.Warning) || [];
		}

		public get warnings() {
			return this.msgs?.filter(x => x.Severity === MessageSeverity.Warning) || [];
		}

		public get infos() {
			return this.msgs?.filter(x => x.Severity === MessageSeverity.Info) || [];
		}

		public key(m: ValidationResult) {
			return m.Message + m.ParamName;
		}
}
</script>
