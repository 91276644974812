<template>
<svg width="10px" height="10px" viewBox="0 0 10 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="UI-Kit---NEW" stroke="none" stroke-width="1" fill="currentColor" fill-rule="evenodd">
        <g id="Icons" transform="translate(-159.000000, -226.000000)" fill="currentColor" fill-rule="nonzero">
            <g id="UI-074-Clear" transform="translate(159.000000, 226.000000)">
                <polygon id="Path" points="9.708 0.708 9 8.8817842e-16 4.854 4.147 0.708 8.8817842e-16 8.8817842e-16 0.708 4.147 4.854 8.8817842e-16 9 0.708 9.708 4.854 5.561 9 9.708 9.708 9 5.561 4.854"></polygon>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
    export default {};
</script>
