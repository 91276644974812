import { PumpDocument, ValidationResult, MessageSeverity } from 'types/dto/CalcServiceDomain';
import { ParamBag } from './ParamBag';

export interface ConflictEvent {
	me: PumpDocument[];
	other: PumpDocument[];
	mode: 'Update' | 'Conflict';
	changedNames: string[];
	changedMsgs?: ValidationResult[];
	onAccept?: () => any;
}

export function sendConflictEvent(ev: ConflictEvent) {
	window.dispatchEvent(new CustomEvent<any>('sizingConflict', { detail: ev  }));
}

export function compareDocs(aDutyPoints: PumpDocument[], bDutyPoints: PumpDocument[], paramPrefix?: string) {
	if (!aDutyPoints && !bDutyPoints || !aDutyPoints.length && !bDutyPoints.length)
		return [];
	if (aDutyPoints && !bDutyPoints || !aDutyPoints && bDutyPoints || aDutyPoints.length !== bDutyPoints.length)
		throw new Error('These documents are not comparable');

	let i = 0;
	const diffNames = [] as string[];
	for (const a of aDutyPoints) {
		const b = bDutyPoints[i++];

		// Static, non-compared properties
		// const aRes: Partial<PumpDocument> = { id: a.id, Name: a.Name, Subtitle: a.Subtitle }
		// const bRes: Partial<PumpDocument> = { id: b.id, Name: b.Name, Subtitle: b.Subtitle }

		// Compare metadata
		// diffProp(a, b, 'Notes', aRes, bRes);

		// Compare data
		const bagA = new ParamBag([a], a.id);
		const bagB = new ParamBag([b], b.id);
		for (const paramName of bagA.allValueNames.filter(x => !paramPrefix || x.startsWith(paramPrefix))) {
			const param = bagA.getParam(paramName);
			if (!param || param.definition.Type === 'Object')
				continue;

			const va = param.getUnitValue()?.valueString;
			const vb = bagB.getParam(paramName)?.getUnitValue()?.valueString;
			// tslint:disable-next-line: triple-equals
			if ((va?.toString() || null) != (vb?.toString() || null)) {
				console.debug('diff in dp ' + i + ', ' + paramName + ': ' + va + ' != ' + vb);
				if (!diffNames.includes(paramName))
					diffNames.push(paramName);
			}
		}
	}
	return diffNames.filter(x => x);
}

export function compareStatuses(aDutyPoints: PumpDocument[], bDutyPoints: PumpDocument[]) {
	if (!aDutyPoints && !bDutyPoints || !aDutyPoints.length && !bDutyPoints.length)
		return [];
	if (aDutyPoints && !bDutyPoints || !aDutyPoints && bDutyPoints || aDutyPoints.length !== bDutyPoints.length)
		throw new Error('These documents are not comparable');

	// Return new and changed messages
	const oldMap = mapMessages(aDutyPoints);
	const newMap = mapMessages(bDutyPoints);
	return Object.keys(newMap).filter(x => !oldMap[x]).map(x => newMap[x]);
}

function mapMessages(docs: PumpDocument[]) {
	const key = (x: ValidationResult) => x.ParamName + x.Message;
	const allStatuses = docs.map(x => x.Status?.filter(s => s.Severity >= MessageSeverity.Warning));
	const oldStatus = ParamBag.mergeMessages(...allStatuses);
	const map: { [key: string]: ValidationResult } = {};
	oldStatus?.forEach(x => map[key(x)] = x);
	return map;
}

function diffProp(a: any, b: any, propName: string, aTarget: any, bTarget: any) {
	// tslint:disable-next-line: triple-equals
	if (a[propName] == b[propName])
		return false;

	aTarget[propName] = a[propName];
	bTarget[propName] = b[propName];
	return true;
}
