import { ServiceClassInfo } from './CalcServiceDomain';
import { PumpResult } from './PumpSearch';

export interface ReportInputs {
	Sizings: ReportSizingData[];
	ModelId?: string;
	ProjectId?: string;
	HtmlView?: boolean;
	UseImperial: boolean;
	ShowWarnings?: boolean;
	ShowComments?: boolean;
	ShowEstimations?: boolean;
	ShowPipes?: boolean;
	ShowCurves?: boolean;
	CurveOnly?: boolean;
	Language?: string;
	Locale?: string;
	RootUrl?: string;

	// Applied if no duty is present (CurveOnly = true)
	TrimPercentage?: number;

	// Curve specific info
	UseSlurry?: boolean;
	IncludeNPSH?: boolean;
	IncludeDutySpeed?: boolean;
	IncludeSystemCurve?: boolean;
	IncludeOverlays?: boolean;
	IncludeDutyLimits?: boolean;

	// Tacked on to pass the desired filename locally
	Filename?: string;
}

export interface ReportSizingData {
	Ids: string[];
}

export interface ChartInputs {
	UseSlurry: boolean;
	IncludeNPSH: boolean;
	IncludeDutyLimits: boolean;
	PumpResult: PumpResult;
	SlurryDensity?: number;
	DutyClass?: ServiceClassInfo;
	ExtraSpeeds?: SpeedCurve[];
	SpeedLimit?: number;
	OverrideTrimPercentage?: number;
}

export interface SpeedCurve {
	Speed: number;
	Flow: number;
	Marked?: boolean;
}

export enum OverlayShape { Rect = 'Rect', Polygon = 'Polygon', Curve = 'Curve' }
export enum OverlayType { Warning = 'Warning', Good = 'Good', Bad = 'Bad', Neutral = 'Neutral'  }

export interface GraphOverlay {
	Shape: OverlayShape;
	Type: OverlayType;
	Text?: string;
	Coords: number[];
}

export interface GraphInfo {
	SCALE_H_MAX: number;
	SCALE_Q_MAX: number;
}

// ChartData properties are exposed on a client need-to-know basis
export interface CurveData {
	overlays: GraphOverlay[];
	graphInfo: GraphInfo;
	belDataset: any;
	efficienceDataset: any;
	headDataset: any;
	npshgraphDataset: any;
}
