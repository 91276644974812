<template>
	<v-menu style="top: -12px" offset-y v-model="menuShowing">
		<template v-slot:activator="{ on: menu, attrs }">
			<v-tooltip bottom max-width="400" open-delay="500" :value="showTooltip" :disabled="menuShowing">
				<template v-slot:activator="{ on: tooltip }">
					<v-btn v-on="{ ...tooltip, ...menu }" v-bind="attrs" text :color="iconColor" class="inline">
						<v-icon small>$filter</v-icon>
					</v-btn>
				</template>
				<div v-if="showTooltip === true" style="white-space: pre-line" v-text="$t('ParamFilter.AutoApplied')" />
				<span v-else-if="activeFilter" v-text="$t('ParamFilter.Active')" />
				<span v-else v-text="$t('ParamFilter.Description')" />
			</v-tooltip>
		</template>
		<v-list min-width="150" dense>
			<v-list-item-group v-model="filter">
				<v-list-item v-for="v of values" style="cursor: pointer" :value="v" :key="v">
					<v-list-item-icon style="margin-right: 6px">
						<v-icon v-show="v === filter">$checkMark</v-icon>
					</v-list-item-icon>
					<v-list-item-content v-text="$t(`ParamFilter.${v}`)" />
				</v-list-item>
			</v-list-item-group>
		</v-list>
	</v-menu>
</template>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Watch } from 'vue-property-decorator';
	import store, { AuthActions, AuthGetters } from '@/store';
	import { ChangeableUserInfo } from 'types/dto/AccessToken';

	const values = ['Basic', 'All', 'UserDefined', 'Problematic'];

	@Component
	export default class ParamFilter extends Vue {
		public filter: string = 'All';
		public readonly values = values;
		public showTooltip?: boolean = null;
		public menuShowing: boolean = null;

		public created() {
			let startVal = store.get(AuthGetters.paramFilter);
			if (!startVal) {
				startVal = 'Basic';
				this.showTooltip = true;
				setTimeout((): void => this.showTooltip = null, 10000);
			}
			if (startVal && values.includes(startVal)) {
				this.filter = startVal;
				this.$emit('change', startVal);
			}
		}

		public get activeFilter() {
			return this.filter != null && this.filter !== 'All';
		}

		public get iconColor() {
			return this.activeFilter ? 'primary' : 'grey';
		}

		@Watch('filter')
		public onFilterChange(newVal: string, oldVal: string) {
			if (!newVal || newVal === oldVal)
				return;
			this.$emit('change', newVal || 'All');

			if (newVal === 'All' || newVal === 'Basic') {
				if (newVal !== store.get(AuthGetters.paramFilter))
					store.dispatch(AuthActions.update, { ParamFilter: newVal } as ChangeableUserInfo);
			}
		}

		@Watch('menuShowing')
		public onMenu(showing: boolean) {
			if (showing)
				this.showTooltip = null;
		}
	}
</script>

<style lang="scss" scoped>
@import '@/sass/_variables.scss';
	.v-btn.inline {
		min-width: 36px;
		width: auto;
		padding: 2px 4px;
		&:hover {
			background-color: $selection-lighten-1;
		}
	}
	span {
		color: $white;
	}
</style>
