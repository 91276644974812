<template>
	<v-tooltip v-if="value" :disabled="!value.diffs && !errorClass && !value.isLong" bottom :color="errorClass" max-width="500" open-delay="500" >
		<template v-slot:activator="{ on }">
			<div v-on="on" class="limit-width" :style="valueStyle">
				<transition name="change" mode="out-in">
					<b :key="value.baseValue" :class="'dp-value ' + messageClass">{{ value.prefix }}{{ value.value }}</b>
				</transition>
			</div>
		</template>
		<div v-if="value.isLong && !value.diffs"><b style="white-space: pre">{{ value.prefix }}{{ value.value }}</b></div>
		<div v-for="msg of messages" :key="msg" v-text="msg" />
		<div v-for="diff of value.diffs" :key="diff" v-text="diff" />
	</v-tooltip>
</template>

<style lang="scss" scoped>
	b {
		white-space: nowrap;
		padding-right: 2px
	}
	.change-enter-active {
		transition: background-color 5s;
	}
	.change-leave-active {
		transition: background-color 0.25s;
	}
	.change-enter, .change-leave-to {
		background-color: #ddf;
	}
	// Stop long text values from overwriting the value name
	.limit-width {
		display: inline-block;
		text-overflow: ellipsis;
		overflow: hidden;
		line-height: 1.1;
		vertical-align: text-bottom;
	}
	.dp-value.purple--text {
		background-image: linear-gradient(to right, rgb(0,0,0,0.01), #4e209840);
	}
	.dp-value.orange--text {
		background-image: linear-gradient(to right, rgb(0,0,0,0.01), #fba70040);
	}
	.dp-value.red--text {
		background-image: linear-gradient(to right, rgb(0,0,0,0.01), #f4433640);
	}
</style>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { ValidationResult, MessageSeverity } from 'types/dto/CalcServiceDomain';
	import UnitValue from '@/common/UnitValue';
	import { ParamValue } from '@/common/ParamValue';
	import { uniqueValues } from '@/common/Tools';

	interface VariantValue {
		name: string;
		value: UnitValue;
		active: boolean;
		errors: ValidationResult[];
		warnings: ValidationResult[];
	}

	interface DisplayValue {
		render?: (v: VariantValue, withUnit: boolean) => string;
		baseValue: any;
		value: string;
		errors: string[];
		warnings: string[];
		infos: string[];
		diffs: string[];
		prefix: string;
		isLong: boolean;
	}

	enum Mode {
		SingleDuty = 'SingleDuty',
		Range = 'Range',
		Diff = 'Diff',
		ShowAll = 'ShowAll'
	}

	@Component
	export default class DutyPointValue extends Vue {
		@Prop() public param: ParamValue;
		@Prop() public sizingId: string;
		@Prop() public fullWidth: boolean;
		@Prop() public mode: Mode;
		@Prop() public assumed: boolean;

		public get valueStyle() {
			const p = this.value;
			const style = (p && (p.diffs || this.messageClass)) ? 'cursor: help' : '';
			return this.fullWidth ? style : (style + '; max-width: 160px');
		}

		public get messageClass() {
			const p = this.value;
			if (p?.errors?.length)
				return 'red--text';
			if (p?.warnings?.length)
				return 'orange--text';
			if (p?.infos?.length)
				return 'purple--text';
			return '';
		}

		public get errorClass() {
			const p = this.value;
			if (p?.errors?.length)
				return 'error';
			if (p?.warnings?.length)
				return 'warning';
			if (p?.infos?.length)
				return 'selection';
			return '';
		}

		public get messages() {
			const val = this.value;
			const all = val.errors.concat(val.warnings).concat(val.infos);
			return uniqueValues(all);
		}

		get useImperial() {
			return this.param.useImperialUnits;
		}

		public get value() {
			const pv = this.param;
			if (!pv)
				return null;

			const k = pv.definition.Name;
			const uv = pv.getUnitValue(this.useImperial);
			// tslint:disable-next-line: triple-equals
			const roundedToZero = pv.definition.Type === 'Number' && !pv.definition.ReadOnly && uv?.valueString == '0' && uv.baseUnitValue != 0;
			const singleDuty = this.mode === Mode.SingleDuty;

			const msgs = singleDuty ? pv.messages : pv.allMessages;
			const warnings = msgs?.filter(x => x.Severity === MessageSeverity.Warning).map(x => x.Message) || [];
			const errors = msgs?.filter(x => x.Severity >= MessageSeverity.Error).map(x => x.Message) || [];
			let infos = msgs?.filter(x => x.Severity === MessageSeverity.Info).map(x => x.Message) || [];
			if (roundedToZero)
				infos = infos.concat(['Smaller than system precision - rounded to zero']);

			const item: DisplayValue = {
				baseValue: (uv.baseUnitValue || '').toString(),
				value: roundedToZero && '0!' || DutyPointValue.displayValue(uv, pv.xlateSection, true),
				errors,
				warnings,
				infos,
				diffs: null as string[],
				prefix: '',
				isLong: false
			};

			// Force tooltip for values that are probably truncated with ellipsis (seal note...)
			if (item.value?.length >= 25 && !this.fullWidth)
				item.isLong = true;

			if (!singleDuty) {
				// If nothing differs in duty points, not numeric, or we don't have duty points, we are done here
				const dpValues = this.dutyPointValues(uv, k, pv);
				if (!dpValues) {
					if (this.mode === Mode.Diff)
						item.value = '-';
				} else {
					item.render = (v: VariantValue, withUnit: boolean) => DutyPointValue.displayValue(v.value, pv.xlateSection, withUnit);
					if (this.mode === Mode.Diff)
						DutyPointValue.createDiff(pv, dpValues, item);
					else
						DutyPointValue.createRange(pv, dpValues, item);

					if (!item.diffs)
						item.diffs = dpValues.map(v => `${v.active ? '➤ ' : '  '}${v.name}: ${item.render(v, true)}`);
				}
			}

			if (this.assumed)
				item.prefix = (item.prefix || '') + '≈ ';
			return item;
		}

		private static createRange(pv: ParamValue, dpValues: VariantValue[], item: DisplayValue) {
			// Calculate min/max for numeric properties
			let min: VariantValue;
			let max: VariantValue;

			if (pv.definition.Type === 'Number') {
				dpValues.forEach(v => {
					if (v.value) {
						const val = v.value.baseUnitValue;
						min = !min || val < min.value.baseUnitValue ? v : min;
						max = !max || val > max.value.baseUnitValue ? v : max;
					}
				});
			}

			// Add UI representation of diffing duty point values
			if (min != null && max != null && min.value.baseUnitValue < max.value.baseUnitValue)
				item.value = `${item.render(min, false)}–${item.render(max, true)}`;
			else
				item.prefix = '👁 '; // The max/min stuff failed. Just show an eye.

			if (!pv.definition.ReadOnly)
				item.prefix = '★ '; // Skip the '👁 ' when showing interval...
		}

		private static createDiff(pv: ParamValue, dpValues: VariantValue[], item: DisplayValue) {
			if (dpValues?.length !== 2)
				return '-';
			item.value = `${item.render(dpValues[0], false)} 🠞 ${item.render(dpValues[1], true)}`;
			item.diffs = [item.value];
		}

		private dutyPointValues(uv: UnitValue, valueName: string, pv: ParamValue): VariantValue[] {
			if (!pv?.variants?.length)
				return;

			let variantValues: VariantValue[];
			const baseVal = uv?.valueString || null;
			const baseValStr = baseVal?.toString() || null;
			let diffFound = false;
			variantValues = [];
			pv.variants?.forEach((v, idx) => {
				const vv = {
					name: v.metadata?.Subtitle || `#${idx + 1}`,
					active: v.metadata?.id === this.sizingId
				} as VariantValue;

				if (v.metadata?.id === this.sizingId) {
					vv.value = uv;
				} else {
					vv.value = pv.getUnitValue(this.useImperial, idx);
					const altValStr = vv.value?.valueString || null;
					if (altValStr !== baseValStr)
						diffFound = true;
				}
				variantValues.push(vv);
			});
			return diffFound ? variantValues : null;
		}

		private static displayValue(pv: UnitValue, xlateSection: string, withUnit?: boolean): string {
			const val = pv?.valueString;
			if (val == null)
				return 'N/A';
			return pv.toString(xlateSection, withUnit);
		}
	}
</script>
