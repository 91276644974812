<template>
	<!-- cosplay as a button -->
  	<v-sheet class="online-icon theme--light v-size--default">
		<v-icon v-if="!connected" small color="warning" class="mb-1">$offline</v-icon> 
		<v-icon v-if="connected" small color="info" class="mb-1">$online</v-icon> 
		{{ connected ? 'Online' : 'Offline' }}
	</v-sheet>
</template>

<style lang="scss" scoped>
	.online-icon .v-sheet {
		align-items: center;
		display: flex;
		margin-right: 30px;
		.v-icon {
			margin-right: 4px;
		}
	}
</style>

<script lang="ts">
	import Vue from 'vue';
	import Component from 'vue-class-component';
	import store, { NetworkGetters } from '@/store';

	@Component
	export default class Snackbar extends Vue {
		public get connected() {
			return !!store.get(NetworkGetters.connected);
		}
	}
</script>
