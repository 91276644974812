import { PumpDocument, TDHMode } from 'types/dto/CalcServiceDomain';
import store, { SizingGetters, SizingActions } from '@/store';
import { BuildInputs, BearingAssemblyResult, DriveArrangement } from 'types/dto/PumpBuild';

export class DutyPoints {
	private sizings: PumpDocument[] = null;
	public sizingId: string;

	public constructor(sizing: PumpDocument, alwaysIncludeChildren?: boolean) {
		if (!sizing)
			return;

		// Not a multiple duty point sizing - nothing to do but use it
		if (!sizing.MDP && !alwaysIncludeChildren)
			this.sizings = [sizing];
		else {
			// Get DPs (i.e. siblings, parent and children)
			// TODO: make more specific to avoid mixups with staging if used for the same pumps)
			this.sizings = DutyPoints.sizingWithChildren(sizing);
		}
		this.sizingId = sizing.id;
	}

	public get asSizings() {
		// Check that no sizing has been deleted
		const fromStore = store.get(SizingGetters.sizings, this.sizings.map(x => x.id)) as PumpDocument[];
		if (fromStore.length !== this.sizings.length) {
			const storeIds = fromStore.map(x => x.id);
			this.sizings = this.sizings.filter(x => storeIds.includes(x.id));
		}
		return this.sizings;
	}

	public static sizingWithChildren(sizing: PumpDocument) {
		return (store.get(SizingGetters.projectSizings, sizing.ProjectId) as PumpDocument[])
			.filter(x => x.id === sizing.id || sizing.ParentId && x.id === sizing.ParentId ||
				x.ParentId && x.ParentId === sizing.id || x.ParentId && x.ParentId === sizing.ParentId)
			.sort((a: PumpDocument, b: PumpDocument) => a.Created === b.Created ? 0 : a.Created > b.Created ? 1 : -1);
	}

	public async load() {
		this.sizings = await DutyPoints.ensureLoaded(this.sizings);
	}

	private static async ensureLoaded(sizings: PumpDocument[]) {
		const needsLoad = sizings.filter(x => !store.get(SizingGetters.fullyLoaded, { id: x.id, skipChildren: true })).map(x => x.id);
		if (needsLoad.length)
			await Promise.all(needsLoad.map(x => store.dispatch(SizingActions.getSizing, x)));
		return sizings.map(x => store.get(SizingGetters.sizing, x.id));
	}

	public static async loadSiblings(sizing: PumpDocument) {
		const siblings = DutyPoints.sizingWithChildren(sizing);
		return DutyPoints.ensureLoaded(siblings);
	}

	public get curDpIndex(): number {
		return this.sizings.findIndex(x => x.id === this.sizingId);
	}

	// Results set by service callers
	public baResults: BearingAssemblyResult[][];

	public get isMDP() { return this.sizings?.length > 1 && this.sizings.some(x => x.MDP) || false; }

	public get recommendedDutyClass() {
		let dutyClass = 1.0;
		(this.sizings || []).forEach(x => {
			if (x.Data?.ServiceClass?.DutyClass > dutyClass)
				dutyClass = x.Data.ServiceClass.DutyClass;
		});
		return dutyClass;
	}

	public getBuildInputs(baseBi: BuildInputs) {
		const bis: BuildInputs[] = [];
		this.sizings.forEach(dp => {
			const pump = dp.Data.Pump;
			const radialLoad = dp.Data.VBelt?.BeltLoad ||
				dp.Data.Drive?.DriveArrangement === DriveArrangement.CustomDrive && dp.Data.Drive.TransmissionLoad || 0;

			const bi = Object.assign({}, baseBi, {
				BeltLoad: radialLoad,
				SheaveForce: dp.Data.VBelt?.SheaveForce || 0,
				BepHead: pump.BepHead,
				BepFlow: pump.BepFlow,
				DutySpeed: pump.DutySpeed,
				FlowRate: dp.Data.Slurry?.FlowRate,
				PDH: dp.Data.Heads?.PDH,
				SlurryDensity: dp.Data.Slurry?.SlurryDensity,
				InletHead: dp.Data.Heads?.InletHead || undefined,
				ShutoffHead: pump.ShutoffHead || undefined,
				ImpellerDiameter: pump.ImpellerDiameter || undefined
			}) as BuildInputs;

			// When calculating TDH, use separate min/max margins for (shaft seal) calculations
			const ic = dp.Data.Inlet;
			if (dp.Data.Heads?.TDHMode === TDHMode.Calculate && ic?.InletDesign != null) {
				if (ic.InletMax != null && ic.InletMax > ic.InletDesign)
					bi.InletHeadPosMargin = ic.InletMax - ic.InletDesign;
				if (ic.InletMin != null && ic.InletMin < ic.InletDesign)
					bi.InletHeadNegMargin = ic.InletDesign - ic.InletMin;
			}

			bis.push(bi);
		});
		return bis;
	}

	public get toBAresult() {
		return this.baResults[this.curDpIndex];
	}

	public baDutyPoints(ba: BearingAssemblyResult): BearingAssemblyResult[] {
		if (!this.baResults || this.baResults.length <= 1)
			return [ba];
		const index = this.baResults[0].findIndex(x => x.Id === ba.Id && x.MaterialNumber === ba.MaterialNumber);
		return index >= 0 && this.baResults.map(x => x[index]) || [ba];
	}

	public writeSelectedBA(ba: BearingAssemblyResult) {
		const batch = this.sizings.map((dp, idx) => {
			const dpBa = ba && this.baResults[idx].find(x => x.Id === ba.Id && x.MaterialNumber === ba.MaterialNumber);
			return { sizingId: dp.id, sections: { BearingAssembly: dpBa || {} },
				target: 'BearingAssembly', newValue: dpBa?.MaterialNumber };
		});
		store.dispatch(SizingActions.updateSections, batch);
	}

	// Just blindly update a section for all duty points. Use this for preconfig data that does not depend on DP (flange etc).
	public updateSections(sections: any, target?: string, newValue?: string) {
		const batch = this.sizings.map(dp => ({ sizingId: dp.id, sections, target, newValue }));
		store.dispatch(SizingActions.updateSections, batch);
	}

	// Blindly set a value in all duty points
	public async setAll(valueName: string, value: any, skipSave?: boolean) {
		const batch = this.sizings.map(x => x.id).map(id => ({ value, sizingId: id, valueName, skipSave }));
		store.dispatch(SizingActions.setValues, batch);
	}
}
