import { ActionContext } from 'vuex';
import { Message, MessageType, TargetType } from 'types/dto/Messages';
import MsgService from '@/services/messages.service';
import Vue from 'vue';
import { minmax } from '@/common/Tools';
import store, { NetworkGetters, SnackActions } from '@/store';

interface IItemMsgState {
	messages: Message[];
	poll?: any;
	observers: number;
	lastChecked?: number;
}

interface IMsgState {
	readonly inboxes: { [id: string]: IItemMsgState };
}

type StoreActionContext = ActionContext<IMsgState, IMsgState>;

const msgState: IMsgState = {
	inboxes: {}
};

const getters = {
	getTarget: (state: IMsgState) => (id: string) => {
		return state.inboxes[id] || null;
	},
	messages: (state: IMsgState) => (id: string, type?: MessageType, subtarget?: string) => {
		const target = getters.getTarget(state)(id);
		if (!target || !target.messages)
			return null;

		let all = target.messages;
		if (!all.length)
			return all;
		if (type) {
			// tslint:disable-next-line: triple-equals
			all = all.filter(x => x.Type == type) || [];
		}
		if (subtarget)
			all = all.filter(x => x.Subtarget === subtarget) || [];
		return all;
	},
	message: (state: IMsgState) => (id: string, msgId: string) => {
		const inbox = getters.getTarget(state)(id);
		return inbox?.messages?.find(x => x.id === msgId);
	},
	lastChange: (state: IMsgState) => (id: string, subtarget: string) => {
		const inbox = getters.getTarget(state)(id);
		return inbox?.messages?.find(x => x.Type === MessageType.Change && x.Target === id && (!subtarget || x.Subtarget === subtarget));
	}
};

const actions = {
	loadInbox({ commit, dispatch, state }: StoreActionContext, { id }: { id: string }) {
		let inbox = getters.getTarget(state)(id);
		if (!inbox) {
			commit('initInbox', { id });
			inbox = getters.getTarget(state)(id);
		}
		const checkInbox = () => dispatch('checkInbox', { id });
		commit('startPoll', { id, func: checkInbox });
		const promise = checkInbox();

		// If we have messages, return them right away while we wait for a "fresh version"
		if (inbox?.messages)
			return inbox.messages;
		return promise;
	},

	checkInbox({ commit, state }: StoreActionContext, { id, force }: { id: string, force?: boolean }) {
		const inbox = getters.getTarget(state)(id);
		if (!inbox || !store.get(NetworkGetters.connected))
			return;

		const now = new Date().getTime();
		if (!force && inbox.lastChecked) {
			const timeSinceCheck = now - inbox.lastChecked;
			if (timeSinceCheck < 3000)
				return;
		}
		inbox.lastChecked = now;

		let createdAfter;
		if (inbox.messages?.length)
			createdAfter = minmax(inbox.messages, 'Created').max as any;

		return MsgService.getMessages(id, undefined, createdAfter).then(res => {
			if (res?.Items?.length) {
				commit('addMsgs', { id, msgs: res.Items });
				return getters.getTarget(state)(id).messages || [];
			}
			return inbox?.messages || [];
		});
	},

	unloadInbox({ commit }: StoreActionContext, { id }: { id: string }) {
		commit('unload', { id });
	},

	addComment({ commit }: StoreActionContext, { target, subtarget, text, cc }: { target: string, subtarget: string, text: string, cc: string[] }) {
		const msg = {
			Target: target,
			Subtarget: subtarget,
			Text: text,
			Type: MessageType.Comment,
			TargetType: TargetType.Sizing,
			Recipients: cc
		} as Message;

		store.dispatch(SnackActions.set, 'Posting message...');
		MsgService.send(msg).then(res => {
			if (res)
				commit('addMsgs', { id: target, msgs: [res] });
		}).catch(() => {
			store.dispatch(SnackActions.set, 'Failed to post message. Please try again.');
		});
	},

	async markRead({ commit }: StoreActionContext, { userId, msgIds }: { userId: string, msgIds: string[] }) {
		const newMsgs = await MsgService.markRead(msgIds) as Message[];
		newMsgs?.forEach(newMsg => commit('update', { inbox: userId, newMsg }));
	},

	async delete({ commit }: StoreActionContext, { inbox, msgId }: { inbox: string, msgId: string }) {
		commit('remove', { inbox, msgId });
		return MsgService.delete(msgId);
	}
};

const mutations = {
	initInbox(state: IMsgState, { id }: { id: string }) {
		const inbox = getters.getTarget(state)(id);
		if (!inbox)
			Vue.set(state.inboxes, id, { messages: null, observers: null } as IItemMsgState);
	},
	addMsgs(state: IMsgState, { id, msgs }: { id: string, msgs: Message[] }) {
		if (id && msgs?.length) {
			let inbox = getters.getTarget(state)(id);
			if (!inbox) {
				mutations.initInbox(state, { id });
				inbox = getters.getTarget(state)(id);
			}
			const existing = inbox.messages || [];
			const notAdded = msgs.filter(m => !existing.some(x => x.id === m.id));
			if (notAdded.length)
				inbox.messages = notAdded.concat(existing);
		}
	},
	unload(state: IMsgState, { id }: { id: string }) {
		const sz = getters.getTarget(state)(id);
		if (!sz) {
			console.error('unload: target ' + id + ' was never loaded');
			return;
		}
		if (--sz.observers <= 0) {
			if (sz.poll)
				clearInterval(sz.poll);
			delete state.inboxes[id];
		}
	},
	startPoll(state: IMsgState, { id, func }: { id: string, func: () => void }) {
		const sz = getters.getTarget(state)(id);
		if (!sz) {
			console.error('startPoll: target ' + id + ' is not initialized');
			return;
		}
		if (sz.poll)
			sz.observers++;
		else {
			sz.observers = 1;
			sz.poll = setInterval(() => {
				func();
			}, 60000);
		}
	},
	update(state: IMsgState, { inbox, newMsg }: { inbox: string, newMsg: Message }) {
		// Here is why we want a To: field...
		const msg = getters.message(state)(inbox, newMsg.id);
		if (msg?.Read !== newMsg.Read)
			msg.Read = newMsg.Read;
	},
	remove(state: IMsgState, { inbox, msgId }: { inbox: string, msgId: string }) {
		const sz = getters.getTarget(state)(inbox);
		if (!sz?.messages)
			return;
		const idx = sz.messages.findIndex(x => x.id === msgId);
		if (idx >= 0)
			sz.messages.splice(idx, 1);
	}
};

export const enum MsgActions {
	loadInbox = 'msg/loadInbox',
	unloadInbox = 'msg/unloadInbox',
	addComment = 'msg/addComment',
	checkInbox = 'msg/checkInbox',
	markRead = 'msg/markRead',
	delete = 'msg/delete'
}

export const enum MsgGetters {
	messages = 'msg/messages',
	lastChange = 'msg/lastChange'
}

export const enum MsgMuts {
}

export const messageStore = {
	namespaced: true,
	state: msgState,
	getters,
	actions: actions as any,
	mutations
};
