<template>
<svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="UI-Kit---NEW" stroke="none" stroke-width="1" fill="currentColor" fill-rule="evenodd">
        <g id="Icons" transform="translate(-396.000000, -653.000000)" fill="currentColor">
            <g id="Shape" transform="translate(396.000000, 653.000000)">
                <path d="M5.44040681,2.96768188 L4.49123382,2.01850893 C5.44807436,1.7066574 6.45950317,1.5443802 7.49988557,1.5443802 C10.2121353,1.5443802 12.7298355,2.64289857 14.5889282,4.63771819 L14.9890137,5.06698608 L14.1305924,5.86704255 L13.7305069,5.43777466 C12.0957184,3.68385316 9.88300324,2.71797182 7.49988557,2.71797182 C6.79492951,2.71797182 6.1049652,2.80311586 5.44040681,2.96768188 Z M7.30602264,4.83318328 L8.58238219,6.10965729 C9.7310257,6.33533479 10.8076859,6.91761018 11.6899109,7.80475617 L12.1037292,8.22086335 L12.9357147,7.39334106 L12.5220108,6.97723389 C11.1450577,5.59272768 9.36149596,4.83020783 7.49988557,4.83020783 C7.43511199,4.83020783 7.37056731,4.83135223 7.30602264,4.83318328 L7.30602264,4.83318328 Z M7.47070312,11.5779877 C6.95217132,11.5779877 6.53182983,11.9983292 6.53182983,12.5167465 C6.53182983,13.0352783 6.95217132,13.4556198 7.47070312,13.4556198 C7.98912047,13.4556198 8.40946198,13.0352783 8.40946198,12.5167465 C8.40946198,11.9983292 7.98912047,11.5779877 7.47070312,11.5779877 L7.47070312,11.5779877 Z M15,14.1701889 L0.829811104,8.8817842e-16 L0,0.829811104 L2.2567749,3.08658601 C1.59038543,3.51894378 0.970687852,4.03724672 0.411071777,4.63771819 L0.0109863281,5.06687165 L0.869522109,5.86704255 L1.2694931,5.43777466 C1.82167055,4.84542847 2.44010924,4.34383392 3.1084442,3.9382553 L4.63268279,5.4624939 C3.84830476,5.82321167 3.11885833,6.3325882 2.47776032,6.97734832 L2.0640564,7.3934555 L2.89627075,8.22086335 L3.30997468,7.80475617 C3.96057129,7.15038299 4.71702574,6.66194915 5.53024292,6.36005402 L7.290802,8.12049867 C6.36737824,8.16822053 5.38181306,8.61637116 4.68292236,9.3145752 L4.26773071,9.72919465 L5.09708405,10.5594635 L5.5122757,10.1448441 C6.03240967,9.62528229 6.79412842,9.28962709 7.45319367,9.28962709 L7.45353697,9.28962709 L7.4943924,9.28962709 L7.4947357,9.28962709 C7.94666291,9.28962709 8.4469986,9.44755553 8.88462067,9.71443178 L14.1701889,15 L15,14.1701889 Z"></path>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
    export default {};
</script>
