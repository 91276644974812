<template>
	<v-progress-linear :active="show" color="white" :indeterminate="true" height="10" />
</template>

<style lang="scss" scoped>
	@import '@/sass/_variables.scss';

	.v-progress-linear {
		-moz-transform: scale(1, -1);
		-webkit-transform: scale(1, -1);
		-o-transform: scale(1, -1);
		-ms-transform: scale(1, -1);
		transform: scale(1, -1);
		background-image: linear-gradient(to right, $green, yellow, $info);
	}
</style>

<script lang="ts">
	import Vue from 'vue';
	import Component from 'vue-class-component';
	import store from '@/store';

	@Component
	export default class Loader extends Vue {
		public show: boolean = false;
		public created() {
			store.watch((state: any) => state.loader.loading, (newVal: number, oldVal: number) => {
				this.show = (newVal !== 0 && oldVal === 0) || false;
			});
		}
	}
</script>
