import Cookies from 'js-cookie';

const KEY_SIG = 'id_sig';
const KEY_PAYLOAD = 'id_payload';
const KEY_REFRESH = 'r_tok';

let storage: Storage;
if (process.env.NODE_ENV === 'test')
	storage = { getItem() { /**/ }, setItem() { /**/ }, removeItem() { /**/ } } as any;
else
	storage = window.localStorage;

class TokenService {
	// Ignoring the expiry time of the passed cookie and using one day here
	// sameSite: 'strict' breaks Firefox when running locally; the OAUTH redirect seems to hide the cookie.
	private static cookieConfig: Cookies.CookieAttributes = { expires: 1, sameSite: 'lax' };

	public static getToken(): string {
		const sig = Cookies.get(KEY_SIG);
		const payload = storage.getItem(KEY_PAYLOAD);
		return sig && payload && `${payload}.${sig}` || null;
	}

	public static getRefreshToken() {
		return Cookies.get(KEY_REFRESH);
	}

	public static saveToken(token: { id: string, refresh: string }, exp: number) {
		// access_token -> not needed
		// refresh_token -> store in cookie (sensitive)
		Cookies.set(KEY_REFRESH, token.refresh, TokenService.cookieConfig);

		// id_token -> store signature in cookie (sensitive), the rest in session storage
		const sigSplit = token.id.lastIndexOf('.');
		const signature = token.id.substring(sigSplit + 1);
		Cookies.set(KEY_SIG, signature, TokenService.cookieConfig);
		const payload = token.id.substring(0, sigSplit);
		storage.setItem(KEY_PAYLOAD, payload);
	}

	public static removeToken() {
		Cookies.remove(KEY_REFRESH);
		Cookies.remove(KEY_SIG);
		storage.removeItem(KEY_PAYLOAD);
	}
}

export default TokenService;
