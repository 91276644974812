<template>
	<v-icon v-if="locked && showReason === undefined" small class="ma-0 pa-0 lock-icon">$lock</v-icon>
	<span v-else-if="locked && showReason !== undefined" small class="ma-0 pa-0">{{ reason }}</span>
	<v-icon v-else-if="shared" small class="ma-0 pa-0 share-icon">$share</v-icon>
</template>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { PumpDocMetaData } from 'types/dto/CalcServiceDomain';
	import SizingInfo from '@/common/SizingInfo';

	@Component
	export default class LockIcon extends Vue {
		@Prop() public model: PumpDocMetaData;
		@Prop() public showReason: boolean;

		public get locked() {
			return SizingInfo.isLocked(this.model);
		}

		public get shared() {
			return this.model?.Editors?.length > 0;
		}

		public get reason() {
			return SizingInfo.lockReason(this.model);
		}
	}
</script>
