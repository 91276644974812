import { render, staticRenderFns } from "./Navigation.vue?vue&type=template&id=0c8fb684&scoped=true"
import script from "./Navigation.vue?vue&type=script&lang=ts"
export * from "./Navigation.vue?vue&type=script&lang=ts"
import style0 from "./Navigation.vue?vue&type=style&index=0&id=0c8fb684&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c8fb684",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAppBar,VBtn,VIcon,VSpacer,VToolbarTitle,VTooltip})
