<template>
	<v-navigation-drawer v-model="open" clipped right app temporary disable-resize-watcher disable-route-watcher>
		<v-list-item v-if="loggedIn">
			<v-list-item-avatar>
				<v-icon :color="isInternal ? 'blue' : undefined" size="20px">$person</v-icon>
			</v-list-item-avatar>
			<v-list-item-title>{{ fullName }}</v-list-item-title>
		</v-list-item>
		<v-divider></v-divider>
		<v-list-item v-if="loggedIn" to="/dashboard" @click="open = false">
			<v-list-item-content>
				<v-list-item-title>{{ t('dashboard') }}</v-list-item-title>
			</v-list-item-content>
		</v-list-item>
		<v-list-item v-if="loggedIn" to="/performance" @click="open = false">
			<v-list-item-content>
				<v-list-item-title>{{ t('perfCurveViewer') }}</v-list-item-title>
			</v-list-item-content>
		</v-list-item>
		<v-list-item @click="handleLogout" v-if="loggedIn">
			<v-list-item-content>
				<v-list-item-title>{{ t('signOut') }}</v-list-item-title>
			</v-list-item-content>
		</v-list-item>
		<v-list-item @click="handleLogin" v-else>
			<v-list-item-content>
				<v-list-item-title>{{ t('signIn') }}</v-list-item-title>
			</v-list-item-content>
		</v-list-item>
		<v-divider></v-divider>
		<v-subheader>{{ t('version') }}</v-subheader>
		<v-list-item>
			<v-list-item-content>
				<v-list-item-title>{{ t('build') }}</v-list-item-title>
			</v-list-item-content>
			<v-list-item-action>
				<v-list-item-action-text v-text="VUE_APP_VERSION"></v-list-item-action-text>
			</v-list-item-action>
		</v-list-item>
		<v-divider></v-divider>
		<v-subheader>{{ $t('common.settings') }}</v-subheader>
		<v-list-item v-if="isDevloper && languages && languages.length > 1">
			<v-select dense outlined :items="languages" v-model="language" :hint="t('language')" persistent-hint>
				<template slot="append">
					<v-icon>mdi-chevron-down</v-icon>
				</template>
			</v-select>
		</v-list-item>
		<v-list-item v-if="locales && locales.length > 1">
			<v-select dense outlined :items="locales" v-model="locale" :hint="t('locale')" persistent-hint>
				<template slot="append">
					<v-icon>mdi-chevron-down</v-icon>
				</template>
			</v-select>
		</v-list-item>
		<v-list-item v-if="loggedIn">
			<v-select dense outlined :items="sous" v-model="systemOfUnit" :hint="$t('Site.SystemOfUnits')" persistent-hint>
				<template slot="append">
     				<v-icon>mdi-chevron-down</v-icon>
   			</template>
			</v-select>
		</v-list-item>
		<!-- <v-list-item v-if="loggedIn">
			<v-switch :disabled="forwardEmail == null" v-model="forwardEmail" persistent-hint hint="Send notifications as email" />
		</v-list-item> -->
		<template v-if="loggedIn">
			<v-divider style="margin-top: 12px"></v-divider>
			<v-subheader>Help and support</v-subheader>
			<v-list-item>
				<v-list-item-content>
					<a class="help-link allcaps" href="https://teams.microsoft.com/l/channel/19%3A7dfa1273e2274709a0e31e1a463c2c5c%40thread.skype/tab%3A%3A742d6d7f-5db8-4016-90b0-48322c62fc65?groupId=80bb6e84-5b01-4c5f-9587-926becd0a184&tenantId=5c5456f4-c402-40c5-a73d-e78777e7bf9e" target="pdkb">
						<v-icon>$enquiry</v-icon> Open knowledge base
					</a>
				</v-list-item-content>
			</v-list-item>
		</template>
	</v-navigation-drawer>
</template>

<style scoped>
	.help-link {
		font-size: 12px;
		text-decoration: none;
	}

	.help-link img {
		vertical-align: text-bottom;
	}
</style>

<script lang="ts">
	import Vue from 'vue';
	import AuthService from '@/services/auth.service';
	import MsgService from '@/services/messages.service';
	import store, { AuthActions, AuthGetters, MenuGetters, MenuActions, SnackActions } from '@/store';
	import { Component, Watch } from 'vue-property-decorator';
	import { ForwardingRule, ForwardType } from 'types/dto/Messages';
	import { locales, fallbackLocale, fallbackLanguage, languages, setLanguage, suggestLocale } from '@/i18n';

	@Component
	export default class Sidebar extends Vue {
		public readonly VUE_APP_VERSION: string = process.env.VUE_APP_VERSION;
		private forwards: ForwardingRule[] = [];

		public t(key: string) {
			return this.$t('app.' + key);
		}

		public get sous() {
			return [{ text: this.$t('SystemOfUnits.Metric'), value: 'Metric'}, { text: this.$t('SystemOfUnits.Imperial'), value: 'Imperial' }];
		}

		public created() {
			if (!this.loggedIn)
				this.language = (navigator.language || (navigator as any).userLanguage || fallbackLanguage).substring(0, 2);
		}

		public get open() {
			return store.get(MenuGetters.open);
		}

		public set open(open: boolean) {
			store.dispatch(open ? MenuActions.MENU_OPEN : MenuActions.MENU_CLOSE);
		}

		public get loggedIn() {
			return store.get(AuthGetters.loggedIn);
		}

		public handleLogout() {
			store.dispatch(AuthActions.logout);
			this.open = false;
		}

		public handleLogin() {
			AuthService.redirect();
			this.open = false;
		}

		public get systemOfUnit() {
			return store.get(AuthGetters.systemOfUnit);
		}

		public set systemOfUnit(sou) {
			if (sou)
				store.dispatch(AuthActions.update, { SystemOfUnit: sou });
		}

		public get isInternal() {
			return store.get(AuthGetters.internalUser);
		}

		public get languages() {
			return languages();
		}

		public get locales() {
			return locales();
		}

		public set language(language) {
			if (!this.isDevloper)
				return;

			setLanguage(language).then(() => {
				if (this.loggedIn)
					store.dispatch(AuthActions.update, { Language: language });
				this.locale = suggestLocale(this.language, this.locale, this.systemOfUnit === 'Imperial');
			}).catch(() => {
				store.commit(SnackActions.set, 'Failed to load language');
			});
		}

		public get language() {
			return this.$i18n.locale || fallbackLanguage;
		}

		public get locale() {
			return store.get(AuthGetters.locale) ?? fallbackLocale;
		}

		public set locale(l) {
			if (this.loggedIn)
				store.dispatch(AuthActions.update, { Locale: l });
		}

		public get fullName() {
			return store.get(AuthGetters.fullName);
		}

		public get forwardEmail(): boolean {
			if (!this.forwards?.length)
				return null;
			return this.forwards.find(x => x.Type === ForwardType.Email)?.Enabled || false;
		}

		public set forwardEmail(newVal: boolean) {
			if (newVal == null)
				return;
			const rule = this.forwards.find(x => x.Type === ForwardType.Email);
			if (!rule)
				return;

			rule.Enabled = newVal;
			MsgService.setForwarding(rule).then(r => {
				rule.Enabled = r.Enabled;
				rule.To = r.To;
				rule.Name = r.Name;
			});
		}

		public get isDevloper() {
			return store.get(AuthGetters.hasRole, 'developer') || false;
		}

		@Watch('open')
		public async onOpen(open: boolean) {
			if (open && this.loggedIn && !this.forwards?.length) {
				this.forwards = await MsgService.getForwards();
			}
		}
	}
</script>
