import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import { auth, AuthActions, AuthMuts, AuthGetters } from './modules/auth.store';
import { snackbar, SnackActions } from './modules/snackbar.store';
import { loader, LoaderMuts } from './modules/loader.store';
import { menu, MenuActions, MenuGetters } from './modules/menu.store';
import { network, NetworkActions, NetworkGetters, NetworkMuts } from './modules/network.store';
import { sizing, SizingActions, SizingMutations, SizingGetters } from './modules/sizing.store';
import { project, ProjectActions, ProjectGetters, ProjectMutations } from './modules/project.store';
import { debug, DebugActions, DebugGetters, DebugMutations } from './modules/debug.store';
import { messageStore, MsgActions, MsgGetters, MsgMuts } from './modules/msg.store';

Vue.use(Vuex);

class ExtendedStore extends Store<{}> {
	public get(name: any, getterArg?: any) {
		if (getterArg !== undefined)
			return this.getters[name](getterArg);
		return this.getters[name];
	}
}

const store = new ExtendedStore({
	modules: {
		auth,
		snackbar,
		loader,
		menu,
		network,
		sizing,
		project,
		debug,
		msg: messageStore
	},
	strict: process.env.NODE_ENV !== 'production'
});

export { SnackActions };
export { LoaderMuts };
export { AuthGetters, AuthActions, AuthMuts };
export { MenuActions, MenuGetters };
export { NetworkActions, NetworkGetters, NetworkMuts };
export { SizingActions, SizingMutations, SizingGetters };
export { ProjectActions, ProjectGetters, ProjectMutations };
export { DebugActions, DebugGetters, DebugMutations };
export { MsgActions, MsgGetters, MsgMuts };

export default store;
