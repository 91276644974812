import { render, staticRenderFns } from "./BeltDrive.vue?vue&type=template&id=4af99caa&scoped=true"
import script from "./BeltDrive.vue?vue&type=script&lang=ts"
export * from "./BeltDrive.vue?vue&type=script&lang=ts"
import style0 from "./BeltDrive.vue?vue&type=style&index=0&id=4af99caa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4af99caa",
  null
  
)

export default component.exports