import axios, { AxiosRequestConfig } from 'axios';
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';
import TokenService from './token.service';
import store, { SnackActions, LoaderMuts, AuthGetters, AuthActions, DebugMutations } from '@/store';
import { MessageSeverity } from 'types/dto/CalcServiceDomain';
import OnlineCheck from '@/common/OnlineCheck';

export default class ApiService {
	public static readonly onlineCheck = new OnlineCheck();

	public static defaultErrorHandler<T>(e: any): Promise<T> {
		// Bail out if network is offline: UI feedback for that is handled below, so don't clobber it here
		if (ApiService.onlineCheck.isOfflineResponse(e))
			throw e;

		const r = e?.response;
		store.commit(DebugMutations.add, {
			category: 'Network',
			entries: [{
				Severity: (r?.status === 401) ? MessageSeverity.Warning : MessageSeverity.Error,
				Source: 'Response',
				Message: r ? ('HTTP ' + r.status + ': ' + r.data) : e.message
			}]
		});

		if (r?.status === 400 && r.data && typeof r.data === 'string' && r.data.length)
			store.dispatch(SnackActions.set, r.data);
		else if (r?.status === 401 && r.config.url && (r.config.url.includes('/api/') || r.config.url.includes('servicedev/')))
			store.dispatch(SnackActions.set, 'Renewing authorization');
		else if (r?.status === 403)
			store.dispatch(SnackActions.set, 'You are not allowed to do this');
		else if (r?.status !== 429) // 429 is handled by callers ("Please try again")
			store.dispatch(SnackActions.set, e.message);
		return ApiService.unauthorizedHandler(e);
	}

	public static init(baseURL: string) {
		if (!window.navigator.onLine)
			ApiService.onlineCheck.goOffline();
		else
			ApiService.onlineCheck.goOnline();

		axios.defaults.baseURL = baseURL;
		axios.interceptors.request.use(config => {
			if (store.get(AuthGetters.loggedIn) && store.get(AuthGetters.hasRole, 'developer'))
				config.headers.common['x-eligo-debug'] = 'yes';
			store.commit(LoaderMuts.start);
			return config;
		}, error => {
			store.commit(LoaderMuts.finish);
			return Promise.reject(error);
		});

		axios.interceptors.response.use(response => {
			store.commit(LoaderMuts.finish);
			ApiService.onlineCheck.handleOfflineStatus(response);
			return response;
		}, error => {
			store.commit(LoaderMuts.finish);
			ApiService.onlineCheck.handleOfflineStatus(error);
			return Promise.reject(error);
		});

		axios.defaults.headers.common['Cache-Control'] = 'no-cache';
		axios.defaults.adapter = throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter,
			{ enabledByDefault: false, cacheFlag: 'useCache' }),
			{ threshold: 100 });
	}

	public static setHeader() {
		axios.defaults.headers.common.Authorization = `Bearer ${TokenService.getToken()}`;
	}

	public static removeHeader() {
		axios.defaults.headers.common = {};
	}

	public static get<T>(resource: string): Promise<T> {
		return axios.get<T>(resource)
			.then(response => response?.data as T)
			.catch<T>(this.defaultErrorHandler);
	}

	public static post<T>(resource: string, data: any, config?: AxiosRequestConfig) {
		return axios.post(resource, data, config)
			.then(response => response?.data as T)
			.catch<T>(this.defaultErrorHandler);
	}

	public static put<T>(resource: string, data: any, config?: AxiosRequestConfig) {
		return axios.put(resource, data, config)
			.then(response => response?.data as T)
			.catch<T>(this.defaultErrorHandler);
	}

	public static delete<T>(resource: string) {
		return axios.delete(resource)
			.then(response => response?.data as T)
			.catch<T>(this.defaultErrorHandler);
	}

	public static customRequest(data: AxiosRequestConfig) {
		return axios(data);
	}

	private static async unauthorizedHandler(error: any): Promise<any> {
		const url = error?.config?.url;
		if (!url)
			throw error;

		const tokenReq = url.includes('/authorization/token/') || url.includes('/authorization/refresh');
		const unauthorizedReq = error?.request?.status === 401;

		// Refresh token has failed (any status code). Logout the user
		if (tokenReq) {
			console.error(`Authorization failed for ${url}, bailing out...`, error);
			store.dispatch(AuthActions.logout);
			throw error;
		}

		if (unauthorizedReq) {
			console.debug(`Unauthorized request for ${url}, trying to refresh token...`);
			try {
				await store.dispatch(AuthActions.refresh);

				let headers: any;
				if (error.config.headers) {
					// Keep content-type and x-headers
					const ctype = error.config.headers['Content-Type'];
					if (ctype)
						headers = { 'Content-Type': ctype };
					const xHeaders = Object.keys(error.config.headers).filter(x => x?.toLowerCase().startsWith('x-'));
					if (xHeaders?.length) {
						headers = headers || {};
						xHeaders.forEach(xh => headers[xh] = error.config.headers[xh]);
					}
				}

				// Retry the original request
				return this.customRequest({
					method: error.config.method,
					url: error.config.url,
					data: error.config.data,
					headers
				}).then(response => response?.data);
			} catch (e) {
				console.error(`Refresh has failed ${(e as any)?.message} - rejecting the original request`);
				throw error;
			}
		}
		// Someting else is failing, throw the original exception
		console.error(`Refresh has failed ${error?.message} - rejecting request`);
		throw error;
	}
}
