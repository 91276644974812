import { render, staticRenderFns } from "./IconCollapse.vue?vue&type=template&id=fbc5c8a6"
import script from "./IconCollapse.vue?vue&type=script&lang=js"
export * from "./IconCollapse.vue?vue&type=script&lang=js"
import style0 from "./IconCollapse.vue?vue&type=style&index=0&id=fbc5c8a6&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports