<template>
	<div>
		<div v-if="!hasData" class="ma-2 grey--text">
			Awaiting particle data... (at least d80/d50 must be defined)
		</div>
		<div v-show="hasData" id="particleCurveArea" class="ma-2"></div>
		<v-btn small v-show="hasData" @click="download" style="margin-top: -12px; margin-bottom: 8px; float: right">Create PDF</v-btn>
	</div>
</template>

<script lang="ts">
import { loadScript } from '@/common/Tools';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import store, { AuthGetters, ProjectGetters, SizingGetters } from '@/store';
import { ParticleInfo, PumpDocument } from 'types/dto/CalcServiceDomain';
import Debounce from '@/common/Debounce';
import PSD, { ParticlePoint } from '@/common/PSD';
import InsightService from '@/services/insight.service';

@Component({
	components: {
	}
})
export default class ParticleCurve extends Vue {
	@Prop() public sizingId: string;

	private reload = new Debounce('ParticleCurveLoad', 1000, () => this.loadCurve());

	public get hasData() {
		const p = this.particles;
		if (!p)
			return false;
		return p.d50 > 0 || p.d80 > 0;
	}

	public get sizing() {
		return this.sizingId && store.get(SizingGetters.sizing, this.sizingId) as PumpDocument;
	}

	public get particles() {
		return this.sizing?.Data?.Particles;
	}

	public get isDevloper() {
		return store.get(AuthGetters.hasRole, 'developer') || false;
	}

	public async created() {
		if (this.hasData)
			this.loadCurve();
		InsightService.trackEvent('ParticleCurve:Open');
	}

	private get reloadTrigger() {
		return this.hasData && JSON.stringify(this.particles) || null;
	}

	@Watch('reloadTrigger')
	private onDataChange(val: string) {
		if (val)
			this.reload.trigger();
	}

	private async loadCurve() {
		await loadScript('https://cdnjs.cloudflare.com/ajax/libs/d3/5.12.0/d3.min.js');
		ParticleCurve.drawParticleCurve(this.particles, this.isDevloper);
	}

	private static drawParticleCurve(data: ParticleInfo, showExtras: boolean) {
		const d3 = (window as any).d3;
		if (!d3)
			return;

		// Clear old contents
		d3.select('#particleCurveSVG').remove();

		const psd = new PSD(data);
		if (psd.broken)
			return;

		// External settings
		const minX = psd.minX;
		const maxX = psd.maxX;
		const maxY = 100;
		const fullWidth = 430;
		const fullHeight = 400;

		// tslint:disable-next-line one-variable-per-declaration
		const marginLeft = 50, marginTop = 20, marginRight = 50, marginBottom = 50;
		const width = fullWidth - marginLeft - marginRight;
		const height = fullHeight - marginTop - marginBottom;
		const xScale = d3.scaleLog().domain([minX, maxX]).range([0, width]);
		const yScale = d3.scaleLinear().domain([0, maxY]).range([height, 0]);

		// Graph area
		const svg = d3
			.select('#particleCurveArea')
			.append('svg')
				.attr('id', 'particleCurveSVG')
				.attr('width', fullWidth)
				.attr('height', fullHeight)
			.append('g')
				.attr('transform', 'translate(' + marginLeft + ','  + marginTop + ')');

		const pts = psd.approximatedPoints;
		let xTicks = [minX, 10e-6, 40e-6, 100e-6, 200e-6, 500e-6, 1e-3, 5e-3, 10e-3, ...pts.map(p => p.x)];

		// Clip to max
		xTicks = xTicks.filter(x => x <= maxX);

		// Remove dupes
		xTicks = [...new Set(xTicks)];

		// Remove labels too close to each other
		xTicks = xTicks.filter(x => !xTicks.some(s => {
			if (s <= x)
				return false;
			const decis = Math.floor(Math.log10(s + x / 2) + 5);
			const dist = 1.3 + 0.15 * decis;
			return s / x < dist;
		})).map(x => 1e6 * x);

		// X axis
		const axisXScale = d3.scaleLog().domain([1e6 * minX, maxX * 1e6]).range([0, width]);
		svg.append('g')
			.attr('transform', 'translate(0,'  + height + ')')
			.call(d3.axisBottom(axisXScale)
				.tickValues(xTicks)
				.tickFormat(d3.format('.0f')));

		// X grid
		svg.append('g')
			.style('stroke', 'lightgrey')
			.style('opacity', '0.2')
			.attr('transform', 'translate(0,'  + height + ')')
			.call(d3.axisBottom(xScale)
				.ticks(10)
				.tickSize(-height)
				.tickFormat(''));

		// Y axis
		svg.append('g')
			.call(d3.axisLeft(yScale));

		// Y grid
		svg.append('g')
			.style('stroke', 'lightgrey')
			.style('opacity', '0.2')
			.call(d3.axisLeft(yScale)
				.ticks(50)
				.tickSize(-width)
				.tickFormat(''));

		// X input value markers
		pts.filter(p => p.name.startsWith('d')).forEach(p => {
			svg.append('line')
				.style('stroke', 'blue')
				.style('stroke-dasharray', '3 2')
				.attr('x1', xScale(p.x)).attr('y1', yScale(0)).attr('x2', xScale(p.x)).attr('y2', yScale(100) - 1);

			svg.append('text')
				.attr('x', xScale(p.x))
				.attr('y', yScale(100) - 5)
				.style('text-anchor', 'middle')
				.style('fill', 'blue')
				.attr('font-weight', 400)
				.attr('font-size', 10)
				.text(p.name);
		});

		// Y input value markers
		pts.filter(p => p.name.startsWith('<')).forEach(p => {
			svg.append('line')
				.style('stroke', 'green')
				.style('stroke-dasharray', '3 2')
				.attr('x1', xScale(minX)).attr('y1', yScale(p.y)).attr('x2', xScale(maxX) + 1).attr('y2', yScale(p.y));

			svg.append('text')
				.attr('x', xScale(maxX) + 4)
				.attr('y', yScale(p.y) + 4)
				.style('text-anchor', 'start')
				.style('fill', 'green')
				.attr('font-weight', 400)
				.attr('font-size', 10)
				.text(p.name);
		});

		// Labels
		const yText = 'Cumulative % passing';
		svg.append('text')
			.attr('transform', 'rotate(-90)')
			.attr('y', 0 - marginLeft + 4)
			.attr('x', 0 - height / 2)
			.attr('dy', '1em')
			.style('text-anchor', 'middle')
			.style('fill', 'black')
			.attr('font-weight', 400)
			.text(yText);

		const xText = 'Particle size (micron)';
		svg.append('text')
			.attr('transform', 'translate(' + (width / 2) + ','  + (height + marginTop + 16) + ')')
			.style('text-anchor', 'middle')
			.style('fill', 'black')
			.attr('font-weight', 400)
			.text(xText);

		const coords = psd.createCoords();
		const lineGen = d3.line()
			// .curve(d3.curveNatural)
			.x((d: any) => xScale(d.x))
			.y((d: any) => yScale(d.y));

		const curves = svg.append('g')
			.attr('fill', 'none')
			.attr('opacity', 0.7)
			.attr('stroke-width', 3);

		curves.append('path')
			.attr('stroke', 'black')
			.attr('d', lineGen(coords));

		if (showExtras) {
			const altPsd = new PSD({ ...data, Sub200micron: null });
			const coords2 = altPsd.createCoords();
			curves.append('path')
				.attr('stroke', 'red')
				.attr('stroke-width', 1)
				.attr('d', lineGen(coords2));
		}

		svg.selectAll('.dot')
			.data(pts)
			.enter()
			.append('circle')
			.attr('r', 3.5)
			.attr('cx', (p: ParticlePoint) => xScale(p.x))
			.attr('cy', (p: ParticlePoint) => yScale(p.y))
			.attr('fill', (p: ParticlePoint) => p.name.startsWith('<') ? 'green' : 'blue');

		const bad = psd.badPoints;
		if (bad?.length)
			svg.append('g')
				.attr('stroke', 'red')
				.attr('stroke-width', '1')
				.attr('fill', 'none')
				.selectAll('.dot')
					.data(bad)
					.enter()
					.append('circle')
					.attr('r', 6)
					.attr('cx', (p: ParticlePoint) => xScale(p.x))
					.attr('cy', (p: ParticlePoint) => yScale(p.y));
	}

	public async download() {
		InsightService.trackEvent('ParticleCurve:CreatePDF');
		const jsPDF = await import('jspdf');
		const svg2pdf = await import('svg2pdf.js');
		const pdfEngine = new jsPDF.jsPDF({ orientation: 'p', unit: 'px', format: [500, 500] });

		const sz = this.sizing;
		const proj = store.get(ProjectGetters.project, sz.ProjectId);
		pdfEngine.text('Particle distribution', 80, 40);
		pdfEngine.text(proj.Name, 80, 55);
		pdfEngine.textWithLink(sz.Name, 80, 70, { url: window.location.href + '?f=psd&v=1' });

		const curveEl = document.getElementById('particleCurveSVG');
		const doc = await svg2pdf.svg2pdf(curveEl, pdfEngine, { x: 35, y: 75, loadExternalStyleSheets: true });
		const filename = `particles_${proj.Name}_${sz.Name}`;
		doc.save(filename.replace(/[\\/:*?"<>|']/g, '_') + '.pdf');
	}
}
</script>
