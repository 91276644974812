<template>
	<v-tooltip v-if="show" bottom>
		<template v-slot:activator="{ on }">
			<div v-on="on" style="display: inline-block" @click.stop="update">
				<DeliveryReadinessIcon :dr="readinessCode" :loading="loading" bkg="#fff" class="center-flex ma-1" />
			</div>
		</template>
		<div>
			<b v-if="title">{{ title }}<br /></b>
			{{readinessDescr || readinessText}}
		</div>
		<div v-if="showAll" class="mt-1 mb-1">
			<div v-for="v of value" :key="`${v.AppliesTo} ${v.AppliesToId}`">
				<DeliveryReadinessIcon :dr="v.Value" style="margin-right: 8px" />{{v.AppliesToId}}
			</div>
		</div>
		<div v-if="false" style="font-style: italic">
			<hr class="mt-3"/>
			DEBUG:
			<div v-if="!!materialNumber">Used material #: {{materialNumber || 'none'}}</div>
			<div v-if="!!(impellerMaterial || caseMaterial)">Used impeller/casing: {{impellerMaterial || 'none'}} / {{caseMaterial || 'none'}}</div>
			<hr/>
			<div v-for="v of value" :key="`${v.AppliesTo} ${v.AppliesToId}`">
				{{v.AppliesTo}} {{v.AppliesToId}}: <b>{{v.Value}}</b>
			</div>
		</div>
	</v-tooltip>
</template>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop, Watch } from 'vue-property-decorator';
	import { DeliveryReadinessValue } from '@/services/pumps.service';
	import store, { AuthGetters } from '@/store';
	import DRSource, { EntryState, PartType } from '@/common/DRSource';
	import DeliveryReadinessIcon from '@/components/DeliveryReadinessIcon.vue';

	@Component({
		components: {
			DeliveryReadinessIcon
		}
	})
	export default class DeliveryReadiness extends Vue {
		@Prop() public id: string;
		@Prop() public pumpId: string;
		@Prop() public partType: PartType;
		@Prop() public impellerMaterial: string;
		@Prop() public caseMaterial: string;
		@Prop() public materialNumber: string;
		@Prop() public showAll: string;
		@Prop() public bkg: string;
		@Prop() public title: string;
		@Prop() public context: any;

		public value: DeliveryReadinessValue[] = null;
		public state: EntryState = EntryState.Waiting;
		public loadedid: string = null;
		private static readonly sources: { [pumpId: string]: DRSource } = { global: new DRSource() };

		public get readinessText() {
			const code = this.readinessCode;
			let text: string;
			switch (code) {
				case 'N/A':
					text = 'Unknown delivery readiness';
					break;
				case 'Loading':
					text = 'Fetching delivery readiness';
					break;
				case 'Failed':
					text = 'Failed to fetch delivery readiness';
					break;
				default:
					text = 'Delivery readiness ' + code;
					break;
			}
			return text;
		}

		public get readinessDescr() {
			const code = this.readinessCode;
			let text: string;
			switch (code) {
				case 'A':
					text = 'Standard offering';
					break;
				case 'B':
					text = 'Consult sales support';
					break;
				case 'C':
					text = 'Spare parts only';
					break;
				default:
					return null;
			}
			return 'Delivery readiness: ' + text;
		}

		public get show() {
			return store.get(AuthGetters.internalUser);
		}

		public get loading() {
			return Boolean(this.state === EntryState.Waiting || this.state === EntryState.Working);
		}

		public update() {
			if (this.id && !this.loading) {
				this.src.clear(this.partType || 'Pump', this.id);
				this.state = EntryState.Waiting;
				this.loadedid = null;
				this.value = null;
			}
		}

		public get readinessCode() {
			const dr = this.readiness;
			if (!dr?.length) {
				if (this.loading)
					return 'Loading';
				else if (this.state === EntryState.Failed)
					return 'Failed';
				return 'N/A';
			}

			const anySelection = Boolean(this.impellerMaterial || this.caseMaterial || this.materialNumber);
			if (anySelection) {
				const selected = [];

				if (this.partType == null || this.partType === 'Pump') {
					if (this.impellerMaterial) {
						const matches = dr.filter(x => x.AppliesTo === 'ImpellerMaterial' && x.AppliesToId === this.impellerMaterial);
						if (!matches.length)
							return 'N/A';
						selected.push(...matches);
					}
					if (this.caseMaterial)  {
						const matches = dr.filter(x => x.AppliesTo === 'CasingMaterial' && x.AppliesToId === this.caseMaterial);
						if (!matches.length)
							return 'N/A';
						selected.push(...matches);
					}
				} else if (this.partType === 'BearingAssembly') {
					if (this.materialNumber) {
						const matches = dr.filter(x => x.AppliesTo === 'MaterialNumber' && x.AppliesToId === this.materialNumber);
						if (!matches.length)
							return 'N/A';
						selected.push(...matches);
					}
				}  else if (this.partType === 'ShaftSeal') {
					selected.push(...dr);
				}

				// Use worst case of selected DRs
				return selected.map(x => x.Value).sort().reverse()[0];
			}

			// Use best case of all DRs if no selection is made
			return dr.map(x => x.Value).sort()[0];
		}

		public get readiness() {
			const id = this.id;
			if (!id || !store.get(AuthGetters.internalUser))
				return null;

			// Beware of short-circuiting && vs. adding reactive dep on loadedid; don't change order...
			if (this.loadedid === id && this.value?.length)
				return this.value;

			this.src.get(this.partType || 'Pump', this.pumpId || 'pump', id, this.context)
				.then(x => this.value = x)
				.catch(x => this.value = null)
				.finally(() => { this.state = this.getState(); this.loadedid = id; });
			return null;
		}

		private get src() {
			// Create pump local/global DR source depending on which parts are pump dependent
			if (this.partType === 'Flange') {
				const pid = this.pumpId;
				if (!DeliveryReadiness.sources[pid])
					DeliveryReadiness.sources[pid] = new DRSource();
				return DeliveryReadiness.sources[pid];
			}
			if (this.partType === 'DriveArrangement') {
				const pid = 'DR_' + Object.values(this.context ?? {}).join('_');
				if (!DeliveryReadiness.sources[pid])
					DeliveryReadiness.sources[pid] = new DRSource();
				return DeliveryReadiness.sources[pid];
			}
			return DeliveryReadiness.sources.global;
		}

		private getState(): EntryState {
			return this.src.state(this.partType || 'Pump', this.id);
		}

		public get contextJson() {
			return this.context ? JSON.stringify(this.context) : undefined;
		}

		@Watch('contextJson')
		public onContextChanged() {
			this.update();
		}
	}
</script>
