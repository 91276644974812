<template>
<svg width="9px" height="15px" viewBox="0 0 9 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="UI-Kit---NEW" stroke="none" stroke-width="1" fill="currentColor" fill-rule="evenodd">
        <g id="Icons" transform="translate(-192.000000, -256.000000)" fill="currentColor" fill-rule="nonzero">
            <g id="UI-024-Gated-Asset" transform="translate(192.000000, 256.000000)">
                <path d="M8,6 L8,3.5 C8,1.57 6.43,0 4.5,0 C2.57,0 1,1.571 1,3.5 L1,6 L0,6 L0,15 L9,15 L9,6 L8,6 Z M2,3.5 C2,2.122 3.122,1 4.5,1 C5.879,1 7,2.122 7,3.5 L7,6 L2,6 L2,3.5 Z M8,14 L1,14 L1,7 L8,7 L8,14 Z" id="Shape"></path>
                <path d="M4,8 L5,8 L5,9 L4,9 L4,8 Z M4,10 L5,10 L5,11 L4,11 L4,10 Z M4,12 L5,12 L5,13 L4,13 L4,12 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
    export default {};
</script>
