import { render, staticRenderFns } from "./ParamList.vue?vue&type=template&id=412d1742&scoped=true"
import script from "./ParamList.vue?vue&type=script&lang=ts"
export * from "./ParamList.vue?vue&type=script&lang=ts"
import style0 from "./ParamList.vue?vue&type=style&index=0&id=412d1742&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "412d1742",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VIcon,VList,VListGroup,VListItem,VListItemActionText,VListItemContent,VListItemTitle})
