import localforage from 'localforage';
import store, { SnackActions } from '@/store';
import { extendPrototype as extendPrototypeStartswith } from 'localforage-startswith';
import { extendPrototype as extendPrototypeGetitems } from 'localforage-getitems';

extendPrototypeStartswith(localforage);
extendPrototypeGetitems(localforage);

export const LocalStorageService = localforage.createInstance({
	name: 'eligo',
	version: 1.0,
	storeName: 'eligo-store'
});

LocalStorageService.setDriver([
	LocalStorageService.INDEXEDDB,
	LocalStorageService.WEBSQL,
	LocalStorageService.LOCALSTORAGE
]).catch(() => store.dispatch(SnackActions.set, `WELP - Failed to initiate storage!`));

export default { LocalStorageService };
