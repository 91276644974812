<template>
<div>
	<v-list-group sub-group :value="true" v-for="([pipe, idx, id], rowIdx) of sortedPipes" :key="id" class="pipe-list">
		<template v-slot:activator>
			<v-list-item-title>
				<div style="float: left; padding-top: 8px;">
					{{$t('paramGroups.Pipe')}} #{{rowIdx + 1}}
					<v-tooltip v-if="isClosestToPump(pipe)" bottom>
						<template v-slot:activator="{ on }">
							<img v-on="on" :src="require('@/assets/pump_icon.svg')" width="21" style="vertical-align: middle; margin-left: 6px; padding-bottom: 4px">
						</template>
						Closest to the pump
					</v-tooltip>
				</div>
				<div style="float: right">
					<!-- Status icon -->
					<v-tooltip v-if="hasError(pipe)" bottom>
						<template v-slot:activator="{ on }">
							<v-icon v-on="on" color="red" class="mx-2 mb-1 pt-1">cancel</v-icon>
						</template>
						Selection has errors
					</v-tooltip>
					<v-tooltip v-else-if="isComplete(pipe)" bottom>
						<template v-slot:activator="{ on }">
							<v-icon v-on="on" color="green" class="mx-2 mb-1 pt-1">check_circle</v-icon>
						</template>
						Selection completed
					</v-tooltip>
					<v-tooltip v-else bottom>
						<template v-slot:activator="{ on }">
							<v-icon v-on="on" color="orange" class="mx-2 mb-1 pt-1">error</v-icon>
						</template>
						More information needed
					</v-tooltip>

					<!-- Action buttons -->
					<v-tooltip v-if="enableParts" bottom>
						<template v-slot:activator="{ on }">
							<v-btn v-on="on" icon tabindex="-1" @click.stop="add(false, rowIdx)">
								<v-icon color="primary">publish</v-icon>
							</v-btn>
						</template>
						Insert part above
					</v-tooltip>
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-btn v-on="on" icon tabindex="-1" @click.stop="remove(idx)">
								<v-icon color="primary" x-small>$clear</v-icon>
							</v-btn>
						</template>
						Delete
					</v-tooltip>
				</div>
			</v-list-item-title>
		</template>
		<PipePartEditor v-if="pipe.Module" :prevPipe="getPrevPipe(idx)" :nextPipe="getNextPipe(idx)" :index="idx" :buildManager="buildManager"
			:values="values" :target="target" :locked="locked" :reverseFlow="location === 'inlet'" @changed="pipeChanged" />
		<PipeEditor v-else :index="idx" :values="values" :target="target" :locked="locked" @changed="pipeChanged" />
	</v-list-group>
	<v-list-item v-if="!locked">
		<v-list-item-content v-if="enableParts" class="px-2 grid">
			<v-btn :disabled="!canAddPart" @click="() => add(false)">Add pipe part</v-btn>
			<v-btn :disabled="!canAddPart" @click="() => add(true)">Add custom piping</v-btn>
		</v-list-item-content>
		<v-list-item-content v-else class="px-2 grid">
			<v-btn @click="() => add(true)">Add pipe</v-btn>
		</v-list-item-content>
	</v-list-item>
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { MessageSeverity, Pipe } from 'types/dto/CalcServiceDomain';
import { ParamBag } from '@/common/ParamBag';
import PipeEditor from '@/components/PipeEditor.vue';
import PipePartEditor from '@/components/PipePartEditor.vue';
import store, { AuthGetters, SizingActions } from '@/store';
import { PumpResult } from 'types/dto/PumpSearch';
import Debounce from '@/common/Debounce';
import BuildManager from '@/common/BuildManager';

@Component({
	components: {
		PipeEditor,
		PipePartEditor
	}
})
export default class PipeList extends Vue {
	@Prop() public values: ParamBag;
	@Prop() public target: string;
	@Prop() public locked: boolean;
	@Prop() public location: 'inlet' | 'outlet';
	@Prop() public buildManager: BuildManager;

	private forceReload = 0;
	getId(num: number) { return `PL_${this.location}_idx${num}_fr${this.forceReload}` }

	public get enableParts() {
		return store.get(AuthGetters.internalUser);
	}

	public get pipes(): Pipe[] {
		return this.values.get<Pipe[]>(this.target) || [];
	}

	public get sortedPipes(): Array<[pipe: Pipe, idx: number, id: string]> {
		const originalPipes = [...this.pipes];
		let sortedPipes: Pipe[];
		if (this.location === 'outlet')
			sortedPipes = originalPipes;
		else
			sortedPipes = [...originalPipes].reverse();
		return sortedPipes.map(p => [p, originalPipes.indexOf(p), this.getId(originalPipes.indexOf(p))]);
	}

	public async add(custom: boolean, beforeIdx?: number) {
		const newList = [...this.pipes];
		const newPipe = { Module: custom ? undefined : { messages: [] } } as Pipe;

		if (this.location === 'inlet')
			newList.reverse();

		newList.splice(beforeIdx ?? newList.length, 0, newPipe);

		if (this.location === 'inlet')
			newList.reverse();

		// Not awaiting set()) here since we are reloading the whole component right away, to avoid double search triggered...
		this.values.set(this.target, newList);
		this.forceReload++;
	}

	public targetPath(index: number, field?: string) {
		const fieldRef = field ? ('.' + field) : '';
		return `${this.target}[${index}]${fieldRef}`;
	}

	public async remove(idx: number) {
		const newList = this.pipes.filter((x, pipeIdx) => pipeIdx !== idx);
		const deletedPipeName = this.targetPath(idx);
		store.dispatch(SizingActions.clearAssumed, { sizingId: this.values.sizingId, prefix: deletedPipeName });
		this.values.set(this.target, newList);
		this.forceReload++;
	}

	public isClosestToPump(p: Pipe) {
		const pos = this.pipes?.indexOf(p);
		if (pos < 0)
			return false;
		return this.location === 'outlet' ? pos == 0 : pos == this.pipes?.length - 1;
	}

	public isLast(p: Pipe) {
		const pos = this.pipes?.indexOf(p);
		if (pos < 0)
			return false;
		return this.location === 'inlet' ? pos == 0 : pos == this.pipes?.length - 1;
	}

	public isComplete(p: Pipe) {
		return p?.Module?.name != null || p.Diameter > 0 && p.Length > 0 && p.RoughnessHeight > 0;
	}

	public hasError(p: Pipe) {
		return p?.Module?.messages?.some(x => x.Severity >= MessageSeverity.Error) === true;
	}

	public get canAddPart() {
		if (!this.pipes?.length)
			return true;
		const lastPipe = this.pipes[this.location === 'inlet' ? 0 : this.pipes.length - 1];
		return lastPipe.Length > 0 && (lastPipe.OutDiameter ?? lastPipe.Diameter) > 0 && !this.hasError(lastPipe);
	}

	public get pumpIndex() {
		const count = this.pipes?.length ?? 0;
		if (this.location === 'outlet')
			return 0;
		return count - 1;
	}

	public get pump() {
		const pump = this.values.get<PumpResult>('Pump');
		return (pump?.Id) ? pump : null;
	}

	public getPrevPipe(idx: number): Partial<Pipe> {
		if (this.location === 'outlet') {
			if (!this.pipes.length || idx === 0)
				return { OutDiameter: this.pump?.OutletDiameter };
			return this.pipes[idx - 1];
		}
		if (this.location === 'inlet') {
			if (!this.pipes.length || idx == this.pipes.length - 1)
				return { OutDiameter: this.pump?.InletDiameter };
			return this.pipes[idx + 1];
		}
	}

	public getNextPipe(idx: number): Partial<Pipe> {
		if (this.location === 'outlet') {
			if (!this.pipes.length || idx === this.pipes.length - 1)
				return undefined;
			return this.pipes[idx + 1];
		}
		if (this.location === 'inlet') {
			if (!this.pipes.length || idx == 0)
				return undefined;
			return this.pipes[idx - 1];
		}
	}

	private readonly saveThrottle = new Debounce('savePipes', 500, () =>
		store.dispatch(SizingActions.update, [ { sizing: this.values.currentSizing } ]));

	public pipeChanged(idx: number) {
		this.saveThrottle.trigger();
	}
}
</script>

<style lang="css">
.pipe-list {
	border-bottom: 1px solid rgb(0, 0, 0, 0.2);
	padding-bottom: 20px;
}

.pipe-list .v-list-group__header.v-list-item {
  padding: 0px 21px 0px 22px !important;
}

.pipe-list .v-list-item__content {
  padding: 10px 0px 0px 0px;
}

.pipe-list div.readonlyValue.pb-2 {
	line-height: 100%;
	margin-top: 8px;
	padding-bottom: 0 !important;
}

/* Darker text in disabled combo */
.pipe-list .v-select__selection.v-select__selection--comma.v-select__selection--disabled,
.pipe-list .theme--light.v-input--is-disabled input {
	color: #777;
}
</style>