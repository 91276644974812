import { ParticleInfo } from 'types/dto/CalcServiceDomain';

export interface ParticlePoint {
	name: string;
	x: number;
	y: number;
	approximated?: boolean;
}

export default class PSD {
	private m: number;
	private accFactor: number;
	private refX: number;
	private refY: number;
	public broken: boolean;

	constructor(private readonly data: ParticleInfo) {
		if (!data?.d50 && !data.d80) {
			this.broken = true;
			return;
		}
		const refs = this.approximatedPoints;
		const [d50, d80] = refs.slice(0, 2);
		this.refY = 50;
		this.adaptCurve(d50.x, d50.y, d80.x, d80.y);
	}

	public get definedPoints() {
		return this.candidates.filter(c => c.x > 0 && c.y > 0);
	}

	public get minX() {
		const min = Math.min(...this.approximatedPoints.map(p => p.x));
		return min < 10e-6 ? 1e-6 : 10e-6;
	}

	public get maxX() {
		const approxD100 = this.data.d100 || this.diameterAtPercent(99.5);
		return Math.max(approxD100, ...this.approximatedPoints.map(p => p.x));
	}

	public get approximatedPoints() {
		const data = this.data;
		if (!data)
			return [];

		const candidates = this.candidates;
		return candidates.map(c => {
			if (c.x > 0 && c.y > 0)
				return c;

			let approxX = c.x;
			if (c.name === 'd50' && data.d80 > 0)
				approxX = data.d80 * 0.414444;
			else if (c.name === 'd80' && data.d50 > 0)
				approxX = data.d50 / 0.414444;

			// Bad data; interpolated d50/d80 ends up higher than d100
			if (data.d100 > 0 && approxX > data.d100)
				return;

			if (approxX > 0) {
				const approxY = c.y || this.percentSmallerThan(approxX);
				if (approxY > 0)
					return { x: approxX, y: approxY, name: c.name + '*', approximated: true };
			}
		})
		.filter(c => c);
	}

	public get badPoints() {
		const approx = this.approximatedPoints;
		const bad: ParticlePoint[] = [];
		for (const p of approx.filter(pa => pa.y > 0)) {
			const calcY = this.percentSmallerThan(p.x);
			const errY = Math.abs(calcY - p.y);
			if (errY > 5)
				bad.push(p);
		}
		return bad;
	}

	public createCoords() {
		if (this.broken)
			return [];
		const start = Math.min(40e-6, this.refX);
		const end = this.maxX;
		const steps = 50;
		let step = (end - start) / steps;
		const coords: any[] = [];
		for (let ps = start; ps <= end; ps += step / steps) {
			const percent = this.percentSmallerThan(ps);
			if (percent > 0.01)
				coords.push({ x: ps, y: percent });
			step *= 1.15;
		}
		return coords;
	}

	private adaptCurve(meanX: number, meanY: number, targetX: number, targetY: number) {
		const partAtMeanDiameter = 1 - meanY / 100.0;
		const partAtTargetDiameter = 1 - targetY / 100.0;

		// Factor for accumulated area of "reference" diameter
		this.accFactor = Math.log(1 / partAtMeanDiameter);
		this.m = Math.log(-Math.log(partAtTargetDiameter) / this.accFactor) / Math.log(targetX / meanX);
		this.refX = meanX;
		if (this.m < 0 || isNaN(this.m) || !isFinite(this.m))
			this.broken = true;
	}

	private percentSmallerThan(x: number) {
		if (this.broken)
			return null;
		const m = this.mValue(x);
		return (1 - Math.exp(this.accFactor * Math.pow(x / this.refX, m) * -1)) * 100;
	}

	private diameterAtPercent(percent: number) {
		if (this.broken)
			return null;
		return this.refX * Math.exp(Math.log(-Math.log(1 - percent / 100) / this.accFactor) / this.m);
	}

	private mValue(particleSize: number) {
		const sub200 = this.data.Sub200micron;
		if (sub200 > 0 && particleSize >= 40e-6 && particleSize < this.refX) {
			// Special m if sub200 is entered by user and sub200/d50 slope is defined and positive. See Fractions.cs.
			if (this.refX < 200e-6 && sub200 > this.refY || this.refX > 200e-6 && sub200 < this.refY)
				return Math.log(-Math.log(1 - sub200 / 100.0) / this.accFactor) / Math.log(200e-6 / this.refX);
		}
		return this.m;
	}

	private get candidates(): ParticlePoint[] {
		const candidates = [
			{ name: 'd50', x: this.data.d50, y: 50 },
			{ name: 'd80', x: this.data.d80, y: 80 },
			{ name: '< 40', x: 40e-6, y: this.data.Sub40micron },
			{ name: '< 200', x: 200e-6, y: this.data.Sub200micron },
			{ name: 'd100', x: this.data.d100, y: 99.9 },
		];
		return candidates;
	}
}
