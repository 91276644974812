import { ParameterDef, PumpDocument } from 'types/dto/CalcServiceDomain';
import UnitValue from './UnitValue';
import { ParamBag } from './ParamBag';
import { tryToTranslate } from '@/i18n';

export function formatField(name: string): string {
	if (!name)
		return '';

	// Translate sizing metadata props. Fix up old logs that called them "Meta.".
	if (name.startsWith('Meta.') || name.startsWith('Sizing'))
		return tryToTranslate(name.replace('Meta.', 'Sizing.')) || name.split('.')[1];

	const def = getDef(name);
	if (!def)
		return name;

	if (def.Name.startsWith('Pipe.')) {
		// Hack on index on pipes...
		const idx = name.indexOf('[');
		let prefix = tryToTranslate('paramGroups.Pipe') + ' ';
		if (idx > 0)
			prefix += '#' + (parseInt(name.substr(idx + 1, 1), 10) + 1) + ' ';
		return prefix + '- ' + tryToTranslate('Pipe' + name.substr(name.indexOf(']') + 1));
	}

	if (def.Type === 'Object' && !def.Name.includes('.')) {
		// Translate "root" objects by guessing their display name property
		const objName =
			tryToTranslate(`${def.Name}.Description`) ||
			tryToTranslate(`${def.Name}.DisplayName`) ||
			tryToTranslate(`paramGroups.${def.Name}`);
		if (objName)
			return objName;
	}

	return tryToTranslate(def.Name) || def.Description || def.Name;
}

export function formatValue(name: string, value: any, useImperial: boolean): string {
	if (!value)
		return '';

	const def = name && getDef(name);
	if (!def || def.Type === 'Object')
		return value;
	return renderValue(def, value, useImperial);
}

function getDef(defName: string) {
	if (!defName)
		return null;

	// Hack: fix array refs since we only have the _value_ name, not the _definition name_
	// I.e. Inlet.Pipes[0].Diameter uses def "Pipe.Diameter"...
	const arrEndIdx = defName.indexOf(']');
	if (arrEndIdx >= 0) {
		// Make a guess that this is a pipe. Fail below if not.
		defName = 'Pipe' + defName.substring(arrEndIdx + 1);
	}
	return ParamBag.getDefinition(defName);
}

function renderValue(def: ParameterDef, value: any, useImperial: boolean): string {
	const unitId = (useImperial && def.PrefImperialUnit) || def.PreferredUnit || def.Unit;
	const uv = new UnitValue(value, unitId, def.Decimals, useImperial);
	const xlateSection = def.Type !== 'Number' ? def.Unit : null;
	return uv.toString(xlateSection);
}

export function cleanCopyForSend(sizing: PumpDocument) {
	if (!sizing)
		return null;
	const clone = JSON.parse(JSON.stringify(sizing)) as PumpDocument;

	// Remove read-only data
	clone.Status = undefined;
	clone.Created = undefined;
	clone.CreatedBy = undefined;

	// Remove empty sections since they are created by getParam when traversing param paths or
	// when all properties of a section are set to null. Empty section objects cause non-nullable
	// fields (double etc) to materialize in the backend and be written. (i.e. empty pump/motor zombies)
	for (const k of Object.keys(clone.Data || {})) {
		const value = (clone.Data as any)[k];
		if (value && typeof (value) === 'object' && (!Object.keys(value).length || !Object.values(value).some(x => x != null)))
			delete (clone.Data as any)[k];
	}
	return clone;
}
