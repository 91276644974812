import ApiService from './api.service';
import { CopySizingInputs, PumpDocument, PumpProject, ParameterDef, PumpDocMetaData, StagingDefinition, SystemCurveInputs, SystemCurveResult } from 'types/dto/CalcServiceDomain';
import { SizingSearchResults } from 'types/dto/SizingSearch';
import store, { DebugActions } from '@/store';
import { AxiosRequestConfig } from 'axios';
import InsightService from './insight.service';
import { compareDocs, sendConflictEvent } from '@/common/compareDocs';
import { cleanCopyForSend } from '@/common/ParamTools';
import ProjectFilter from '@/common/ProjectFilter';

class SizingService {
	private readonly API = process.env.VUE_APP_CALCSERVICE_ENDPOINT;

	public fetchSizing(id: string) {
		return ApiService.get<PumpDocument>(this.API + '/sizing/' + encodeURIComponent(id));
	}

	public getParameterDefs() {
		return ApiService.get<ParameterDef[]>(this.API + '/parameters');
	}

	public getProjectParameterDefs() {
		return ApiService.get<ParameterDef[]>(this.API + '/projectparams');
	}

	public getProjects(filter?: ProjectFilter) {
		let url = `${this.API}/projects?category=${filter?.shared ? 'Shared' : 'Mine'}`;
		if (filter?.createdAfter)
			url += `&createdAfter=${filter.createdAfter.toISOString()}`;
		return ApiService.get<PumpProject[]>(url);
	}

	public getProject(projectId: string) {
		return ApiService.get<PumpProject>(this.API + '/project/' + encodeURIComponent(projectId));
	}

	public getProjectSizings(projectId: string) {
		return ApiService.get<PumpDocMetaData[]>(this.API + '/project/' + encodeURIComponent(projectId) + '/sizings');
	}

	public getProjectPumps(projectId: string) {
		return ApiService.get<{[id: string]: string}>(this.API + '/project/' + encodeURIComponent(projectId) + '/sizings/Pump.DisplayName');
	}

	public getRecentSizings() {
		return ApiService.get<PumpDocMetaData[]>(this.API + '/sizings/recent');
	}

	public search(text: string, searchShared?: boolean) {
		let url = this.API + '/search?q=' + encodeURIComponent(text);
		if (searchShared)
			url += '&shared=true';
		return ApiService.get<SizingSearchResults>(url);
	}

	public async update(batch: Array<{ sizing: PumpDocument, target?: string, newValue?: string }>) {
		let config: AxiosRequestConfig;
		if (batch[0].target) {
			config = { headers: { 'X-change-target': batch[0].target } } as AxiosRequestConfig;
			if (batch[0].newValue)
				config.headers['X-change-new-value'] = SizingService.headerSafeString(batch[0].newValue);
		}

		const payload = batch.map(x => cleanCopyForSend(x.sizing));
		const res = await ApiService.post<PumpDocument[]>(this.API + '/sizing', payload, config).catch(error => {
			const resp = error?.response;

			// tslint:disable-next-line: triple-equals
			if (resp?.status == 409) {
				InsightService.trackEvent('Sizing:Conflict');
				const newVersion = (resp.data || {}) as PumpDocument;
				const sizing = batch.find(x => x.sizing.id === newVersion.id).sizing;
				const changedNames = compareDocs([sizing], [newVersion]);
				sendConflictEvent({
					mode: 'Conflict',
					me: [{ ...sizing, id: 'me' }],
					other: [{ ...newVersion, id: 'new' }],
					changedNames
				});
			}
			throw error;
		});
		store.dispatch(DebugActions.replace, { category: 'Sizing', entries: res?.length ? res[0].Status : [] });
		return res;
	}

	public async clone(sizing: PumpDocument, newName: string, targetProjectId?: string): Promise<PumpDocument[]> {
		const copydata: CopySizingInputs = {
			TargetProjectId: targetProjectId || sizing.ProjectId,
			SizingId: sizing.id,
			NewName: newName
		};
		return await ApiService.post<PumpDocument[]>(this.API + '/copy', copydata);
	}

	public delete(id: string) {
		return ApiService.delete(this.API + '/sizing/' + encodeURIComponent(id));
	}

	public createProject(name: string, custRef: string) {
		return ApiService.post<PumpProject>(this.API + '/project', { Name: name, CustomerId: custRef  });
	}

	public updateProject(project: PumpProject, target?: string, newValue?: string) {
		const clone = JSON.parse(JSON.stringify(project)) as PumpProject;

		// Remove read-only data from server
		clone.Created = undefined;
		clone.CreatedBy = undefined;

		let config: AxiosRequestConfig;
		if (target) {
			config = { headers: { 'X-change-target': target } } as AxiosRequestConfig;
			if (newValue)
				config.headers['X-change-new-value'] = SizingService.headerSafeString(newValue);
		}

		return ApiService.put<PumpProject>(this.API + '/project', clone, config);
	}

	public becomeEditor(currentOwnerId: string, newEditorId: string) {
		return ApiService.post<number>(this.API + `/projects/becomeeditor`, { currentOwnerId, newEditorId });
	}

	public deleteProject(id: string): any {
		return ApiService.delete(this.API + '/project/' + encodeURIComponent(id));
	}

	public stage(id: string, def: StagingDefinition) {
		return ApiService.post<PumpDocument[]>(this.API + '/staging/' + encodeURIComponent(id), def);
	}

	public unstage(id: string) {
		return ApiService.delete<PumpDocument>(this.API + '/staging/' + encodeURIComponent(id));
	}

	public addDutyPoint(id: string) {
		return ApiService.post<PumpDocument[]>(this.API + '/dutypoint/' + encodeURIComponent(id), undefined);
	}

	public removeDutyPoint(id: string) {
		return ApiService.delete<PumpDocument[]>(this.API + '/dutypoint/' + encodeURIComponent(id));
	}

	public systemCurve(def: SystemCurveInputs) {
		return ApiService.post<SystemCurveResult>(this.API + '/systemcurve', def);
	}

	private static headerSafeString(s: string) {
		if (!s?.length)
			return '';
		return s.replace(/[\r\n\t\v ]+/g, ' ').trim().substring(0, 200);
	}
}
export default new SizingService();
