import ApiService from './api.service';
import TokenService from './token.service';
import AuthService from './auth.service';
import InsightService from './insight.service';
import AuthenticationError from '@/store/modules/auth.error';
import { UserInfoResponse, AccessTokenResponse } from 'types/dto/AccessToken';

class UserService {
	private readonly API = process.env.VUE_APP_AUTHSERVICE_ENDPOINT;

	private authErrorHandler(error: any) {
		TokenService.removeToken();
		let statusCode = '400';
		let statusText = error.message || 'Unknown error';
		const resp = error.response;
		if (resp) {
			statusCode = resp.status || statusCode;
			statusText = error.response.statusText || statusText;
		}
		throw new AuthenticationError(statusCode, statusText);
	}

	/**
	 * Callback of the login of the user. Store the access token to TokenService.
	 *
	 * @returns access_token
	 * @throws AuthenticationError
	 */
	public async callback(grant: string): Promise<AccessTokenResponse> {
		return AuthService.accessToken(grant)
			.then((response) => {
				TokenService.saveToken({
					id: response.id_token,
					refresh: response.refresh_token
				}, response.expires_in);
				// Set global header
				ApiService.setHeader();
				return response;
			})
			.catch<any>(this.authErrorHandler);
	}

	/**
	 * Refresh the access token.
	 */
	public async refreshToken(): Promise<string> {
		const refreshToken = TokenService.getRefreshToken();
		if (!refreshToken)
			this.authErrorHandler({ message: 'No refresh token provided' });
		return AuthService.refreshToken(refreshToken)
			.then((response) => {
				InsightService.trackEvent('Auth:RefreshToken', response);
				TokenService.saveToken({
					id: response.id_token,
					refresh: response.refresh_token
				}, response.expires_in);
				// Update the global header
				ApiService.setHeader();
				return response.access_token;
			})
			.catch<any>(this.authErrorHandler);
	}

	public async userInfo(): Promise<UserInfoResponse> {
		return AuthService.userInfo()
			.catch<any>(this.authErrorHandler);
	}

	/**
	 * Logout the current user by removing the token from storage.
	 */
	public logout() {
		InsightService.trackEvent('Auth:Logout');
		AuthService.logout();
	}

	/**
	 * Update profile settings for the logged in user.
	 * @param user
	 */
	public async update(user: any) {
		InsightService.trackEvent('Auth:UpdateUser', user);
		return ApiService.post<UserInfoResponse>(this.API + '/authentication/userinfo/', user);
	}
}

export default new UserService();
