<template>
<svg width="8px" height="6px" viewBox="0 0 8 6" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="UI-Kit---NEW" stroke="none" stroke-width="1" fill="currentColor" fill-rule="evenodd">
        <g id="Icons" transform="translate(-333.000000, -406.000000)" fill="currentColor" fill-rule="nonzero">
            <g id="UI-039-field-valid" transform="translate(333.000000, 406.000000)">
                <polygon id="Path" points="6.401 0 2.754 3.647 0.707 1.601 3.33066907e-16 2.308 2.754 5.061 7.108 0.708"></polygon>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
    export default {};
</script>
