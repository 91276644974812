<template>
	<div>
		<v-btn v-if="!show" @click="show = true">Admin menu</v-btn>
		<v-card v-else outlined class="mt-6" style="min-width: 700px">
			<v-row class="pl-4">
				<v-col class="pt-4 mb-0">
					<h2>Admin operations</h2>
				</v-col>
			</v-row>
			<v-row class="pl-4">
				<v-col cols="3">
					<v-btn :disabled="!grantEditorValid || working" class="mt-2" @click="grantEditor">Grant editor</v-btn>
				</v-col>
				<v-col cols="4">
					<UserSelector :disabled="working" v-model="ownerUser" label="in all projects of user"/>
				</v-col>
				<v-col cols="4">
					<UserSelector :disabled="working" v-model="newUser" label="to user"/>
				</v-col>
			</v-row>
		</v-card>
		<Confirm ref="confirm" />
	</div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import UserSelector, { IUserEntry } from '@/components/UserSelector.vue';
import Confirm from '@/components/Confirm.vue';
import store, { ProjectActions, SnackActions } from '@/store';
import InsightService from '@/services/insight.service';

@Component({
	components: {
		UserSelector,
		Confirm
	}
})
export default class AdminBox extends Vue {
	public show = false;
	public working: boolean = false;
	public ownerUser: IUserEntry = null;
	public newUser: IUserEntry = null;

	public get grantEditorValid() {
		return this.ownerUser != null && this.newUser != null && this.ownerUser.id !== this.newUser.id;
	}

	public async grantEditor() {
		const dialog: any = this.$refs.confirm;
		const msg = `Do you want to make ${this.newUser.DisplayName} editor in all projects created by ${this.ownerUser.DisplayName}?`;
		const sure = await dialog.open(msg, { width: 500 });
		if (!sure)
			return;

		const payload = { currentOwnerId: this.ownerUser.id, newEditorId: this.newUser.id };
		try {
			this.working = true;
			const changed = await store.dispatch(ProjectActions.becomeEditor, payload);
			store.dispatch(SnackActions.set, `Changed ${changed ?? 0} projects`);
		} catch (err) {
			InsightService.trackEvent('Project:Error', payload);
			store.dispatch(SnackActions.set, 'Failed to update projects');
		} finally {
			this.working = false;
		}
	}
}
</script>
