import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import About from './views/About.vue';
import Dashboard from './views/Dashboard.vue';
import Project from './views/Project.vue';
import Pumps from './views/Pumps.vue';
import Performance from './views/Performance.vue';
import BearingAssemblies from './views/BearingAssemblies.vue';
import Health from './views/Health.vue';
import store, { AuthGetters, AuthActions } from '@/store';
import InsightService from './services/insight.service';

Vue.use(Router);
const router = new Router({
	base: process.env.BASE_URL,
	routes: [
		{ path: '/', name: 'home', component: Home, meta: { public: true } },
		{ path: '/about', name: 'about', component: About, meta: { public: true } },
		{ path: '/dashboard', name: 'dashboard', component: Dashboard },
		{ path: '/project/:id', name: 'project', component: Project, props: true },
		{ path: '/project/:id/:sizingid', name: 'sizing', component: Project, props: true },
		{ path: '/sizing/:sizingid', name: 'directsizing', component: Project, props: true },
		{ path: '/pumps', name: 'pumps', component: Pumps },
		{ path: '/pumps/:id', name: 'pump', component: Pumps, props: true },
		{ path: '/bas', name: 'badefs', component: BearingAssemblies },
		{ path: '/bas/:id', name: 'badef', component: BearingAssemblies, props: true },
		{ path: '/health', name: 'health', component: Health },
		{ path: '/logout', name: 'logout', component: Home },
		{ path: '/performance', name: 'performance', component: Performance },
		{ path: '/performance/:id', name: 'performancePump', component: Performance, props: true },
	],
});

router.beforeEach((to, from, next) => {
	const isPublic = to.matched.some(record => record.meta.public);
	const loggedIn = !!store.get(AuthGetters.loggedIn);

	if (!loggedIn && to.query?.code) {
		// We got a code=xxxxx from Metso login
		store.dispatch(AuthActions.login, { code: to.query?.code }).then(() => {
			if (to.path && to.path !== '/') {
				// Metso login passed a subpage redirect; do it now when login is done. Strip 'code=xxxxx' and avoid '//'.
				const targetPath = (to.path || '/').replace('//', '/');
				InsightService.trackEvent('Auth:DirectLink', targetPath);
				router.push(targetPath);
			} else
				router.push('/dashboard');
		});
		return next({ name: 'home' });
	}

	if (!isPublic && !loggedIn) {
		return next({
			path: '/',
			query: { redirect: to.fullPath }
		});
	}

	if (loggedIn) {
		if (to.name === 'home')
			return next('/dashboard');
		else if (to.name === 'logout') {
			store.dispatch(AuthActions.logout);
			return next(window.location.origin);
		}
	}
	next();
});

export default router;
