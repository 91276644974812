/**
 * Writes performance summary to a DOM element
 */
export function PerformancePreview(data: any, element: string) {
    setTimeout(() => {
        const iframe = document.getElementById(element) as HTMLIFrameElement;
        let contentDoc = (iframe.contentWindow || iframe.contentDocument);
        if ((contentDoc as Window).document)
            contentDoc = (contentDoc as Window).document;
        contentDoc.open();
        (contentDoc as Document).write(data);
        contentDoc.close();

        // Adjust frame to contents when loaded
        setTimeout(() => {
            if ((contentDoc as any).body && (contentDoc as any).body.clientHeight > 1000) {
                const height = (contentDoc as any).body.clientHeight;
                iframe.height = (height + 50).toString();
            }
        }, 500);
    }, 50);
}