<template >
	<v-dialog v-model="visible" max-width="600px" persistent @keydown.esc="closeDialog">
		<v-form ref="sizingForm" @submit.prevent="createStaging" v-model="valid">
			<v-card class="pl-0 pt-2 pb-5">
				<v-card-title  class="pl-8 pt-6">
					<span v-if="!updating && tdh > maxpdh" class="headline"><h2>Warning! Pump staging recommended.</h2></span>
					<span v-else class="headline"><h2>Configure pump staging</h2></span>
					<h5>Max head per pump stage for this service class is {{ renderHead(maxpdh || 0) }}.</h5>
				</v-card-title>
				<v-card-text class="pb-0">
					<div class="pl-4 pb-2">
						<span v-if="!updating">Recommended stages: <b v-text="recommendedStages"/>.</span>
						<template v-if="remainingTDH">
							Remaining TDH to distribute: <b :class="{ 'red--text': remainingTDH < 0 }">{{ renderHead(remainingTDH) }}</b>
						</template>
						<SimpleWarnings v-if="stagingErrors" :msgs="stagingErrors"  />
					</div>
					<v-list dense>
						<v-list-item v-for="(stage, idx) of editableStages" :key="stage.id" dense>
							<v-list-item-content>
								<UnitNumeric :param="getParam(stage)" writable="true" :customDescription="stage.name" />
							</v-list-item-content>
							<v-list-item-action>
								<v-tooltip bottom class="tool-tip" v-if="stage.PDH > maxpdh" color="warning" max-width="500" open-delay="500">
									<template v-slot:activator="{ on }">
										<v-icon class="action-icon" v-on="on" color="warning">error</v-icon>
									</template>	
									<span>Stage PDH exceeds service class max head</span>
								</v-tooltip>
								<v-tooltip bottom class="tool-tip" v-else max-width="500" open-delay="500">
									<template v-slot:activator="{ on }">			
											<v-icon class="action-icon ml-1" size="17" v-on="on" :disabled="stage.fixed" @click="removeStage(stage)">$clear</v-icon>									
									</template>
									<span>Remove stage</span>
								</v-tooltip>
							</v-list-item-action>
						</v-list-item>
						<v-list-item>
							<v-list-item-content>
								<v-row>
									<v-col>
										<v-btn v-if="!updating" dense @click="addStage(null)">Add stage</v-btn>
									</v-col>
									<v-col></v-col>
									<v-col>
										<v-btn dense @click="redistribute()">Redistribute TDH</v-btn>
									</v-col>
								</v-row>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-card-text>
				<v-card-actions class="mr-6">
					<v-spacer></v-spacer>
					<v-btn outlined color="error" @click="closeDialog">{{ updating ? 'Cancel' : 'No staging' }}</v-btn>
					<v-btn text :class="[canCreate ? 'focus':'']" @click="createStaging" :disabled="!canCreate">
						{{ updating ? 'Update' : 'Continue with ' + stages.length + ' stages' }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>

<style lang="scss" scoped>

	.tool-tip {
		cursor: help;
	}
	.action-icon {
		margin-bottom: 30px;
	}

</style>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { PumpDocument, PumpStage, MessageSeverity, StagingErrorSource } from 'types/dto/CalcServiceDomain';
	import store, { SizingActions, SizingGetters, DebugActions } from '@/store';
	import UnitNumeric from '@/components/Fields/UnitNumeric.vue';
	import SimpleWarnings from '@/components/SimpleWarnings.vue';
	import { ParamBag } from '@/common/ParamBag';
	import BuildManager from '@/common/BuildManager';

	interface StageDef extends PumpStage {
		id: string;
		name: string;
		PDH: number;
		fixed?: boolean;
	}

	@Component({
		components: {
			UnitNumeric,
			SimpleWarnings
		}
	})
	export default class StagingDialog extends Vue {
		@Prop() public sizing: PumpDocument;
		@Prop() public tdh: number;
		@Prop() public maxpdh: number;

		public visible: boolean = false;
		public valid: boolean = true;
		public stages: StageDef[] = [];
		private bags: { [key: string]: ParamBag } = {};

		public mounted() {
			this.visible = true;
		}

		public PDHrules(value: number | string) {
			if (value <= 0)
				return 'A positive, non-zero value is required';
			if (value && parseFloat(value as string) > this.tdh)
				return 'Value must be less than TDH';
			return true;
		}

		get useImperial() {
			return ParamBag.useImperial(this.sizing?.id);
		}

		public renderHead(head: number) {
			if (this.useImperial)
				return (head * 3.280839895).toFixed(1) + ' ft';
			return head.toFixed(1) + ' m';
		}

		public get PDHsum() {
			if (!this.stages || !this.stages.length)
				return 0;
			return this.stages.map(x => x.PDH && parseFloat(x.PDH.toString().replace(',', '.')) || 0).reduce((prev, cur) => prev + cur);
		}

		public get remainingTDH() {
			return (this.tdh || 0) - this.PDHsum;
		}

		public get canCreate() {
			let valid = true;
			if (!this.stages || !this.stages.length)
				valid = false;
			else {
				this.stages.forEach(stage => {
					const result = this.PDHrules(stage.PDH);
					if (result === true)
						this.bags[stage.id].variants[0].Status = [];
					else {
						this.bags[stage.id].variants[0].Status = [{ Source: 'StagingDialog', Severity: MessageSeverity.Error, ParamName: 'Heads.PDH', Message: result }];
						valid = false;
					}
				});
			}
			this.valid = valid;
			return valid && this.stages.length >= 2 && Math.abs(this.remainingTDH) < 0.05;
		}

		public get recommendedStages() {
			return Math.min(20, Math.max(2, Math.ceil(this.tdh / this.maxpdh)));
		}

		public get updating() {
			return !!this.sizing?.Staged;
		}

		public get existingStages() {
			return [this.sizing, ...store.get(SizingGetters.childSizings, this.sizing.id) as PumpDocument[]];
		}

		public get stagingErrors() {
			const msgs = this.sizing.Status?.filter(x => x.Source === StagingErrorSource && x.Severity >= MessageSeverity.Warning);
			return msgs?.length ? msgs : null;
		}

		public get editableStages() {
			// No TDH yet
			if (!this.tdh)
				return [];

			if (!this.stages.some(x => x.PDH == null)) {
				// Let user continue editing
				if (this.visible)
					return this.stages;

				// Dialog is closed - keep staging if the sum is correct
				if (this.canCreate)
					return this.stages;
			}

			if (this.updating) {
				this.existingStages.forEach((s: PumpDocument) => this.addStage(s.Data?.Heads?.PDH || 0, s.id));
			} else {
				// Reset to default stage setup
				this.stages = this.stages.slice(0, 2);
				if (!this.stages.length) {
					const pdh = Math.floor(10 * this.tdh / this.recommendedStages) / 10.0;
					for (let i = 0; i < this.recommendedStages; i++)
						this.addStage(i < (this.recommendedStages - 1) ? pdh : null);
				}
			}
			return this.stages;
		}

		public addStage(pdh?: number, stageId?: string) {
			const index = this.stages.length + 1;
			const stage = {
				id: stageId || Math.random().toString(),
				name: `Stage ${index} PDH`,
				PDH: pdh || Math.floor(100 * this.remainingTDH) / 100.0,
				fixed: index < 3 || stageId != null
			} as StageDef;
			this.stages.push(stage);
			Vue.set(this.bags, stage.id, new ParamBag([{ Data: { Heads: stage }, Status: [] } as any as PumpDocument]));
		}

		public removeStage(stage: StageDef) {
			this.stages = this.stages.filter(x => x.id !== stage.id);
		}

		public redistribute() {
			const pdh = Math.floor(100 * this.tdh / this.stages.length) / 100.0;
			this.stages.forEach(stage => this.bags[stage.id].set('Heads.PDH', pdh));
		}

		public async createStaging() {
			const updateOthers = this.updating;
			const def = this.stages.map(x => ({ PDH: parseFloat(x.PDH.toString().replace(',', '.')) } as PumpStage));
			const changed = await store.dispatch(SizingActions.stage, { id: this.sizing.id, def });
			if (changed?.length)
				store.dispatch(DebugActions.replace, { category: 'Sizing', entries: changed[0].Status });
			this.closeDialog();

			// Recalculate build of other stages if something affected it during restaging
			if (updateOthers) {
				for (const stage of this.existingStages) {
					if (stage.id !== this.sizing.id)
						BuildManager.recalc(stage);
				}
			}
		}

		public closeDialog() {
			const formEl = this.$refs.sizingForm as HTMLFormElement;
			formEl.resetValidation();
			this.visible = false;
			this.$emit('closed');
		}

		public getParam(stage: StageDef) {
			return this.bags[stage.id].getParam('Heads.PDH');
		}
	}
</script>