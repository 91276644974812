<template>
	<v-container>
		<v-layout wrap>
			<v-flex xs12>
				<v-row style="background-color: lightgray; border-style: outset; padding-left: 12px; padding-right: 12px">
					<v-col cols="12">
						<h3 class="text-xs-center">BA viewer {{ doc ? `${doc.DisplayName} (id ${doc.id})` : '' }}</h3>
					</v-col>
				</v-row>
				<v-row v-if="doc">
					<v-col cols="12">
						<BADrawing :id="id" :measurements="true" :hints="true" :useBA="doc" style="max-height: 250px" />
					</v-col>
				</v-row>
				<v-row v-if="doc && isDevloper">
					<v-col cols="12" >
						<div style="display: grid; grid-template-columns: 1fr 1fr;">
						<template v-for="prop in propNames">
							<div :key="prop" class="mb-1">
								<v-text-field :label="prop" type="number" v-model.number="doc[prop]" hide-details class="ma-2 mb-1"
									style="display:inline-block; width: 220px" />
								<i title="Click to revert" style="cursor: pointer" v-if="doc[prop] != old[prop]" @click="$set(doc, prop, old[prop])">
									({{ old[prop] }})
								</i>
							</div>
						</template>
						</div>
					</v-col>
				</v-row>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import UnitNumeric from '@/components/Fields/UnitNumeric.vue';
import PlusMinus from '@/components/PlusMinus.vue';
import BADrawing from '@/components/BADrawing.vue';
import { BearingAssembly } from 'types/dto/BearingAssembly';
import pumpsService from '@/services/pumps.service';
import store, { AuthGetters } from '@/store';

@Component({
	components: {
		BADrawing,
		UnitNumeric,
		PlusMinus
	}
})
export default class BearingAssemblies extends Vue {
	@Prop() public id: string;
	public doc: BearingAssembly = null;
	public old: BearingAssembly = null;

	public readonly propNames: Array<keyof BearingAssembly> = [
		'DRIVE_END_LENGTH',
		'DRIVE_END_DIAMETER',
		'DRIVE_END_BRG_SEAT_DIAMETER',
		'DRIVE_END_BRG_SEAT_LENGTH',
		'BRGDRV_WIDTH',
		'BRGWET_WIDTH',
		'LENGTH_BETWEEN_BEARING',
		'DIA_BETWEEN_BEARINGS',
		'SHAFT_L1', 'SHAFT_D1', 'SHAFT_L2', 'SHAFT_D2', 'SHAFT_L3', 'SHAFT_D3', 'SHAFT_L4', 'SHAFT_D4', 'SHAFT_L5', 'SHAFT_D5',
		'NeedsShaftExt',
		'BRGDRV_A_DIMENSION',
		'BRGWET_A_DIMENSION',
		'BRG_MOUNT',
		'BearingArrangement'
	];

	public baItemText(i: BearingAssembly) {
		return `${i.DisplayName} ${i.MaterialNumber}`;
	}

	public get isDevloper() {
		return store.get(AuthGetters.hasRole, 'developer') || false;
	}

	@Watch('id')
	public async loadBA(id: string) {
		if (!id || this.doc?.id === id)
			return;

		const res = await pumpsService.getBADefinition(id);
		this.old = res && JSON.parse(JSON.stringify(res));
		this.doc = res;
	}

	public async created() {
		try {
			if (this.id)
				await this.loadBA(this.id);
		} catch (e) {
			console.error('Failed to get BA');
		}
	}
}
</script>
