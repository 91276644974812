<template>
	<v-app id="app inspire">
		<!-- app bar -->
		<Navigation :shown="hasToolbar" class="hidden-print" />

		<!-- main contents -->
		<v-content>
			<router-view :key="$route && $route.fullPath" />
		</v-content>

		<!-- right menu -->
		<Sidebar class="hidden-print" />

		<!-- footer -->
		<v-footer v-if="hasToolbar" app color="white" elevation="4" class="hidden-print">
			<img :src="require('@/assets/Metso_Logo_Black_RGB.svg')" height="16" style="margin: 8px auto;">
			<v-spacer />
			<small>{{ $t('app.metsoCopyright') }}</small>
			<template v-if="isDevloper">
				<v-spacer />
				<v-btn v-if="logCount > 0 || showLog" x-small @click="showLog = !showLog" class="light-green">Debug log ({{ logCount }})</v-btn>
				<MessageLog v-if="showLog" style="clear:all; width:100%" />
			</template>
		</v-footer>

		<!-- dialogs and popups -->
		<Snackbar />
		<v-dialog v-model="showUpdateDialog" persistent width="350">
			<v-card class="pr-2 pb-2">
				<v-card-title class="headline">{{ $t('app.newVersion') }}</v-card-title>
				<v-card-text>{{ $t('app.reloadPage') }}</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn dark @click="reload">{{ $t('common.ok') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-if="showRedirect" v-model="showRedirect" persistent width="450">
			<v-card class="pa-8">
				<v-card-title>PumpDim {{oldEnvName}} has moved!</v-card-title>
				<v-card-text>
					Please update your bookmarks. The new URL is <br>
					<a :href="newUrl"><b>{{ newUrl }}</b></a>
					<div v-if="directLink">
						<br><a :href="directLink" class="allcaps">» Proceed to the linked sizing or project »</a>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
	  <div class="text-center">
		 <v-snackbar color="primary" v-model="showAddToHomeScreen" :top=true :timeout=10000>
			{{ installText }}
			<v-btn color="cancel" text @click="showAddToHomeScreen = false">{{ $t('common.cancel') }}</v-btn>
			<v-btn light text @click="installApp">{{ $t('common.ok') }}</v-btn>
		 </v-snackbar>
	  </div>
	</v-app>
</template>

<style lang="scss">
	.v-footer img {
		margin: 0 12px;
	}
	.dialog.centered-dialog, .v-dialog.centered-dialog {
		background: #282c2dad;
		box-shadow: none;
		border-radius: 6px;
		width: auto;
		color: whitesmoke;
	}

	@media only screen and (max-width: 800px) {
		.v-content {
			max-width: 98%; // This makes sure that all the content in dashboard is fitted to a small screen.
		}

		.Navigation {
			max-width: 77%; // This makes sure that the nav-bar is fitted to a smaller screen
		}
	}

	@media print {
		.hidden-print {
			display: none !important;
		}
	}
</style>

<script lang="ts">
	import Vue from 'vue';
	import Snackbar from '@/components/Snackbar.vue';
	import Loader from '@/components/Loader.vue';
	import Navigation from '@/components/Navigation.vue';
	import Sidebar from '@/components/Sidebar.vue';
	import MessageLog from '@/components/MessageLog.vue';
	import { Component, Watch } from 'vue-property-decorator';
	import store, { DebugGetters, NetworkGetters, SizingActions, AuthGetters, AuthActions } from '@/store';
	import InsightService from '@/services/insight.service';
	import ApiService from '@/services/api.service';
	import router from './router';

	@Component({
		components: {
			Snackbar,
			Loader,
			Navigation,
			Sidebar,
			MessageLog
		}
	})
	export default class App extends Vue {
		public showUpdateDialog: boolean = false;
		public showLog: boolean = false;
		public showAddToHomeScreen: boolean = false;
		public showRedirect: boolean = false;
		public newUrl: string = null;

		public get logCount() {
			return (store.get(DebugGetters.log) || []).length;
		}

		public get installText() {
			return this.$t('app.installText');
		}

		public get oldEnvName() {
			const dl = document.location;
			const loc = dl.hostname || dl.host || dl.href;
			if (loc) {
				if (loc.includes('eligodev'))
					return 'dev';
				if (loc.includes('eligostaging'))
					return 'staging';
			}
		}

		public get directLink() {
			let hash = document.location?.hash;
			if (this.newUrl && hash?.length > 4) {
				const rd = hash.indexOf('?redirect=');
				if (rd >= 0)
					hash = '#' + decodeURIComponent(hash.substring(rd + 10));
				return this.newUrl + hash;
			}
		}

		public installApp() {
			console.debug('Before install prompt');
			this.showAddToHomeScreen = false;
			if ((window as any).Eligo.deferredPrompt) {
				// Show the prompt
				(window as any).Eligo.deferredPrompt.prompt();
				// Wait for the user to respond to the prompt
				(window as any).Eligo.deferredPrompt.userChoice.then((choiceResult: any) => {
					if (choiceResult.outcome === 'accepted')
						console.debug('User accepted the PumpDim prompt');
					else
						console.debug('User dismissed the PumpDim prompt');
					(window as any).Eligo.deferredPrompt = null;
				});
			}
		}

		@Watch('offline')
		private offlineChanged(offline: boolean) {
			InsightService.disableTelemetry(offline);
			if (!offline)
				this.forceLogin();
		}

		public created() {
			if (this.oldEnvName) {
				this.newUrl = 'https://pumpdimdev.azurefd.net/' + this.oldEnvName + '/';
				this.showRedirect = true;
			}

			// Create namespace for prompt.
			(window as any).Eligo = (window as any).Eligo || {};

			document.addEventListener('swUpdated', (ev: Event) => this.showUpdateDialog = true, { once: true });

			window.addEventListener('online', () => ApiService.onlineCheck.checkOnline());
			window.addEventListener('offline', () => ApiService.onlineCheck.checkOnline());

			window.addEventListener('beforeinstallprompt', (e: any) => {
				if (this.offline)
					return;
				// Prevent Chrome 67 and earlier from automatically showing the prompt
				e.preventDefault();

				// Stash the event so it can be triggered later.
				(window as any).Eligo.deferredPrompt = e;

				// Delay install-popup
				setTimeout(() => this.showAddToHomeScreen = true, 2000);
			});

			window.addEventListener('appinstalled', evt => console.log('PumpDim installed'));
		}

		public async forceLogin() {
			if (store.get(AuthGetters.guestUser) || !store.get(AuthGetters.loggedIn)) {
				await store.dispatch(AuthActions.relogin);
				if (this.$route && this.$route.name !== 'home')
					router.push('/');
			}
		}

		public get offline() {
			return !store.get(NetworkGetters.connected);
		}

		public get shouldSynchronize(): boolean {
			return !!(store.get(AuthGetters.loggedIn) && !store.get(AuthGetters.guestUser) &&
				store.get(AuthGetters.userId) && !this.offline);
		}

		@Watch('shouldSynchronize')
		public synchronize(shouldSynchronize: boolean) {
			if (shouldSynchronize)
				store.dispatch(SizingActions.updateDirtySizings);
		}

		public reload() {
			window.location.reload();
		}

		get hasToolbar() {
			return this.$route && this.$route.name !== 'home' || false;
		}

		public get isDevloper() {
			return true; //store.get(AuthGetters.hasRole, 'developer') || false;
		}
	}
</script>