<template>
	<div v-if="items">
		<!-- Search container -->
		<v-card v-if="!hideFooter" flat :class="[noui === false ? 'search-container-modal' : 'search-container']">
			<v-text-field v-model="searchText" :label="noui === false ? 'Search' : ''" outlined hide-details class="search-bar" :class="[noui === false ? 'search-bar-modal' : 'search-bar']">
			  <template slot="prepend-inner">
     				<v-icon class="search-icon">$search</v-icon>
   		</template>
		</v-text-field>
		</v-card>	
		<!-- Changelog table -->
		<v-data-table dense :items="items" :key="loadedInfo" item-key="id" :headers="headers" disable-sort :search="searchText"
			:class="tableClass(noui)" :hide-default-footer="hideFooter" :custom-filter="filter" @update:page="newPage" :footer-props="{'items-per-page-options': [10]}">
			<template v-slot:item="x">
				<tr :class="rowClass(x.item)">
					<td>{{ formatDate(x.item.Created) }}</td>
					<td><Username :id="x.item.Source" /></td>
					<td v-if="hasLinks">
						<a v-if="formatTarget(x.item, true)" :href="formatTarget(x.item, true)" v-text="formatTarget(x.item)" />
					</td>
					<td>
						<v-chip class="table-chip no-hover" v-if="isChange(x.item)" x-small :color="color(x.item)" :text-color="textColor(x.item)" v-text="formatEvent(x.item)" />
						<span v-else>{{ x.item.Text }} </span>
					</td>
					<td v-if="hasSubtargets">{{ formatField(x.item.Subtarget) }} </td>
					<td v-if="hasValues" class="align-right">{{ formatValue(x.item) }}</td>
					<td>
						<v-tooltip v-if="isDeletable(x.item)" bottom>
							<template v-slot:activator="{ on }">
									<v-icon v-on="on" color="primary" dense x-small @click="erase(x.item)">$delete</v-icon>
							</template>
							{{ $t('common.delete') }}
						</v-tooltip>
					</td>
				</tr>
			</template>
		</v-data-table>
		<div v-if="hideFooter && items.length > 10">
			<b>...</b>
		</div>
		<Confirm v-if="hasDeletable" ref="confirmDelete" />
	</div>
</template>

<style lang="scss" scoped>
	@import '@/sass/_variables.scss';

	.message-table.v-data-table td, .message-table.v-data-table th {
		padding: 0;
		padding-right: 8px;
		:is(span) {
			font-weight: normal;
		}
	}

	.message-table.v-data-table tr {
		height: 28px;
	}

	.message-table.noui  {
		td, span {
			font-size: 13px !important;
		}
		td {
			line-height: 1.15;
			padding: 5px 5px 5px 0;
		}
	}

	.message-table.v-data-table tr.unread td {
		font-weight: bold;
		color: black;
		:is(span) {
			font-weight: bold;
		}
	}

	.search-container {
		background-color: $grey-lighten-2;
		padding: 16px;
		margin-bottom: 8px;
	}

	.search-container-modal {
		margin: 3px 0 2px;
	}
 </style>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop, Watch } from 'vue-property-decorator';
	import { Message, MessageType, TargetType } from 'types/dto/Messages';
	import { localDateString } from '@/common/Tools';
	import Username from '@/components/Username.vue';
	import Confirm from '@/components/Confirm.vue';
	import store, { AuthGetters, MsgGetters, MsgActions, SizingGetters, SizingActions, ProjectGetters, ProjectActions } from '@/store';
	import { PumpDocument, PumpProject } from 'types/dto/CalcServiceDomain';
	import { formatField, formatValue } from '@/common/ParamTools';
	import { tryToTranslate } from '@/i18n';
	import { ParamBag } from '@/common/ParamBag';
	import colors from '@/sass/_variables.scss';

	@Component({
		components: {
			Username,
			Confirm
		}
	})
	export default class ChangeLog extends Vue {
		@Prop() public target: string;
		@Prop() public subtarget: string;
		@Prop() public type: MessageType;
		@Prop() public noui: boolean | string;
		@Prop() public showDelete: boolean | string;

		// Trigger to re-render the list when all mentioned items are loaded
		public loadedInfo: number = 0;
		public searchText: string = null;
		public page: number = 1;

		public filter(value: string, search: string, item: Message) {
			if (!search?.trim().length)
				return true;
			if (value == null || value === '')
				return false;
			if (value === item.Source) {
				const udata = store.get(AuthGetters.displayName, value) as string;
				if (udata)
					value = udata;
			} else if (value === item.Subtarget)
				value = formatField(value);
			else if (value === item.Value)
				value = this.formatValue(item);

			search = search.trim().toLowerCase();
			return value.toLowerCase().includes(search);
		}

		public newPage (page: number) {
			this.page = page;
		}

		public get headers() {
			const headers: any[] = [
				{ text: 'Time', value: 'Created', align: 'left' },
				{ text: 'User', value: 'Source' }
			] ;

			if (this.hasLinks)
				headers.push({ text: 'Link', value: 'Target' });

			if (this.type === MessageType.Comment)
				headers.push({ text: 'Comment', value: 'Text' });
			else if (this.type === MessageType.Change)
				headers.push({ text: 'Event', value: 'Text' });
			else
				headers.push({ text: 'Text/event', value: 'Text' });

			if (this.hasSubtargets)
				headers.push({ text: 'Parameter', value: 'Subtarget' });

			if (this.hasValues)
				headers.push({ text: 'Value', value: 'Value', align: 'right' });

			// Always add this one so td/th count matches...
			headers.push({ text: '', sortable: false, style: 'margin:0; padding: 0' });
			return headers;
		}

		public get hasLinks() {
			return this.items?.some(x => x.Target && x.Target !== this.target) || false;
		}

		public get hasSubtargets() {
			return this.items?.some(x => x.Subtarget && x.Subtarget !== this.subtarget) || false;
		}

		public get hasValues() {
			return this.items?.some(x => x.Value != null && x.Value !== '') || false;
		}

		public get hasDeletable() {
			if (!this.showDelete && this.showDelete !== '')
				return false;
			return this.items?.some(x => this.isDeletable(x));
		}

		public isDeletable(x: Message) {
			if (!this.showDelete && this.showDelete !== '')
				return false;

			// Allow deletion of comments made by current user
			if (x.Type === MessageType.Comment && x.Source === this.me)
				return true;

			// Allow deletion in current user's inbox
			return this.target === this.me;
		}

		public get hideFooter() {
			return this.noui || this.noui === '' || this.items.length <= 10;
		}

		public get items() {
			if (!this.target)
				return [];

			const items = store.get(MsgGetters.messages)(this.target, this.type, this.subtarget) as Message[] || [];

			// Slight hack: include latest change in comments if done by someone else (for parameter value comment lists only)
			if (this.type === MessageType.Comment && this.subtarget) {
				const lastChange = store.get(MsgGetters.lastChange)(this.target, this.subtarget) as Message;
				if (lastChange && lastChange.Source !== this.me)
					return [lastChange].concat(items);
			}
			return items;
		}

		public isChange(x: Message) {
			return x.Type === MessageType.Change;
		}

		public get useImperial() {
			if (this.type === MessageType.Change && this.target)
				return ParamBag.useImperial(this.target);
			return store.get(AuthGetters.systemOfUnit) === 'Imperial';
		}

		public rowClass(x: Message) {
			if (!x.Read && this.target === this.me)
				return 'unread';
		}

		public tableClass(noui: boolean | string) {
			return noui === false ? 'message-table noui nowrap' : 'message-table nowrap';
		}

		get me() {
			return store.get(AuthGetters.userId);
		}

		public color(item: Message) {
			return ({
				Updated: colors.green,
				Created: colors.green,
				Deleted: colors.error
			} as any)[item.Text] || colors.greyLighten1;
		}

		public textColor(item: Message) {
			return (item.Text === 'Updated' || item.Text === 'Created' || item.Text === 'Deleted') ? 'white' : 'black';
		}

		public formatDate(d: any) {
			return localDateString(d, true);
		}

		public formatField(name: string) {
			return formatField(name);
		}

		public formatValue(m: Message) {
			return formatValue(m.Subtarget, m.Value, this.useImperial);
		}

		public formatEvent(m: Message) {
			if (!m.Text)
				return '';
			if (m.Type === MessageType.Comment)
				return m.Text;
			return tryToTranslate(`changeEvent.${m.Text}`) || m.Text;
		}

		public async erase(m: Message) {
			const dialog: any = this.$refs.confirmDelete;
			const sure = await dialog.open('This can not be undone.');
			if (sure)
				store.dispatch(MsgActions.delete, { inbox: this.target, msgId: m.id });
		}

		public async created() {
			await store.dispatch(MsgActions.loadInbox, { id: this.target });
			await ChangeLog.loadMentionedItems(this.items, this.page);
			this.loadedInfo++;
		}

		public beforeDestroy() {
			if (this.target)
				store.dispatch(MsgActions.unloadInbox, { id: this.target });
		}

		public formatTarget(m: Message, asHref?: boolean) {
			if (!m.Target || m.Target === this.target)
				return '';

			if (m.TargetType === TargetType.Sizing) {
				if (asHref)
					return this.$router.resolve({ name: 'directsizing', params: { sizingid: m.Target } }).href;
				const sz = store.get(SizingGetters.sizing, m.Target) as PumpDocument;
				if (sz)
					return sz.Name;
			}

			if (m.TargetType === TargetType.Project) {
				if (asHref)
					return this.$router.resolve({ name: 'project', params: { id: m.Target } }).href;
				const p = store.get(ProjectGetters.project, m.Target) as PumpProject;
				if (p)
					return p.Name;
			}
			return asHref ? null : 'Link';
		}

		@Watch('items')
		private async onItemsChange(items: Message[]) {
			await ChangeLog.loadMentionedItems(items, this.page);
			this.loadedInfo++;
		}

		@Watch('page')
		private async onPageChange() {
			await ChangeLog.loadMentionedItems(this.items, this.page);
		}

		private static async loadMentionedItems(items: Message[], page: number) {
			const loaded: string[] = [];

			const pageSize = 10;
			const start = page == 1 ? 0 : ((page - 1 ) * pageSize);
			const itemsToLoad = items.slice(start, start + pageSize);

			for (const m of itemsToLoad) {
				if (m.Target && m.TargetType === TargetType.Sizing) {
					const sz = store.get(SizingGetters.sizing, m.Target) as PumpDocument;
					if (!sz && !loaded.includes(m.Target)) {
						loaded.push(m.Target);
						await store.dispatch(SizingActions.getSizing, m.Target);
					}
				}

				if (m.Target && m.TargetType === TargetType.Project) {
					const projectId = m.Target;
					const project = store.get(ProjectGetters.project, projectId) as PumpProject;
					if (!project && !loaded.includes(projectId)) {
						loaded.push(projectId);
						await store.dispatch(ProjectActions.getProject, projectId);
					}
				}
			}
		}
	}
</script>
