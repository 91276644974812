class AuthenticationError extends Error {
	public errorCode: string;
	constructor(errorCode: string, message: string) {
		super(message);
		this.name = this.constructor.name;
		this.message = message;
		this.errorCode = errorCode;
	}
}
export default AuthenticationError;
