<template>
	<div>
		<v-data-table class="warning-table" dense :items="items" item-key="id" :headers="headers" disable-pagination hide-default-footer>
			<template v-slot:item="x">
				<tr>
					<td>
						<v-chip class="table-chip no-hover" v-if="x.item.Severity != null" x-small :color="color(x.item)" text-color="white" v-text="type(x.item)" />
					</td>
					<td>{{ formatField(x.item.ParamName) }}</td>
					<td v-if="sizing">
						<DutyPointValue v-if="x.item.ParamName" :param="params.getParam(x.item.ParamName)" :sizingId="params.sizingId" />
					</td> 
					<td>{{ x.item.Message }}</td>
				</tr>
			</template>
		</v-data-table>
	</div>
</template>

<style lang="scss" scoped>
	@import '@/sass/main.scss';

	.warning-table.v-data-table tr {
		height: 28px !important;
	}

 </style>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { MessageSeverity, ValidationResult, PumpDocument } from 'types/dto/CalcServiceDomain';
	import { formatField } from '@/common/ParamTools';
	import DutyPointValue from '@/components/DutyPointValue.vue';
	import { ParamBag } from '@/common/ParamBag';
	import colors from '@/sass/_variables.scss';

	@Component({
		components: {
			DutyPointValue
		}
	})
	export default class WarningList extends Vue {
		@Prop() public sizing: PumpDocument;
		@Prop() public variants: PumpDocument[];
		@Prop() public msgs: ValidationResult[];

		public params: ParamBag = null;

		public created() {
			this.params = new ParamBag(this.variants || [], this.sizing?.id, true);
		}

		public get headers() {
			const headers = [
				{ text: 'Level', value: 'Severity' },
				{ text: 'Parameter', value: 'ParamName', visible: false }
			];
			if (this.sizing)
				headers.push({ text: 'Value', value: 'Value' });
			headers.push({ text: 'Text', value: 'Message' });
			return headers;
		}

		public get items() {
			// Gather unique messages from all duty points
			const all: ValidationResult[] = (this.msgs || ParamBag.mergeMessages(this.sizing?.Status, ...this.variants?.map(x => x.Status)))
				?.filter(x => x.Severity >= MessageSeverity.Info);

			if (!all?.length)
				return [];

			return all.sort((x, y) => {
				const xkey = (50 - x.Severity ?? 0).toString().padStart(3, '0') + x.ParamName + x.Message;
				const ykey = (50 - y.Severity ?? 0).toString().padStart(3, '0') + y.ParamName + y.Message;
				return xkey === ykey ? 0 : xkey > ykey ? 1 : -1;
			});
		}

		public color(item: ValidationResult) {
			if (item.Severity < MessageSeverity.UsedValue || item.Severity > MessageSeverity.InternalError)
				return colors.selection;
			return [colors.green, colors.grey, colors.warning, colors.selection, colors.warning, colors.error, colors.error][item.Severity + 2];
		}

		public formatField(valueName: string) {
			return formatField(valueName);
		}

		public type(item: ValidationResult) {
			return this.$t(`MessageSeverity.${item.Severity}`);
		}
	}
</script>
