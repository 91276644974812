import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Axios from 'axios';

Vue.use(VueI18n);

export const fallbackLocale = 'sv-SE';
export const fallbackLanguage = 'en';

function loadLocaleMessages() {
	// Bundle English, lazy load other languages
	const allLanguages = require.context('../../locales', true, /en\.json$/i);
	const messages = {} as any;
	const localeMatch = /([A-Za-z0-9-_]+)\./i;
	allLanguages.keys().forEach(key => {
		const matched = localeMatch.exec(key);
		if (matched?.length > 1) {
			const locale = matched[1];
			messages[locale] = allLanguages(key);
		}
	});
	return messages;
}

// Both locale and fallbackLocale are used for current _language_ for $t et al...
const vuei18n = new VueI18n({
	locale: process.env.VUE_APP_I18N_LOCALE || fallbackLanguage,
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || fallbackLanguage,
	messages: loadLocaleMessages(),
	silentTranslationWarn: true
});

export default vuei18n;

export function languages(): Array<{ text: string, value: string }> {
	return require('../../locales/languages.json').map((lang: { text: string, code: string }) => ({
		text: lang.text || lang.code || '???',
		value: lang.code
	}));
}

export function locales(): Array<{ text: string, value: string }> {
	return require('../../locales/locales.json').map((c: { text: string, code: string }) => ({
		text: c.text || c.code || '???',
		value: c.code
	}));
}

async function loadLanguage(language: string) {
	if (!language)
		throw new Error('No language specified');

	language = language.toLowerCase();
	if (vuei18n.messages[language])
		return;

	const url = `${process.env.BASE_URL}locales/${language}.json?v=${process.env.VUE_APP_VERSION}`;
	const res = await Axios.get(url);
	if (res?.data)
		vuei18n.setLocaleMessage(language, res.data);
	else
		throw new Error('Failed to load language; bad response');
}

export async function setLanguage(language: string) {
	language = language.toLowerCase();
	await loadLanguage(language);

	if (!vuei18n.messages[language])
		language = fallbackLanguage;

	if (vuei18n.locale !== language)
		vuei18n.locale = language;
}

export function tryToTranslate(key: string) {
	if (key == null)
		return undefined;
	let text = vuei18n.te(key) && vuei18n.t(key);
	if (text && typeof(text) === 'string')
		return text;
	text = vuei18n.te(key, 'en') && vuei18n.t(key, 'en');
	if (text && typeof(text) === 'string')
		return text;
}

const dateFormatOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
const timeFormatOptions: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
const dateTimeFormatOptions: Intl.DateTimeFormatOptions = { ...dateFormatOptions, ...timeFormatOptions };

export function localizedDateString(locale: string, utcString: string | Date, relative?: boolean) {
	if (!utcString)
		return '';

	const appliedLocales = [locale ?? fallbackLocale, fallbackLocale];
	const date = typeof utcString === 'object' ? utcString : new Date(utcString);
	if (!date.getHours() && !date.getMinutes() && !date.getSeconds())
		return date.toLocaleDateString(appliedLocales, dateFormatOptions);

	if (relative) {
		const now = new Date();
		if (now.toDateString() === date.toDateString())
			return date.toLocaleTimeString(appliedLocales, timeFormatOptions);
	}

	return date.toLocaleString(appliedLocales, dateTimeFormatOptions);
}

export function suggestLocale(lang: string, currentLocale?: string, imperialSOU?: boolean) {
	if (!currentLocale || currentLocale.substring(0, 2) !== lang) {
		const suggestedLocale = locales().find(x => x.value.startsWith(lang))?.value;
		if (suggestedLocale) {
			if (imperialSOU && suggestedLocale === 'en-GB')
				return 'en-US';
			return suggestedLocale;
		}
	}
	return currentLocale ?? fallbackLocale;
}
