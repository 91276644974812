import ApiService from './api.service';
import { AxiosRequestConfig } from 'axios';
import { ChartInputs, CurveData, GraphInfo, GraphOverlay, ReportInputs } from 'types/dto/Reports';
import { loadScript } from '@/common/Tools';

type InitChartFunction =
	(elementId: string, belDataset: any, efficienceDataset: any, headDataset: any, requestedAreaDataset: any,
		graphInfo: GraphInfo, npshgraphDataset: any, width: number, height: number, useImperial: boolean,
		useSlurry: boolean, overlays: GraphOverlay[]) => void;

class ReportingService {
	private readonly API = process.env.VUE_APP_REPORTINGSERVICE_ENDPOINT;

	public generateSummary(ri: ReportInputs): Promise<any> {
		if ((!ri.ProjectId || !ri?.Sizings?.length) && !ri?.ModelId)
			return null;

		const config: AxiosRequestConfig = {
			method: 'post',
			url: this.API + '/summary',
			data: ri,
			responseType: ri.HtmlView ? undefined : 'blob'
		};

		return ApiService.customRequest(config)
			.then(response => {
				if (ri.HtmlView)
					return response?.data;

				const baseName = this.cleanFilename(ri.Filename) || `${new Date().getTime()}`;
				const fileName = `${baseName}.pdf`;
				this.downloadAsFile(response.data, fileName);
			}).catch<void>(ApiService.defaultErrorHandler);
	}

	public generateProjectExcel(ri: ReportInputs): Promise<any> {
		if (!ri?.Sizings?.length)
			return null;

		const config: AxiosRequestConfig = {
			method: 'post',
			url: this.API + '/projectexcel',
			data: ri,
			responseType: 'blob'
		};

		return ApiService.customRequest(config)
			.then(response => {
				const fileName = `project_${this.cleanFilename(ri.Filename)}.xlsx`;
				this.downloadAsFile(response.data, fileName);
			}).catch<void>(ApiService.defaultErrorHandler);
	}

	private downloadAsFile(data: any, fileName: string) {
		const url = window.URL.createObjectURL(new Blob([data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', fileName);
		document.body.appendChild(link);
		link.click();
		link.remove();
	}

	public async getCurveData(data: ChartInputs): Promise<CurveData> {
		return ApiService.post<any>(this.API + '/chartdata', data);
	}

	public async initCharts(): Promise<InitChartFunction> {
		await Promise.all([
			loadScript(this.API + '/chartscripts?v=' + process.env.VUE_APP_VERSION),
			loadScript('https://cdnjs.cloudflare.com/ajax/libs/d3/5.12.0/d3.min.js')
		]);
		return (window as any).initPerformanceChart;
	}

	private cleanFilename(name: string) {
		return name?.replace(/[\\/:*?"<>|']/g, '_');
	}
}

export default new ReportingService();
