<template>
	<SizingIcon v-if="visible" :state="state" class="icon" size="16"/>
</template>

<style lang="scss" scoped>
	.icon {
		margin-left: 6px;
	}
</style>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { GroupValidationResult } from '@/common/GroupValidationResult';
	import { ParamBag } from '@/common/ParamBag';
	import SizingIcon from '@/components/SizingIcon.vue';

	@Component({
		components: {
			SizingIcon
		}
	})
	export default class GroupValidation extends Vue {
		@Prop() public groups: string[];
		@Prop() public values: ParamBag;

		get visible(): boolean {
			return this.errorsInGroups(this.groups).Visible;
		}

		get state(): string {
			return this.errorsInGroups(this.groups).State;
		}

		private errorsInGroups(groups: string[]): GroupValidationResult {
			if (!groups || !this.values)
				return new GroupValidationResult([]);

			const res = groups.map(g => this.values.errorsInGroup(g, null, true).Messages).flat();
			return new GroupValidationResult(res);
		}
	}
</script>
