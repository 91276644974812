<template>
	<div class="floater" :style="pos === 'l' ? 'left: 0' : 'right: 0'">
		<v-tooltip bottom>
			<template #activator="{ on }">
				<div v-on="on" @click="$emit('close')" class="top-btn close-btn">X</div>
			</template>
			Close
		</v-tooltip>

		<v-tooltip bottom>
			<template #activator="{ on }">
				<div v-on="on" @click="togglePos" class="top-btn toggle-btn">{{ pos === 'l' ? '»' : '«' }}</div>
			</template>
			Move to other side
		</v-tooltip>

		<div v-if="!hasData" class="ma-6 grey--text">
			Awaiting system curve data...
		</div>
		<div v-show="hasData" id="sysCurveArea" class="ma-2"></div>
		<v-btn small v-show="hasData" @click="download" style="position: absolute; right: 6px; bottom: 6px">Create PDF</v-btn>
	</div>
</template>

<style scoped>
.floater {
	width: 600px;
	height: 400px;
	border: 1px solid black;
	background-color: white;
	z-index: 100;
	position: fixed;
	bottom: 44px;
}

.top-btn {
	position: absolute;
	padding: 5px;
	font-weight: bold;
	cursor: pointer;
}

.close-btn {
	right: 0;
}

.toggle-btn {
	left: 0;
	font-size: 150%;
	padding: 0 5px;
}
</style>

<script lang="ts">
import { loadScript } from '@/common/Tools';
import Vue from 'vue';
import SizingService from '@/services/sizing.service';
import { Component, Prop, Watch } from 'vue-property-decorator';
import store, { AuthGetters, ProjectGetters, SizingGetters } from '@/store';
import { PumpDocument } from 'types/dto/CalcServiceDomain';
import Debounce from '@/common/Debounce';
import { Svg2pdfOptions } from 'svg2pdf.js';
import { jsPDFOptions } from 'jspdf';
import InsightService from '@/services/insight.service';

interface SysCurveData {
	color?: string;
	opacity?: number;
	reqFlow?: number;
	reqHead?: number;
	rawPoints: number[];
}

interface CurveSettings {
	xScale: (x: number) => number;
	yScale: (x: number) => number;
}

// Sanity upper limit for curve
const maxAllowedHead = 400;

// tslint:disable:no-bitwise

@Component({
	components: {
	}
})
export default class SystemCurve extends Vue {
	@Prop() public dutypoints: PumpDocument[];
	@Prop() public sizingId: string;

	public pos: 'l' | 'r' = 'r';
	private curves: SysCurveData[] = null;
	private reload = new Debounce('SysCurveLoad', 1000, () => this.loadCurve());

	public get hasData() {
		return !!(this.dutypoints?.length && this.sizing && this.curves?.length);
	}

	public get sizing() {
		return this.sizingId && store.get(SizingGetters.sizing, this.sizingId) as PumpDocument;
	}

	public get useImperial() {
		return store.get(AuthGetters.systemOfUnit) === 'Imperial';
	}

	public async created() {
		await loadScript('https://cdnjs.cloudflare.com/ajax/libs/d3/5.12.0/d3.min.js');
		this.loadCurve();
		InsightService.trackEvent('SysCurve:Open');
	}

	public togglePos() {
		this.pos = this.pos === 'l' ? 'r' : 'l';
	}

	@Watch('useImperial')
	private redraw() {
		if (!this.curves?.length)
			return;

		const maxQ = SystemCurve.maxFlow(this.dutypoints);
		const maxH = SystemCurve.maxHead(this.curves);
		SystemCurve.drawSystemCurve(this.curves, maxQ, maxH, this.useImperial);
	}

	private get reloadTrigger() {
		if (!this.dutypoints?.length)
			return null;

		const data = this.dutypoints.map(dp => ({
			Liquid: dp.Data.Liquid,
			Particles: dp.Data.Particles,
			Slurry: dp.Data.Slurry,
			Heads: dp.Data.Heads,
			Inlet: dp.Data.Inlet,
			Outlet: dp.Data.Outlet
		}));
		return JSON.stringify(data);
	}

	@Watch('reloadTrigger')
	private onSystemChange() {
		this.reload.trigger();
	}

	private async loadCurve() {
		const curves: SysCurveData[] = [];
		const dps = this.dutypoints.filter(dp => dp.id !== this.sizingId)
			.concat(this.dutypoints.filter(dp => dp.id === this.sizingId));

		for (const dp of dps) {
			const rawPoints = await this.getSystemCurve(dp.id);
			curves.push({
				rawPoints,
				reqFlow: dp.Data.Slurry?.FlowRate,
				reqHead: dp.Data.Heads?.TDH,
				opacity: (this.sizingId && this.sizingId === dp.id) ? 0.7 : 0.32
			});
		}
		this.curves = curves;
		this.redraw();
	}

	public async download() {
		InsightService.trackEvent('SysCurve:CreatePDF');
		const jsPDF = await import('jspdf');
		const svg2pdf = await import('svg2pdf.js');
		const pdfEngine = new jsPDF.jsPDF({ orientation: 'l', unit: 'px' });

		const sz = this.sizing;
		const proj = store.get(ProjectGetters.project, sz.ProjectId);
		pdfEngine.text('System curve', 20, 25);
		pdfEngine.text(proj.Name, 20, 40);
		pdfEngine.textWithLink(sz.Name, 20, 55, { url: window.location.href + '?f=syscurve&v=1' });

		const curveEl = document.getElementById('sysCurveSVG');
		const doc = await svg2pdf.svg2pdf(curveEl, pdfEngine, { x: 15, y: 50 });
		const filename = `system_curve_${proj.Name}_${sz.Name}`;
		doc.save(filename.replace(/[\\/:*?"<>|']/g, '_') + '.pdf');
	}

	private async getSystemCurve(sizingId: string) {
		const sci = {
			SizingId: sizingId,
			FromFlow: 0.5 / 3600.0,
			ToFlow: SystemCurve.maxFlow(this.dutypoints),
			MaxHead: maxAllowedHead,
			WaterOnly: false
		};
		return (await SizingService.systemCurve(sci))?.Points;
	}

	// Start of functions that might move to report backend, thus kept static
	private static maxFlow(duties: PumpDocument[]) {
		const max = Math.max(...duties?.map(x => x.Data?.Slurry?.FlowRate));
		return (max || 100 / 3600.0) * 1.5;
	}

	private static maxHead(curves: SysCurveData[]): number {
		const max = Math.max(...curves?.map(x => x.rawPoints).flat()?.filter((x, idx) => !!(idx & 1)));
		return Math.min(max || 100, maxAllowedHead);
	}

	private static drawSystemCurve(curves: SysCurveData[], maxFlow: number, maxHead: number, useImperial: boolean) {
		const d3 = (window as any).d3;
		if (!d3)
			return;

		// External settings
		const maxX = maxFlow * 3600;
		const maxY = maxHead;
		const fullWidth = 580;
		const fullHeight = 370;

		// tslint:disable-next-line one-variable-per-declaration
		const marginLeft = 50, marginTop = 20, marginRight = 30, marginBottom = 50;
		const width = fullWidth - marginLeft - marginRight;
		const height = fullHeight - marginTop - marginBottom;
		const xScale = d3.scaleLinear().domain([0, maxX]).range([0, width]);
		const yScale = d3.scaleLinear().domain([0, maxY]).range([height, 0]);

		// Clear old contents
		d3.select('#sysCurveSVG').remove();

		// Graph area
		const svg = d3
			.select('#sysCurveArea')
			.append('svg')
				.attr('width', fullWidth)
				.attr('height', fullHeight)
				.attr('id', 'sysCurveSVG')
			.append('g')
				.attr('transform', 'translate(' + marginLeft + ','  + marginTop + ')');

		// Axis scales
		const flowUnit = useImperial ? 15850.372483753 / 3600 : 1; // m3/h -> GPM
		const flowScale = d3.scaleLinear()
			.domain([0, maxX * flowUnit])
			.range([0, width]);

		const headUnit = useImperial ? 3.280839895 : 1; // m -> ft
		const headScale = d3.scaleLinear()
			.domain([0, maxY * headUnit])
			.range([height, 0]);

		// Axes and grids
		svg.append('g')
			.attr('class', 'x-axis')
			.attr('transform', 'translate(0,'  + height + ')')
			.call(d3.axisBottom(flowScale));

		svg.append('g')
			.attr('class', 'x-grid')
			.style('stroke', 'grey-lighten-1')
			.style('opacity', '0.2')
			.attr('transform', 'translate(0,'  + height + ')')
			.call(d3.axisBottom(flowScale)
				.ticks(50)
				.tickSize(-height)
				.tickFormat(''));

		svg.append('g')
			.attr('class', 'y-axis')
			.call(d3.axisLeft(headScale));

		svg.append('g')
			.attr('class', 'y-grid')
			.style('stroke', 'grey-lighten-1')
			.style('opacity', '0.2')
			.call(d3.axisLeft(headScale)
				.ticks(50)
				.tickSize(-width)
				.tickFormat(''));

		// Labels
		const yText = useImperial ? 'H (ft)'  : 'H (m)';
		svg.append('text')
			.attr('transform', 'rotate(-90)')
			.attr('class', 'y-grid-text')
			.attr('y', 0 - marginLeft + 4)
			.attr('x', 0 - height / 2)
			.attr('dy', '1em')
			.style('text-anchor', 'middle')
			.style('fill', 'black')
			.attr('font-weight', 400)
			.text(yText);

		const xText = useImperial ? 'Q (gpm)'  : 'Q (m³/h)';
		svg.append('text')
			.attr('class', 'x-grid-text')
			.attr('transform', 'translate(' + (width / 2) + ','  + (height + marginTop + 16) + ')')
			.style('text-anchor', 'middle')
			.style('fill', 'black')
			.attr('font-weight', 400)
			.text(xText);

		// Draw curve from raw points; see comments in site.js about axes being "special"
		for (const curve of curves) {
			const coordStr = curve.rawPoints
				.map((c, idx) => (idx & 1) === 0 ? xScale(c * 3600.0) : yScale(c))
				.map((c, idx) => c.toString() + ((idx & 1) === 0 ? ',' : ' '))
				.join('').trim();

			svg.append('g')
				.append('path')
				.attr('d', 'M' + coordStr)
				.attr('class', 'line')
				.style('fill', 'none')
				.style('stroke', curve.color || 'black')
				.style('opacity', curve.opacity)
				.style('stroke-width', '3');

			SystemCurve.drawDuty(svg, { xScale, yScale }, curve, maxX * height / width, maxY);
		}
	}

	private static drawDuty(svg: any, { xScale, yScale }: CurveSettings, duty: SysCurveData, w: number, h: number) {
		if (!duty?.reqFlow || !duty.reqHead)
			return;

		const flow = duty.reqFlow * 3600;
		const tdh = duty.reqHead;
		const nudge = 0.07;
		const dx = nudge * w;
		const dy = nudge * h;

		const lines = [
			{ from: { X: flow - dx, Y: tdh }, to: { X: flow + dx, Y: tdh } },
			{ from: { X: flow, Y: tdh + dy }, to: { X: flow, Y: tdh - dy } }
		];

		svg.append('g')
			.selectAll('.line')
			.data(lines)
			.enter()
			.append('line')
			.style('stroke', duty.color || 'blue')
			.style('opacity', duty.opacity)
			.attr('class', 'line')
			.attr('x1', (d: any) => xScale(d.from.X))
			.attr('y1', (d: any) => yScale(d.from.Y))
			.attr('x2', (d: any) => xScale(d.to.X))
			.attr('y2', (d: any) => yScale(d.to.Y));
	}
}
</script>
