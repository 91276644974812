import { PumpDocMetaData, DocState, MetaData, PumpDocument } from 'types/dto/CalcServiceDomain';
import store, { AuthGetters, ProjectGetters } from '@/store';
import { PumpResult, PumpStatus } from 'types/dto/PumpSearch';

export default class SizingInfo {
	public static lockReason(item: PumpDocMetaData): string {
		if (!item)
			return null;
		if (item.DocState === DocState.Deleted)
			return 'Sizing is deleted. Undelete it to make it editable.';
		if (SizingInfo.isLocked(item))
			return 'Sizing is locked, since you are not an editor.';
		return null;
	}

	public static isLocked(item: MetaData): boolean {
		if (!item)
			return false;
		if (item.DocState === DocState.Deleted)
			return true;
		const me = store.get(AuthGetters.userId);
		if (store.get(AuthGetters.guestUser) || !item.CreatedBy || item.CreatedBy === me)
			return false;
		if (item.Editors?.includes(me))
			return false;
		const projectId = (item as PumpDocMetaData).ProjectId;
		const project = projectId && store.get(ProjectGetters.project, projectId) as MetaData;
		if (!project)
			return true;
		return !(project.CreatedBy === me || project.Editors?.includes(me));
	}

	public static isShared(item: MetaData): boolean {
		if (!item)
			return false;
		if (item.Editors?.some(x => x !== item.CreatedBy))
			return true;
		const projectId = (item as PumpDocMetaData).ProjectId;
		if (!projectId)
			return false;
		const project = store.get(ProjectGetters.project, projectId) as MetaData;
		return project?.Editors?.some(x => x !== item.CreatedBy) || false;
	}

	public static creators(item: MetaData) {
		if (!item)
			return [];

		const creators: string[] = [];
		const projectId = (item as PumpDocMetaData).ProjectId;
		if (projectId) {
			const project = store.get(ProjectGetters.project, projectId) as MetaData;
			if (!project)
				console.error('Project is not loaded');
			if (project?.CreatedBy)
				creators.push(project.CreatedBy);
		}

		if (item.CreatedBy && !creators.includes(item.CreatedBy))
			creators.push(item.CreatedBy);
		return creators;
	}

	public static editors(item: MetaData, excludeCreators?: boolean) {
		if (!item)
			return [];

		const editors: string[] = excludeCreators ? [] : SizingInfo.creators(item);
		const projectId = (item as PumpDocMetaData).ProjectId;
		if (projectId) {
			const project = store.get(ProjectGetters.project, projectId) as MetaData;
			if (!project)
				console.error('Project is not loaded');
			if (project?.Editors)
				editors.push(...project.Editors.filter(x => !editors.includes(x)));
		}

		if (item?.Editors)
			editors.push(...item.Editors.filter(x => !editors.includes(x)));
		return editors;
	}

	public static isSizingDeletable(item: PumpDocument) {
		return item && !item.MDP && !item.Staged && !item.ParentId && item.CreatedBy === store.get(AuthGetters.userId);
	}

	// Hide horrible ancient subtitle hacks here (numerics have "Duty point " prepended...)
	public static subtitle(s: PumpDocument) {
		if (!s || !s.MDP && !s.Staged)
			return null;

		const st = s.Subtitle;
		if (s.Staged && st)
			return 'Stage ' + st;
		if (s.MDP && st.length === 1 && st.match(/[0-9]/))
			return 'Duty point ' + st;
		return st;
	}

	public static pumpStatusIcon(pr: { Status: PumpStatus }) {
		switch (pr.Status) {
			case PumpStatus.NonStandard: return '🧰';
			case PumpStatus.Legacy: return '✝️';
			case PumpStatus.WIP: return '✨';
			case PumpStatus.Unavailable: return '❌';
			default: return '';
		}
	}
}
