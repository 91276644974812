<template>
	<div class="infobox">
		<span><v-icon small color="selection">info</v-icon>&nbsp;<slot /></span>
	</div>
</template>

<style lang="scss" scoped>
	@import '@/sass/_variables.scss';
	.infobox {
		color: rgba(0, 0, 0, 0.6);
		padding: 12px 14px 12px 14px;	
		border: 1px solid rgba(0,0,0,0.2);
		border-radius: 0px;
		font-size: 14px;
		background-color: white;
		margin: 0 0 24px 0;
		line-height: 1.3;
	}

	.infobox .v-icon {
		vertical-align: text-top;
	}

	.infobox span {
		font-weight: normal;
		font-size: 14px;
	}
</style>

<script lang="ts">
	import Vue from 'vue';
	import { Component } from 'vue-property-decorator';

	@Component
	export default class InfoBox extends Vue {
	}
</script>
