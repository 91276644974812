<template>
<svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="UI-Kit---NEW" stroke="none" stroke-width="1" fill="currentColor" fill-rule="evenodd">
        <g id="Icons" transform="translate(-124.000000, -256.000000)" fill="currentColor" fill-rule="nonzero">
            <g id="Group" transform="translate(124.000000, 256.000000)">
                <g id="Group" transform="translate(0.000000, 0.000000)">
                    <path d="M8.19683537,0.64 C7.88331531,0.474354596 7.55527743,0.337783726 7.21683537,0.232 L6.92083537,1.188 C7.19983537,1.275 7.47183537,1.388 7.72983537,1.524 L8.19683537,0.64 Z" id="Path"></path>
                    <path d="M14.9228354,14.146 L9.73383537,8.967 C10.5258354,8.002 10.9848354,6.821 10.9978354,5.555 C11.0146429,4.11265469 10.4603294,2.72219769 9.45583537,1.687 L9.45783537,1.686 L9.43683537,1.663 L9.42683537,1.65 L9.42583537,1.651 C9.31657998,1.51706948 9.1997208,1.38952603 9.07583537,1.269 L8.38183537,1.991 C8.48883537,2.092 8.58783537,2.2 8.67983537,2.314 L9.06883537,2 L8.71183537,2.35 C9.5503368,3.20028991 10.0134689,4.35091743 9.99783537,5.545 C9.98854006,6.73882347 9.50249219,7.87941581 8.64783537,8.713 C7.78883537,9.555 6.65183537,9.996 5.45183537,10 C4.25754218,9.99059641 3.11656093,9.5041762 2.28283537,8.649 C1.44283537,7.791 0.985835367,6.656 0.997835367,5.454 C1.00983537,4.252 1.48983537,3.126 2.34783537,2.285 C3.19883537,1.451 4.37783537,0.958 5.56183537,1 L5.56883537,0.5 L5.56883537,1 L6.06883537,1 L6.06883537,0 L5.56883537,0 L5.56883537,0.001 C4.10489085,-0.018813855 2.69354782,0.546299268 1.64783537,1.571 C-0.518061807,3.69861836 -0.553399623,7.17782772 1.56883537,9.349 C2.58724667,10.3947855 3.98213345,10.989044 5.44183537,10.999 L5.49883537,11 C6.81083537,11 8.04083537,10.522 9.03283537,9.681 L14.2148354,14.854 L14.9228354,14.146 Z" id="Path"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
    export default {};
</script>
