<template>
<svg width="7px" height="7px" viewBox="0 0 7 7" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="UI-Kit---NEW" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Icons" transform="translate(-193.000000, -475.000000)" fill="currentColor" fill-rule="nonzero">
            <g id="UI-062-Open-in-another-window" transform="translate(193.000000, 475.000000)">
                <polygon id="Path" points="6 6 1 6 1 1 3 1 3 0 0 0 0 7 7 7 7 4 6 4"></polygon>
                <polygon id="Path" points="4 0 4 1 5.293 1 2.637 3.656 3.344 4.363 6 1.707 6 3 7 3 7 0"></polygon>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
    export default {};
</script>
