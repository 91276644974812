<template>
	<span :class="spanClass">
		{{ fullName }}
		<v-progress-circular v-if="loading" :size="12" indeterminate />
	</span>
</template>

<script lang="ts">
	import Vue from 'vue';
	import Component from 'vue-class-component';
	import { Prop } from 'vue-property-decorator';
	import store, { AuthGetters, AuthMuts } from '@/store';
	import Debounce from '@/common/Debounce';
	import AuthService from '@/services/auth.service';

	@Component
	export default class Username extends Vue {
		@Prop() public id: string;
		@Prop({default: 'thin'}) public spanClass: string;
		public loading: boolean = false;

		private static readonly userFetch: Debounce = new Debounce('UserFetch', 100, Username.fetchUsers);
		private static userFetchQueue: string[] = [];

		private static fetchUsers(): Promise<any> {
			const ufq = Username.userFetchQueue;
			if (!ufq || !ufq.length)
				return;
			Username.userFetchQueue = [];
			return AuthService.userInfos(ufq).then(uinfo => store.commit(AuthMuts.publicUsers, uinfo));
		}

		public get fullName() {
			if (!this.id)
				return 'Unknown user';
			const udata = store.get(AuthGetters.displayName, this.id);
			if (udata && udata !== this.id) {
				this.loading = false;
				return udata;
			}

			if (!Username.userFetchQueue.includes(this.id)) {
				this.loading = true;
				setTimeout(() => this.loading = false, 5000);
				Username.userFetchQueue.push(this.id);
				Username.userFetch.trigger();
			}
			return 'Other user';
		}

		public mounted() {
			this.loading = this.id && Username.userFetchQueue.includes(this.id);
		}
	}
</script>
