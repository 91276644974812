<template>
	<v-container>
		<v-layout wrap>
			<v-flex xs12>
				<v-row style="background-color: lightgray; border-style: outset; padding-left: 12px; padding-right: 12px">
					<v-col cols="5">
						<h3 class="text-xs-center">Pump viewer {{ pump ? ` - ${pump.DisplayName} (${pump.Id})` : '' }}</h3>
						<div style="font-size: 80%">
							<span v-if="version">{{ version }}</span>
							<v-btn x-small icon title="Reload" @click="reload"><v-icon>refresh</v-icon></v-btn>
						</div>
					</v-col>
					<v-col cols="2">
						<v-select v-if="pumps" :items="ranges" v-model="range" label="Range" hide-details no-data-text="Loading..." />
					</v-col>
					<v-col cols="5">
						<v-select v-if="pumps" :items="pumpsInRange(range)" label="Pump" item-value="Id" hide-details
							:item-text="pumpItemText" @change="gotoPump" v-model="chosenPumpId" no-data-text="Loading..." />
					</v-col>
				</v-row>
				<v-row v-if="doc && doc.Data && doc.Data.Pump && doc.Data.Pump.Id">
					<v-col cols="5">
						<h3 class="mb-3">Duty/result</h3>
						<ParamList v-if="pumpBag" mode="ShowAll" :params="pumpBag" inline />
					</v-col>
					<v-col cols="7">
						<h3>Test values</h3>
						<v-row>
							<v-col>
								<PlusMinus @change="nudgeTDH" />
								<UnitNumeric :param="pumpBag.getParam('Heads.TDH')" readwrite />
								<PlusMinus @change="nudgeFlow" />
								<UnitNumeric :param="pumpBag.getParam('Slurry.FlowRate')" readwrite />
								<UnitNumeric :param="pumpBag.getParam('Slurry.SlurryDensity')" />
								<UnitNumeric :param="pumpBag.getParam('Liquid.Temp')" />
								<v-text-field label="Corrosion index" v-model="corrFact" class="v-text-field--enclosed" outlined />
								<UnitNumeric v-if="doc && doc.Data && doc.Data.Pump && doc.Data.Pump.MinTrimPercentage" :param="pumpBag.getParam('Pump.TrimPercentage')" />
							</v-col>
							<v-col>
								<UnitNumeric :param="pumpBag.getParam('Slurry.ManualHR')" />
								<UnitNumeric :param="pumpBag.getParam('Slurry.ManualQR')" />
								<UnitNumeric :param="pumpBag.getParam('Slurry.ManualER')" />
								<UnitNumeric :param="pumpBag.getParam('Slurry.FrothFactor')" />
								<div style="font-size: 85%">
									Impeller materials: <b v-text="pumpDef.ImpellerMaterials && pumpDef.ImpellerMaterials.join(', ')"/><br />
									Useable: <b v-text="pump && pump.ImpellerMaterials && pump.ImpellerMaterials.join(', ')"/><br />
									Casing materials: <b v-text="pumpDef.CaseMaterials && pumpDef.CaseMaterials.join(', ')"/><br />
									Useable: <b v-text="pump && pump.CaseMaterials && pump.CaseMaterials.join(', ')"/><br />
									<div v-if="pump && pump.ActualHR">
										Net derating (H/E/Q): <b>{{ pump.ActualHR }}/{{ pump.ActualER }}/{{ pump.ActualQR }}</b><br />
									</div>
								</div>
							</v-col>
						</v-row>
						<div v-if="doc && doc.Data && doc.Data.Pump && doc.Data.Pump.Messages && doc.Data.Pump.Messages.length">
							<h3>Messages</h3>
							<SimpleWarnings :msgs="doc.Data.Pump.Messages" />
						</div>
					</v-col>
				</v-row>
				<v-row v-if="chosenPumpId">
					<div class="mt-0 pt-2 pl-6 pr-4 pb-2" style="font-size: .875rem; background-color: white">
						<PumpCurve :pumpId="chosenPumpId" :variants="curveInputs" :key="triggerCurveUpdate" />
					</div>
				</v-row>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Axios from 'axios';
import store, { DebugActions, DebugMutations } from '@/store';
import pumpsService from '@/services/pumps.service';
import { PumpResult, PumpSearch } from 'types/dto/PumpSearch';
import { DeratingMethod, PumpDocument } from 'types/dto/CalcServiceDomain';
import PumpCurve from '@/components/PumpCurve.vue';
import ParamList from '@/components/ParamList.vue';
import SimpleWarnings from '@/components/SimpleWarnings.vue';
import UnitNumeric from '@/components/Fields/UnitNumeric.vue';
import PlusMinus from '@/components/PlusMinus.vue';
import { ParamBag } from '@/common/ParamBag';
import { localDateString, merge, uniqueValues } from '@/common/Tools';
import SizingInfo from '@/common/SizingInfo';

const pumpsEndpoint = process.env.VUE_APP_PUMPSERVICE_ENDPOINT + '/pumps';

@Component({
	components: {
		PumpCurve,
		ParamList,
		SimpleWarnings,
		UnitNumeric,
		PlusMinus
	}
})
export default class Pumps extends Vue {
	@Prop() public id: string;
	public pumps: PumpResult[] = [];
	public pump: PumpResult = null;
	public pumpBag: ParamBag = null;
	public range: string = 'HM';
	public chosenPumpId: string = null;
	public corrFact: string = null;
	public triggerCurveUpdate = 0;

	public doc: PumpDocument = {
		Data: {
			Heads: { TDH: null, PDH: null },
			Liquid: { Temp: null },
			Slurry: { FlowRate: null, SlurryDensity: 998, ManualQR: null, ManualHR: null, ManualER: null, FrothFactor: null },
			Pump: { TrimPercentage: null },
			ServiceClass: {} // Needed for now to show allowed duty area since there is no flag
		},
		Status: []
	} as PumpDocument;

	public get currentId() {
		return this.chosenPumpId || this.id || null;
	}

	public get pumpDef() {
		return this.chosenPumpId && this.pumps?.find(x => x.Id === this.chosenPumpId) || {} as PumpResult;
	}

	public get version() {
		const v = this.pumpDef?.Version;
		if (!v?.VersionName?.length)
			return '';
		return `Version "${v.VersionName}" (${localDateString(v.Updated)}, ${v.UpdatedBy})`;
	}

	@Watch('doc.Data.Heads.TDH')
	public async onTDHChange() {
		const data = this.doc?.Data;
		if (!data)
			return;
		data.Heads.PDH = data.Heads.TDH;
	}

	public get searchTrigger() {
		const sp = this.searchParams;
		return !sp ? null : JSON.stringify(sp);
	}

	@Watch('searchTrigger')
	public async onSearchParamsChange() {
		const sp = this.searchParams;
		if (!sp) {
			// Reload the pump def, clearing any obsoleted duty data
			if (this.currentId) {
				this.loadPump(this.currentId);
				this.doc.Status = [];
				this.pumpBag = new ParamBag([this.doc]);
			}
			return;
		}
		this.setUrlParams(sp);
		store.commit(DebugMutations.clear);
		const res = await pumpsService.getPumps([sp], true, true);
		if (res?.length && res[0].DutyPoints?.length)
			store.dispatch(DebugActions.replace, { category: 'Pump', entries: res[0].DutyPoints[0].Messages || [] });

		const newPump = res?.length && res[0].DutyPoints?.length && res[0].DutyPoints[0];
		if (newPump.MinTrimPercentage && this.doc.Data.Pump.TrimPercentage < newPump.MinTrimPercentage)
			this.doc.Data.Pump.TrimPercentage = newPump.TrimPercentage = null;

		merge(newPump, this.doc.Data.Pump);
		this.doc.Status = newPump.Messages || [];
		this.pump = newPump;
	}

	private setUrlParams(sp: PumpSearch) {
		const setParams = {} as any;
		Object.keys(sp)
			.filter(x => x !== 'SpecificPumpId' && x !== 'DeratingMethod')
			.forEach(k => (sp as any)[k] ? setParams[k] = ('' + (sp as any)[k]).substring(0, 9) : null);

		const setKeys = Object.keys(setParams);
		if (setKeys.length) {
			const urlParams = setKeys.map(k => k + '=' + encodeURIComponent(setParams[k]));
			history.replaceState({}, undefined, `#/pumps/${sp.SpecificPumpId}?${urlParams.join('&')}`);
		}
	}

	private useUrlParams() {
		const p = this.$route.query;
		const keys = Object.keys(p || {});
		for (const k of keys) {
			switch (k) {
				case 'PDH':
				this.doc.Data.Heads.PDH = this.doc.Data.Heads.TDH = parseFloat(p[k] as string);
				break;
			case 'LiquidTemp':
				this.doc.Data.Liquid.Temp = parseFloat(p[k] as string);
				break;
			case 'CorrosionIndex':
				this.doc.Data.Liquid.CorrosionIndex = parseFloat(p[k] as string);
				this.corrFact = p[k] as string;
				break;
			case 'TrimPercentage':
				this.doc.Data.Pump.TrimPercentage = parseFloat(p[k] as string);
				break;
			default:
				const param = this.pumpBag.getParam(`Slurry.${k}`);
				if (param)
					param.setValue(parseFloat(p[k] as string));
				break;
			}
		}
	}

	public get searchParams() {
		const data = this.doc?.Data;
		if (data && this.currentId && data.Slurry.FlowRate > 0 && data.Heads.PDH > 0) {
			const corrFact = parseFloat(this.corrFact);
			return {
				SpecificPumpId: this.currentId,
				FlowRate: data.Slurry.FlowRate,
				SlurryDensity: data.Slurry.SlurryDensity,
				PDH: data.Heads.PDH,
				ManualHR: data.Slurry.ManualHR,
				ManualER: data.Slurry.ManualER,
				ManualQR: data.Slurry.ManualQR,
				DeratingMethod: DeratingMethod.Manual,
				FrothFactor: data.Slurry.FrothFactor,
				InletHead: data.Heads?.InletHead,
				TrimPercentage: data.Pump.TrimPercentage,

				// Material related stuff
				LiquidTemp: data.Liquid?.Temp || undefined,
				CorrosionIndex: corrFact > 0 ? corrFact : undefined
			} as PumpSearch;
		}
		return null;
	}

	public get ranges() {
		return uniqueValues(this.pumps, 'PumpRange').sort();
	}

	public pumpsInRange(range: string) {
		return this.pumps.filter(x => x.PumpRange === range);
	}

	public pumpItemText(i: PumpResult) {
		return `${i.DisplayName} ${i.ImpellerDescr} ${SizingInfo.pumpStatusIcon(i)}`;
	}

	public get curveInputs() {
		return this.searchParams != null ? [this.doc] : null;
	}

	@Watch('id')
	public loadPump(id: string) {
		if (!id)
			return;
		this.chosenPumpId = id;
		if (this.doc?.Data?.Pump?.Id === id)
			return;

		let pr = this.pumps.find(x => x.Id === id);
		if (pr) {
			pr = JSON.parse(JSON.stringify(pr));
			this.pump = pr;
			pr.HR = pr.ER = pr.QR = null;

			if (pr.MinTrimPercentage && this.doc.Data.Pump.TrimPercentage >= pr.MinTrimPercentage)
				pr.TrimPercentage = this.doc.Data.Pump.TrimPercentage;
			else {
				pr.TrimPercentage = null;
				this.doc.Data.Pump.TrimPercentage = null;
			}

			merge(pr, this.doc.Data.Pump);

			if (this.range !== pr.PumpRange)
				this.range = pr.PumpRange;
		}
	}

	public gotoPump(id: string) {
		history.pushState({}, undefined, '#/pumps/' + id);
		this.loadPump(id);
	}

	public nudgeFlow(delta: number) {
		const val = this.doc.Data.Slurry.FlowRate;
		if (!val)
			return;
		const step = Math.pow(10, Math.ceil(0.001 + Math.log10(3600.0 * val)) - 2);
		this.pumpBag.set('Slurry.FlowRate', val + delta * step / 3600.0);
	}

	public nudgeTDH(delta: number) {
		const val = this.doc.Data.Heads.TDH;
		if (!val)
			return;
		const step = Math.pow(10, Math.ceil(0.001 + Math.log10(val)) - 2);
		this.pumpBag.set('Heads.TDH', val + delta * step);
	}

	public async created() {
		this.pumpBag = new ParamBag([this.doc]);
		this.useUrlParams();
		try {
			const res = await Axios.get<PumpResult[]>(pumpsEndpoint);
			this.pumps = res?.data || [];
			if (this.id)
				this.loadPump(this.id);
			return res;
		} catch (e) {
			console.error('Failed to get pump list');
		}
	}

	public async reload() {
		const res = await Axios.get<PumpResult[]>(pumpsEndpoint);
		this.pumps = res?.data || [];
		this.loadPump(this.currentId);
		this.triggerCurveUpdate++;
	}
}
</script>
