<template>
	<span :class="{ 'loading' : loading }">
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 7" width="25px" height="11px">
			<rect v-if="bkg" :fill="bkg" x="0" y="0" width="19" height="7" rx="2" ry="2"></rect>
			<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
				<rect v-if="green" fill="#51D645" x="1" y="1" width="5" height="5" rx="2.5"></rect>
				<rect v-else fill="#B3B3B3" x="1" y="1" width="5" height="5"></rect>
				<rect v-if="yellow" fill="#FBA700" x="7" y="1" width="5" height="5" rx="2.5"></rect>
				<rect v-else fill="#B3B3B3" x="7" y="1" width="5" height="5"></rect>
				<rect v-if="red" fill="#EB2814" x="13" y="1" width="5" height="5" rx="2.5"></rect>
				<rect v-else fill="#B3B3B3" x="13" y="1" width="5" height="5"></rect>
				<line v-if="dr === 'N/A'" x1="1" y1="6" x2="18" y2="1" fill="none" stroke="red" stroke-width="1"></line>
			</g>
		</svg>
	</span>
</template>

<style scoped>
	.loading {
		animation-name: throb;
		animation-duration: 1000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		animation-direction: alternate;
	}

	@keyframes throb {
		from { opacity: 1.0; }
		to { opacity: 0.1; }
	}
</style>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop } from 'vue-property-decorator';

	@Component
	export default class DeliveryReadinessIcon extends Vue {
		@Prop() public dr: string;
		@Prop() public loading: boolean;
		@Prop() public bkg: string;

		public get green() { return this.dr === 'A'; }
		public get yellow() { return this.dr === 'B'; }
		public get red() { return this.dr === 'C'; }
	}
</script>
