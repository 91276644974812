
interface INetworkState {
	connected: boolean;
}

const state: INetworkState = {
	connected: true
};

const getters = {
	connected: (currentState: INetworkState) => !!(currentState.connected && window.navigator.onLine)
};

const actions = {
	setConnected: ({ commit }: any, status: boolean = true) => commit(status ? 'connect' : 'disconnect')
};

const mutations = {
	connect(currentState: any) {
		currentState.connected = true;
	},
	disconnect(currentState: any) {
		currentState.connected = false;
	}
};

export const enum NetworkActions {
	setConnected = 'network/setConnected',
}

export const enum NetworkGetters {
	connected = 'network/connected',
}

export const enum NetworkMuts {
	connected = 'network/connect',
	disconnect = 'network/disconnect'
}

export const network = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
