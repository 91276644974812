<template>
	<v-app id="inspire">
		<v-content>
			<v-container fluid fill-height>
				<v-layout align-center justify-center>
					<v-flex xs12 sm8 md4>
						<Login />						
					</v-flex>
				</v-layout>
			</v-container>
		</v-content>
	</v-app>
</template>

<script lang="ts">
	import Login from '@/components/Login.vue';
	import Vue from 'vue';
	import { Component } from 'vue-property-decorator';

	@Component({
		components: {
			Login
		}
	})
	export default class Home extends Vue {}
</script>
