import ApiService from './api.service';
import { AccessTokenResponse, UserInfoResponse, PublicUserInfo } from 'types/dto/AccessToken';
import { EncodeDecode } from '@/common/EncodeDecode';
import InsightService from './insight.service';

class AuthService {
	private readonly API = process.env.VUE_APP_AUTHSERVICE_ENDPOINT;
	private readonly CALLBACK_PROXY = process.env.VUE_APP_AUTH_CALLBACK_PROXY;
	private readonly REDIRECT_OVERRIDE = process.env.VUE_APP_AUTH_CALLBACK_REDIRECT_OVERRIDE;
	private readonly AUTH_URL = process.env.VUE_APP_AUTH_URL;
	private readonly CLIENT_ID = process.env.VUE_APP_AUTH_CLIENT_ID;
	private readonly AUTH_LOGOUT_URL = process.env.VUE_APP_AUTH_LOGOUT_URL;

	public accessToken(grant: string) {
		return ApiService.get<AccessTokenResponse>(this.API + '/authentication/token/' +
			grant + '/?redirect=' + this.CALLBACK_PROXY);
	}

	public refreshToken(grant: string) {
		return ApiService.get<AccessTokenResponse>(this.API + '/authentication/refresh/' + grant);
	}

	public userInfo() {
		return ApiService.get<UserInfoResponse>(this.API + '/authentication/userinfo');
	}

	public async userInfos(ids: string[]) {
		if (!ids)
			return [];
		const list = ids.map(x => x.trim()).filter(x => x).join(',');
		if (!list.length)
			return [];
		return ApiService.get<PublicUserInfo[]>(this.API + '/authentication/userinfos?ids=' + list);
	}

	public async searchUsers(text: string) {
		if (!text || !text.trim().length)
			return [];
		return ApiService.get<PublicUserInfo[]>(this.API + '/authentication/search?q=' + encodeURIComponent(text));
	}

	public logout() {
		ApiService.get<void>(this.API + '/authentication/revoke').then(() => {
			const baseUrl = window.location.origin;
			const logoutUrl = `${this.AUTH_LOGOUT_URL}${encodeURIComponent(baseUrl)}&client_id=${this.CLIENT_ID}`;
			window.location.href = logoutUrl;
		});
	}

	public redirect(returnPath?: string) {
		let state = this.REDIRECT_OVERRIDE || '';
		if (returnPath?.length && returnPath !== '/')
			state += returnPath;

		let redirectUri = this.AUTH_URL +
			'?response_type=code' +
			'&scope=openid' +
			'&client_id=' + encodeURIComponent(this.CLIENT_ID) +
			'&redirect_uri=' + encodeURIComponent(this.CALLBACK_PROXY);

		if (state?.length)
			redirectUri += '&state=' + EncodeDecode.b64EncodeUnicode(state);
		InsightService.trackEvent('Auth:RedirectToLogin', returnPath);
		window.location.href = redirectUri;
	}
}
export default new AuthService();
