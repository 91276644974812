<template>
	<v-container>
		<v-layout wrap>
			<v-flex xs12>
				<h1 class="text-xs-center">System health check</h1>
				<ul>
					<li v-for="c of checks" :key="c.url" class="pb-4">
						<i>{{ c.url }}</i>
						<br />
						<v-icon small :color="color(c)">lens</v-icon>&nbsp;{{ c.httpResult }} after {{ c.time }}&nbsp;ms
						<b v-if="c.version"><br />Version {{ c.version }}</b>
					</li>
				</ul>
				<v-btn :disabled="working" @click="check">Check again</v-btn>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script lang="ts">
	import Vue from 'vue';
	import { Component } from 'vue-property-decorator';
	import Axios, { AxiosResponse } from 'axios';

	const enum Status { Checking, Good, Bad }

	interface Check {
		url: string;
		status?: Status;
		httpResult?: string;
		version?: string;
		time?: number;
	}

	interface HealthResult {
		name: string;
		version: string;
		location: string;
	}

	@Component
	export default class Health extends Vue {
		public readonly checks: Check[] = [
			process.env.VUE_APP_CALCSERVICE_ENDPOINT + '/health',
			process.env.VUE_APP_PUMPSERVICE_ENDPOINT + '/health',
			process.env.VUE_APP_AUTHSERVICE_ENDPOINT + '/health',
			process.env.VUE_APP_BUILDSERVICE_ENDPOINT + '/health',
			process.env.VUE_APP_REPORTINGSERVICE_ENDPOINT + '/health',
			process.env.VUE_APP_MSGSERVICE_ENDPOINT + '/health'
		].map(url => ({ url, status: null, httpResult: null, version: null }));

		public mounted() {
			this.check();
		}

		public get working() {
			return this.checks.some(x => x.status === Status.Checking);
		}

		public color(c: Check) {
			return c.status === Status.Good ? 'green' : c.status === Status.Bad ? 'red' : 'grey';
		}

		public check() {
			this.checks.forEach(async c => {
				c.status = Status.Checking;
				c.version = null;
				c.httpResult = 'Working...';
				let res: AxiosResponse<HealthResult>;
				const time = new Date().getTime();
				try {
					res = await Axios.get<HealthResult>(c.url);
					c.httpResult = res?.status + ' ' + res.statusText;
					c.version = res?.data && res.data.version || null;
				} catch (ex) {
					c.httpResult = (ex as any).message || res?.status + ' ' + res.statusText || 'Unknown error';
				} finally {
					c.time = new Date().getTime() - time;
					c.status = res?.status >= 200 && res.status < 300 ? Status.Good : Status.Bad;
				}
			});
		}
	}
</script>