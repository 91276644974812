<template>
	<article class="padding content content-wrapper" v-if="project && values">
		<v-container>
			<v-row>
					<v-col cols="12" xl="4" lg="6" md="6" sm="12">
						<v-card outlined class="base-padding">
							<v-card-title><h3>Customer data</h3>	
							</v-card-title>
							<v-card-text>
								<div class="pb-4" v-if="project && project.QuoteId">
									<i>Connected to
										<v-tooltip bottom>
											<template #activator="{ on }">
												<a v-on="on" :href="quoteUrl" class="text-decoration-none">
													<span class="text-decoration-underline">QUOTATION</span>&nbsp;&nbsp;<v-icon x-small> $launch</v-icon>
												</a>
											</template>
											Open in PQP
										</v-tooltip>
									</i>
								</div>
								<TextField :param="values.getParam('CustomerReference')" :locked="metaLocked" class="pt-1" />
								<TextField :param="values.getParam('CustomerName')" :locked="metaLocked" />
								<TextField :param="values.getParam('Name')" :rules="rules" :locked="metaLocked" hideDetails="true" />
							</v-card-text>
						</v-card>
					</v-col>
					<v-col cols="12" xl="4" lg="6" md="6" sm="12">
					<v-card outlined class="base-padding">
							<v-card-title><h3>Project data</h3></v-card-title>
							<v-card-text>
								<SelectField :param="values.getParam('Site.SystemOfUnits')" :locked="locked" />
								<TextField :param="values.getParam('Notes')" textArea="true" :locked="locked" hideDetails="true" />
							</v-card-text>
						</v-card>
					</v-col>
					<v-col cols="12" xl="4" lg="6" md="6" sm="12">
						<v-card outlined class="base-padding">
							<v-card-title><h3>Default site conditions</h3></v-card-title>
							<v-card-subtitle v-if="project && !project.QuoteId"><span class="small">Will be used for new sizings</span></v-card-subtitle>
							<v-card-text class="pb-0">
								<InfoBox v-if="project && project.QuoteId">
									Site conditions are set per sizing when the project is based on a quotation.
								</InfoBox>
								<template v-else>
									<UnitNumeric :param="values.getParam('Site.Altitude')" :locked="locked" />
									<UnitNumeric :param="values.getParam('Site.AmbientTemp')" :locked="locked" />
									<UnitNumeric :param="values.getParam('Site.MainsVoltage')" :locked="locked" />
									<SelectField :param="values.getParam('Site.MainsFreq')" :locked="locked" />
								</template>
							</v-card-text>
						</v-card>
					</v-col>
			</v-row>
		</v-container>
	</article>
</template>

<style lang="css" scoped>

	@media only screen and (max-width: 960px) {
		.content-wrapper {
			margin-top:50px;
		}
	}
</style>

<script lang="ts">
	import Vue from 'vue';
	import Component from 'vue-class-component';
	import { Prop } from 'vue-property-decorator';
	import { PumpProject } from 'types/dto/CalcServiceDomain';
	import { ParamBag } from '@/common/ParamBag';
	import SelectField from '@/components/Fields/SelectField.vue';
	import TextField from '@/components/Fields/TextField.vue';
	import UnitNumeric from '@/components/Fields/UnitNumeric.vue';
	import InfoBox from '@/components/InfoBox.vue';
	import store, { NetworkGetters } from '@/store';
	import SizingInfo from '@/common/SizingInfo';
	import { getQuoteUrl } from '@/common/Tools';

	const quoteBaseUrl: string = process.env.VUE_APP_PQP_BASE_URL;

	@Component({
		name: 'project-editor',
		components: {
			SelectField,
			TextField,
			UnitNumeric,
			InfoBox
		}
	})
	export default class ProjectEditor extends Vue {
		@Prop() public project: PumpProject;
		@Prop() public values: ParamBag;

		public rules = (value: any) => !!value || 'Required.';

		public get locked() {
			return !store.get(NetworkGetters.connected) || SizingInfo.isLocked(this.project);
		}

		public get metaLocked() {
			return this.locked || this.project?.QuoteId != null;
		}

		public get quoteUrl() {
			return getQuoteUrl(this.project);
		}
	}
</script>
