<template>
	<div>
		<div v-for="filter of usableFilters" :key="filter.propName">
			<div style="padding: 12px 4px 0 0; float: left">
				<label class="v-label">{{ readable(filter.propName, xlatePrefix) }}: </label>
			</div>
			<v-chip-group column style="margin-bottom: 0">
				<template v-for="f of filter.values">
					<transition name="fade" :key="f.value">
						<v-chip class="filter" v-if="!f.parent || f.parent.enabled" :class="{ vague: f.noMatches, child: !!f.parent, selected: f.enabled  }"
							:disabled="f.noMatches" :input-value="f.enabled" @click="f.enabled = !f.enabled" v-keyboard-click>
							<v-icon v-if="f.group && !f.enabled" small class="plus-icon">$plus</v-icon>
							<v-icon v-if="f.enabled" size="18">$chipCheck</v-icon>&nbsp;{{ readable(f.value, filter.xlateKey) }}
						</v-chip>
					</transition>
				</template>
			</v-chip-group>
		</div>
		<a href="#" class="allcaps" v-show="anyEnabled" @click.stop.prevent="clearAll">Clear filters</a>
	</div>
</template>

<style lang="scss" scoped>
	@import '@/sass/_variables.scss';

	.v-icon {
		margin: 1px;
		margin-left: -4px; // Align circle check/plus to chip outline; it seems to have wasted space on its left
	}

	.v-icon.plus-icon {
		border-radius: 50%;
		border: 1px solid #000;
		background-color: $selection-lighten-1;
		align-items: center;
	}
	
	.vague {
		opacity: 0.5;
	}

	.selected {
		background-color: $selection-lighten-1 !important;
		border:0 !important;
	}

	.child {
		font-style: italic;
	}

	.v-chip {
		margin-right: 4px !important;
		margin-bottom: 4px !important;
	}

	.fade-enter-active, .fade-leave-active {
  		transition: transform .4s;
	}
	.fade-enter, .fade-leave-to {
		transform: scaleX(0.05)
	}
	.fade-enter-to, .fade-leave {
		transform: scaleX(1)
	}
</style>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop } from 'vue-property-decorator';
	import ItemFilter from '@/common/ItemFilter';
	import UnitValue from '@/common/UnitValue';

	@Component
	export default class FilterList extends Vue {
		@Prop() public filters: Array<ItemFilter<any>>;
		@Prop() public xlatePrefix: string;

		public readable(s: any, xlateKey: string) {
			if (s === null)
				s = this.$t('common.none') as string;
			return UnitValue.readableString(s, xlateKey);
		}

		public get usableFilters() {
			return this.filters.filter(x => x.values?.length > 1);
		}

		public get anyEnabled() {
			// Require minimum 3 filters to stop having "Clear filter" in trivial places such as motors and VBelt
			return this.filters.length > 2 && this.filters.some(x => x.enabled);
		}

		public clearAll() {
			this.filters.forEach(x => x.clear());
		}

	}
</script>
