import { PipingModule } from 'types/dto/PipingModule';
import { MessageSeverity, ValidationResult } from 'types/dto/CalcServiceDomain';
import pumpsService from '@/services/pumps.service';
import store, { DebugActions } from '@/store';

export interface PipingFilter {
	partType?: string;
	liquidTemp?: number;
	pressure?: number;
	particleD80?: number;
	inNominalSize?: any;
	outNominalSize?: any;
	inDiameter?: number;
	outDiameter?: number;
	reverseFlow?: boolean;
	componentLength?: any;
	cuttable?: boolean;
	cutLength?: number;
	drillingPatterns?: string[];
	moduleName?: string;
	includeInvalid?: boolean;
}

export async function getMatchingPipes(filter: PipingFilter) {
	return await pumpsService.getPipingModules(filter);
}

export async function getPipeMessages(filter: PipingFilter, moduleName: string) {
	return await pumpsService.getPipingMessages(filter, moduleName);
}

export function isCuttable(x: PipingModule) {
	return x.properties?.canBeCut == true || x.properties?.canBeCut === 'true';
}

export function summarizeErrors(modules: PipingModule[]) {
	if (!modules?.length)
		return {};

	const reasons: any = {};
	function addMsg(x: ValidationResult) {
		if (x.Severity < MessageSeverity.Error || !x.Message?.length)
			return;
		if (!reasons[x.Message])
			reasons[x.Message] = 1;
		else
			reasons[x.Message]++;
	}
	modules.forEach(m => m.messages?.forEach(addMsg));
	return reasons;
}

export function equivalentBendLength(m: PipingModule, pipeParamName?: string) {
	if (!m?.properties)
		return;

	const bendAngle = m.properties.bendAngle as any as number;
	const bendRadius = m.properties.bendRadius;
	const diameter = m.properties.innerDiameterIn;
	if ([bendAngle, bendRadius, diameter].some(x => !(x > 0)))
		return;

	const round = (x: number) => Math.round(10.0 * x) / 10.0;

	// Calculate R/D, capped to 2 <= x <= 3
	const rawStraightness = bendRadius / diameter;
	const cappedStraightness = Math.min(Math.max(2, rawStraightness), 3);

	// Scale (L/D)eq (# of diameters) linearly between 2: 26 and 3: 20.6
	// See https://neutrium.net/fluid-flow/pressure-loss-from-fittings-equivalent-length-method/ [90° Elbow Curved, Threaded]
	const diaEquivalents = 26 - (26 - 20.6) * (cappedStraightness - 2);

	// Convert to eq. length
	const eqLengthMm = diaEquivalents * diameter * bendAngle / 90.0;
	const eqLengthM = round(eqLengthMm / 1000.0);

	if (pipeParamName) {
		const paramName = `${pipeParamName}.ExtraLength`;
		store.dispatch(DebugActions.replace, {
			category: paramName,
			entries: [{
				Severity: MessageSeverity.Debug,
				Source: 'PipingManager',
				Message: `R/D = ${round(cappedStraightness)} (raw ${round(rawStraightness)}) → (L/D)eq = ${round(diaEquivalents)} → eqLength = ${eqLengthM} m`,
				ParamName: paramName
			}]
		});
	}

	return eqLengthM;
}
