<template>
	<v-autocomplete
		:label="label || 'With user'"
		value="value"
		:items="users"
		:loading="loading"
		:search-input.sync="search"
		:disabled="disabled"
		item-text="DisplayName"
		item-value="id"
		return-object
		:placeholder="placeHolder"
		:append-icon="null"
		hide-no-data
		class="user-selector"
		rounded
		v-focus
		@input="select"
		@keyup.enter="$emit('enter')"
		@keyup.esc="$emit('esc')"
	/>
</template>

<style lang="css">
	.v-text-field--rounded>.v-input__control>.v-input__slot {
		padding: 0px 0px 15px 0 !important;
	}
	.v-select:not(.v-text-field--single-line):not(.v-text-field--outlined) .v-select__slot>input {
		font-size: 12px;
	}
	.v-application--is-ltr .v-chip .v-chip__close.v-icon.v-icon--right {
		height: 14px !important;
		width: 14px !important;
	}
</style>

<script lang="ts">
	import Vue from 'vue';
	import Component from 'vue-class-component';
	import { Prop, Watch } from 'vue-property-decorator';
	import store, { AuthGetters, SnackActions } from '@/store';
	import AuthService from '@/services/auth.service';

	export interface IUserEntry {
		id: string;
		DisplayName: string;
	}

	@Component
	export default class UserSelector extends Vue {
		@Prop() public value: IUserEntry;
		@Prop() public disabled: boolean;
		@Prop() public label: string;

		public users: IUserEntry[] = [];
		public loading: boolean = false;
		public search: string = null;
		public lastSearch: string = null;
		private static readonly isEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

		private get me() {
			return store.get(AuthGetters.userId);
		}

		public get isMailSearch() {
			const s = this.search?.trim().toLowerCase();
			if (!s || !s.length)
				return false;
			return s.length >= 8 && UserSelector.isEmail.test(s);
		}

		public get placeHolder() {
			if (store.get(AuthGetters.internalUser))
				return 'Start typing to find users...';
			return 'Enter e-mail address...';
		}

		public select(user: IUserEntry) {
			this.lastSearch = user?.DisplayName ?? '';
			this.$emit('input', user);
		}

		@Watch('search')
		public async doSearch(s: string) {
			if (this.loading || s == null)
				return;

			s = s.trim().toLowerCase();
			if (!s.length) {
				// Clear text => clear search
				this.lastSearch = null;
				this.select(null);
				this.users = [];
			}

			// Too short to search for?
			if (s.length < 2)
				return;

			// Non-mail search not allowed?
			if (!this.isMailSearch && !store.get(AuthGetters.internalUser))
				return;

			const last = this.lastSearch?.toLowerCase();
			if (last) {
				if (this.isMailSearch) {
					// Skip repeating search if nothing changed
					if (last === s)
						return;
				} else {
					// Don't make a more specific search call since we would not get more results anyway
					if (last.length <= s.length && s.substr(0, last.length) === last)
						return;
				}
			}
			this.lastSearch = s;

			try {
				this.loading = true;
				const res = await AuthService.searchUsers(s) || [];
				this.users = res.map(x => this.createResult(res, x));
			} catch (ex) {
				store.dispatch(SnackActions.set, 'Failed to search for users. Please try again.');
			} finally {
				this.loading = false;
			}
		}

		private createResult(items: any[], u: any) {
			const entry = { id: u.id, DisplayName: `${u.FirstName} ${u.LastName}` };

			if (this.isMailSearch && u.Email)
				entry.DisplayName += ` <${u.Email}>`;
			else if (items.some(x => x.FirstName === u.FirstName && x.LastName === u.LastName && x.id !== u.id))
				entry.DisplayName += ` <${u.Email ?? u.id}>`;

			if (this.me === entry.id)
				entry.DisplayName += ` (You)`;
			return entry;
		}
	}
</script>
