<template>

	<v-row class="project-filters">
		<!-- Search column -->
		<v-col>
			<v-text-field hide-details :disabled="offline"  outlined ref="sizingSearchBox" @keyup="searchChanged" class="search-bar pt-1">
			<template slot="prepend-inner">
						<v-icon class="search-icon">$search</v-icon>
						<v-progress-circular v-if="searching" size="20" indeterminate class="search-loader" />
				</template>
			</v-text-field>
		</v-col>
		<!-- Action column -->
		<v-col class="center-horizontal align-right inline">
			<v-tooltip top max-width="500" open-delay="500" nudge-top="-4">
				<template v-slot:activator="{ on }">
					<v-btn v-on="on" :disabled="offline" @click="reloadProjects" text color="primary" small class="action-btn">
						<v-icon>$update</v-icon>
					</v-btn>
				</template>
				<span>Look for changes</span>
			</v-tooltip>
			<v-menu class="action-menu" offset-y>
				<template v-slot:activator="{ on }">
					<v-btn id="_projectSortBtn" v-on="on" text color="primary" small class="action-btn">
						<v-icon size="18">sort</v-icon>
					</v-btn>
				</template>
				<v-list min-width="150">
					<v-list-item @click="sortProjects('Created')">
						<v-list-item-content>Oldest first</v-list-item-content>
						<v-list-item-action><v-icon v-if="sortOn === 'Created'">$checkMark</v-icon></v-list-item-action>
					</v-list-item>
					<v-list-item @click="sortProjects('-Created')">
						<v-list-item-content>Newest first</v-list-item-content>
						<v-list-item-action><v-icon v-if="sortOn === '-Created'">$checkMark</v-icon></v-list-item-action>
					</v-list-item>
					<v-list-item @click="sortProjects('Name')">
						<v-list-item-content>Project name</v-list-item-content>
						<v-list-item-action><v-icon v-if="sortOn === 'Name'">$checkMark</v-icon></v-list-item-action>
					</v-list-item>
					<v-list-item @click="sortProjects('CustomerName')">
						<v-list-item-content>Customer name</v-list-item-content>
						<v-list-item-action><v-icon v-if="sortOn === 'CustomerName'">$checkMark</v-icon></v-list-item-action>
					</v-list-item>
				</v-list>
			</v-menu>
			<v-tooltip top max-width="500" open-delay="500" activator="#_projectSortBtn" nudge-top="-4">
				<span>Project sort order</span>
			</v-tooltip>
			<v-menu class="action-menu" offset-y>
				<template v-slot:activator="{ on }">
					<v-btn :disabled="offline" id="_projectFilterBtn" v-on="on" text :color="filterEnabled ? 'primary' : 'grey'" small class="action-btn">
						<v-icon  size="18">$filter</v-icon>
					</v-btn>
				</template>
				<v-list min-width="150">
					<v-list-item v-for="days of [7, 30, 100, 365]" :key="'cdays' + days" @click="setProjectFilter(days)">
						<v-list-item-content v-if="days === 365">Created past year</v-list-item-content>
						<v-list-item-content v-else>Created last {{days}} days</v-list-item-content>
						<v-list-item-action><v-icon v-if="showCreatedDays === days">$checkMark</v-icon></v-list-item-action>
					</v-list-item>
					<v-list-item @click="setProjectFilter(0)">
						<v-list-item-content>All projects</v-list-item-content>
						<v-list-item-action><v-icon v-if="!filterEnabled">$checkMark</v-icon></v-list-item-action>
					</v-list-item>
				</v-list>
			</v-menu>
			<v-tooltip top max-width="500" open-delay="500" activator="#_projectFilterBtn" :value="showFilterTooltip" nudge-top="-4">
				<span v-if="showFilterTooltip">A default project filter has been applied. Click <v-icon size="18" color="white">$filter</v-icon> to change it.</span>
				<span v-else-if="!filterEnabled">Project filter</span>
				<span v-else-if="showCreatedDays">Created last {{showCreatedDays}} days</span>
				<span v-else>Unknown project filter applied</span>
			</v-tooltip>
		</v-col>
		<!-- Toggle shared column -->
		<v-col class="share-col">
			<v-switch class="switch-control" hide-details :disabled="offline" v-model="showSharedProjects">
				<template #label>
					<span class="small">Show projects shared with me</span>
				</template>
			</v-switch>
		</v-col>
	</v-row>
</template>

<style lang="scss" scoped>
	@import '@/sass/_variables.scss';

	.project-filters {
		margin: auto;
  		width: 100%;
		background-color: $grey-lighten-2;
	}

	.share-col {
		max-width: 240px;
		min-width: 240px;
	}

	.switch-control {
		 float:right;
		 padding-top:0;
	}

	.action-btn {
		min-width:30px !important;
		max-width: 30px;
		margin: 0px 5px 0px 5px;
	}

	.action-menu {
		top: -12px;
	}

</style>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import ProjectFilter from '@/common/ProjectFilter';
import SizingService from '@/services/sizing.service';
import store, { AuthActions, AuthGetters, NetworkGetters, ProjectActions, ProjectGetters } from '@/store';
import { PumpProject } from 'types/dto/CalcServiceDomain';
import Debounce from '@/common/Debounce';
import { UserSetting } from '@/store/modules/auth.store';

function safeNum(s: string) {
	if (s == null)
		return null;
	if (typeof s === 'number')
		return s;
	return parseInt(s, 10);
}

@Component({})
export default class ProjectFilterPanel extends Vue {
	public showSharedProjects: boolean = false;
	public showFilterTooltip?: boolean = null;
	public searchPhrase: string = '';
	public searching: number = 0;
	private lastGet: number = 0;
	private armed: boolean = false;

	public async created() {
		await this.initializeList();
	}

	public get userId() {
		return store.get(AuthGetters.userId);
	}

	@Watch('userId')
	public userChanged(userId: string, oldUserId: string) {
		this.initializeList();
	}

	private async initializeList() {
		if (this.offline || !this.userId)
			return;
		if (this.missingFilterSettings) {
			// User is loaded but has no filters; set defaults and show a message about this for 10 secs
			this.showCreatedDays = 30;
			this.showFilterTooltip = true;
			setTimeout((): void => this.showFilterTooltip = null, 10000);
		}
		await this.loadProjects();
		this.armed = true;
	}

	public get sortOn() {
		return store.get(AuthGetters.setting, UserSetting.projectSortOrder) || 'Created';
	}

	public set sortOn(value: string) {
		store.dispatch(AuthActions.updateSetting, { key: UserSetting.projectSortOrder, value });
	}

	public get showCreatedDays() {
		return safeNum(store.get(AuthGetters.setting, UserSetting.projectCreatedDays));
	}

	public set showCreatedDays(value: number) {
		store.dispatch(AuthActions.updateSetting, { key: UserSetting.projectCreatedDays, value });
	}

	public get missingFilterSettings() {
		return store.get(AuthGetters.setting, UserSetting.projectCreatedDays) == null;
	}

	public get offline() {
		return !store.get(NetworkGetters.connected);
	}

	public get filter() {
		const filter = new ProjectFilter();
		filter.shared = this.showSharedProjects || false;
		const nowDate = new Date();
		nowDate.setUTCSeconds(0);
		nowDate.setUTCMilliseconds(0);

		const now = nowDate.getTime();
		if (this.showCreatedDays)
			filter.createdAfter = new Date(now - 24 * 3600 * 1000 * this.showCreatedDays);
		return filter;
	}

	public get filterJson() {
		return JSON.stringify(this.filter) + this.offline;
	}

	@Watch('filterJson')
	public onFilterChange() {
		if (this.offline)
			return;
		this.$emit('filter', this.filter);
		if (this.armed)
			this.loadProjects();
	}

	public sortProjects(prop: keyof PumpProject | '-Created') {
		this.sortOn = prop;
		this.$emit('sort', this.sortOn);
	}

	public setProjectFilter(createdDays: number) {
		if (createdDays != null) {
			if (createdDays && this.showCreatedDays === createdDays)
				createdDays = 0;
			this.showCreatedDays = createdDays;
		}
	}

	public reloadProjects() {
		const timeSinceUpdate = new Date().getTime() - this.lastGet;
		if (timeSinceUpdate > 1000)
			this.loadProjects();
	}

	// Workaround for v-model since it causes reflow berserk and input box lag
	public searchChanged() {
		const ref = this.$refs.sizingSearchBox as Vue;
		const el = ref?.$el?.getElementsByTagName('input');
		if (el?.length)
			this.searchPhrase = el[0].value || '';
	}

	public get canonicalizedSearchPhrase() {
		const str = this.searchPhrase?.trim().toLowerCase();
		return !str || (str.length < 2) ? null : str;
	}

	public get filterEnabled() {
		return this.showCreatedDays > 0;
	}

	private readonly searchThrottle = new Debounce('search', 500, () => this.search());

	@Watch('canonicalizedSearchPhrase')
	public triggerSearch(s: string) {
		if (!s)
			this.$emit('search', null);
		else
			this.searchThrottle.trigger();
	}

	public async search() {
		const s = this.canonicalizedSearchPhrase;
		if (!s) {
			this.$emit('search', null);
			return;
		}

		try {
			this.searching++;
			const res = await SizingService.search(s, this.showSharedProjects);

			// A new search was triggered while searching - abort
			if (s !== this.canonicalizedSearchPhrase)
				return;

			const result = { phrase: this.searchPhrase, result: res, hidden: [] as string[] };
			if (res?.Hits && this.filterEnabled) {
				const foundProjs = new Set<string>(res.Hits.map(x => x.ProjectId));
				const missing: string[] = [];
				foundProjs.forEach(pid => {
					if (!store.get(ProjectGetters.project, pid))
						missing.push(pid);
				});
				result.hidden = missing;
			}

			this.$emit('search', result);
		} finally {
			this.searching--;
		}
	}

	private async loadProjects() {
		this.lastGet = new Date().getTime();
		this.$emit('sort', this.sortOn);
		return store.dispatch(ProjectActions.getProjects, this.filter);
	}
}
</script>
