<template>
	<v-card flat fill-height class="pr-0 mr-0 mb-2">
		<v-card-title class="pt-1 pl-0"><h3>{{ description }}</h3></v-card-title>
		<v-card-text class="pa-0">
			<v-list class="pa-0">
				<v-list-item-group>
					<v-list-item  v-for="(item, index) in availableBearingAssemblies" :key="key(item)"
						class="v-list-border" :class="selectedKey === key(item) ? 'sel-list-item': 'active-item'" @click.stop="toggle(index)" active-class="active-item"	
						@mouseover="showCommentIcon = selectedKey === key(item)" @mouseout="showCommentIcon = false">
						<v-list-item-icon>
							<v-icon :color="selectedKey === key(item) ? 'selection' : 'grey'" :disabled="locked" @click.stop="itemClicked(item)">
								{{ selectedKey === key(item) ? 'radio_button_on' : 'radio_button_off' }}
							</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>
							<h3 class="d-flex align-center">
							{{ item.DisplayName || item.MaterialNumber }} {{ item.BearingArrangement && $t('BearingArrangement.' + item.BearingArrangement) }}
								<span class="ml-1" v-if="item.MaterialNumber != item.DisplayName"> ({{ item.MaterialNumber }}) </span>
								<v-tooltip bottom :disabled="!hasWarning(item) && !hasInfo(item)" :color="hasWarning(item) ? 'warning' : 'selection'">
									<template v-slot:activator="{ on }">
										<span v-on="on">
											<v-icon v-if="hasWarning(item)" small color="warning" class="ml-1 mt-n1 cursor-help">error</v-icon>
											<v-icon v-if="hasInfo(item)" small color="selection" class="ml-1 mt-n1 cursor-help">info</v-icon>
										</span>
									</template>
									<div v-for="w of warnings(item)" v-text="w" :key="w" />
								</v-tooltip>
								<DeliveryReadiness v-if="!!pumpId" partType="BearingAssembly" :id="item.Id" :pumpId="pumpId" :materialNumber="item.MaterialNumber" />
							</h3>
								<CommentIcon v-if="target && selectedKey === key(item)" :target="target" field="BearingAssembly" type="Comment" :visible="showCommentIcon" />
							</v-list-item-title>
							<v-list-item-subtitle class="grid auto" v-if="!expandContent[index]">
								<ParamList :params="getValueBag(item)" :names="assyPropsBrief" :inline="true" />
							</v-list-item-subtitle>
							<v-list-item-content class="grid auto" v-else>
								<BADrawing :id="item.Id" class="max-height mb-3"/>
								<ParamList :params="getValueBag(item)" :names="assyProps1" />
								<ParamList :params="getValueBag(item)" :names="assyProps2" />
								<a href="#" @click.stop.prevent="viewBA(item)" v-if="isDevloper" class="mt-1 allcaps">Open in BA viewer</a>
							</v-list-item-content>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-card-text>
	</v-card>
</template>

<style lang="scss" scoped>
	.max-height {
		max-height: 50px;
	}
</style>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { BearingAssemblyResult } from 'types/dto/PumpBuild';
	import { ParamBag } from '@/common/ParamBag';
	import ParamList from '@/components/ParamList.vue';
	import CommentIcon from '@/components/CommentIcon.vue';
	import DeliveryReadiness from '@/components/DeliveryReadiness.vue';
	import BADrawing from '@/components/BADrawing.vue';
	import BuildManager from '@/common/BuildManager';
	import { DutyPoints } from '@/common/DutyPoints';
	import { MessageSeverity } from 'types/dto/CalcServiceDomain';
	import { tryToTranslate } from '@/i18n';
	import store, { AuthGetters } from '@/store';

	const BAGROUP = 'BearingAssembly';

	@Component({
		components: {
			ParamList,
			CommentIcon,
			DeliveryReadiness,
			BADrawing
		}
	})
	export default class BearingAssemblyList extends Vue {
		@Prop() public values: ParamBag;
		@Prop() public locked: boolean;
		@Prop() public buildManager: BuildManager;
		@Prop() public dutyPoints: DutyPoints;
		@Prop() public target: string;

		public expandContent: boolean[] = [];

		private static readonly ASSYPROPS1 = ['AxialLoad', 'RadialLoad', 'CritSpeed'].map(x => `${BAGROUP}.${x}`);
		private static readonly ASSYPROPS2 = ['BearingWetLife', 'BearingDriveLife', 'DeflectionGland', 'DeflectionImpeller'].map(x => `${BAGROUP}.${x}`);
		private static readonly ASSYPROPSBRIEF = ['BearingWetLife', 'BearingDriveLife'].map(x => `${BAGROUP}.${x}`);

		public readonly assyProps1 = BearingAssemblyList.ASSYPROPS1;
		public readonly assyProps2 = BearingAssemblyList.ASSYPROPS2;
		public readonly assyPropsBrief = BearingAssemblyList.ASSYPROPSBRIEF;
		public showCommentIcon: boolean = false;

		public async mounted() {
			await this.buildManager.doSearchBearingAssemblies();

			// Preselect single option
			const available = this.availableBearingAssemblies || [];
			if (available.length === 1 && !this.selectedBearingAssembly && !this.locked)
				this.buildManager.selectBA(available[0]);
		}

		public get selectedKey() {
			return this.key(this.selectedBearingAssembly);
		}

		public get selectedBearingAssembly() {
			return this.buildManager.selectedBA;
		}

		// Skip impeller measurements missing warning on displayname since it spams all unselected BAs
		private skipGroups(ba: BearingAssemblyResult) {
			return (!this.selectedKey || this.key(ba) !== this.selectedKey) ? `${BAGROUP}.DisplayName` : undefined;
		}

		public warnings(ba: BearingAssemblyResult) {
			return this.getValueBag(ba)?.errorsInGroup(BAGROUP, this.skipGroups(ba)).Messages?.
				filter(x => x.Severity >= MessageSeverity.Info).map(x => x.Message) || [];
		}

		public hasWarning(ba: BearingAssemblyResult) {
			const msgs = this.getValueBag(ba).errorsInGroup(BAGROUP, this.skipGroups(ba));
			return msgs.HasErrors || msgs.HasWarnings;
		}

		public hasInfo(ba: BearingAssemblyResult) {
			const msgs = this.getValueBag(ba).errorsInGroup(BAGROUP, this.skipGroups(ba));
			return msgs.HasInfos;
		}

		public get pumpId() {
			return this.buildManager?.selectedPump?.Id || null;
		}

		public getValueBag(ba: BearingAssemblyResult) {
			// If this is the selected assembly, all data is in the sizing itself already. Return it as-is to get
			// the actual duty points in offline as well. Otherwise, fall through and create a "fake" sizing for
			// how it hypothetically would be if it was selected.
			const curSel = this.selectedBearingAssembly;
			if (curSel && this.key(curSel) === this.key(ba))
				return this.values;

			const all = this.dutyPoints.baDutyPoints(ba);
			return this.values.createVariants(all.map(x => ({ values: { BearingAssembly: x }, messages: x.Messages })));
		}

		get description() {
			const descr = tryToTranslate(`paramGroups.${BAGROUP}`);
			if (descr)
				return descr;
			const param = this.values.getParam(BAGROUP);
			return param?.definition.Description || 'Bearing assembly';
		}

		public key(b: BearingAssemblyResult) {
			return b?.MaterialNumber || null;
		}

		public get availableBearingAssemblies() {
			return this.buildManager.availableBAs;
		}

		public toggle(index: number) {
			Vue.set(this.expandContent, index, !this.expandContent[index]);
		}

		public itemClicked(ba: BearingAssemblyResult) {
			if (!this.locked)
				this.buildManager.selectBA(ba);
		}

		public get isDevloper() {
			return store.get(AuthGetters.hasRole, 'developer');
		}

		public viewBA(ba: BearingAssemblyResult) {
			this.$router.push({ name: 'badef', params: { id: ba.Id }});
		}
	}
</script>
