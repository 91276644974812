export const enum MessageType {
	Comment = 'Comment',
	Change = 'Change',
	Direct = 'Direct'
}

export const enum TargetType {
	Sizing = 'Sizing',
	Project = 'Project'
}

export interface Message {
	id?: string;
	Type?: MessageType;
	Created?: string;
	Source?: string;
	Text?: string;
	Read?: boolean;

	// Target information
	TargetType?: TargetType;
	Target: string;
	TargetUrl?: string;
	Subtarget?: string;

	// Change message extras
	Value?: string;

	// Use separate recipients instead of Target if set; when forwarding etc (not persisted)
	Recipients?: string[];
}

export interface MessageResult {
	Items: Message[];
	Error: string;
}

export enum ForwardType {
	Email = 'Email'
}

export interface ForwardingRule {
	Name: string;
	Type: ForwardType;
	MessageType: MessageType;
	Enabled: boolean;
	To: string;
}
