import { ValidationResult, ServiceClassInfo, DeratingMethod } from './CalcServiceDomain';

export enum PumpType {
	Unknown = 'Unknown',
	Horizontal = 'Horizontal',
	VerticalSump = 'VerticalSump',
	VerticalTank = 'VerticalTank',
	VerticalFroth = 'VerticalFroth'
}

export enum Option {
	Sprayhole = 'Sprayhole',
	Agitator = 'Agitator',
	SprayholeAndAgitator = 'SprayholeAndAgitator'
}

export enum PumpUsage {
	PressFilter = 'PressFilter'
}

export enum PumpStatus {
	Unavailable = 'Unavailable',
	Standard = 'Standard',
	NonStandard = 'NonStandard',
	Legacy = 'Legacy',
	WIP = 'WIP'
}

export interface PumpSearch {
	FlowRate?: number;
	PDH?: number;
	SlurryDensity?: number;
	FrothFactor?: number;
	d100?: number;
	NPSHA?: number;
	SpecificPumpId?: string;
	ServiceClass?: ServiceClassInfo;
	Usage?: PumpUsage;
	Statuses?: PumpStatus[];
	DeratingMethod?: DeratingMethod;
	ManualHR: number;
	ManualER: number;
	ManualQR: number;
	InletHead: number;

	// Material evaluation data
	LiquidTemp?: number;
	CorrosionIndex?: number;
	SelectedImpellerMaterial?: string;
	SelectedCaseMaterial?: string;

	// User specified
	TrimPercentage?: number;
}

export interface SimpleVersionInfo {
	Updated: string;
	UpdatedBy: string;
	VersionName: string;
}

// Single pump duty returned to service user
export interface PumpResult {
	// Pump data from data source
	Id: string;
	PumpType: PumpType;
	PumpRange: string;
	Revision: number;
	DisplayName: string;
	TypeCode: string;
	ImpellerType: string;
	ImpellerDescr: string;
	Vanes: number;
	MaxSphere: number;
	ImpellerCategory: string;
	DutyClass: number;
	ImpellerMaterials: string[];
	CaseMaterials: string[];
	Option?: Option;
	Status: PumpStatus;
	UsableFrames: string[];
	UsableFlanges: string[];
	InletDiameter: number;
	OutletDiameter: number;
	ImpellerDiameter: number;
	MinTrimPercentage: number;
	Version?: SimpleVersionInfo;

	// Calculated by PumpFilter
	Suitable: boolean;
	DutyEfficiency: number;
	InletVelocity: number;
	OutletVelocity: number;
	TipSpeed: number;
	BepFlow: number;
	BepPercent: number;
	BepEfficiency: number;
	BepHead: number;
	AbsorbedPower: number;
	AgitatorPower: number;
	DutySpeed: number;
	HR: number;
	ER: number;
	QR: number;
	NPSHR: number;
	ShutoffHead: number;
	Messages: ValidationResult[];
	SpeedLimit: number;
	ActualHR?: number;
	ActualER?: number;
	ActualQR?: number;

	// User configurable (read/write)
	TrimPercentage: number;
	ImpellerMaterial: string;
	CaseMaterial: string;
}

export interface MDPPumpResult {
	DutyPoints: PumpResult[];
}
