import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';

// custom M:O icon components
import ChipCheck from '@/components/icons/IconChipCheck.vue';
import Menu from '@/components/icons/IconMenu.vue';
import Clear from '@/components/icons/IconClear.vue';
import Delete from '@/components/icons/IconDelete.vue';
import Bell from '@/components/icons/IconBell.vue';
import Update from '@/components/icons/IconUpdate.vue';
import Expand from '@/components/icons/IconPlus.vue';
import Search from '@/components/icons/IconSearch.vue';
import Share from '@/components/icons/IconShare.vue';
import Filter from '@/components/icons/IconFilter.vue';
import CheckMark from '@/components/icons/IconCheckMark.vue';
import Person from '@/components/icons/IconPerson.vue';
import Online from '@/components/icons/IconOnline.vue';
import Offline from '@/components/icons/IconOffline.vue';
import Comment from '@/components/icons/IconComment.vue';
import Enquiry from '@/components/icons/IconEnquiry.vue';
import Lock from '@/components/icons/IconLock.vue';
import Launch from '@/components/icons/IconLaunch.vue';
import Collapse from '@/components/icons/IconCollapse.vue';

Vue.use(Vuetify);

export default new Vuetify({
	icons: {
		iconfont: 'mdi',
		values: {
			chipCheck: {
				component: ChipCheck
			},
			menu: {
				component: Menu
			},
			clear: {
				component: Clear
			},
			delete: {
				component: Delete
			},
			bell: {
				component: Bell
			},
			update: {
				component: Update
			},
			expand: {
				component: Expand
			},
			search: {
				component: Search
			},
			share: {
				component: Share
			},
			filter: {
				component: Filter
			},
			checkMark: {
				component: CheckMark
			},
			person: {
				component: Person
			},
			online: {
				component: Online
			},
			offline: {
				component: Offline
			},
			comment: {
				component: Comment
			},
			enquiry: {
				component: Enquiry
			},
			lock: {
				component: Lock
			},
			launch: {
				component: Launch
			},
			collapse: {
				component: Collapse
			}
		}
	},
	theme: {
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: '#000000',
				secondary: '#B3B3B3',
				accent: '#00E2B2',
				error: '#eb2814',
				warning: '#FBA700',
				info: '#00E2B2',
				selection: '#4E2098',
				assumed: '#F0EAFF',
				inProgress: '#CCCCCC',
				infoLighten1: '#B2F6E7',
				infoLighten2: '#D9FBF4',
				errorLighten1: '#FAC9C4',
				warningLighten1: '#FEE9BF',
				selectionLighten1: '#E5DEF0',
				greyLighten1: '#E6E6E6',
				greyLighten2: '#F5F5F5',
				greenLighten1: '#D3F5D0',
				accentLighten1: '#B2F6E7',
				accentLighten2: '#D9FBF4',
				blueLighten: '#BBDEFB',
				grey: '#9B9B9B',
				white: '#FFFFFF',
				green: '#51D645',
				appBg: '#F4F5F5',
			},
		},
	},
});
