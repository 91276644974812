import UserService from '@/services/user.service';
import TokenService from '@/services/token.service';
import AuthenticationError from './auth.error';
import InsightService from '@/services/insight.service';
import router from '@/router';
import { UserInfoResponse, ChangeableUserInfo, PublicUserInfo } from 'types/dto/AccessToken';
import store, { NetworkGetters } from '@/store';
import { LocalStorageService } from '@/services/localstorage.service';
import { fallbackLocale, fallbackLanguage, setLanguage } from '@/i18n';
import ApiService from '@/services/api.service';
import { Commit, Dispatch } from 'vuex';

interface IAuthState {
	authenticating: boolean;
	accessToken: string | null;
	authenticationErrorCode: number;
	authenticationError: string;
	refreshTokenPromise: any;
	userInfo: UserInfoResponse;
	lastOpenedProject: string;
	publicUserInfo: PublicUserInfo[];
}

const authState: IAuthState = {
	authenticating: false,
	accessToken: TokenService.getToken(),
	authenticationErrorCode: 0,
	authenticationError: '',
	refreshTokenPromise: null,
	userInfo: null,
	lastOpenedProject: null,
	publicUserInfo: []
};

export const UserSetting = {
	projectSortOrder: 'projectSortOrder',
	projectCreatedDays: 'projectCreatedDays',
	reportSettings: 'reportSettings',
	pumpInfoCurve: 'pumpInfoCurve',
	reportCurve: 'reportCurve'
};

function storePublicUserInfo(userInfo: PublicUserInfo, targetState: IAuthState) {
	let pui = targetState.publicUserInfo.find(x => x.id === userInfo.id);
	if (pui) {
		pui.FirstName = userInfo.FirstName;
		pui.LastName = userInfo.LastName;
	} else {
		pui = { id: userInfo.id, FirstName: userInfo.FirstName, LastName: userInfo.LastName };
		targetState.publicUserInfo.push(pui);
	}
	return pui;
}

const guestUserId = 'guest_user';
const INTERNALROLE = '_int';

const getters = {
	loggedIn: (targetState: IAuthState) => targetState.accessToken ? true : false,
	guestUser: (targetState: IAuthState) => !!(targetState.userInfo?.id === guestUserId),
	internalUser: (targetState: IAuthState) => !!(targetState.userInfo?.Roles?.includes(INTERNALROLE)),
	authenticationErrorCode: (targetState: IAuthState) => targetState.authenticationErrorCode,
	authenticationError: (targetState: IAuthState) => targetState.authenticationError,
	authenticating: (targetState: IAuthState) => targetState.authenticating,
	userId: (targetState: IAuthState) => targetState.userInfo?.id,
	fullName: (targetState: IAuthState) => targetState.userInfo ?
		`${targetState.userInfo.FirstName} ${targetState.userInfo.LastName}` : '',
	displayName: (targetState: IAuthState) => (id: string) => {
		const ui = targetState.userInfo;
		if (ui?.id === id)
			return `${ui.FirstName} ${ui.LastName}`;
		const pui = targetState.publicUserInfo.find(x => x.id === id);
		return pui && `${pui.FirstName} ${pui.LastName}` || id;
	},
	roles: (targetState: IAuthState) => targetState.userInfo?.Roles || [],
	language: (targetState: IAuthState) => targetState.userInfo?.Language || fallbackLanguage,
	locale: (targetState: IAuthState) => targetState.userInfo?.Locale || fallbackLocale,
	systemOfUnit: (targetState: IAuthState) => targetState.userInfo ? targetState.userInfo.SystemOfUnit : 'Metric',
	paramFilter: (targetState: IAuthState) => targetState.userInfo?.ParamFilter,
	lastOpenedProject: (targetState: IAuthState) => targetState.lastOpenedProject,
	hasRole: (targetState: IAuthState) => (role: string) => targetState.userInfo?.Roles?.includes(role) || false,
	setting: (targetState: IAuthState) => (key: string) => {
		if ((UserSetting as any)[key] === undefined) {
			console.error('User setting ' + key + ' is not defined');
			return;
		}
		const settings = targetState.userInfo?.Settings;
		return settings?.[key];
	}
};

const setGuestUser = async ({ commit }: any) => {
	const u = await LocalStorageService.startsWith('u:');
	const userInfo = (!u || !Object.keys(u).length) ? { id: guestUserId, FirstName: 'Guest', LastName: '(Offline)' } : Object.values(u)[0];
	commit('userInfo', userInfo);
	commit('loginSuccess', guestUserId);
	router.push('/dashboard');
};

const actions = {
	async reload({ commit }: any) {
		if (!store.get(NetworkGetters.connected)) {
			setGuestUser({ commit });
		} else {
			try {
				// Fetch user-details
				UserService.userInfo()
					.then(resp => commit('userInfo', resp))
					.catch(e => {
						if (e instanceof AuthenticationError)
							commit('loginError', { errorCode: e.errorCode, errorMessage: e.message });
					});
			} catch (e) {
				commit('loginError', { errorCode: '400', errorMessage: 'Unable to reload user data' });
			}
		}
	},

	async login({ commit }: any, { code }: any) {
		if (!store.get(NetworkGetters.connected)) {
			return setGuestUser({ commit });
		} else {
			commit('loginRequest');
			try {
				return UserService.callback(code).then(resp => {
					if (resp && resp.access_token) {
						return UserService.userInfo().then(userResp => {
							commit('userInfo', userResp);
							commit('loginSuccess', resp.access_token);
							InsightService.trackEvent('Auth:LoggedIn');
						});
					}
				})
				.catch(e => {
					if (e instanceof AuthenticationError)
						commit('loginError', { errorCode: e.errorCode, errorMessage: e.message });
				});
			} catch (e) {
				commit('loginError', { errorCode: '400', errorMessage: 'Authentication failure; please sign in again' });
			}
		}
	},

	async refresh({ commit, state }: any) {
		if (!store.get(NetworkGetters.connected))
			return;

		commit('loginRequest');
		try {
			if (!state.refreshTokenPromise) {
				const p = UserService.refreshToken().then(token => {
					commit('refreshTokenPromise', null);
					commit('refreshSuccess', token);
				});
				commit('refreshTokenPromise', p);
			}
			return await state.refreshTokenPromise;
		} catch (e) {
			commit('refreshTokenPromise', null);
			commit('loginError', {
				errorCode: '400', errorMessage: 'Failed to renew session; please sign in again'
			});
		}
	},

	logout({ commit }: any) {
		commit('logoutSuccess');
		if (store.get(NetworkGetters.connected))
			UserService.logout();
		// Remove the token and remove Authorization header from Api Service as well
		TokenService.removeToken();
		ApiService.removeHeader();
	},

	update({ commit, state }: { commit: Commit, state: IAuthState }, updatedUser: ChangeableUserInfo) {
		if (!updatedUser.Language && !updatedUser.Locale && !updatedUser.SystemOfUnit && !updatedUser.ParamFilter && !updatedUser.Settings)
			return;
		const user: UserInfoResponse = state.userInfo;
		const change = {
			Language: updatedUser.Language || user.Language,
			Locale: updatedUser.Locale || user.Locale,
			SystemOfUnit: updatedUser.SystemOfUnit || user.SystemOfUnit,
			ParamFilter: updatedUser.ParamFilter || user.ParamFilter,
			Settings: updatedUser.Settings || user.Settings
		};
		commit('userInfo', change);
		if (store.get(NetworkGetters.connected))
			UserService.update(updatedUser);
	},

	relogin({ commit }: any) {
		InsightService.trackEvent('Auth:Relogin');
		commit('relogin');
	},

	updateSetting({ dispatch, state, commit }: { dispatch: Dispatch, state: IAuthState, commit: Commit }, setting: { key: string, value: string }) {
		if (JSON.stringify(getters.setting(state)(setting.key)) === JSON.stringify(setting.value))
			return;
		commit('updateSetting', setting);
		const userInfo: ChangeableUserInfo = state.userInfo;
		dispatch('update', userInfo);
	}
};

const mutations = {
	loginRequest(targetState: IAuthState) {
		targetState.authenticating = true;
		targetState.authenticationError = '';
		targetState.authenticationErrorCode = 0;
	},

	loginSuccess(targetState: IAuthState, accessToken: string | null) {
		targetState.accessToken = accessToken;
		targetState.authenticating = false;
	},

	refreshSuccess(targetState: IAuthState, accessToken: string | null) {
		targetState.accessToken = accessToken;
		targetState.authenticating = false;
	},

	relogin(targetState: IAuthState) {
		targetState.authenticating = false;
		targetState.authenticationErrorCode = 0;
		targetState.authenticationError = '';
		targetState.userInfo = null;
		targetState.accessToken = '';
	},

	loginError(targetState: IAuthState, { errorCode, errorMessage }: any) {
		InsightService.trackEvent('Auth:LoginError', { errorCode, errorMessage });
		targetState.authenticating = false;
		targetState.authenticationErrorCode = errorCode;
		targetState.authenticationError = errorMessage;
		targetState.userInfo = null;
		targetState.accessToken = '';

		const redirect = router?.currentRoute?.path;
		if (redirect && redirect !== '/')
			router.push('/?redirect=' + encodeURIComponent(redirect));
		else
			router.push('/');
	},

	logoutSuccess(targetState: IAuthState) {
		const id = targetState.userInfo?.id;
		targetState.accessToken = '';
		targetState.userInfo = null;
		InsightService.setUserContext(null);
		LocalStorageService.removeItem('u:' + id);
		router.push('/');
	},

	refreshTokenPromise(targetState: IAuthState, promise: any) {
		targetState.refreshTokenPromise = promise;
	},

	userInfo(targetState: IAuthState, userInfo: UserInfoResponse) {
		const existing = targetState.userInfo;
		if (userInfo && existing) {
			// Update existing values since state should not be mutated by caller
			for (const k of Object.keys(userInfo))
				(existing as any)[k] = (userInfo as any)[k];
		} else {
			// Make sure all settings are defined and thus reactive
			if (!userInfo.Settings)
				userInfo.Settings = { };
			Object.values(UserSetting).forEach(key => {
				if (userInfo.Settings[key] === undefined)
					userInfo.Settings[key] = null;
			});
			if (userInfo.Locale === undefined)
				userInfo.Locale = null;
			targetState.userInfo = userInfo;
		}

		storePublicUserInfo(userInfo, targetState);
		InsightService.setUserContext(userInfo.id);
		LocalStorageService.removeItem('u:' + guestUserId);
		LocalStorageService.setItem('u:' + userInfo.id, userInfo);

		if (userInfo.Language && getters.hasRole(targetState)('developer'))
			setLanguage(userInfo.Language);
	},

	lastOpenedProject(targetState: IAuthState, lastOpenedProject: string) {
		targetState.lastOpenedProject = lastOpenedProject;
	},

	publicUsers(targetState: IAuthState, data: PublicUserInfo[]) {
		if (data?.length)
			data.forEach(x => storePublicUserInfo(x, targetState));
	},

	updateSetting(targetState: IAuthState, { key, value }: { key: string, value: string }) {
		if ((UserSetting as any)[key] === undefined) {
			console.error('User setting ' + key + ' is not defined');
			return;
		}
		if (!targetState.userInfo?.Settings) {
			console.error('Settings are not initialized');
			return;
		}
		const userInfo: ChangeableUserInfo = targetState.userInfo;
		userInfo.Settings[key] = value;
	}
};

export const enum AuthMuts {
	lastOpenedProject = 'auth/lastOpenedProject',
	publicUsers = 'auth/publicUsers',
	defineSetting = 'auth/defineSetting'
}

export const enum AuthActions {
	reload = 'auth/reload',
	login = 'auth/login',
	refresh = 'auth/refresh',
	logout = 'auth/logout',
	update = 'auth/update',
	relogin = 'auth/relogin',
	updateSetting = 'auth/updateSetting'
}

export const enum AuthGetters {
	loggedIn = 'auth/loggedIn',
	authenticationErrorCode = 'auth/authenticationErrorCode',
	authenticationError = 'auth/authenticationError',
	authenticating = 'auth/authenticating',
	userId = 'auth/userId',
	fullName = 'auth/fullName',
	// roles = 'auth/roles',
	language = 'auth/language',
	locale = 'auth/locale',
	lastOpenedProject = 'auth/lastOpenedProject',
	systemOfUnit = 'auth/systemOfUnit',
	paramFilter = 'auth/paramFilter',
	hasRole = 'auth/hasRole',
	displayName = 'auth/displayName',
	guestUser = 'auth/guestUser',
	internalUser = 'auth/internalUser',
	setting = 'auth/setting'
}

export const auth = {
	namespaced: true,
	state: authState,
	getters,
	actions,
	mutations
};
