import { render, staticRenderFns } from "./ChangeLog.vue?vue&type=template&id=190a8017&scoped=true"
import script from "./ChangeLog.vue?vue&type=script&lang=ts"
export * from "./ChangeLog.vue?vue&type=script&lang=ts"
import style0 from "./ChangeLog.vue?vue&type=style&index=0&id=190a8017&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "190a8017",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VChip,VDataTable,VIcon,VTextField,VTooltip})
