<template>
	<v-container class="pa-0">
		<v-row>
			<v-col cols="12" xl="5" lg="12">
				<!-- filters -->
				<ExpandableCard title="Filters" :expanded="showingAlternatives">	
					<FilterList :filters="filters" xlatePrefix="Pump" />

					<div v-if="pumps && pumps.length">
						<v-slider :label="$t('Pump.MinDutyClass')" class="duty-slider" v-model="minDutyClass"
							thumb-label="always" thumb-size="28" min="1" max="4" step="0.1" hide-details>
							<template v-slot:thumb-label="{ value }"><span class="bw">{{value}}</span></template>
						</v-slider>
						<v-btn class="inline-block" v-if="suggestDutyClass" @click="useSuggestedDutyClass">◀ Use suggested: {{ suggestedDutyClass.toFixed(1) }}</v-btn>
					</div>

					<div v-if="!offline">
						<label class="v-label product-category">Product category:</label>
						<v-chip-group mandatory v-model="category" active-class="sel-choice-chip" multiple class="inline-block">
							<v-chip value="Standard" v-keyboard-click :disabled="searching">Standard</v-chip>
							<v-chip value="NonStandard" v-keyboard-click :disabled="searching">Non-standard</v-chip>
							<v-chip value="Legacy" v-keyboard-click :disabled="searching">Legacy</v-chip>
							<v-chip v-if="isInternal" value="WIP" v-keyboard-click :disabled="searching">WIP</v-chip>
							<v-chip v-if="isInternal" value="Unsuitable" v-keyboard-click :disabled="searching">Unsuitable</v-chip>
							<v-chip v-if="isDevloper" value="Wacko" v-keyboard-click :disabled="searching">Wacko</v-chip>
						</v-chip-group>
					</div>
				</ExpandableCard>
			</v-col>
				<v-col cols="12" xl="7" lg="12" class="order-xl-first">
					<!-- table & warning messages -->
					<ExpandableCard :title="showingSelectedPump ? 'Selected pump' : 'Suitable pumps'" expanded width="1000px">
						<SimpleWarnings v-if="showMessages" :msgs="messages" />

						<v-data-table v-if="fixedPump || !hasErrors" :items="filteredPumps" :headers="headers" persistent :loading="searching"
							item-key="Id" :hide-default-footer="showingSelectedPump" ref="dTable">
							<template v-slot:item="props">
								<tr @click="props.expand(!props.isExpanded)" :class="[isSelected(props.item) && 'sel-list-item expanded', props.isExpanded ? 'expanded' : '']"
									@mouseover="showCommentIcon = isSelected(props.item)" @mouseout="showCommentIcon = false">
									<td @click.stop="pumpClick(props.item)">
										<v-icon :color="isSelected(props.item) ? 'selection' : 'grey'" :disabled="!clickable(props.item)">
											{{ isSelected(props.item) ? 'radio_button_on' : 'radio_button_off' }}
										</v-icon>
									</td>
									<v-tooltip bottom :disabled="!pumpWarn(props.item)" :color="pumpWarn(props.item, true)" max-width="500" open-delay="500" >
										<template v-slot:activator="{ on }">
											<td v-on="on" :class="pumpWarn(props.item, true)">
												{{ props.item.DisplayName }}&nbsp;<span v-text="statusIcon(props.item)" />
											</td>
										</template>
										<span class="wrap">{{ pumpWarn(props.item) }}</span>
									</v-tooltip>
									<td class="pa-0 ma-0">
										<DeliveryReadiness :id="props.item.Id" :key="props.item.Id"
											:impellerMaterial="props.item.ImpellerMaterial || imFilter.singleValue"
											:caseMaterial="props.item.CaseMaterial || cmFilter.singleValue" />
									</td>
									<td>{{ props.item.ImpellerDescr }}</td>
									<td class="text-right">{{ props.item.Vanes }}</td>
									<v-tooltip bottom :disabled="!speedWarn(props.item)" :color="speedWarn(props.item, true)" max-width="500" open-delay="500" >
										<template v-slot:activator="{ on }">
											<td v-on="on" :class="'text-right ' + speedWarn(props.item, true)">{{ props.item.DutySpeed }}&nbsp;rpm</td>
										</template>
										<span class="wrap">{{ speedWarn(props.item) }}</span>
									</v-tooltip>
									<v-tooltip bottom :disabled="!tipWarn(props.item)" :color="tipWarn(props.item, true)" max-width="500" open-delay="500" >
										<template v-slot:activator="{ on }">
											<td v-on="on" :class="'text-right ' + tipWarn(props.item, true)">{{ renderItem(props.item, 'TipSpeed', 'm/s', 1) }}</td>
										</template>
										<span class="wrap">{{ tipWarn(props.item) }}</span>
									</v-tooltip>
									<td class="text-right"><template v-if="props.item.DutyEfficiency > 0">{{ props.item.DutyEfficiency.toFixed(1) }}&nbsp;%</template></td>
									<v-tooltip bottom :disabled="!inletWarn(props.item)" :color="inletWarn(props.item, true)" max-width="500" open-delay="500" >
										<template v-slot:activator="{ on }">
											<td v-on="on" :class="'text-right ' + inletWarn(props.item, true)">{{ renderItem(props.item, 'InletVelocity', 'm/s', 1) }}</td>
										</template>
										<span class="wrap">{{ inletWarn(props.item) }}</span>
									</v-tooltip>
									<v-tooltip bottom :disabled="!bepWarn(props.item)" :color="bepWarn(props.item, true)" max-width="500" open-delay="500" >
										<template v-slot:activator="{ on }">
											<td v-on="on" :class="'text-right ' + bepWarn(props.item, true)">{{ Math.round(props.item.BepPercent) }}&nbsp;%</td>
										</template>
										<span class="wrap">{{ bepWarn(props.item) }}</span>
									</v-tooltip>
									<td class="readonlyValue">
										<CommentIcon class="comment-icon" v-if="target && isSelected(props.item)" :target="target" field="Pump" type="Comment" :visible="true"  :key="props.item.Id" />
									</td>
								</tr>
							</template>
							<template v-slot:expanded-item="props">
								<td :colspan="headers.length" class="" :class="isSelected(props.item) && 'expanded'">
									<!-- Use keep-alive here to keep pump curve config between pagings -->
									<keep-alive>
										<PumpInfo :pump="props.item" :dutyPoints="props.item.DutyPoints" :params="params"
											:key="`${buildManager.sizingId}_${props.item.Id}`" />
									</keep-alive>
									<UnitNumeric v-if="trimmable(props.item)" :minValue="props.item.MinTrimPercentage" :param="getSharedParam('Pump.TrimPercentage')" class="trim-input" />
								</td>
							</template>
						</v-data-table>					
						<div class="pt-4" v-if="showingSelectedPump && !showingAlternatives && !offline">
							<v-btn @click="showAlternatives">Look for alternatives...</v-btn>
						</div>
					</ExpandableCard>
			</v-col>
		</v-row>
	
		<!-- Confirm modal dialog -->
		<Confirm ref="confirm" />
	</v-container>
</template>

<style lang="scss" scoped>
	@import '@/sass/main.scss';
	.v-tooltip { display: none; }

	td.warning {
		cursor: help;
		background-color: $warning-lighten-1 !important;
		border-bottom: 1px solid $grey-lighten-1 !important;
	}

	td.error {
		cursor: help;
		background-color: $error-lighten-1 !important;
		border-bottom: 1px solid $grey-lighten-1 !important;
	}

	td.special-availability span {
		vertical-align: text-bottom;
	}

	.v-list-item:hover {
  		background-color: $selection-lighten-1;
	}

	.expanded {
		background-color:$white !important;
		border-bottom: 1px solid $grey-lighten-1 !important;
	}

	.v-data-table td, .message-table.v-data-table th {
		overflow: hidden !important;
	}

	tr:hover {
  		background-color: $selection-lighten-1 !important;
	}

	span.wrap {
		white-space: pre-wrap;
	}

	.v-label.product-category {
		vertical-align: top; 
		float: left;
		padding: 15px 5px 0 0;
	}

	.duty-slider {
		display: inline-block; 
		min-width: 400px; 
		min-height: 44px;
		margin-top: 35px;
	}

	.comment-icon {
		padding-top: 5px;
	}

	.trim-input {
		 width: 250px;
	}
</style>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop, Watch } from 'vue-property-decorator';
	import { PumpResult, PumpType, PumpStatus, MDPPumpResult } from 'types/dto/PumpSearch';
	import { MessageSeverity, ValidationResult } from 'types/dto/CalcServiceDomain';
	import PumpInfo from '@/components/PumpInfo.vue';
	import Confirm from '@/components/Confirm.vue';
	import FilterList from '@/components/FilterList.vue';
	import CommentIcon from '@/components/CommentIcon.vue';
	import SimpleWarnings from '@/components/SimpleWarnings.vue';
	import UnitNumeric from '@/components/Fields/UnitNumeric.vue';
	import DeliveryReadiness from '@/components/DeliveryReadiness.vue';
	import store, { SnackActions, AuthGetters, NetworkGetters } from '@/store';
	import UnitValue from '@/common/UnitValue';
	import { DutyPoints } from '@/common/DutyPoints';
	import BuildManager from '@/common/BuildManager';
	import ItemFilter from '@/common/ItemFilter';
	import InsightService from '@/services/insight.service';
	import { ParamBag } from '@/common/ParamBag';
	import SizingInfo from '@/common/SizingInfo';
	import WorkQueue from '@/common/WorkQueue';
	import ExpandableCard from '@/components/ExpandableCard.vue';

	const pumpHeaders: any[] = [
		{ text: '', sortable: '', value: '_checked_' },
		{ text: 'Pump', value: 'DisplayName' },
		{ text: '', value: '_dr_', sortable: '', class: 'pa-0 ma-0' },
		{ text: 'Impeller', value: 'ImpellerType' },
		{ text: 'Vanes', value: 'Vanes', align: 'end' },
		{ text: 'Speed', value: 'DutySpeed', align: 'end' },
		{ text: 'Tip speed', value: 'TipSpeed', align: 'end' },
		{ text: 'Efficiency', value: 'DutyEfficiency', align: 'end' },
		{ text: 'Inlet velocity', value: 'InletVelocity', align: 'end' },
		{ text: '% BEP flow', value: 'BepPercent', align: 'end' },
		{ text: '', sortable: '', class: 'pa-0' }
	];

	@Component({
		components: {
			PumpInfo,
			Confirm,
			FilterList,
			CommentIcon,
			SimpleWarnings,
			UnitNumeric,
			DeliveryReadiness,
			ExpandableCard
		}
	})
	export default class PumpList extends Vue {
		@Prop() public messages: ValidationResult[];
		@Prop() public locked: boolean;
		@Prop() public dutyPoints: DutyPoints;
		@Prop() public buildManager: BuildManager;
		@Prop() public target: string;
		@Prop() public params: ParamBag;
		@Prop() public wq: WorkQueue;

		public pumps: PumpResult[] = [];
		public originalPumps: MDPPumpResult[] = [];
		public searching: boolean = false;
		public readonly headers = pumpHeaders;
		public minDutyClass: number = 0;
		public showingAlternatives: boolean = false;
		public showCommentIcon: boolean = false;
		public includeNonStandard: boolean = false;
		public category: Array<PumpStatus | 'Unsuitable' | 'Wacko'> = [ PumpStatus.Standard ];

		public readonly imFilter = new ItemFilter<PumpResult>('ImpellerMaterials', 'PumpMaterial', false, PumpList.matGroupings);
		public readonly cmFilter = new ItemFilter<PumpResult>('CaseMaterials', 'PumpMaterial', false, PumpList.matGroupings);

		private static readonly matGroupings = [{ match: /^R1[0-9][0-9]/, value: 'NR' }, { match: /^GPS8[0-9][0-9]/, value: 'HC' }];
		private readonly typeFilter = new ItemFilter<PumpResult>('PumpType', 'PumpType');
		private static readonly errorsShownInTable = [ 'Pump.BepPercent', 'Pump.TipSpeed', 'Pump.InletVelocity', 'Pump.DutySpeed' ];

		public readonly filters = [
			this.typeFilter,
			new ItemFilter<PumpResult>('PumpRange', 'PumpRange'),
			new ItemFilter<PumpResult>('Option', 'PumpOption', true),
			new ItemFilter<PumpResult>('ImpellerType', 'ImpellerType'),
			this.imFilter,
			this.cmFilter
		];

		@Watch('suggestedDutyClass')
		public dutyClassRecalculated(val: number, oldVal: number) {
			this.minDutyClass = val;
		}

		public get fixedPump() {
			return this.buildManager.selectedPump;
		}

		public get showingSelectedPump() {
			return !!(this.fixedPump && (!this.pumps || this.pumps.length < 2));
		}

		public get offline() {
			return !store.get(NetworkGetters.connected);
		}

		public isSelected(x: PumpResult) {
			return !!(x && this.fixedPump?.Id === x.Id);
		}

		public get currentDpIdx() {
			return Math.max(0, this.params?.variants?.findIndex(x => x.id === this.params.sizingId));
		}

		public clickable(x: PumpResult) {
			if (this.locked)
				return false;
			if (this.isSelected(x))
				return true;
			return this.isSuitable(x);
		}

		private isSuitable(x: PumpResult) {
			return x.Suitable;
		}

		public trimmable(x: PumpResult) {
			return x.MinTrimPercentage > 0 && this.isSelected(x);
		}

		public getSharedParam(name: string) {
			if (this.dutyPoints?.isMDP)
				return ParamBag.getMultiWriteParam(name, this.params.variants.map(x => x.id));
			return this.params.getParam(name);
		}

		@Watch('fixedPump')
		public fixedPumpChanged(val: PumpResult) {
			if (this.fixedPump)
				this.expandPump(this.fixedPump);
			else
				this.showingAlternatives = true;
		}

		private expandPump(pump: PumpResult, expand: boolean = true) {
			const tableData = this.$refs.dTable as any;
			if (tableData) {
				// Try Vuetify 2 first, then fall back to 1.5
				if (tableData.expand)
					tableData.expand(pump, expand);
				else
					this.$set(tableData.expanded, pump.Id, expand);
			}
		}

		public mounted() {
			if (!this.fixedPump)
				this.showingAlternatives = true;

			if (this.fixedPump)
				this.expandPump(this.fixedPump);
			else
				this.searchPumps();
		}

		@Watch('pumpFilter')
		public pumpFilterChanged(val: any) {
			if (!this.searching && (this.showingAlternatives || !this.hasErrors))
				this.searchPumps();
		}

		private static mergeResult(result: MDPPumpResult, preferredDpIdx: number) {
			// Gather unique messages from all duty points
			const allMsgs = ParamBag.mergeMessages(...result.DutyPoints.map(x => x.Messages))
				.filter(x => x.ParamName?.startsWith('Pump.'));

			// Mix in "original" result since the additional duty points do not have any static data
			// TODO: make users select the duty point instead!
			// TODO: clean up; skipped ", { Messages: allMsgs }" since this should not be needed anymore
			const merged = Object.assign({ DutyPoints: result.DutyPoints }, result.DutyPoints[preferredDpIdx]);
			merged.Suitable = result?.DutyPoints?.every(y => y.Suitable) || false;
			return merged;
		}

		public get filteredPumps() {
			let pumps = this.pumps || [];
			this.filters.forEach(f => pumps = f.filter(pumps));
			if (this.minDutyClass > 0) {
				// Compare against the nearest lower desired class since pump duty class 3 supports all duty classes *below 4.0*
				const minClass = Math.floor(this.minDutyClass);
				pumps = pumps.filter(x => x.DutyClass >= minClass);
			}
			this.filters.forEach(f => f.update(pumps));

			// Put selected item on top and add it if filtered away
			if (this.fixedPump) {
				const dps = this.dutyPoints.asSizings.map(x => x.Data?.Pump);
				const fullPump = Object.assign({ DutyPoints: dps }, this.fixedPump);
				const selected = PumpList.mergeResult(fullPump, this.currentDpIdx);
				pumps = [selected as PumpResult].concat(pumps.filter(x => x.Id !== this.fixedPump.Id));
			}
			return pumps;
		}

		public showAlternatives() {
			this.showingAlternatives = true;
			this.searchPumps();
		}

		public get hasErrors() {
			return !!this.messages?.some(x => x.Severity >= MessageSeverity.Error);
		}

		public get showMessages() {
			return !!this.messages?.some(x => x.Severity >= MessageSeverity.Info);
		}

		public get suggestedDutyClass() {
			return this.dutyPoints.recommendedDutyClass;
		}

		public get suggestDutyClass() {
			const suggested = this.dutyPoints.recommendedDutyClass;
			return suggested >= 1.0 && (this.minDutyClass === 0 || this.minDutyClass.toFixed(1) !== suggested.toFixed(1));
		}

		public get isDevloper() {
			return store.get(AuthGetters.hasRole, 'developer');
		}

		public get isInternal() {
			return store.get(AuthGetters.internalUser);
		}

		get useImperial() {
			return ParamBag.useImperial(this.params.sizingId);
		}

		// Dependencies on which to trigger list search
		private get pumpFilter() {
			return this.buildManager.pumpDutyString + store.get(NetworkGetters.connected) +
				this.locked + this.category.join(',');
		}

		public async searchPumps() {
			if (!store.get(NetworkGetters.connected) || !this.showingAlternatives)
				return;

			// No pump and errors: something is preventing search, such as missing data
			if (this.hasErrors && !this.fixedPump)
				return;

			const statuses = this.category.filter(x => x !== 'Unsuitable' && x !== 'Wacko') as PumpStatus[];
			if (!statuses.length)
				return;

			this.useSuggestedDutyClass();
			this.searching = true;
			try {
				const res = await this.wq.replace('SearchPumps', () =>
					this.buildManager.searchPumps(statuses, this.category.includes('Unsuitable'), this.category.includes('Wacko')));
				if (res == null) {
					store.dispatch(SnackActions.set, 'Pump search failed');
					return;
				}
				this.originalPumps = res;

				const dpIdx = this.currentDpIdx;
				const merged = res.map(x => PumpList.mergeResult(x, dpIdx));
				this.filters.forEach(f => f.update(merged || []));
				this.setDefaultFilters(merged);
				this.pumps = merged;
			} finally {
				this.searching = false;
			}
		}

		private setDefaultFilters(pumps: PumpResult[]) {
			if (this.dutyPoints?.asSizings?.some(x => x.Data?.Slurry?.FrothFactor > 1)) {
				if (pumps?.some(x => x?.PumpType === PumpType.VerticalFroth)) {
					if (!this.filters.some(x => x.enabled))
						this.typeFilter.set(PumpType.VerticalFroth);
				}
			}
		}

		public bepWarn(pr: PumpResult, asStyle?: boolean): any {
			return this.paramMessages(pr, 'Pump.BepPercent', asStyle);
		}

		public tipWarn(pr: PumpResult, asStyle?: boolean): any {
			return this.paramMessages(pr, 'Pump.TipSpeed', asStyle);
		}

		public inletWarn(pr: PumpResult, asStyle?: boolean): any {
			return this.paramMessages(pr, 'Pump.InletVelocity', asStyle);
		}

		public speedWarn(pr: PumpResult, asStyle?: boolean): any {
			return this.paramMessages(pr, 'Pump.DutySpeed', asStyle);
		}

		public pumpWarn(pr: PumpResult, asStyle?: boolean): any {
			const suitable = this.isSuitable(pr);
			let result = '';
			if (asStyle && !suitable)
				result = 'error';
			else {
				// Gather all messages that are not already shown in a dedicated column
				const msgs = ((this.isSelected(pr) ? this.messages : pr.Messages) || [])
					.filter(x => !PumpList.errorsShownInTable.includes(x.ParamName));
				if (!suitable || msgs?.some(x => x.Severity >= MessageSeverity.Error)) {
					if (asStyle)
						result = 'error';
					else {
						// Make sure there is at least one error explaining why the pump is red
						let errors = msgs.filter(x => x.Severity >= MessageSeverity.Error).map(x => x.Message);
						if (!errors.length)
							errors = ['Unsuitable'];
						result = errors.join('\n');
					}
				} else if (msgs?.some(x => x.Severity === MessageSeverity.Warning)) {
					if (asStyle)
						result = 'warning';
					else
						result =  msgs.filter(x => x.Severity >= MessageSeverity.Warning).map(x => x.Message).join('\n');
				}
			}

			// Mark non-standard availabilities
			if (asStyle && pr.Status != null && pr.Status !== PumpStatus.Standard)
				result += ' special-availability ' + pr.Status.toLowerCase();
			return result?.trim();
		}

		public statusIcon(pr: PumpResult) {
			return SizingInfo.pumpStatusIcon(pr);
		}

		private paramMessages(pr: PumpResult, paramName: string | string[], asStyle: boolean): any {
			if (!pr)
				return;

			// Use pump messages saved in the sizing for selected pump since CalcService might have added some comments
			let msgs = this.isSelected(pr) ? this.messages : pr.Messages || [];
			if (Array.isArray(paramName))
				msgs = msgs.filter(x => paramName.includes(x.ParamName));
			else
				msgs = msgs.filter(x => x.ParamName === paramName);
			if (!msgs.length)
				return;

			const errs = msgs.filter(x => x.Severity >= MessageSeverity.Error).map(x => x.Message).join('\n');
			if (errs?.length)
				return asStyle ? 'error' : errs;
			const warns = msgs.filter(x => x.Severity === MessageSeverity.Warning).map(x => x.Message).join('\n');
			if (warns?.length)
				return asStyle ? 'warning' : warns;
		}

		public useSuggestedDutyClass() {
			this.minDutyClass = parseFloat(this.suggestedDutyClass.toFixed(1));
		}

		public async pumpClick(pump: PumpResult) {
			if (!this.clickable(pump))
				return true;

			// Second click on same pump = unselect
			const oldPump = this.fixedPump;
			const oldPumpId = oldPump?.Id;
			if (oldPumpId && pump?.Id === oldPumpId)
				pump = null;

			// Changing/removing pump?
			if (oldPump && (!pump || pump.Id !== oldPumpId)) {
				if (this.buildManager.selectedFrame || this.buildManager.selectedFlange) {
					const dialog: any = this.$refs.confirm;
					const changePump = await dialog.open('This will clear all prebuild configuration.');
					if (!changePump)
						return true;
				}
				if (oldPumpId && pump)
					this.expandPump(oldPump, false);

				// Clear trim so it does not carry over to the new pump
				if (oldPump?.TrimPercentage)
					await this.dutyPoints.setAll('Pump.TrimPercentage', null, true);
			}

			const extraProps = {} as any;
			if (pump?.Id) {
				// Use filter settings to deduce material to preselect
				if (this.imFilter.singleValue && !pump.ImpellerMaterial)
					extraProps.ImpellerMaterial = this.imFilter.singleValue;
				if (this.cmFilter.singleValue && !pump.CaseMaterial)
					extraProps.CaseMaterial = this.cmFilter.singleValue;

				InsightService.trackEvent('Sizing:SelectPump', pump);
			} else
				InsightService.trackEvent('Sizing:DeselectPump', oldPump);

			const fullPump = pump == null ? null : this.originalPumps.find(x => x.DutyPoints && x.DutyPoints[0].Id === pump.Id);
			await this.buildManager.selectPump(fullPump, extraProps);
			if (!pump || !pump.Id)
				this.searchPumps();
			return true;
		}

		public renderItem(p: PumpResult, prop: string, unit: string, decimals?: number) {
			const val = (p as any)[prop];
			const uv = new UnitValue(val, unit, decimals, this.useImperial);
			return (uv.toString() || '').replace(' ', ' ');
		}
	}
</script>
