<template>
	<v-card flat class="mb-2">
		<v-card-text class="no-padding">
			<v-list>
				<v-list-group v-model="expandModel" :append-icon="icon">
					<template v-slot:activator>
						<v-list-item-title>
							<h3>{{ translatedTitle }}</h3>
						</v-list-item-title>
					</template>
					<v-container>
						<slot/>
					</v-container>
				</v-list-group>
			</v-list>
		</v-card-text>
	</v-card>
</template>

<style lang="scss" scoped>
	div .container {
		padding: 0 20px 20px 20px;
	}
</style>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop, Watch } from 'vue-property-decorator';
	import { tryToTranslate } from '@/i18n';
	import { GetExpandCollapseIcon } from '@/common/Tools';

	@Component({
		components: { },
	})

	export default class ExpandableCard extends Vue {
		@Prop() public title: string;
		@Prop() public expanded: boolean;
		@Prop() public disabled: boolean;

		public expandModel: boolean = true;

		public get translatedTitle() {
			return tryToTranslate(`paramGroups.${this.title}`) || this.title;
		}

		public created() {
			this.onChange(this.expanded);
		}

		public get icon() {
			return GetExpandCollapseIcon(this.expandModel);
		}

		@Watch('expanded')
		public onChange(val: any) {
			const wasExpanded = this.expandModel;
			if (val != null)
				this.expandModel = val === true || val === '';
		}

		@Watch('expandModel')
		public onExpandedChange(value: boolean) {
			this.$emit('change', value);
		}
	}
</script>
