import { delay, retry, uniqueValues } from './Tools';
import PumpService from '@/services/pumps.service';

export type PartType = 'Pump' | 'BearingAssembly' | 'ShaftSeal' | 'Flange' | 'Motor' | 'DriveArrangement';

export const enum EntryState { Waiting, Working, Done, Failed }

interface IdResult {
	id: string;
	value: any;
}

// tslint:disable max-classes-per-file
class Entry {
	public readonly pumpId: string;
	public readonly id: string;
	public readonly done = new Promise<any>((resolve, reject) => { this.resolve = resolve; this.reject = reject; });
	public readonly timestamp: number;
	public value: any = null;
	public state: EntryState = EntryState.Waiting;

	private resolve: (value: any) => void;
	private reject: (value: any) => void;

	public constructor(pumpId: string, id: string) {
		this.pumpId = pumpId;
		this.id = id;
		this.timestamp = new Date().getTime();
	}

	public succeed(value: any) {
		this.value = value;
		this.state = EntryState.Done;
		this.resolve(value);
	}

	public fail(err: any) {
		this.value = undefined;
		this.state = EntryState.Failed;
		this.reject(err);
	}
}

type Fetcher = (pumpId: string, ids: string[]) => Promise<IdResult[]>;

// tslint:disable max-classes-per-file
class Worker {
	public readonly entries: { [id: string]: Entry } = {};
	private readonly work: Promise<any>;

	public constructor(fetch: Fetcher) {
		this.work = new Promise<any>(async () => {
			while (true) {
				await delay(1000);
				const pending = Object.values(this.entries).filter(x => x.state === EntryState.Waiting);
				const pumpIds = uniqueValues(pending, 'pumpId');

				for (const pumpId of pumpIds) {
					const toFetch = pending.filter(x => x.pumpId === pumpId);
					if (!toFetch.length)
						continue;
					toFetch.forEach(x => x.state = EntryState.Working);

					try {
						const fetchIds = toFetch.map(x => x.id);
						const result = await fetch(pumpId, fetchIds);
						result?.forEach(x => this.entries[x.id]?.succeed(x.value));

						const missingInResult = fetchIds.filter(id => !result?.some(x => x.id === id));
						missingInResult.forEach(id => this.entries[id]?.succeed(null));
					} catch (e) {
						toFetch.forEach(x => x.fail(e));
					}
				}
			}
		});
	}
}

export default class DRSource {
	private readonly workers: { [type: string]: Worker } = {};

	public state(type: PartType, id: string) {
		return this.workers[type]?.entries[id]?.state;
	}

	public async get(type: PartType, pumpId: string, id: string, context?: any): Promise<any> {
		let worker = this.workers[type];
		if (!worker)
			worker = this.workers[type] = new Worker((pmpId: string, ids: string[]) => this.fetch(type, pmpId, ids, context));

		let entry = worker.entries[id];
		if (!entry || this.shouldPurge(entry))
			entry = worker.entries[id] = new Entry(pumpId, id);

		if (entry.state === EntryState.Done || entry.state === EntryState.Failed)
			return entry.value;
		return entry.done;
	}

	private shouldPurge(entry: Entry): boolean {
		if (!entry)
			return false;
		const age = new Date().getTime() - entry.timestamp;

		if (entry.state === EntryState.Done)
			return age > 5 * 60 * 1000; //  5 mins before retry = current cache time of the delivery readiness service
		else if (entry.state === EntryState.Failed)
			return age > 3 * 60 * 1000; // Retry failed after 3 mins = be sure that previous retries are completed to avoid looping
		return false;
	}

	public clear(type: PartType, id: string) {
		if (this.workers[type]?.entries[id])
			delete this.workers[type].entries[id];
	}

	private async fetch(type: PartType, pumpId: string, ids: string[], context?: any): Promise<IdResult[]> {
		const source = () =>
			type === 'Pump' || type == null ? PumpService.getPdbInfo(ids) :
			PumpService.getPartPdbInfo(type, pumpId, ids, context);

		const data = await retry(source, 5000, 3);
		return data?.map(x => ({ id: x.id, value: x.DeliveryReadiness }));
	}
}
