<template>
<svg width="13px" height="12px" viewBox="0 0 13 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="UI-Kit---NEW" stroke="none" stroke-width="1" fill="currentColor" fill-rule="evenodd">
        <g id="Icons" transform="translate(-124.000000, -363.000000)" fill="currentColor">
            <g id="Shape" transform="translate(124.000000, 363.000000)">
                <path d="M6.229,1.5 C6.005,0.64 5.229,0 4.3,0 C3.371,0 2.594,0.64 2.371,1.5 L0,1.5 L0,2.5 L2.371,2.5 C2.595,3.36 3.371,4 4.3,4 C5.229,4 6.005,3.36 6.229,2.5 L13,2.5 L13,1.5 L6.229,1.5 Z M4.3,3 C3.749,3 3.3,2.551 3.3,2 C3.3,1.449 3.749,1 4.3,1 C4.851,1 5.3,1.449 5.3,2 C5.3,2.551 4.851,3 4.3,3 Z M9.54,4 C8.611,4 7.835,4.64 7.611,5.5 L0,5.5 L0,6.5 L7.611,6.5 C7.835,7.36 8.611,8 9.54,8 C10.469,8 11.245,7.36 11.469,6.5 L13,6.5 L13,5.5 L11.469,5.5 C11.245,4.64 10.469,4 9.54,4 Z M9.54,7 C8.98771525,7 8.54,6.55228475 8.54,6 C8.54,5.44771525 8.98771525,5 9.54,5 C10.0922847,5 10.54,5.44771525 10.54,6 C10.54,6.55228475 10.0922847,7 9.54,7 Z M12,9.5 L13,9.5 L13,10.5 L12,10.5 L12,9.5 Z M2,8 C0.897,8 0,8.897 0,10 C0,11.103 0.897,12 2,12 C2.929,12 3.705,11.36 3.929,10.5 L11,10.5 L11,9.5 L3.929,9.5 C3.705,8.64 2.929,8 2,8 Z M2,11 C1.46509303,10.9760723 1.04373254,10.5354419 1.04373254,10 C1.04373254,9.46455813 1.46509303,9.02392775 2,9 C2.53490697,9.02392775 2.95626746,9.46455813 2.95626746,10 C2.95626746,10.5354419 2.53490697,10.9760723 2,11 L2,11 Z"></path>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
    export default {};
</script>
