import { register } from 'register-service-worker';

/* tslint:disable:no-console */

const registrationInterval: number = Number(process.env.VUE_APP_REGISTRATION_INTERVAL) || 10;
if (process.env.NODE_ENV === 'production') {
	register(`./${process.env.BASE_URL}service-worker.js`, {
		ready() {
			console.log('App is being served from cache by a service worker. For more details, visit https://goo.gl/AFskqB');
		},
		registered(registration: ServiceWorkerRegistration) {
			console.log('Service worker has been registered; arming initial Vue3 check');
			setInterval(() => {
				checkForVue3Worker(registration);
			}, 1000 * 60 * registrationInterval);

			setTimeout(() => {
				checkForVue3Worker(registration);
			}, 5000);
		},
		cached() {
			console.log('Content has been cached for offline use.');
		},
		updatefound() {
			console.log('New content is downloading.');
		},
		updated(registration: ServiceWorkerRegistration) {
			console.log('New content is available; requesting refresh in 15 secs.');
			setTimeout(() => updateApp(registration), 15000);
		},
		offline() {
			console.log('No internet connection found. App is running in offline mode.');
		},
		error(error: any) {
			console.error('Error during service worker registration:', error);
		},
	});
}

async function checkForVue3Worker(registration: ServiceWorkerRegistration) {
	console.log('Checking for Vue3 service worker...');
	try {
		const res = await fetch('sw.js', { headers: { 'Cache-Control': 'no-cache', 'pragma': 'no-cache' } });
		if (res && res.status == 200) {
			console.log('Found Vue3 service worker!');
			updateApp(registration)
		}
		else
			console.log('No Vue3 version found; status ' + (res && res.status));
	} catch(err) {
		console.log('No Vue3 version found; exception thrown', err);
	};
}

async function updateApp(registration: ServiceWorkerRegistration) {
	console.debug('Unregistering worker...');
	try {
		await registration.unregister();
	} catch(err) {
		console.log('Failed to unregister service worker', err);
	}
	
	console.debug('Unregistered. Clearing caches...');
	if (typeof(caches) !== 'undefined') {
		try {
			const cacheNames = await caches.keys();
			await Promise.all(cacheNames.map((cacheName) => {
				console.debug(' - purging ' + cacheName);
				caches.delete(cacheName);
			}));
		} catch (err) {
			console.log('Failed to purge a cache entry', err);
		}
	}

	console.log('Sending content refresh event');
	document.dispatchEvent(new CustomEvent('swUpdated', { detail: registration }));
}
