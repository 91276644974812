<template>
	<v-dialog :value="data" :width="600 + (valueBags && valueBags.length * 55 || 0)">
		<v-card class="pa-4">
			<v-card-title v-if="mode === 'Update'"><h2>Updated conditions</h2></v-card-title>
			<v-card-title v-else><h2>Version conflict</h2></v-card-title>
			<v-card-text>
				<div v-if="mode === 'Update'">
					There have been updates of the product data or calculations this sizing was based upon.
				</div>
				<div v-else>
					The saved version of this sizing was changed by <b><Username :id="other && other.LastChangedBy" /></b>
					at {{ changed }}.
				</div>
				<template v-if="changedNames && changedNames.length && valueBags && valueBags.length">
					<h2 class="diff-section">Updated values</h2>
					<div style="max-width: 530; overflow-x: auto">
						<table class="mt-5 mb-2 pa-3 diff-table">
							<thead>
								<tr>
									<th></th>
									<th v-for="(valueBag, idx) of valueBags" :key="'th' + valueBag.sizingId + idx" v-text="subtitle(idx)" />
								</tr>
							</thead>
							<tbody>
								<tr>
									<td class="pr-2">
										<ParamList :params="valueBags[0]" :names="changedNames" mode="Diff" :titlesOnly="true" key="pnames" />
									</td>
									<td v-for="(valueBag, idx) of valueBags" :key="valueBag.sizingId + idx">
										<ParamList :params="valueBag" :names="changedNames" mode="Diff" :notitles="true" />
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</template>
				<div v-else-if="!valueBags || !valueBags.length">
					<i>No comparable sizings were supplied. Internal error.</i>
				</div>

				<div v-if="changedMsgs && changedMsgs.length">
					<h4 class="diff-section">Added/updated warnings and errors</h4>
					<WarningList class="ma-4 mt-2 mb-6" :msgs="changedMsgs" />
				</div>

				<div v-if="mode === 'Update'" class="mt-2">
					Click <b>OK</b> to use the updated data.
				</div>
				<div v-else class="mt-2">
					Click <b>OK</b> to accept the changes and load the latest version.
				</div>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn class="focus" text @click="$emit('ok')">{{ $t('common.ok') }}</v-btn>
				<v-btn v-if="mode === 'Update'" text @click="$emit('cancel')">{{ $t('common.cancel') }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style lang="scss" scoped>
	@import '@/sass/_variables.scss';

	table.diff-table {
		background-color: $selection-lighten-1;
		thead th {
			min-width: 120px;
			max-width: 200px;
			text-align: left;
			font-weight: normal;
			overflow-x: auto;
			padding-bottom: 4px;
		}
	} 

	.diff-section {
		border-bottom: 1px solid gray;
		margin-top: 12px;
	}
	
</style>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop } from 'vue-property-decorator';
	import Username from '@/components/Username.vue';
	import ParamList from '@/components/ParamList.vue';
	import WarningList from '@/components/WarningList.vue';
	import { localDateString } from '@/common/Tools';
	import { ParamBag } from '@/common/ParamBag';
	import { ConflictEvent } from '@/common/compareDocs';

	const enum Mode {
		Conflict,
		Update
	}

	@Component({
		components: {
			Username,
			ParamList,
			WarningList
		}
	})
	export default class ConflictDialog extends Vue {
		@Prop() public data: ConflictEvent;

		public get changed() {
			return localDateString(this.other?.LastChanged);
		}

		public get mode() {
			return this.data?.mode;
		}

		public get me() {
			return this.data?.me?.length && this.data.me[0] || null;
		}

		public get other() {
			return this.data?.other?.length && this.data.other[0] || null;
		}

		public get changedNames() {
			return this.data?.changedNames || [];
		}

		public get changedMsgs() {
			return this.data?.changedMsgs || [];
		}

		public get valueBags(): ParamBag[] {
			if (!this.me || !this.other || this.data.other.length !== this.data.me.length)
				return null;
			return this.data.me.map((x, idx) => new ParamBag([x, this.data.other[idx]], x.id));
		}

		public subtitle(idx: number) {
			if (this.data?.me?.length < 2)
				return;
			return this.data.me[idx].Subtitle;
		}
}
</script>
