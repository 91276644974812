import { render, staticRenderFns } from "./UserSelector.vue?vue&type=template&id=fae2be8e"
import script from "./UserSelector.vue?vue&type=script&lang=ts"
export * from "./UserSelector.vue?vue&type=script&lang=ts"
import style0 from "./UserSelector.vue?vue&type=style&index=0&id=fae2be8e&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
installComponents(component, {VAutocomplete})
