import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import App from './App.vue';
import router from './router';
import store, { SnackActions, AuthActions } from '@/store';
import './registerServiceWorker';
import ApiService from '@/services/api.service';
import TokenService from '@/services/token.service';
import InsightService from '@/services/insight.service';

import { ParamBag } from '@/common/ParamBag';
import '@/sass/main.scss';
import i18n from './i18n';

Vue.config.productionTip = false;

// Set the base URL of the API
ApiService.init(process.env.VUE_APP_BASEURL_ENDPOINT);

// If token exists set header
if (TokenService.getToken()) {
	ApiService.setHeader();
	store.dispatch(AuthActions.reload);
}

const instance = new Vue({
	router,
	store,
	vuetify,
	i18n,
	render: (h: any) => h(App)
});

// Register global custom directive `v-focus`
Vue.directive('focus', {
	inserted: (el: HTMLElement) => {
		setTimeout(() => {
			const inputs = el.getElementsByTagName('input');
			if (inputs?.length)
				inputs[0].focus();
			else
				el.focus();
		}, 100);
	}
});

function kbdClick(ev: KeyboardEvent) {
	if (ev?.target?.dispatchEvent && ['Space', 'Enter', ' '].includes(ev.key)) {
		let e: Event;
		if (typeof(Event) === 'function')
			e = new Event('click', { bubbles: true, cancelable: true });
		else {
			e = document.createEvent('Event');
			e.initEvent('click', true, true);
		}
		ev.target.dispatchEvent(e);
	}
}

function killSpace(ev: KeyboardEvent) {
	if (['Space', ' '].includes(ev.key))
		return false;
}

Vue.directive('keyboard-click', {
	bind: (el: HTMLElement) => {
		el?.addEventListener('keydown', kbdClick);
		el.onkeydown = killSpace;
	},
	unbind: (el: HTMLElement) => el?.removeEventListener('keydown', kbdClick)
});

// Setup application insights
InsightService.init({
	key: process.env.VUE_APP_CLIENT_INSTRUMENTATION_KEY,
	baseName: process.env.VUE_APP_CLIENT_INSTRUMENTATION_BASENAME
});

// @ts-ignore
let disaster = null;
ParamBag.initialize()
	.catch(reason => disaster = reason.toString())
	.finally(() => {
		instance.$mount('#app');
		// @ts-ignore
		if (disaster)
			// @ts-ignore
			store.dispatch(SnackActions.set, `Failed to contact server. Please try again later. (${disaster})`);
	});
