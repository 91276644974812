<template>
<svg class="collapse" viewBox="0 0 13 1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>CEE96195-766F-4921-BD06-581F51652238@1x</title>
    <g id="UI-Kit---NEW" stroke="none" stroke-width="1" fill="currentColor" fill-rule="evenodd">
        <g id="Icons" transform="translate(-124.000000, -446.000000)" fill="#currentColor" fill-rule="nonzero">
            <g id="UI-044-Collapse" transform="translate(124.000000, 446.000000)">
                <polygon id="Path" points="0 0 13 0 13 1 0 1"></polygon>
            </g>
        </g>
    </g>
</svg>
</template>
<style>
    svg.collapse {
        height: 16px;
        width: 16px;
    }
</style>

<script>
    export default {};
</script>